import React, { useCallback, useState, useEffect } from 'react';
import { useSwipeable, SwipeableHandlers } from 'react-swipeable';
import { Box, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LeftArrow from '@mui/icons-material/ArrowLeft';
import RightArrow from '@mui/icons-material/ArrowRight';
import {
	getWeekData,
	getYearWeekRange,
	getWeeks,
	getNumberOfWeeks,
	now,
	getDate,
} from './helpers';
import { DateTime } from 'luxon';
import {
	onSetCalendarMonthData,
	WeekRangeData,
} from '../../../actions/time-actions';
import useTranslation from '../../../hooks/useTranslation';
import { useDispatch } from 'react-redux';
import useLanguage from '../../../hooks/useLanguage';

const useStyles = makeStyles( ( theme ) => {
	const color = theme.palette.grey[ 500 ];
	return {
		menuContainer: {
			justifyContent: 'flex-start',
			alignItems: 'center',
			display: 'flex',
			maxHeight: theme.spacing( 3.75 ),
			overflow: 'hidden',
		},
		weekData: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			justifyItems: 'center',
			flexDirection: 'column',
			height: '3rem',
		},
		weekDataItem: {
			fontSize: theme.spacing( 1.75 ),
			fontWeight: 900,
			color: color,
			height: '1.5rem',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	};
} );
interface DesktopWeekSelectorProps {
	onWeekRangeSelected: ( weekRange: WeekRangeData ) => void;
	selectedWeekRange?: WeekRangeData;
}

const DesktopWeekSelector: React.FC<DesktopWeekSelectorProps> = ( {
	onWeekRangeSelected,
	selectedWeekRange,
} ) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [ selectedYear, setSelectedYear ] = useState<string>(
		DateTime.fromJSDate( new Date() ).year.toString()
	);

	const [ selectedWeek, setSelectedWeek ] = useState<any>(
		getWeekData( '1', selectedYear )
	);

	const weeks = getWeeks( getNumberOfWeeks( Number( selectedYear ) ) );

	useEffect( () => {
		if ( selectedWeekRange ) {
			const initialWeekData: DateTime = DateTime.fromJSDate(
				selectedWeekRange.from
			);
			const initialWeekEndData = DateTime.fromJSDate( selectedWeekRange.to );
			const initialYear =
				initialWeekData.weekNumber === 1
					? initialWeekEndData.weekYear.toString()
					: initialWeekData.weekYear.toString();

			const initialValue = getWeekData(
				initialWeekData.weekNumber.toString(),
				initialYear
			);

			setSelectedYear( initialYear );
			setSelectedWeek( initialValue );
		}
	}, [ selectedWeekRange ] );

	const onSelectWeek = useCallback(
		( weekValue ) => {
			const weekRange = getYearWeekRange( weekValue, selectedYear );
			dispatch(
				onSetCalendarMonthData(
					new Date( weekRange.from.getFullYear(), weekRange.from.getMonth() )
				)
			);
			setTimeout( () => {
				dispatch( onSetCalendarMonthData() );
			}, 100 );
			onWeekRangeSelected( weekRange );
		},
		[ onWeekRangeSelected, selectedYear, dispatch ]
	);

	const onSelectNextWeek = useCallback( () => {
		const nextWeek = Number( selectedWeek.value ) + 1;
		if (
			nextWeek > weeks.length &&
			Number( selectedYear ) + 1 <= now.getFullYear() + 1
		) {
			const weekRange = getYearWeekRange(
				'1',
				( Number( selectedYear ) + 1 ).toString()
			);
			onWeekRangeSelected( weekRange );
			setSelectedYear( ( Number( selectedYear ) + 1 ).toString() );
		} else if ( Number( selectedYear ) + 1 > now.getFullYear() + 1 ) {
			return;
		} else {
			onSelectWeek( nextWeek );
		}
	}, [
		selectedYear,
		onSelectWeek,
		setSelectedYear,
		onWeekRangeSelected,
		selectedWeek.value,
		weeks.length,
	] );

	const onSelectPreviousWeek = useCallback( () => {
		const nextWeek = Number( selectedWeek.value ) - 1;
		if ( nextWeek < 1 && Number( selectedYear ) - 1 >= now.getFullYear() - 1 ) {
			const numberOfWeeksString = getNumberOfWeeks(
				Number( selectedYear ) - 1
			).toString();
			const weekRange = getYearWeekRange(
				numberOfWeeksString,
				( Number( selectedYear ) - 1 ).toString()
			);
			onWeekRangeSelected( weekRange );
			setSelectedYear( ( Number( selectedYear ) - 1 ).toString() );
		} else if ( Number( selectedYear ) < now.getFullYear() - 1 ) {
			return;
		} else {
			onSelectWeek( nextWeek );
		}
	}, [
		selectedYear,
		onSelectWeek,
		setSelectedYear,
		onWeekRangeSelected,
		selectedWeek.value,
	] );

	const handlers: SwipeableHandlers = useSwipeable( {
		onSwipedLeft: onSelectNextWeek,
		onSwipedRight: onSelectPreviousWeek,
		preventDefaultTouchmoveEvent: true,
		trackMouse: true,
	} );

	const t = useTranslation();

	const localeLanguage = useLanguage();

	const week = DateTime.fromJSDate(
		getDate( selectedWeekRange, 'from' )
	).weekNumber;
	const year = DateTime.fromJSDate(
		getDate( selectedWeekRange, week === 1 ? 'to' : 'from' )
	).weekYear;
	const monthStart = DateTime.fromJSDate(
		getDate( selectedWeekRange, 'from' )
	).setLocale( localeLanguage ).monthLong;
	const monthEnd = DateTime.fromJSDate(
		getDate( selectedWeekRange, 'to' )
	).setLocale( localeLanguage ).monthLong;
	const startWeekDay = DateTime.fromJSDate(
		getDate( selectedWeekRange, 'from' )
	).day;
	const endWeekDay = DateTime.fromJSDate( getDate( selectedWeekRange, 'to' ) ).day;
	const headerString = `${ t( 'Week' ) } ${ week } ${ year }`;
	const weekRangeString = `${ startWeekDay } ${ monthStart } - ${ endWeekDay } ${ monthEnd }`;

	return (
		<Box { ...handlers } className={ classes.menuContainer }>
			<IconButton size="small" onClick={ onSelectPreviousWeek } >
				<LeftArrow style={ { padding: 0, margin: 0 } } />
			</IconButton>
			<Box className={ classes.weekData }>
				<Typography
					className={ classes.weekDataItem }
				>{ `${ headerString } / ${ weekRangeString }` }</Typography>
			</Box>
			<IconButton size="small" onClick={ onSelectNextWeek } >
				<RightArrow />
			</IconButton>
		</Box>
	);
};

export default DesktopWeekSelector;
