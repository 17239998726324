import React from 'react';

import { Box, Typography, useTheme, CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { DateTime } from 'luxon';

import useExpenseClaimsData from '../../../hooks/useExpenseClaimsData';
import useTranslation from '../../../hooks/useTranslation';
import ExpenseClaimFileViewDesktop from '../ExpenseClaims/ExpenseClaimFileViewDesktop';
import { expenseClaimStatuses } from '../ExpenseClaims/expenses-utils';

export interface ExpenseClaimDetailsDesktopProps {
	id?: string;
}

const useStyles = makeStyles( ( theme ) => ( {
	container: {
		padding: theme.spacing( 0, 2 ),
	},
	delete: {
		color: theme.palette.error.dark,
		cursor: 'pointer',
	},
	detailsProp: {
		padding: theme.spacing( 1, 2 ),
		display: 'flex',
		alignItems: 'flex-start',
	},
	status: {
		padding: theme.spacing( 2, 2, 0, 2 ),
		display: 'flex',
		alignItems: 'center',
	},
	tab: {
		textAlign: 'center',
		backgroundColor: theme.palette.custom.paper,
		color: theme.palette.custom.gray,
		padding: theme.spacing( 2, 2, 1.75, 2 ),
		borderWidth: theme.spacing( 0, 0, 0.25, 0 ),
		borderStyle: 'solid',
		borderColor: theme.palette.custom.paper,
		textTransform: 'uppercase',
	},
	selectedTab: {
		fontWeight: 900,
		color: theme.palette.primary.dark,
		borderBottomColor: theme.palette.primary.dark,
	},
	value: {
		textOverflow: 'ellipsis',
		overflowX: 'auto',
		overflowY: 'hidden',
		textAlign: 'left',
	},
	statusValue: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.contrastText,
		fontWeight: 900,
		padding: theme.spacing( 1 ),
		borderRadius: theme.spacing( 0.25 ),
		display: 'inline-block',
	},
	option: {
		padding: theme.spacing( 1 ),
		margin: theme.spacing( 2, 1 ),
		cursor: 'pointer',
		fontWeight: 700,
		color: theme.palette.primary.dark,
	},
	statusText: {
		display: 'flex',
		justifyContent: 'center',
		fontWeight: 900,
		padding: theme.spacing( 0.5, 0 ),
		color: theme.palette.primary.contrastText,
		borderRadius: theme.spacing( 1 ),
		width: '80%',
	},
} ) );

const ExpenseClaimDetailsDesktop: React.FC<ExpenseClaimDetailsDesktopProps> = ( {
	id,
} ) => {
	const classes = useStyles();
	const theme = useTheme();

	const {
		selectedExpenseClaim: expenseClaim,
		approvalRoute,
		isLoadingApprovalRoute,
		documents,
		isLoadingDocuments,
	} = useExpenseClaimsData( id );

	const t = useTranslation();

	return (
		<Box className={ classes.container }>
			<Box className={ classes.detailsProp }>
				<Box flex="1">
					<Typography
						className={ classes.statusText }
						style={ {
							backgroundColor:
								theme.palette.custom[
								expenseClaimStatuses[ expenseClaim?.status.id || '0' ]
								],
						} }
					>{ `Amount ${ expenseClaim?.status.desc }` }</Typography>
				</Box>
				<Box flex="1" textAlign="right">
					<Typography className={ classes.value }>
						{ `€${ expenseClaim?.salesAmount?.toFixed( 2 ) }` }
					</Typography>
				</Box>
			</Box>
			{ [
				{
					label: t( 'Date' ),
					value: DateTime.fromJSDate(
						new Date( expenseClaim?.date || '' )
					).toISODate(),
				},
				{ label: t( 'Code' ), value: expenseClaim?.descriptionExpenseClaimCode },
				{
					label: t( 'Description' ),
					value: expenseClaim?.description || '-',
				},
				{
					label: t( 'Detailed description' ),
					value: expenseClaim?.detailedDescription || '-',
				},
				{ label: t( 'Approver' ), value: approvalRoute?.employeeName },
			].map( ( element ) => (
				<Box className={ classes.detailsProp } key={ element.label }>
					<Box flex="1" display="flex" alignItems="center">
						<Typography>{ element.label }</Typography>
						{ element.label === 'Approver' && isLoadingApprovalRoute && (
							<Box
								width={ 25 }
								height={ 25 }
								display="flex"
								justifyContent="center"
								alignItems="center"
								paddingLeft={ 1 }
							>
								<CircularProgress size={ 20 } />
							</Box>
						) }
					</Box>
					<Box flex="1" overflow="hidden">
						{ element.label === 'Approver' && isLoadingApprovalRoute ? null : (
							<Typography className={ classes.value }>{ element.value }</Typography>
						) }
					</Box>
				</Box>
			) ) }
			<Box className={ classes.detailsProp }>
				<Box width="50%" display="flex" alignItems="center">
					<Typography>{ t( 'Attachments' ) }</Typography>
					{ isLoadingDocuments && (
						<Box
							width={ 25 }
							height={ 25 }
							display="flex"
							justifyContent="center"
							alignItems="center"
							paddingLeft={ 1 }
						>
							<CircularProgress size={ 20 } />
						</Box>
					) }
				</Box>
				<Box className={ classes.value }>
					{ documents.map( ( document ) => (
						<ExpenseClaimFileViewDesktop
							key={ document.id }
							documentId={ document.id }
							document={ document }
							employeeCode={ expenseClaim?.employeeCode }
							expenseClaimId={ expenseClaim?.id }
						/>
					) ) }
				</Box>
			</Box>
		</Box>
	);
};

export default ExpenseClaimDetailsDesktop;
