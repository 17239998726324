import { DateTime } from 'luxon';
import { getDateOfISOWeek, getWeekRange } from '../../../utils/date';
import { WeekRangeData } from '../../../actions/time-actions';

export const now = new Date();
export const currentYear = now.getFullYear();

export const getWeeks = ( weeks: number ) =>
	Array.from( Array( weeks ).keys() ).map( ( x ) => ( x + 1 ).toString() );
export const years: string[] = ( () => {
	const result = [ currentYear - 1, currentYear, currentYear + 1 ].map( ( year ) =>
		year.toString()
	);
	return result;
} )();

export const getYearWeekRange = ( weekNumber: string, selectedYear: string ) => {
	const date = getDateOfISOWeek( +weekNumber, +selectedYear );
	const weekRange: WeekRangeData = getWeekRange( date );
	return weekRange;
};

export const checkLeapYear = ( year: number ) =>
	( year % 4 === 0 && year % 100 !== 0 ) || year % 400 === 0;

export const getNumberOfWeeks = ( year: number ) => {
	const lastDayWeekNumber = DateTime.fromObject( { year, month: 12, day: 31 } )
		.weekNumber;

	const numberOfWeeks = lastDayWeekNumber === 1 ? 52 : lastDayWeekNumber;
	return numberOfWeeks;
};

export const getWeekData = ( week: string, year: string ) => {
	const range = getYearWeekRange( week, year );
	const monthStart = DateTime.fromJSDate( range.from ).monthLong;
	const monthEnd = DateTime.fromJSDate( range.to ).monthLong;
	const startWeekDay = DateTime.fromJSDate( range.from ).day;
	const endWeekDay = DateTime.fromJSDate( range.to ).day;
	const value = week;
	const label = `(${ week }) ${ startWeekDay } ${ monthStart } - ${ endWeekDay } ${ monthEnd }`;
	return { value, label };
};

export const getDate = (
	weekRange: WeekRangeData | undefined,
	weekRangeProp: 'from' | 'to'
) => ( weekRange ? weekRange[ weekRangeProp ] : getWeekRange( now )[ weekRangeProp ] );
