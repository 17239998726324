import React, { useState, useCallback, useEffect } from 'react';

import clsx from 'clsx';
import { Select, FormLabel, FormControl, MenuItem, Box, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import useTranslation from '../../hooks/useTranslation';

const useStyles = makeStyles( ( theme: Theme ) =>
	createStyles( {
		formControl: {
			display: 'flex',
			flexDirection: 'row',
		},
		select: {
			flex: 1,
			margin: theme.spacing( 2, 1, 0 ),
			maxWidth: `calc( 100% - 10em )`,
			paddingLeft: theme.spacing( 1 ),
			color: theme.palette.text.primary,
		},
		readOnlySelect: {
			background: theme.palette.custom.lightBlueGray,
		},

		label: {
			minWidth: theme.spacing( 14 ),
			maxWidth: theme.spacing( 17.5 ),
			color: theme.palette.text.primary,
		},
		icon: {
			color: theme.palette.text.secondary,
		},
		container: {
			margin: theme.spacing( 0, 2 ),
		},
		formLabelContainer: {
			margin: theme.spacing( 0, 2, 1, 0 ),
			display: 'flex',
			alignItems: 'flex-end',
		},
		headerSelect: {
			fontWeight: 900,
			maxWidth: `calc( 100% )`,
			fontSize: theme.spacing( 2.5 ),
			padding: theme.spacing( 0, 0.5 ),
			margin: 0,
		},
		formLabelHeaderContainer: {
			display: "none",
		},
		selectItem: {
			whiteSpace: 'break-spaces',
		},
	} )
);

export interface DropdownProps {
	label: string;
	options: Array<string>;
	selectedValue: string;
	onValueChange: ( value: string, label: string ) => void;
	autoExpand: boolean;
	isHeader?: boolean;
	labelClass?: string;
	selectClass?: string;
	selectValueClass?: string;
}

const Dropdown: React.FC<DropdownProps> = ( {
	label,
	options,
	selectedValue,
	onValueChange,
	autoExpand,
	isHeader,
	labelClass,
	selectClass,
	selectValueClass,
} ) => {
	const [ isOpen, setIsOpen ] = useState<boolean>( autoExpand );
	const classes = useStyles();
	const t = useTranslation();

	const onSelectChange = useCallback<any>(
		( event: any ) => {
			onValueChange( event?.target?.value as string, label );
			setIsOpen( false );
		},
		[ onValueChange, setIsOpen, label ]
	);

	const toggleOpen = useCallback( () => {
		setIsOpen( !isOpen );
	}, [ isOpen, setIsOpen ] );

	useEffect( () => {
		// Automatic selection of the value when there is only 1 option.
		if ( options.length === 1 ) {
			if ( isOpen ) {
				setIsOpen( false );
			}
			if ( selectedValue !== options[ 0 ] ) {
				onValueChange( options[ 0 ], label );
			}
		}
	}, [ options, onValueChange, isOpen, setIsOpen, selectedValue, label ] );

	const selectClassName = isHeader
		? clsx( classes.select, classes.headerSelect )
		: options.length === 1
			? clsx( classes.select, classes.readOnlySelect )
			: classes.select;

	const formLabelContainerClassName = isHeader
		? clsx( classes.formLabelContainer, classes.formLabelHeaderContainer )
		: classes.formLabelContainer;

	return (
		<Box className={ classes.container }>
			<FormControl
				fullWidth={ true }
				size="medium"
				className={ classes.formControl }
			>
				<Box className={ labelClass || formLabelContainerClassName }>
					<FormLabel
						onClick={ toggleOpen }
						focused={ false }
						className={ classes.label }
						classes={ { root: classes.label } }
					>
						{ t( label ) }
					</FormLabel>
				</Box>
				<Select
					readOnly={ options.length === 1 }
					className={ selectValueClass || selectClass || selectClassName }
					classes={ {
						icon: classes.icon,

					} }
					native={ false }
					open={ isOpen }
					onOpen={ () => setIsOpen( true ) }
					onClose={ () => setIsOpen( false ) }
					value={ ( selectedValue ) }
					onChange={ onSelectChange }
					variant='standard'
				>
					{ options.map( ( value: string ) => (
						<MenuItem key={ value } value={ value } className={ classes.selectItem } classes={ { root: classes.selectItem, } }>
							{ t( value ) }
						</MenuItem>
					) ) }
				</Select>
			</FormControl>
		</Box>
	);
};

export default Dropdown;
