import React, { useCallback } from 'react';

import { Box, Typography, Badge, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Euro, AttachFile as AttachmentIcon } from '@mui/icons-material';
import clsx from 'clsx';
import { expenseClaimStatuses } from './expenses-utils';
import useExpenseClaimsData from '../../../hooks/useExpenseClaimsData';
import { useDispatch } from 'react-redux';
import { getExpenseClaimDetails } from '../../../actions/api-actions';
import OverlayLoader from '../../OverlayLoader';
import useTranslation from '../../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	cardContainer: {
		borderStyle: 'solid',
		display: 'flex',
		padding: theme.spacing( 2, 1, 0 ),
		cursor: 'pointer',
		borderColor: theme.palette.custom.paper,
		flexDirection: 'column',
		borderWidth: theme.spacing( 0.125 ),
		borderTopWidth: 0,
	},
	date: {
		fontSize: theme.spacing( 1.5 ),
	},
	desc: {
		fontSize: theme.spacing( 1.5 ),
		padding: theme.spacing( 0, 0.5, 0, 0 ),
	},
	selectedItem: {
		backgroundColor: theme.palette.custom.paper,
		borderLeftColor: theme.palette.primary.main,
		borderLeftWidth: theme.spacing( 0.25 ),
	},
	currencyContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '25%',
	},
	disabledAction: {
		opacity: '0.5',
	},
	status: {
		textAlign: 'center',
		padding: theme.spacing( 0.75, 1.5 ),
		color: theme.palette.primary.contrastText,
		fontSize: theme.spacing( 1.5 ),
		fontWeight: 900,
		alignSelf: 'flex-end',
	},
	infoContainer: {
		flex: '1',
		display: 'flex',
		justifyContent: 'space-between',
	},
	sectionContainer: {
		display: 'flex',
		padding: theme.spacing( 0, 0, 1 ),
	},
	disabled: {
		opacity: '0.5',
	},
	amount: {
		fontWeight: 900,
	},
	currency: {
		fontSize: theme.spacing( 1.75 ),
		fontWeight: 900,
	},
	badgeCircle: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		zIndex: 0,
	},
	badge: {
		margin: theme.spacing( -1, 1, 0, 0 ),
	},
} ) );

export interface ExpenseClaimTemplateCardDesktopProps {
	amount: string;
	status: string;
	id: string;
	date: string;
	codeDescription: string;
	description: string;
	onSelect: ( id: string ) => void;
	className?: string;
	disabled?: boolean;
	statusId: string;
	isSelected: boolean;
	employeeCode: string;
}

const ExpenseClaimTemplateCardDesktop: React.FC<ExpenseClaimTemplateCardDesktopProps> =
	( {
		amount,
		date,
		id,
		onSelect,
		disabled = false,
		description,
		status,
		codeDescription,
		statusId,
		isSelected,
		employeeCode,
	} ) => {
		const classes = useStyles();
		const theme = useTheme();
		const dispatch = useDispatch();

		const {
			documents,
			isLoadingApprovalRoute,
			isLoadingProgress,
			isLoadingExpenseClaim,
		} = useExpenseClaimsData( id );

		const numberOfFiles = documents.length;

		const onClickHandler = useCallback( () => {
			if (
				!disabled &&
				!isLoadingApprovalRoute &&
				!isLoadingProgress
			) {
				dispatch( getExpenseClaimDetails( { employeeCode, id } ) );
			}
			onSelect( id );
		}, [
			onSelect,
			disabled,
			id,
			isLoadingApprovalRoute,
			isLoadingProgress,
			employeeCode,
			dispatch,
		] );

		const disabledClass = disabled
			? clsx( classes.cardContainer, classes.disabled )
			: classes.cardContainer;

		const containerClass = isSelected
			? clsx( disabledClass, classes.selectedItem )
			: disabledClass;

		const t = useTranslation();

		return (
			<Box
				className={ containerClass }
				onClick={ onClickHandler }
				position="relative"
			>
				<OverlayLoader
					loadingCondition={ isLoadingExpenseClaim }
					message={ t( 'Action proceed' ) }
				/>
				<Box className={ classes.sectionContainer }>
					<Box className={ classes.currencyContainer }>
						<Euro className={ classes.currency } />
						<Typography className={ classes.amount }>{ amount }</Typography>
					</Box>
					<Box className={ classes.infoContainer }>
						<Typography>{ codeDescription }</Typography>
						<Box>
							{ numberOfFiles ? (
								<Badge
									badgeContent={ numberOfFiles }
									classes={ {
										badge: classes.badgeCircle,
										root: classes.badge,
									} }
								>
									<AttachmentIcon />
								</Badge>
							) : null }
						</Box>
					</Box>
				</Box>
				<Box className={ classes.sectionContainer }>
					<Box width="25%">
						<Typography className={ classes.date }>{ date }</Typography>
					</Box>
					<Box className={ classes.infoContainer }>
						<Typography className={ classes.desc }>
							{ description || '-' }
						</Typography>
						<Typography
							className={ classes.status }
							style={ {
								backgroundColor:
									theme.palette.custom[ expenseClaimStatuses[ statusId ] ],
							} }
						>
							{ status }
						</Typography>
					</Box>
				</Box>
			</Box>
		);
	};
export default ExpenseClaimTemplateCardDesktop;
