import { Action, ActionType } from "../types/actions";
import { ExpenseClaim, ExpenseClaimsData } from "../types/api/expenseClaims";

export interface ExpenseClaimsState {
	expenseClaimsData: ExpenseClaimsData | null;
	isLoading: boolean;
	error: string;
}

export const expenseClaimsReducer = (
	state: ExpenseClaimsState = {
		expenseClaimsData: null,
		isLoading: false,
		error: "",
	},
	action: Action
): ExpenseClaimsState => {
	switch ( action.type ) {
		case ActionType.GET_EXPENSE_CLAIM_SUCCEEDED: {
			const expenseClaim: ExpenseClaim = action.expenseClaim;
			const expenseClaims =
				state.expenseClaimsData?.expenseClaims.map( ( element ) => {
					if ( expenseClaim.id === element.id ) {
						return { ...expenseClaim, isLoading: false };
					}

					return element;
				} ) || [];
			if ( !expenseClaims.find( ( element ) => element.id === expenseClaim.id ) ) {
				expenseClaims.unshift( expenseClaim );
			}

			const expenseClaimsData = { ...state.expenseClaimsData, expenseClaims };

			return {
				...state,
				expenseClaimsData,
				isLoading: false,
			};
		}
		case ActionType.GET_EXPENSE_CLAIMS_STARTED:
		case ActionType.CREATE_EXPENSE_CLAIM_STARTED: {
			return {
				...state,
				error: "",
				isLoading: true,
			};
		}
		case ActionType.RELEASE_EXPENSE_CLAIM_STARTED:
		case ActionType.GET_EXPENSE_CLAIM_STARTED:
		case ActionType.DELETE_EXPENSE_CLAIM_STARTED: {
			const id = action.id;
			const expenseClaims =
				state.expenseClaimsData?.expenseClaims?.map( ( element ) => {
					if ( id === element.id ) {
						return { ...element, isLoading: true };
					}
					return element;
				} ) || [];

			const expenseClaimsData = { ...state.expenseClaimsData, expenseClaims };

			return {
				...state,
				expenseClaimsData,
			};
		}
		case ActionType.GET_EXPENSE_CLAIMS_SUCCEEDED: {
			const { expenseClaimsData } = action;

			return {
				...state,
				expenseClaimsData,
				isLoading: false,
				error: "",
			};
		}
		case ActionType.DELETE_EXPENSE_CLAIM_SUCCEEDED: {
			const { id } = action;
			const expenseClaims =
				state.expenseClaimsData?.expenseClaims.filter(
					( element ) => element.id !== id
				) || [];

			const expenseClaimsData = { ...state.expenseClaimsData, expenseClaims };

			return {
				...state,
				expenseClaimsData,
				isLoading: false,
				error: "",
			};
		}
		case ActionType.RELEASE_EXPENSE_CLAIM_FAILED:
		case ActionType.DELETE_EXPENSE_CLAIM_FAILED: {
			const id = action.id;
			const expenseClaims =
				state.expenseClaimsData?.expenseClaims?.map( ( element ) => {
					if ( id === element.id ) {
						return { ...element, isLoading: false };
					}
					return element;
				} ) || [];

			const expenseClaimsData = { ...state.expenseClaimsData, expenseClaims };

			return {
				...state,
				expenseClaimsData,
				isLoading: false,
			};
		}
		case ActionType.CREATE_EXPENSE_CLAIM_FAILED: {
			const { error } = action;
			return {
				...state,
				isLoading: false,
				error,
			};
		}
		case ActionType.DISMISS_EXPENSE_CLAIM_ERROR: {
			return {
				...state,
				error: "",
			};
		}
		case ActionType.GET_EXPENSE_CLAIMS_FAILED:
		default:
			return state;
	}
};
