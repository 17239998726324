import { useState } from 'react';

export interface HomeMenuActions {
	isAddingHours: boolean;
	setIsAddingHours: ( value: boolean ) => void;
}

const useDesktopHomeMenuActions = (): HomeMenuActions => {
	const [ isAddingHours, setIsAddingHours ] = useState<boolean>( false );

	return {
		isAddingHours,
		setIsAddingHours,
	};
};

export default useDesktopHomeMenuActions;
