import { Action, ActionType } from "../types/actions";

import {
  ExpenseClaim,
  ExpenseClaimDocument,
  ExpenseClaimsAttachments,
} from "../types/api/expenseClaims";

export interface ExpenseClaimAttachmentsState {
  attachments: Array<ExpenseClaimsAttachments>;
  isLoading: boolean;
  error: string;
}

export const expenseClaimAttachmentsReducer = (
  state: ExpenseClaimAttachmentsState = {
    attachments: [],
    isLoading: false,
    error: "",
  },
  action: Action
): ExpenseClaimAttachmentsState => {
  switch (action.type) {
    case ActionType.GET_EXPENSE_CLAIMS_SUCCEEDED: {
      const expenseClaims: Array<ExpenseClaim> =
        action.expenseClaimsData.expenseClaims;
      const attachments: Array<ExpenseClaimsAttachments> = expenseClaims.map(
        (expenseClaim) => {
          const currentAttachmentIndex = state.attachments.findIndex(
            (item) => item.id === expenseClaim.id
          );
          if (currentAttachmentIndex !== -1) {
            return {
              ...state.attachments[currentAttachmentIndex],
              isLoading: true,
            };
          }
          return {
            isLoading: true,
            documents: [],
            upToDate: false,
            id: expenseClaim.id,
          };
        }
      );

      return { ...state, attachments };
    }
    case ActionType.GET_EXPENSE_CLAIM_DOCUMENTS_SUCCEEDED: {
      const {
        documents,
        expenseClaimId,
      }: { documents: Array<ExpenseClaimDocument>; expenseClaimId: string } =
        action;

      const newAttachment = {
        id: expenseClaimId,
        isLoading: false,
        upToDate: false,
        documents,
      };
      const attachmentIndex = state.attachments.findIndex(
        (a) => a.id === expenseClaimId
      );
      const attachments = [...state.attachments];

      if (attachmentIndex === -1) {
        attachments.push(newAttachment);
      } else {
        attachments[attachmentIndex] = newAttachment;
      }

      return {
        ...state,
        attachments,
        isLoading: false,
      };
    }
    case ActionType.GET_EXPENSE_CLAIM_DOCUMENTS_FAILED: {
      const { expenseClaimId } = action;
      const currentAttachments = [...state.attachments].find(
        (item) => item.id === expenseClaimId
      );
      if (currentAttachments) {
        currentAttachments.isLoading = false;
        const attachments = [...state.attachments].map((item) => {
          if (item.id === expenseClaimId) {
            return currentAttachments;
          }
          return item;
        });
        return { ...state, attachments };
      }
      return state;
    }
    //
    case ActionType.GET_EXPENSE_CLAIM_DOCUMENT_SUCCEEDED: {
      const { expenseClaimId, documentId, files } = action;
      const currentAttachments = [...state.attachments].find(
        (item) => item.id === expenseClaimId
      );
      if (currentAttachments) {
        currentAttachments.documents.forEach((document) => {
          if (document.id === documentId) {
            document.files = files;
            document.isLoaded = true;
          }
        });
        const attachments = [...state.attachments].map((item) => {
          if (item.id === expenseClaimId) {
            return currentAttachments;
          }
          return item;
        });
        return { ...state, attachments };
      }
      return state;
    }
    case ActionType.DELETE_EXPENSE_CLAIM_DOCUMENT_SUCCEEDED: {
      const { expenseClaimId, documentId } = action;
      const currentAttachments = [...state.attachments].find(
        (item) => item.id === expenseClaimId
      );
      if (currentAttachments) {
        const newDocuments = currentAttachments.documents.filter(
          (document) => document.id !== documentId
        );
        currentAttachments.documents = newDocuments;
        const attachments = [...state.attachments].map((item) => {
          if (item.id === expenseClaimId) {
            return currentAttachments;
          }

          return item;
        });

        return { ...state, attachments };
      }
      return state;
    }
    case ActionType.DELETE_EXPENSE_CLAIM_DOCUMENT_STARTED:
    case ActionType.GET_EXPENSE_CLAIM_DOCUMENT_STARTED: {
      const { expenseClaimId, documentId } = action;
      const currentAttachments = [...state.attachments].find(
        (item) => item.id === expenseClaimId
      );
      if (currentAttachments) {
        currentAttachments.documents.forEach((document) => {
          if (document.id === documentId) {
            document.isLoaded = false;
            document.failed = false;
          }
        });
        const attachments = [...state.attachments].map((item) => {
          if (item.id === expenseClaimId) {
            return currentAttachments;
          }
          return item;
        });

        return { ...state, attachments };
      }

      return state;
    }
    case ActionType.DELETE_EXPENSE_CLAIM_SUCCEEDED: {
      const { id } = action;
      const attachments = state.attachments.filter(
        (attachment) => attachment.id !== id
      );
      return { ...state, attachments };
    }
    case ActionType.GET_EXPENSE_CLAIM_DOCUMENT_FAILED:
    case ActionType.DELETE_EXPENSE_CLAIM_DOCUMENT_FAILED: {
      const { expenseClaimId, documentId } = action;
      const currentAttachments = [...state.attachments].find(
        (item) => item.id === expenseClaimId
      );
      if (currentAttachments) {
        currentAttachments.documents.forEach((document) => {
          if (document.id === documentId) {
            document.isLoaded = false;
            document.failed = true;
          }
        });
        const attachments = [...state.attachments].map((item) => {
          if (item.id === expenseClaimId) {
            return currentAttachments;
          }

          return item;
        });

        return { ...state, attachments };
      }
      return state;
    }
    default:
      return state;
  }
};
