import React from 'react';

import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useTranslation from '../../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	button: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		borderTopWidth: theme.spacing( 0.25 ),
		borderTopColor: theme.palette.custom.lightGray,
		borderRadius: 0,
		borderTopStyle: 'solid',
		boxSizing: 'border-box',
		padding: theme.spacing( 1.5 ),
		fontWeight: 900,
		'&:hover': {
			backgroundColor: theme.palette.background.paper,
		},
	},
} ) );

const CreateDestinationButton = ( {
	onClick,
	actionText,
}: {
	onClick: ( e: any ) => void;
	actionText: string;
} ) => {
	const classes = useStyles();
	const t = useTranslation();

	return (
		<Button
			color="primary"
			aria-label="add"
			className={ classes.button }
			onClick={ onClick }
		>
			{t( actionText ) }
		</Button>
	);
};

export default CreateDestinationButton;
