import React, { useState, useCallback } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import ReleaseTimesheetDropdown from './ReleaseTimesheetDropdown';
import useTimesheetReleaseOptions from '../../../hooks/useTimesheetReleaseOptions';
import { ReleaseTimeSheetArguments } from '../../../types/api/timesheets';
import { useDispatch, useSelector } from 'react-redux';
import { releaseTimesheet } from '../../../actions/api-actions';
import { getOvertimeCompensationOptionsByType } from './helpers';
import { AppState } from '../../../reducers';
import { clearReleaseTimesheetError } from '../../../actions/ui-actions';
import ReleaseTimesheetErrorMessage from './ReleaseTimesheetErrorMessage';
import ReleaseTimesheetSuccessMessage from './ReleaseTimesheetSuccessMessage';
import { OvertimeOptions } from '../Minimal/Minimal';
import useTranslation from '../../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	timesheetRow: {
		display: 'flex',
		padding: theme.spacing( 1, 2 ),
	},
	label: {
		display: 'flex',
		alignItems: 'center',
		color: theme.palette.primary.main,
		flex: 1,
		minWidth: theme.spacing( 16 ),
		maxWidth: theme.spacing( 17.5 ),
	},
	value: {
		overflow: 'hidden',
		flex: 1,
		margin: theme.spacing( 0, 2.8, 0 ),
		maxWidth: `calc( 100% - 10em )`,
		paddingLeft: theme.spacing( 1 ),
	},
	button: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		borderTopWidth: theme.spacing( 0.25 ),
		borderTopColor: theme.palette.custom.lightGray,
		borderRadius: 0,
		borderTopStyle: 'solid',
		boxSizing: 'border-box',
		padding: theme.spacing( 1.5 ),
		paddingBottom: theme.spacing( 2.5 ),
		fontWeight: 900,
		'&:hover': {
			backgroundColor: theme.palette.background.paper,
		},
	},
	loadingMessage: {
		position: 'absolute',
		padding: theme.spacing( 2 ),
		width: '100%',
		height: '100%',
		top: 0,
		left: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.66)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 1,
	},
} ) );

interface ReleaseTimesheetProps {
	date: string;
	onReleaseSuccess: () => void;
	onReleaseFailed: () => void;
	overtimeOptions: OvertimeOptions[];
}

const ReleaseTimesheetForm: React.FC<ReleaseTimesheetProps> = ( {
	date,
	onReleaseSuccess,
	overtimeOptions,
	onReleaseFailed,
} ) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [ selectedValues, setSelectedValues ] = useState<string[][]>( [] );
	const [ isReleaseStarted, setIsReleaseStarted ] = useState<boolean>( false );

	const [ comments, setComments ] = useState<string[]>( [] );

	const {
		isLoading: isLoadingDropdownsOptionsData,
		overtimeAccountsOptions,
	} = useTimesheetReleaseOptions( overtimeOptions );

	const selectedWeekRange = useSelector(
		( state: AppState ) => state.timeState.weekRange
	);
	const { isLoading: isReleasingTimesheets, error } = useSelector(
		( state: AppState ) => state.releasedTimesheetsState
	);

	const onChangeOvertimeCode = useCallback(
		( value: string, optionIndex: number, accountIndex: number ) => {
			const newValues = [ ...selectedValues ];
			let currentAccountValues: string[] = newValues[ accountIndex ];

			if ( !currentAccountValues ) {
				currentAccountValues = [];
			}
			const values: string[] = [ ...currentAccountValues ];
			values[ optionIndex ] = value;

			newValues[ accountIndex ] = values;
			setSelectedValues( newValues );
		},
		[ selectedValues, setSelectedValues ]
	);

	const onReleaseAction = useCallback( () => {
		setIsReleaseStarted( true );
		const overtimeRequestData: ReleaseTimeSheetArguments = {
			date,
			releaseTimesheets: overtimeAccountsOptions.map(
				( overtimeAccountOptions, accountIndex ) => {
					let selectedAccountValues = selectedValues[ accountIndex ];
					if ( !selectedAccountValues ) {
						selectedAccountValues = [];
					}

					let comment = comments[ accountIndex ];
					if ( !comment ) {
						comment = '';
					}

					return {
						employeeCode: overtimeAccountOptions.employeeCode,
						overtimeMonFri: getOvertimeCompensationOptionsByType(
							overtimeAccountOptions.accountOptions,
							0,
							selectedAccountValues
						),
						overtimeSat: getOvertimeCompensationOptionsByType(
							overtimeAccountOptions.accountOptions,
							1,
							selectedAccountValues
						),
						overtimeSun: getOvertimeCompensationOptionsByType(
							overtimeAccountOptions.accountOptions,
							2,
							selectedAccountValues
						),
						comment,
					};
				}
			),
		};

		dispatch( releaseTimesheet( overtimeRequestData ) );
	}, [ comments, selectedValues, date, dispatch, overtimeAccountsOptions ] );

	const dismissError = useCallback( () => {
		dispatch( clearReleaseTimesheetError() );
		onReleaseFailed();
	}, [ dispatch, onReleaseFailed ] );

	const t = useTranslation();

	if ( !selectedWeekRange ) {
		// This should never occur, but well...
		return <Typography>{ 'No week range selected...' }</Typography>;
	}

	if ( error ) {
		return (
			<ReleaseTimesheetErrorMessage
				error={ error }
				onDismissError={ dismissError }
			/>
		);
	}

	if ( !isReleasingTimesheets && isReleaseStarted ) {
		return (
			<ReleaseTimesheetSuccessMessage onSuccessDismiss={ onReleaseSuccess } />
		);
	}

	return !isLoadingDropdownsOptionsData ? (
		<>
			{ isReleasingTimesheets ? (
				<Box className={ classes.loadingMessage }>
					<Typography>{ 'Releasing your timesheets...' }</Typography>
				</Box>
			) : null }
			<Box m={ 2 } display="flex" flexDirection="column" flex="1">
				{ overtimeAccountsOptions.map( ( overtimeAccountOptions, accountIndex ) => {
					const overtimeOptions = overtimeAccountOptions.accountOptions;

					let selectedAccountValues = selectedValues[ accountIndex ];
					if ( !selectedAccountValues ) {
						selectedAccountValues = [];
					}

					return (
						<Box
							key={ overtimeAccountOptions.employeeCode }
							flex="1"
							display="flex"
							flexDirection="column"
						>
							<Box p={ 2 }>
								<Typography variant="h3">
									{ overtimeAccountOptions.companyName }
								</Typography>
							</Box>
							{ overtimeOptions.map( ( overtimeOption, optionIndex ) => (
								<ReleaseTimesheetDropdown
									key={ overtimeOption.label }
									selectedValues={ selectedAccountValues }
									onChangeOvertimeCode={ onChangeOvertimeCode }
									userAccountIndex={ accountIndex }
									overtimeOption={ overtimeOption }
									optionIndex={ optionIndex }
								/>
							) ) }
							<Box className={ classes.timesheetRow }>
								<Box className={ classes.label }>
									<Typography>{ t( 'Comment' ) }</Typography>
								</Box>
								<TextField
									className={ classes.value }
									multiline={ true }
									placeholder={ t( 'comment here' ) }
									name="comment"
									onChange={ ( e ) => {
										const newComments = [ ...comments ];
										newComments[ accountIndex ] = e.target.value;
										setComments( newComments );
									} }
									fullWidth
									inputProps={ { maxLength: '60' } }
								/>
							</Box>
						</Box>
					);
				} ) }
			</Box>
			<Button fullWidth className={ classes.button } onClick={ onReleaseAction }>
				{ t( 'Release timesheet' ) }
			</Button>
		</>
	) : (
		<Typography>{ t( 'Loading' ) }</Typography>
	);
};

export default ReleaseTimesheetForm;
