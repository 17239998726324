import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import useTranslation from '../../../hooks/useTranslation';

export interface ReleaseTimesheetSuccessMessageProps {
	onSuccessDismiss: () => void;
}

const ReleaseTimesheetSuccessMessage: React.FC<ReleaseTimesheetSuccessMessageProps> = ( { onSuccessDismiss } ) => {

	const t = useTranslation()

	return (
		<Box m={ 2 }>
			<Typography>{ t( "successful release" ) }</Typography>
			<Box paddingTop="1em">
				<Button variant="outlined" color="primary" onClick={ onSuccessDismiss } >{ 'Okay' }</Button>
			</Box>
		</Box>
	)
}

export default ReleaseTimesheetSuccessMessage;
