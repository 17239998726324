import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
	useTranslation as useTranslationI18nHook,
} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { languageOptions } from '../components/screens/Settings/Settings';

import { AppState } from '../reducers';

const useTranslation = () => {
	const language = useSelector(
		( state: AppState ) => state.settingsState.settings.language
	);
	const { t, i18n } = useTranslationI18nHook();

	useEffect( () => {
		if ( language ) {
			i18n.changeLanguage( language.toLowerCase() );
		} else {
			i18n.use( LanguageDetector );
			const lng = languageOptions
				.includes( navigator.language )
				? navigator.language
				: 'en';
			i18n.changeLanguage( lng );
		}
	}, [ i18n, language ] );

	return t;
};

export default useTranslation;
