import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Add } from '@mui/icons-material';

import LeaveRequestQuickAction from '../../controls/QuickAction';
import DesktopTemplateCard from '../../DesktopTemplateCard';
// import { ReactComponent as LeaveRequestIcon } from './../../../images/leave-request-icon.svg';
import LeaveRequestIcon from './../../../icons/LeaveRequestIcon';

import makeStyles from '@mui/styles/makeStyles';

export interface LeavesMenuDesktopProps { }

const useStyles = makeStyles( theme => ( {
	leaveRequestQuickAction: {
		margin: theme.spacing( 1.5, 8 )
		// color: theme.palette.primary.main,
	},
	icon: {
		color: theme.palette.primary.main,
	}
} ) )

const LeavesMenuDesktop: React.FC<LeavesMenuDesktopProps> = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const classes = useStyles();

	const onClickOverviewHandler = useCallback( () => {
		navigate( '/leave', {
			state: {
				previous: location.pathname,
			}
		} );
	}, [ navigate, location.pathname ] );

	const onClickAddHandler = useCallback( () => {
		navigate( '/create-leave-request', {
			state: {
				previous: location.pathname,
			}
		} );
	}, [ navigate, location.pathname ] );

	return (
		<>
			<LeaveRequestQuickAction
				onClick={ onClickOverviewHandler }
				tooltipTitle="Alt+L"
				title={ 'Leave overview / request' }
				Icon={ () => <LeaveRequestIcon width={ 24 } height={ 24 } className={ classes.icon } /> }
				className={ classes.leaveRequestQuickAction }
			/>
			<DesktopTemplateCard
				title={ 'Add leave request' }
				action={ onClickAddHandler }
				Icon={ Add }
			/>
		</>
	);
};

export default LeavesMenuDesktop;
