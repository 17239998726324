
import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";

interface ConfirmBoxProps {
	yes: string;
	no: string;
	title: string;
	contentMessage: string;
	visible: boolean;
	onCancel: ( cb?: Function ) => void;
	onConfirm: () => void;
}

const useStyles = makeStyles( ( theme ) => ( {
	confirm: {
		textTransform: 'none',
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
	close: {
		textTransform: 'none',
		backgroundColor: theme.palette.grey[ 600 ],
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.grey[ 500 ],
		},
	},
} ) );

const ConfirmBox: React.FC<ConfirmBoxProps> = ( {
	yes,
	no,
	visible,
	onCancel,
	onConfirm,
	title,
	contentMessage
} ) => {
	const classes = useStyles();
	const t = useTranslation();

	const confirm = () => {
		onCancel();
		onConfirm();
	};

	const close = () => {
		onCancel();
	};
	return (
		<>
			{ visible && (
				<Dialog open={ visible } onClose={ close }>
					<DialogTitle>{ t( title ) }</DialogTitle>
					<DialogContent>
						<DialogContentText id="leaver-route-alert">
							{ t( contentMessage ) }
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={ close } className={ classes.close }>
							{ t( no ) }
						</Button>
						<Button onClick={ confirm } className={ classes.confirm }>
							{ t( yes ) }
						</Button>
					</DialogActions>
				</Dialog>

			) }
		</>
	);
};

export default ConfirmBox;