import React, { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { AppState } from './../../../reducers/index';

import { DestinationFormProps } from './DestinationFormTypes';

import Dropdown from './../../controls/Dropdown';
import TextBox from './../../controls/TextBox';

import FooterHint from '../../controls/FooterHint';

import { ActivitiesData } from '../../../types/api/timesheets';
import { getDestinationTextFieldCustomPlaceHolder } from './destination-utils';
import CreateDestinationButton from './CreateDestinationButton';
import useTranslation from '../../../hooks/useTranslation';

const IndirectActivityForm: React.FC<DestinationFormProps> = ( {
	values,
	onValuesChange,
	onSubmit,
	autoExpand,
	isLoadingData,
	employeeCode,
	ColorPicker,
} ) => {
	let hint = '';

	const activitiesData = useSelector<AppState, Array<ActivitiesData>>(
		( state ) => {
			if ( state.timesheetSourcesState.data ) {
				return state.timesheetSourcesState.data.activities;
			}
			return [];
		}
	);

	const activityData = activitiesData.find(
		( data ) => data.employeeCode === employeeCode
	);

	const activities = useMemo(
		() => ( activityData ? activityData.activities : [] ),
		[ activityData ]
	);

	let activitiesValues: Array<string> = [];

	if ( activities && activities.length ) {
		activitiesValues = activities.map(
			( activity ) => activity.activityDescription
		);
	}

	const onFormSubmit = useCallback( () => {
		const selectedActivity = activities.find(
			( activity ) => activity.activityDescription === values[ 'Activity' ]
		);

		onSubmit( selectedActivity );
	}, [ onSubmit, values, activities ] );

	const alternativeActionHandler = useCallback( () => {
		const selectedActivity = activities.find(
			( activity ) => activity.activityDescription === values[ 'Activity' ]
		);

		onSubmit( selectedActivity, true );
	}, [ onSubmit, values, activities ] );

	interface DropdownField {
		type: 'dropdown';
		label: string;
		options: Array<string>;
	}

	interface TextField {
		type: 'text';
		label: string;
	}

	const fields: Array<
		DropdownField | TextField
	> | null = activitiesValues.length
			? [
				{
					label: 'Activity',
					options: activitiesValues,
					type: 'dropdown',
				},
				{
					label: 'Name',
					type: 'text',
				},
			]
			: null;

	const form = [];

	const t = useTranslation();

	if ( fields ) {
		let hasFieldsWithoutValue = false;

		for ( let fieldIndex = 0; fieldIndex < fields.length; fieldIndex++ ) {
			const field: DropdownField | TextField = fields[ fieldIndex ];

			const value = values[ field.label ] ? values[ field.label ] : '';
			switch ( field.type ) {
				case 'dropdown':
					if ( field.options && field.options.length ) {
						form.push(
							<Dropdown
								key={ field.label }
								autoExpand={ autoExpand }
								label={ field.label }
								options={ field.options }
								selectedValue={ value }
								onValueChange={ ( val ) => {
									onValuesChange( {
										...values,
										[ field.label ]: val,
									} );
								} }
							/>
						);
					}
					break;
				case 'text':
					form.push(
						<TextBox
							key={ field.label }
							label={ field.label }
							customText={ t(
								getDestinationTextFieldCustomPlaceHolder( field.label ) || ''
							) }
							value={ value }
							onChange={ ( val ) => {
								onValuesChange( {
									...values,
									[ field.label ]: val,
								} );
							} }
						/>
					);
					break;
			}

			if ( !value ) {
				hasFieldsWithoutValue = true;
				hint = `Select ${ field.label.toLowerCase() }`;
				break;
			}
		}

		if ( !hasFieldsWithoutValue ) {
			form.push( ColorPicker );
		}
	} else {
		form.push(
			<Box p={ 2 }>
				<Typography>{ t( 'no indirect activities' ) }</Typography>
			</Box>
		);
	}

	let footerHint = null;

	if ( hint ) {
		if ( hint === 'Select name' ) {
			footerHint = (
				<FooterHint
					hint="Enter name to save quick action"
					alternativeActionText="Continue"
					onAlternativeActionClick={ alternativeActionHandler }
				/>
			);
		} else {
			footerHint = <FooterHint hint={ hint } />;
		}
	}

	return (
		<>
			<Box marginLeft={ 2 } marginRight={ 2 } flex="1" overflow="auto">
				{ !isLoadingData ? (
					form
				) : (
					<Box p={ 2 }>
						<Typography>{ t( 'Loading' ) }</Typography>
					</Box>
				) }
			</Box>
			{footerHint }
			{values.Name && (
				<CreateDestinationButton
					onClick={ onFormSubmit }
					actionText="Save changes"
				/>
			) }
		</>
	);
};

export default IndirectActivityForm;
