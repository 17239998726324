import React from 'react';
import { Box, Typography } from '@mui/material';
import LoadingHeader from './Headers/LoadingHeader';

const Loading: React.FC = () => (
	<Box>
		<LoadingHeader />
		<Box p={ 2 } textAlign="center">
			<Typography>Loading...</Typography>
		</Box>
	</Box>
);

export default Loading;
