import React, { useState, useCallback, useEffect } from 'react';

import { Box, Input } from '@mui/material';
import TimePickerMenu from './TimePickerMenu';

export interface TimeInputProps {
	containerClass: string;
	inputClass: string;
	name: string;
	inputRef: any;
	defaultValue?: string;
}

const TimeInput: React.FC<TimeInputProps> = ( {
	containerClass,
	inputClass,
	inputRef,
	name,
	defaultValue = '08:00',
} ) => {
	const [ fieldTime, setFieldTime ] = useState<string>();
	const [ isTimePickerOpen, setIsTimePickerOpen ] = useState<boolean>( false );

	const onCloseTimePicker = useCallback( () => {
		setIsTimePickerOpen( false );
	}, [] );

	const onOpenTimePicker = useCallback( () => {
		setIsTimePickerOpen( true );
	}, [] );

	const onConfirmTime = useCallback( ( time: string ) => {
		setFieldTime( time );
	}, [] );

	useEffect( () => {
		setFieldTime( defaultValue );
	}, [ defaultValue ] )

	return (
		<Box className={ containerClass }>
			<Input
				name={ name }
				inputRef={ inputRef }
				className={ inputClass }
				value={ fieldTime || defaultValue }
				readOnly
				onClick={ onOpenTimePicker }
			/>
			<TimePickerMenu
				open={ isTimePickerOpen }
				onClose={ onCloseTimePicker }
				onConfirm={ onConfirmTime }
				defaultValue={ defaultValue }
			/>
		</Box>
	);
};

export default TimeInput;
