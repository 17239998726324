import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
	Dialog,
	IconButton,
	Slide,
	MenuItem,
	Fab,
	Typography,
	Box,
	SlideProps,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';

import ReleaseTimesheetForm from './ReleaseTimesheetForm';
import { DateTime } from 'luxon';
import AddTimesheetHeader from '../../Headers/AddTimesheetHeader';
import useTimesheetReleaseOptions from '../../../hooks/useTimesheetReleaseOptions';
import {
	undoReleaseTimesheet,
	releaseTimesheet,
} from '../../../actions/api-actions';
import {
	AccountReleaseTimeSheet,
	ReleaseTimeSheetArguments,
} from '../../../types/api/timesheets';
import UndoReleaseConfirmDialog from './UndoReleaseConfirm';
import ReleaseConfirmDialog from './ReleaseConfirmDialog';
import { OvertimeOptions } from '../Minimal/Minimal';
import { getNextWeekRange } from '../../../utils/date';
import { WeekRangeData } from '../../../actions/time-actions';
import DesktopPopUpHeader from '../../Headers/DesktopPopUpHeader';
import DesktopTemplateCard from '../../DesktopTemplateCard';
import useTranslation from '../../../hooks/useTranslation';

const Transition = React.forwardRef<unknown, SlideProps>( function Transition (
	props,
	ref
) {
	return <Slide direction="up" ref={ ref } { ...props } />;
} );

const useStyles = makeStyles( ( theme: Theme ) =>
	createStyles( {
		menuItem: {
			fontWeight: 900,
			backgroundColor: theme.palette.background.default,
			'&:hover': { backgroundColor: theme.palette.background.default },
			color: theme.palette.primary.main,
			textTransform: 'none',
			display: 'flex',
			justifyContent: 'flex-end',
			paddingRight: 0,
			opacity: 0.9,
			zIndex: 1,
		},
		fabIcon: {
			margin: theme.spacing( 0, 1 ),
		},
		menuItemLabel: {
			backgroundColor: theme.palette.background.default,
			color: theme.palette.text.primary,
			fontWeight: 900,
			fontSize: theme.spacing( 1.75 ),
			padding: theme.spacing( 2 ),
		},
		mediaHeader: {
			display: 'flex',
			position: 'relative',
			marginBottom: theme.spacing( 1 ),
			padding: theme.spacing( 0.5 ),
		},
		iconButton: {
			marginLeft: theme.spacing( 1 ),
			color: theme.palette.custom.white,
		},
		dialog: {
			backgroundColor: theme.palette.background.default,
			maxWidth: theme.spacing( 80 ),
			margin: '0 auto',
			padding: 0,
			borderStyle: 'solid',
			borderWidth: theme.spacing( 0.1 ),
			borderColor: theme.palette.background.paper,
		},
		settings: {
			padding: theme.spacing( 0.5, 1.25 ),
		},
	} )
);

export interface ReleaseTimesheetUIProps {
	hasEnoughHours: boolean;
	overtimeOptions: OvertimeOptions[];
	fromDateTime: DateTime;
	onRefreshTimesheetData: ( weekRange: WeekRangeData ) => void;
	disableUnRelease: boolean;
	isDesktop?: boolean;
}

const ReleaseTimesheetUI: React.FC<ReleaseTimesheetUIProps> = ( {
	hasEnoughHours,
	fromDateTime,
	overtimeOptions,
	onRefreshTimesheetData,
	disableUnRelease,
	isDesktop = false,
} ) => {
	const classes = useStyles();

	const [ isReleaseDialogOpen, setIsReleaseDialogOpen ] = React.useState( false );
	const [ isUndoReleaseDialogOpen, setIsUndoReleaseDialogOpen ] = React.useState(
		false
	);
	const [
		isReleaseConfirmDialogOpen,
		setIsReleaseConfirmDialogOpen,
	] = React.useState( false );

	const dispatch = useDispatch();

	const setNextWeekRange = useCallback(
		( numberOfWeeks: number = 0 ) => {
			const weekRange = getNextWeekRange(
				numberOfWeeks,
				fromDateTime.toJSDate()
			);
			onRefreshTimesheetData( weekRange );
		},
		[ fromDateTime, onRefreshTimesheetData ]
	);

	const openReleaseDialog = useCallback( () => {
		setIsReleaseDialogOpen( true );
	}, [ setIsReleaseDialogOpen ] );

	const closeReleaseDialog = useCallback( () => {
		setNextWeekRange();
		setIsReleaseDialogOpen( false );
	}, [ setIsReleaseDialogOpen, setNextWeekRange ] );

	const onReleaseSuccessMessage = useCallback( () => {
		setNextWeekRange( 1 );
		setIsReleaseDialogOpen( false );
	}, [ setIsReleaseDialogOpen, setNextWeekRange ] );

	const onConfirmReleaseSuccess = useCallback( () => {
		setIsReleaseConfirmDialogOpen( false );
	}, [ setIsReleaseConfirmDialogOpen ] );

	const onReleaseFailed = useCallback( () => {
		setIsReleaseDialogOpen( false );
	}, [ setIsReleaseDialogOpen ] );

	const {
		overtimeAccountsOptions,
		releasedAccountsOptions,
	} = useTimesheetReleaseOptions( overtimeOptions );

	const hasOvertime = overtimeOptions.find(
		( x ) =>
			x.hasMonFriOvertime || x.hasSaturdayOvertime || x.hasSunPubHolOvertime
	);

	const hasActiveAccountOptions = overtimeAccountsOptions.length > 0;

	const accountOptions = hasActiveAccountOptions
		? overtimeAccountsOptions
		: releasedAccountsOptions || [];

	const releaseButtonTitle =
		hasActiveAccountOptions || !hasEnoughHours
			? 'Release timesheet'
			: 'Back to draft';

	const ReleaseButtonIcon =
		hasActiveAccountOptions || !hasEnoughHours ? SaveIcon : UndoIcon;

	const releaseTimesheets: AccountReleaseTimeSheet[] = accountOptions.map(
		( option ) => {
			return {
				employeeCode: option.employeeCode,
				comment: '',
			};
		}
	);

	const overtimeRequestData: ReleaseTimeSheetArguments = useMemo(
		() => ( {
			date: fromDateTime.toISODate(),
			releaseTimesheets,
		} ),
		[ fromDateTime, releaseTimesheets ]
	);

	const onConfirmUndo = useCallback( () => {
		setIsUndoReleaseDialogOpen( true );
		setIsReleaseConfirmDialogOpen( false );
		dispatch( undoReleaseTimesheet( overtimeRequestData, setNextWeekRange ) );

	}, [ dispatch, overtimeRequestData, setNextWeekRange ] );

	const onConfirmRelease = useCallback( () => {
		const nextWeekRange = getNextWeekRange( 1, fromDateTime.toJSDate() );
		setIsUndoReleaseDialogOpen( false );
		setIsReleaseConfirmDialogOpen( true );
		dispatch( releaseTimesheet( overtimeRequestData, nextWeekRange ) );
	}, [ dispatch, overtimeRequestData, fromDateTime ] );

	const releaseButtonClickHandler = hasActiveAccountOptions
		? hasOvertime
			? openReleaseDialog
			: onConfirmRelease
		: onConfirmUndo;

	const disabledAction =
		!hasEnoughHours ||
		( releaseButtonTitle === 'Back to draft' && disableUnRelease );

	useHotkeys(
		'alt+r',
		( e ) => {
			e.preventDefault();
			if ( isDesktop && !disabledAction ) {
				releaseButtonClickHandler();
			}
		},
		[ disabledAction, isDesktop, releaseButtonClickHandler ]
	);

	const t = useTranslation();

	return <>
		{ isDesktop ? (
			<DesktopTemplateCard
				title={ releaseButtonTitle }
				action={ releaseButtonClickHandler }
				toolTipTitle="Alt+R"
				Icon={ ReleaseButtonIcon }
				disabled={ disabledAction }
			/>
		) : (
			<MenuItem
				className={ classes.menuItem }
				color="primary"
				onClick={ releaseButtonClickHandler }
				disabled={ disabledAction }
				aria-label={ releaseButtonTitle }
			>
				<Typography className={ classes.menuItemLabel }>
					{ t( releaseButtonTitle ) }
				</Typography>
				<Fab className={ classes.fabIcon } size="small" color="primary">
					<ReleaseButtonIcon />
				</Fab>
			</MenuItem>
		) }
		<Dialog
			open={ isReleaseDialogOpen }
			fullScreen={ !isDesktop }
			onClose={ closeReleaseDialog }
			classes={ { paper: classes.dialog } }
			TransitionComponent={ Transition }
		>
			<Box display="flex" flexDirection="column" flex="1">
				{ isDesktop ? (
					<DesktopPopUpHeader onClosePopUp={ closeReleaseDialog } />
				) : (
					<AddTimesheetHeader
						CustomButton={
							<IconButton
								edge="start"
								color="inherit"
								onClick={ closeReleaseDialog }
								aria-label="close"
								className={ classes.iconButton }
								size="large">
								<CloseIcon />
							</IconButton>
						}
					/>
				) }
				<ReleaseTimesheetForm
					overtimeOptions={ overtimeOptions }
					onReleaseSuccess={ onReleaseSuccessMessage }
					date={ fromDateTime.toISODate() }
					onReleaseFailed={ onReleaseFailed }
				/>
			</Box>
		</Dialog>
		<ReleaseConfirmDialog
			open={ isReleaseConfirmDialogOpen }
			onConfirmReleaseSuccess={ onConfirmReleaseSuccess }
		/>
		<UndoReleaseConfirmDialog
			open={ isUndoReleaseDialogOpen }
			onRefreshTimesheetData={ setNextWeekRange }
		/>
	</>;
};

export default ReleaseTimesheetUI;
