import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import LeavesListDesktop from './LeavesListDesktop';
import ManageLeaveDesktop from '../ManageLeaves/ManageLeaveDesktop';
import { AppState } from '../../../reducers';
import { LeaveRequest } from '../../../types/api/leaveRequests';

export interface LeavesDesktopProps {
	editMode: boolean;
}

const useStyles = makeStyles( ( theme ) => ( {
	container: {
		display: 'flex',
		justifyContent: 'center',
	},
	sectionDivider: {
		height: '100vh ',
		borderRightWidth: theme.spacing( 0.25 ),
		borderRightColor: theme.palette.custom.paper,
		borderRightStyle: 'solid',
		overflow: 'hidden',
	},
	overview: { width: '45%' },
	details: { width: '55%' },
} ) );

const LeavesDesktop: React.FC<LeavesDesktopProps> = ( { editMode } ) => {
	const classes = useStyles();

	const [ leaveRequestToEdit, setLeaveRequestToEdit ] = useState<LeaveRequest>();

	const { employeeCode, leaveRequests } = useSelector( ( state: AppState ) => {
		return {
			employeeCode: state.userState.selectedUser?.employeeCode,
			leaveRequests: state.leaveRequestState.accountRequests?.leaveRequests,
		};
	} );

	const setLeaveRequestId = useCallback(
		( id?: string ) => {
			if ( leaveRequests && employeeCode ) {
				const leaveRequest = leaveRequests.find( ( leave ) => leave.id === id );
				setLeaveRequestToEdit( leaveRequest );
			}
		},
		[ leaveRequests, employeeCode ]
	);

	useEffect( () => {
		if ( !editMode ) {
			setLeaveRequestId();
			return;
		}
		if ( leaveRequests && leaveRequests.length ) {
			setLeaveRequestId( leaveRequests[ 0 ]?.id );
		}
	}, [ leaveRequests, setLeaveRequestId, editMode ] );

	return (
		<Box className={ classes.container }>
			<Box className={ classes.sectionDivider } />
			<Box className={ classes.overview }>
				<LeavesListDesktop
					setLeaveRequestId={ setLeaveRequestId }
					leaveRequest={ leaveRequestToEdit }
				/>
			</Box>
			<Box className={ classes.sectionDivider } />
			<Box className={ classes.details }>
				<ManageLeaveDesktop
					leaveRequestToEdit={ leaveRequestToEdit }
					editMode={ editMode }
				/>
			</Box>
		</Box>
	);
};

export default LeavesDesktop;
