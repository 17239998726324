import React, { useContext } from 'react';
import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Dropdown from '../../controls/Dropdown';
import HomeHeader from '../../Headers/LogoHeader';
import SettingsTemplateCard from '../../SettingsTemplateCard';
import useSettingsOptions from '../../../hooks/useSettingsOptions';
import PrintTimesheetSettings from './PrintTimesheetSettings';
import {
	GetApp as InstallAppIcon,
	Replay as ReloadIcon,
	//   ClearAll as ClearIcon,
} from '@mui/icons-material';
import useTranslation from '../../../hooks/useTranslation';
import { AddToHomeScreenContext } from '../../Providers/AddToHmeScreenProvider';
import SettingsContactLine from '../../controls/SettingsContactLine';
import BackToFactorySettingsConfirm from './BackToFactorySettingsConfirm';

const darkModeOptions = [ 'Use system settings', 'Light mode', 'Dark mode' ];
export const languageOptions = [ 'Use system settings', 'en', 'nl' ];

interface SettingsProps {
	onLogOutClick?: () => void;
	isDesktopActive?: boolean;
}

const useStyles = makeStyles( ( theme ) => ( {
	settingsContainer: {
		display: 'flex',
		flexDirection: 'column',
		height: 'calc( 100vh - 10rem )',
		flex: '1',
	},
} ) );

const Settings: React.FC<SettingsProps> = ( {
	onLogOutClick,
	isDesktopActive = false,
} ) => {
	const {
		selectedTheme,
		setThemeSettings,
		onClearSettings,
		onClearCache,
		selectedLanguage,
		setLanguageSettings,
	} = useSettingsOptions();

	const classes = useStyles();

	const { prompt, promptToInstall } = useContext( AddToHomeScreenContext );

	const t = useTranslation();

	return (
		<Box className={ classes.settingsContainer }>
			{ !isDesktopActive && (
				<HomeHeader showBackButton={ true } onLogOutClick={ onLogOutClick } />
			) }
			<Box m={ 2 } flex="1" overflow="auto">
				<Box m={ 2 }>
					<Typography variant="h2">{ t( 'My Settings' ) }</Typography>
				</Box>
				<Box m={ 1 }>
					<Dropdown
						label="Theme"
						options={ darkModeOptions }
						selectedValue={ selectedTheme || darkModeOptions[ 0 ] }
						onValueChange={ setThemeSettings }
						autoExpand={ false }
					/>
				</Box>
				<Box m={ 1 }>
					<Dropdown
						label="Language"
						options={ languageOptions }
						selectedValue={ selectedLanguage || languageOptions[ 0 ] }
						onValueChange={ setLanguageSettings }
						autoExpand={ false }
					/>
				</Box>
				{/* <SettingsTemplateCard
					title={ 'Back to factory settings' }
					action={ onClearSettings }
					actionTitle={ 'Clear' }
					Icon={ ClearIcon }
					isCardClickable
				/> */}
				<BackToFactorySettingsConfirm action={ onClearSettings } />
				<SettingsTemplateCard
					title={ 'Reload personal data' }
					action={ onClearCache }
					actionTitle={ 'Reload' }
					Icon={ ReloadIcon }
					isCardClickable
				/>
				<SettingsTemplateCard
					title={ 'Add OnTime to your home screen' }
					action={ promptToInstall }
					actionTitle={ 'Add' }
					disabled={ !prompt }
					Icon={ InstallAppIcon }
					isCardClickable
				/>
				<PrintTimesheetSettings />
			</Box>
			<SettingsContactLine />
		</Box>
	);
};

export default Settings;
