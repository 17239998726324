import React, { useState, useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import CopyIcon from '@mui/icons-material/FileCopy';
import PrintIcon from '@mui/icons-material/Print';
import AddIcon from '@mui/icons-material/Add';

import ReleaseTimesheetUI, {
	ReleaseTimesheetUIProps,
} from '../ReleaseTimesheet/ReleaseTimesheetUI';

import CopyTimesheet from '../Minimal/CopyTimesheetForm';
import PrintTimesheetForm from '../PrintTimesheet/PrintTimesheetForm';
import DesktopTemplateCard from '../../DesktopTemplateCard';
import { useSelector } from 'react-redux';
import { AppState } from '../../../reducers';

export interface ManageDraftsMenuProps {
	releaseTimesheetUIProps: ReleaseTimesheetUIProps;
	onAddTime: () => void;
	isInactiveWeek?: boolean
}

const ManageDraftsMenuDesktop: React.FC<ManageDraftsMenuProps> = ( {
	releaseTimesheetUIProps,
	onAddTime,
	isInactiveWeek = false,
} ) => {
	const [ isCopyTimesheetFormOpen, setIsCopyTimesheetFormOpen ] = useState<
		boolean
	>( false );
	const [ isPrintTimesheetFormOpen, setIsPrintTimesheetFormOpen ] = useState<
		boolean
	>( false );

	const error = useSelector<AppState>( state => state.timesheetsState.error );

	const onOpenCopyTimesheetForm = useCallback( () => {
		setIsCopyTimesheetFormOpen( true );
		setIsPrintTimesheetFormOpen( false );
	}, [] );

	const onCloseCopyTimesheetForm = useCallback( () => {
		setIsCopyTimesheetFormOpen( false );
	}, [] );

	const onOpenPrintTimesheetForm = useCallback( () => {
		setIsPrintTimesheetFormOpen( true );
		setIsCopyTimesheetFormOpen( false );
	}, [] );

	const onClosePrintTimesheetForm = useCallback( () => {
		setIsPrintTimesheetFormOpen( false );
	}, [] );

	useHotkeys( 'ctrl+c', ( event ) => {
		event.preventDefault();
		if ( !isInactiveWeek ) {
			onOpenCopyTimesheetForm();
		}
	} );

	useHotkeys( 'alt+p', ( event ) => {
		event.preventDefault();
		onOpenPrintTimesheetForm();
	} );

	return (
		<>
			<DesktopTemplateCard
				title={ "Add hours" }
				action={ onAddTime }
				Icon={ AddIcon }
				disabled={ !!error || isInactiveWeek }
			/>
			<DesktopTemplateCard
				title="Copy hours"
				action={ onOpenCopyTimesheetForm }
				toolTipTitle="Ctrl+C"
				Icon={ CopyIcon }
				disabled={ !!error || isInactiveWeek }
			/>
			<ReleaseTimesheetUI { ...releaseTimesheetUIProps } />
			{ isCopyTimesheetFormOpen && (
				<CopyTimesheet
					isOpen={ isCopyTimesheetFormOpen }
					onClose={ onCloseCopyTimesheetForm }
				/>
			) }
			{ isPrintTimesheetFormOpen && (
				<PrintTimesheetForm
					isOpen={ isPrintTimesheetFormOpen }
					onClose={ onClosePrintTimesheetForm }
				/>
			) }
			<DesktopTemplateCard
				title="Print timesheet"
				action={ onOpenPrintTimesheetForm }
				toolTipTitle="Alt+P"
				Icon={ PrintIcon }
				disabled={ !!error }
			/>
		</>
	);
};

export default ManageDraftsMenuDesktop;
