import React, { useCallback } from 'react';
import { IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { useNavigate, useLocation } from 'react-router-dom';

export interface BackButtonProps {
	className?: string;
}

const BackButton: React.FC<BackButtonProps> = ( { className } ) => {
	const navigate = useNavigate();
	const location = useLocation()

	// TO DO
	const onClickBackHandler = useCallback( () => {
		const previousPath = ( location.state as any )?.previous;
		if ( previousPath && previousPath !== '/' ) {
			navigate( -1 );
		} else {
			navigate( '/' );
		}
	}, [ navigate, location.state ] );

	return (
		<IconButton aria-label="Back" onClick={ onClickBackHandler } size="large">
			<ArrowBack className={ className } />
		</IconButton>
	);
};

export default BackButton;
