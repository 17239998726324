import { DateTime } from 'luxon';

import { getWeekdayDate, Weekday } from '../../../utils/date';

export const getLeaveItemTitle = (
	startDate: DateTime,
	endDate: DateTime,
	prefix: string,
	localeLanguage: string,
	leaveDayName?: Weekday
) => {
	if ( leaveDayName ) {
		return `${ prefix } ( ${ getWeekdayDate( startDate, leaveDayName )
			.setLocale( localeLanguage )
			.toFormat( 'MMM dd' ) } )`;
	}
	return `${ prefix } ( ${ startDate
		.setLocale( localeLanguage )
		.toFormat( 'MMM dd' ) } - ${ endDate
			.setLocale( localeLanguage )
			.toFormat( 'MMM dd' ) } )`;
};

export const defaultStartDate = DateTime.fromJSDate( new Date() ).plus( { day: 1 } ).startOf( 'day' );
export const defaultEndDate = DateTime.fromJSDate( new Date() ).plus( { day: 2 } ).startOf( 'day' );


