import { AuthorizationState } from "../reducers/authorization";
import {
	ExpenseClaim,
	ExpenseClaimDetails,
	ExpenseClaimApprovalRoute,
	ExpenseClaimApprovalProgress,
	ExpenseClaimsData,
	ExpenseClaimDocument,
	ExpenseClaimFile,
	ExpenseClaimsSourceData,
} from "../types/api/expenseClaims";
import { AccountRequests } from "../types/api/leaveRequests";
import {
	Activity,
	PublicHolidaysData,
	Timesheet,
	CurrentTimesheetData,
	TimesheetSourceDataResponseData,
} from "../types/api/timesheets";
import { GetSettingsResponseData } from "../types/api/settings";
import { WeekRangeData } from "../actions/time-actions";
import { UserInfo, UserInfoAccount } from "../types/api/user";
import { TotalHoursGroup } from "../components/Desktop/DesktopLayout";

export enum ActionType {
	SET_AUTHORIZATION_DATA = "SET_AUTHORIZATION_DATA",
	GET_EXPENSE_CLAIM_APPROVAL_ROUTE_FAILED = "GET_EXPENSE_CLAIM_APPROVAL_ROUTE_FAILED",
	GET_EXPENSE_CLAIM_APPROVAL_ROUTE_SUCCEEDED = "GET_EXPENSE_CLAIM_APPROVAL_ROUTE_SUCCEEDED",
	GET_EXPENSE_CLAIM_DETAILS_FAILED = "GET_EXPENSE_CLAIM_DETAILS_FAILED",
	GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_FAILED = "GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_FAILED",
	GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_SUCCEEDED = "GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_SUCCEEDED",
	GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_STARTED = "GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_STARTED",
	GET_EXPENSE_CLAIM_APPROVAL_ROUTE_STARTED = "GET_EXPENSE_CLAIM_APPROVAL_ROUTE_STARTED",
	RELEASE_EXPENSE_CLAIM_SUCCEEDED = "RELEASE_EXPENSE_CLAIM_SUCCEEDED",
	GET_EXPENSE_CLAIMS_SUCCEEDED = "GET_EXPENSE_CLAIMS_SUCCEEDED",
	DELETE_EXPENSE_CLAIM_SUCCEEDED = "DELETE_EXPENSE_CLAIM_SUCCEEDED",
	GET_EXPENSE_CLAIM_SUCCEEDED = "GET_EXPENSE_CLAIM_SUCCEEDED",
	GET_EXPENSE_CLAIMS_STARTED = "GET_EXPENSE_CLAIMS_STARTED",
	CREATE_EXPENSE_CLAIM_STARTED = "CREATE_EXPENSE_CLAIM_STARTED",
	RELEASE_EXPENSE_CLAIM_STARTED = "RELEASE_EXPENSE_CLAIM_STARTED",
	GET_EXPENSE_CLAIM_STARTED = "GET_EXPENSE_CLAIM_STARTED",
	DELETE_EXPENSE_CLAIM_STARTED = "DELETE_EXPENSE_CLAIM_STARTED",
	GET_EXPENSE_CLAIMS_FAILED = "GET_EXPENSE_CLAIMS_FAILED",
	RELEASE_EXPENSE_CLAIM_FAILED = "RELEASE_EXPENSE_CLAIM_FAILED",
	DELETE_EXPENSE_CLAIM_FAILED = "DELETE_EXPENSE_CLAIM_FAILED",
	CREATE_EXPENSE_CLAIM_FAILED = "CREATE_EXPENSE_CLAIM_FAILED",
	DISMISS_EXPENSE_CLAIM_ERROR = "DISMISS_EXPENSE_CLAIM_ERROR",
	GET_EXPENSE_CLAIM_DOCUMENTS_SUCCEEDED = "GET_EXPENSE_CLAIM_DOCUMENTS_SUCCEEDED",
	GET_EXPENSE_CLAIM_DOCUMENTS_FAILED = "GET_EXPENSE_CLAIM_DOCUMENTS_FAILED",
	GET_EXPENSE_CLAIM_DOCUMENT_SUCCEEDED = "GET_EXPENSE_CLAIM_DOCUMENT_SUCCEEDED",
	DELETE_EXPENSE_CLAIM_DOCUMENT_SUCCEEDED = "DELETE_EXPENSE_CLAIM_DOCUMENT_SUCCEEDED",
	DELETE_EXPENSE_CLAIM_DOCUMENT_STARTED = "DELETE_EXPENSE_CLAIM_DOCUMENT_STARTED",
	GET_EXPENSE_CLAIM_DOCUMENT_STARTED = "GET_EXPENSE_CLAIM_DOCUMENT_STARTED",
	GET_EXPENSE_CLAIM_DOCUMENT_FAILED = "GET_EXPENSE_CLAIM_DOCUMENT_FAILED",
	DELETE_EXPENSE_CLAIM_DOCUMENT_FAILED = "DELETE_EXPENSE_CLAIM_DOCUMENT_FAILED",
	GET_EXPENSE_CLAIMS_SOURCES_DATA_STARTED = "GET_EXPENSE_CLAIMS_SOURCES_DATA_STARTED",
	GET_EXPENSE_CLAIMS_SOURCES_DATA_SUCCEEDED = "GET_EXPENSE_CLAIMS_SOURCES_DATA_SUCCEEDED",
	GET_EXPENSE_CLAIMS_SOURCES_DATA_FAILED = "GET_EXPENSE_CLAIMS_SOURCES_DATA_FAILED",
	GET_LEAVE_REQUESTS_STARTED = "GET_LEAVE_REQUESTS_STARTED",
	GET_LEAVE_REQUEST_ACTIVITIES_STARTED = "GET_LEAVE_REQUEST_ACTIVITIES_STARTED",
	GET_LEAVE_REQUESTS_SUCCEEDED = "GET_LEAVE_REQUESTS_SUCCEEDED",
	GET_LEAVE_REQUEST_ACTIVITIES_SUCCEEDED = "GET_LEAVE_REQUEST_ACTIVITIES_SUCCEEDED",
	GET_LEAVE_REQUEST_ACTIVITIES_FAILED = "GET_LEAVE_REQUEST_ACTIVITIES_FAILED",
	GET_LEAVE_REQUESTS_FAILED = "GET_LEAVE_REQUESTS_FAILED",
	PRINT_TIMESHEET_STARTED = "PRINT_TIMESHEET_STARTED",
	PRINT_TIMESHEET_SUCCEEDED = "PRINT_TIMESHEET_SUCCEEDED",
	PRINT_TIMESHEET_FAILED = "PRINT_TIMESHEET_FAILED",
	GET_PUBLIC_HOLIDAYS_STARTED = "GET_PUBLIC_HOLIDAYS_STARTED",
	GET_PUBLIC_HOLIDAYS_SUCCEEDED = "GET_PUBLIC_HOLIDAYS_SUCCEEDED",
	GET_PUBLIC_HOLIDAYS_FAILED = "GET_PUBLIC_HOLIDAYS_FAILED",
	RELEASE_TIMESHEET_STARTED = "RELEASE_TIMESHEET_STARTED",
	UNDO_RELEASE_TIMESHEET_STARTED = "UNDO_RELEASE_TIMESHEET_STARTED",
	RELEASE_TIMESHEET_FAILED = "RELEASE_TIMESHEET_FAILED",
	RELEASE_TIMESHEET_SUCCEEDED = "RELEASE_TIMESHEET_SUCCEEDED",
	UNDO_RELEASE_TIMESHEET_SUCCEEDED = "UNDO_RELEASE_TIMESHEET_SUCCEEDED",
	CLEAR_RELEASE_TIMESHEET_ERROR = "CLEAR_RELEASE_TIMESHEET_ERROR",
	UNDO_RELEASE_TIMESHEET_FAILED = "UNDO_RELEASE_TIMESHEET_FAILED",
	GET_SETTINGS_SUCCEEDED = "GET_SETTINGS_SUCCEEDED",
	SAVE_SETTINGS_SUCCEEDED = "SAVE_SETTINGS_SUCCEEDED",
	SAVE_SETTINGS_STARTED = "SAVE_SETTINGS_STARTED",
	SAVE_SETTINGS_FAILED = "SAVE_SETTINGS_FAILED",
	CLEAR_SETTINGS_SUCCEEDED = "CLEAR_SETTINGS_SUCCEEDED",
	GET_SETTINGS_STARTED = "GET_SETTINGS_STARTED",
	GET_SETTINGS_FAILED = "GET_SETTINGS_FAILED",
	SET_TEMP_DESTINATION = "SET_TEMP_DESTINATION",
	SET_WEEK_RANGE_DATA = "SET_WEEK_RANGE_DATA",
	SET_SELECTED_EXPENSE_CLAIMS_YEAR = "SET_SELECTED_EXPENSE_CLAIMS_YEAR",
	SET_SELECTED_LEAVE_REQUESTS_YEAR = "SET_SELECTED_LEAVE_REQUESTS_YEAR",
	SET_CALENDAR_MONTH_DATA = "SET_CALENDAR_MONTH_DATA",
	SET_TIMESHEET_REQUEST_HOURS = "SET_TIMESHEET_REQUEST_HOURS",
	RESET_TIMESHEET_REQUEST_HOURS = "RESET_TIMESHEET_REQUEST_HOURS",
	GET_TIME_SHEET_STARTED = "GET_TIME_SHEET_STARTED",
	GET_TIME_SHEET_SUCCEEDED = "GET_TIME_SHEET_SUCCEEDED",
	GET_CURRENT_TIME_SHEET_SUCCEEDED = "GET_CURRENT_TIME_SHEET_SUCCEEDED",
	GET_TIME_SHEET_FAILED = "GET_TIME_SHEET_FAILED",
	DELETE_TIME_SHEET_LINE_ITEM_STARTED = "DELETE_TIME_SHEET_LINE_ITEM_STARTED",
	ADD_TIMESHEET_STARTED = "ADD_TIMESHEET_STARTED",
	COPY_TIMESHEET_STARTED = "COPY_TIMESHEET_STARTED",
	GET_CURRENT_TIME_SHEET_STARTED = "GET_CURRENT_TIME_SHEET_STARTED",
	ADD_TIMESHEET_SUCCEEDED = "ADD_TIMESHEET_SUCCEEDED",
	DELETE_TIME_SHEET_LINE_ITEM_FAILED = "DELETE_TIME_SHEET_LINE_ITEM_FAILED",
	ADD_TIMESHEET_FAILED = "ADD_TIMESHEET_FAILED",
	COPY_TIMESHEET_FAILED = "COPY_TIMESHEET_FAILED",
	GET_CURRENT_TIME_SHEET_FAILED = "GET_CURRENT_TIME_SHEET_FAILED",
	GET_TIME_SHEET_SOURCE_DATA_SUCCEEDED = "GET_TIME_SHEET_SOURCE_DATA_SUCCEEDED",
	GET_TIME_SHEET_SOURCE_DATA_STARTED = "GET_TIME_SHEET_SOURCE_DATA_STARTED",
	GET_TIME_SHEET_SOURCE_DATA_FAILED = "GET_TIME_SHEET_SOURCE_DATA_FAILED",
	GET_USER_INFO_STARTED = "GET_USER_INFO_STARTED",
	GET_USER_INFO_SUCCEEDED = "GET_USER_INFO_SUCCEEDED",
	GET_USER_INFO_FAILED = "GET_USER_INFO_FAILED",
	SET_SELECTED_USER = "SET_SELECTED_USER",
}

interface actionSetAuthorizationData {
	type: ActionType.SET_AUTHORIZATION_DATA;
	authorizationData: AuthorizationState;
}

interface actionGetExpenseClaimApprovalRouteFailed {
	type: ActionType.GET_EXPENSE_CLAIM_APPROVAL_ROUTE_FAILED;
	error: any;
}

interface actionGetExpenseClaimApprovalRouteSucceeded {
	type: ActionType.GET_EXPENSE_CLAIM_APPROVAL_ROUTE_SUCCEEDED;
	approvalRoute: ExpenseClaimApprovalRoute;
	expenseClaimId: string;
}

interface actionGetExpenseClaimDetailsFailed {
	type: ActionType.GET_EXPENSE_CLAIM_DETAILS_FAILED;
	error: any;
	details: Array<ExpenseClaimDetails>;
}
interface actionGetExpenseClaimApprovalFailed {
	type: ActionType.GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_FAILED;
	error: any;
}

interface actionGetExpenseClaimApprovalProgressSucceeded {
	type: ActionType.GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_SUCCEEDED;
	approvalProgress: ExpenseClaimApprovalProgress[];
	expenseClaimId: string;
}

interface actionGetExpenseClaimApprovalProgressStarted {
	type: ActionType.GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_STARTED;
	expenseClaimId: string;
}

interface actionGetExpenseClaimApprovalRouteStarted {
	type: ActionType.GET_EXPENSE_CLAIM_APPROVAL_ROUTE_STARTED;
	expenseClaimId: string;
}
interface actionReleaseExpenseClaimSucceeded {
	type: ActionType.RELEASE_EXPENSE_CLAIM_SUCCEEDED;
	expenseClaimId: string;
	messages: Array<string>;
}

interface actionGetExpenseClaimsSucceeded {
	type: ActionType.GET_EXPENSE_CLAIMS_SUCCEEDED;
	expenseClaimsData: ExpenseClaimsData;
}
interface actionDeleteExpenseClaimSucceeded {
	type: ActionType.DELETE_EXPENSE_CLAIM_SUCCEEDED;
	id: string;
}

interface actionGetExpenseClaimSucceeded {
	type: ActionType.GET_EXPENSE_CLAIM_SUCCEEDED;
	expenseClaim: ExpenseClaim;
}

interface actionGetExpenseClaimsStarted {
	type: ActionType.GET_EXPENSE_CLAIMS_STARTED;
}

interface actionCreateExpenseClaimStarted {
	type: ActionType.CREATE_EXPENSE_CLAIM_STARTED;
}

interface actionReleaseExpenseClaimStarted {
	type: ActionType.RELEASE_EXPENSE_CLAIM_STARTED;
	id: string;
}

interface actionGetExpenseClaimStarted {
	type: ActionType.GET_EXPENSE_CLAIM_STARTED;
	id: string;
}

interface actionDeleteExpenseClaimStarted {
	type: ActionType.DELETE_EXPENSE_CLAIM_STARTED;
	id: string;
}

interface actionGetExpenseClaimsFailed {
	type: ActionType.GET_EXPENSE_CLAIMS_FAILED;
	error: any;
}

interface actionReleaseExpenseClaimFailed {
	type: ActionType.RELEASE_EXPENSE_CLAIM_FAILED;
	error: any;
	id: string;
}

interface actionDeleteExpenseClaimFailed {
	type: ActionType.DELETE_EXPENSE_CLAIM_FAILED;
	id: string;
}

interface actionCreateExpenseClaimFailed {
	type: ActionType.CREATE_EXPENSE_CLAIM_FAILED;
	errorMessage: string;
	id?: string;
	error: any;
}

interface actionDismissExpenseClaimError {
	type: ActionType.DISMISS_EXPENSE_CLAIM_ERROR;
}

interface actionGetExpenseClaimDocumentsSucceeded {
	type: ActionType.GET_EXPENSE_CLAIM_DOCUMENTS_SUCCEEDED;
	documents: Array<ExpenseClaimDocument>;
	expenseClaimId: string;
}

interface actionGetExpenseClaimDocumentsFailed {
	type: ActionType.GET_EXPENSE_CLAIM_DOCUMENTS_FAILED;
	error: any;
	expenseClaimId: string;
}

interface actionGetExpenseClaimDocumentSucceeded {
	type: ActionType.GET_EXPENSE_CLAIM_DOCUMENT_SUCCEEDED;
	expenseClaimId: string;
	documentId: string;
	files: Array<ExpenseClaimFile>;
}

interface actionDeleteExpenseClaimDocumentSucceeded {
	type: ActionType.DELETE_EXPENSE_CLAIM_DOCUMENT_SUCCEEDED;
	expenseClaimId: string;
	documentId: string;
}

interface actionDeleteExpenseClaimDocumentStarted {
	type: ActionType.DELETE_EXPENSE_CLAIM_DOCUMENT_STARTED;
	expenseClaimId: string;
	documentId: string;
}

interface actionGetExpenseClaimDocumentStarted {
	type: ActionType.GET_EXPENSE_CLAIM_DOCUMENT_STARTED;
	expenseClaimId: string;
	documentId: string;
}

interface actionGetExpenseClaimDocumentFailed {
	type: ActionType.GET_EXPENSE_CLAIM_DOCUMENT_FAILED;
	expenseClaimId: string;
	documentId: string;
}

interface actionDeleteExpenseClaimDocumentFailed {
	type: ActionType.DELETE_EXPENSE_CLAIM_DOCUMENT_FAILED;
	expenseClaimId: string;
	documentId: string;
}

interface actionGetExpenseClaimsSourcesDataStarted {
	type: ActionType.GET_EXPENSE_CLAIMS_SOURCES_DATA_STARTED;
}

interface actionGetExpenseClaimsSourcesDataSucceeded {
	type: ActionType.GET_EXPENSE_CLAIMS_SOURCES_DATA_SUCCEEDED;
	expenseClaimsSourceData: ExpenseClaimsSourceData;
}

interface actionGetExpenseClaimsSourcesDataFailed {
	type: ActionType.GET_EXPENSE_CLAIMS_SOURCES_DATA_FAILED;
}

interface actionGetLeaveRequestsStarted {
	type: ActionType.GET_LEAVE_REQUESTS_STARTED;
}

interface actionGetLeaveRequestActivitiesStarted {
	type: ActionType.GET_LEAVE_REQUEST_ACTIVITIES_STARTED;
}

interface actionGetLeaveRequestsSucceeded {
	type: ActionType.GET_LEAVE_REQUESTS_SUCCEEDED;
	accountRequests: AccountRequests;
}

interface actionGetLeaveRequestActivitiesSucceeded {
	type: ActionType.GET_LEAVE_REQUEST_ACTIVITIES_SUCCEEDED;
	activities: Array<Activity>;
}

interface actionGetLeaveRequestActivitiesFailed {
	type: ActionType.GET_LEAVE_REQUEST_ACTIVITIES_FAILED;
	error: any;
}

interface actionGetLeaveRequestsFailed {
	type: ActionType.GET_LEAVE_REQUESTS_FAILED;
	error: any;
}

interface actionPrintTimesheetStarted {
	type: ActionType.PRINT_TIMESHEET_STARTED;
}

interface actionPrintTimesheetSucceeded {
	type: ActionType.PRINT_TIMESHEET_SUCCEEDED;
	message: string;
}

interface actionPrintTimesheetFailed {
	type: ActionType.PRINT_TIMESHEET_FAILED;
}

interface actionGetPublicHolidaysStarted {
	type: ActionType.GET_PUBLIC_HOLIDAYS_STARTED;
}

interface actionGetPublicHolidaysSucceeded {
	type: ActionType.GET_PUBLIC_HOLIDAYS_SUCCEEDED;
	publicHolidays: Array<PublicHolidaysData>;
	selectedWeekHolidays: Array<PublicHolidaysData>;
}

interface actionGetPublicHolidaysFailed {
	type: ActionType.GET_PUBLIC_HOLIDAYS_FAILED;
	error: any;
}

interface actionReleaseTimesheetStarted {
	type: ActionType.RELEASE_TIMESHEET_STARTED;
}

interface actionUndoReleaseTimesheetStarted {
	type: ActionType.UNDO_RELEASE_TIMESHEET_STARTED;
}

interface actionReleaseTimesheetFailed {
	type: ActionType.RELEASE_TIMESHEET_FAILED;
	error: any;
}

interface actionReleaseTimesheetSucceeded {
	type: ActionType.RELEASE_TIMESHEET_SUCCEEDED;
}

interface actionUndoReleaseTimesheetSucceeded {
	type: ActionType.UNDO_RELEASE_TIMESHEET_SUCCEEDED;
}

interface actionClearReleaseTimesheetError {
	type: ActionType.CLEAR_RELEASE_TIMESHEET_ERROR;
}

interface actionUndoReleaseTimesheetFailed {
	type: ActionType.UNDO_RELEASE_TIMESHEET_FAILED;
	error: any;
}

interface actionGetSettingsSucceeded {
	type: ActionType.GET_SETTINGS_SUCCEEDED;
	settings: GetSettingsResponseData;
}

interface actionSaveSettingsSucceeded {
	type: ActionType.SAVE_SETTINGS_SUCCEEDED;
	settings: any;
}

interface actionSaveSettingsStarted {
	type: ActionType.SAVE_SETTINGS_STARTED;
}

interface actionSaveSettingsFailed {
	type: ActionType.SAVE_SETTINGS_FAILED;
	error: any;
}

interface actionClearSettingsSucceeded {
	type: ActionType.CLEAR_SETTINGS_SUCCEEDED;
}

interface actionGetSettingsStarted {
	type: ActionType.GET_SETTINGS_STARTED;
}

interface actionGetSettingsFailed {
	type: ActionType.GET_SETTINGS_FAILED;
	error: any;
}

interface actionSetTempDestination {
	type: ActionType.SET_TEMP_DESTINATION;
	destination: any;
}

interface actionSetWeekRangeData {
	type: ActionType.SET_WEEK_RANGE_DATA;
	weekRange: WeekRangeData;
}

interface actionSetSelectedExpenseClaimsYear {
	type: ActionType.SET_SELECTED_EXPENSE_CLAIMS_YEAR;
	expenseClaimsYear: number;
}

interface actionSetSelectedLeaveRequestsYear {
	type: ActionType.SET_SELECTED_LEAVE_REQUESTS_YEAR;
	leaveRequestsYear: number;
}

interface actionSetCalendarMonthData {
	type: ActionType.SET_CALENDAR_MONTH_DATA;
	calendarMonthData?: Date;
}

interface actionSetTimesheetRequestHours {
	type: ActionType.SET_TIMESHEET_REQUEST_HOURS;
	timesheetHours: TotalHoursGroup;
}

interface actionResetTimesheetRequestHours {
	type: ActionType.RESET_TIMESHEET_REQUEST_HOURS;
}

interface actionGetTimeSheetStarted {
	type: ActionType.GET_TIME_SHEET_STARTED;
	startedAt: number;
}

interface actionGetTimeSheetSucceeded {
	type: ActionType.GET_TIME_SHEET_SUCCEEDED;
	timesheets: Array<Timesheet>;
	startedAt?: number;
}

interface actionGetCurrentTimeSheetSucceeded {
	type: ActionType.GET_CURRENT_TIME_SHEET_SUCCEEDED;
	currentTimesheets: Array<CurrentTimesheetData>;
	startedAt: number;
}

interface actionGetTimeSheetFailed {
	type: ActionType.GET_TIME_SHEET_FAILED;
	error: any;
	startedAt: number;
}

interface actionDeleteTimeSheetLineItemStarted {
	type: ActionType.DELETE_TIME_SHEET_LINE_ITEM_STARTED;
	startedAt: number;
}

interface actionAddTimesheetStarted {
	type: ActionType.ADD_TIMESHEET_STARTED;
	startedAt: number;
}

interface actionCopyTimesheetStarted {
	type: ActionType.COPY_TIMESHEET_STARTED;
	startedAt: number;
}

interface actionGetCurrentTimeSheetStarted {
	type: ActionType.GET_CURRENT_TIME_SHEET_STARTED;
	startedAt: number;
}

interface actionAddTimesheetSucceeded {
	type: ActionType.ADD_TIMESHEET_SUCCEEDED;
	timesheets: Array<Timesheet>;
	startedAt: number;
}

interface actionDeleteTimeSheetLineItemFailed {
	type: ActionType.DELETE_TIME_SHEET_LINE_ITEM_FAILED;
	error: any;
	startedAt: number;
}

interface actionAddTimesheetFailed {
	type: ActionType.ADD_TIMESHEET_FAILED;
	error: any;
	startedAt: number;
}

interface actionCopyTimesheetFailed {
	type: ActionType.COPY_TIMESHEET_FAILED;
	startedAt: number;
	error: any;
}

interface actionGetCurrentTimeSheetFailed {
	type: ActionType.GET_CURRENT_TIME_SHEET_FAILED;
	error: any;
	startedAt: number;
}

interface actionGetTimeSheetSourceDataSucceeded {
	type: ActionType.GET_TIME_SHEET_SOURCE_DATA_SUCCEEDED;
	timesheetSourceData: TimesheetSourceDataResponseData | null;
}

interface actionGetTimeSheetSourceDataStarted {
	type: ActionType.GET_TIME_SHEET_SOURCE_DATA_STARTED;
}

interface actionGetTimeSheetSourceDataFailed {
	type: ActionType.GET_TIME_SHEET_SOURCE_DATA_FAILED;
	error: any;
}

interface actionGetUserInfoStarted {
	type: ActionType.GET_USER_INFO_STARTED;
}

interface actionGetUserInfoSucceeded {
	type: ActionType.GET_USER_INFO_SUCCEEDED;
	userInfo: UserInfo;
}

interface actionGetUserInfoFailed {
	type: ActionType.GET_USER_INFO_FAILED;
	error: any;
}

interface actionSetSelectedUser {
	type: ActionType.SET_SELECTED_USER;
	selectedUser: UserInfoAccount;
}

export type Action =
	| actionSetAuthorizationData
	| actionGetExpenseClaimApprovalRouteFailed
	| actionGetExpenseClaimApprovalRouteSucceeded
	| actionGetExpenseClaimDetailsFailed
	| actionGetExpenseClaimApprovalFailed
	| actionGetExpenseClaimApprovalProgressSucceeded
	| actionGetExpenseClaimApprovalProgressStarted
	| actionGetExpenseClaimApprovalRouteStarted
	| actionReleaseExpenseClaimSucceeded
	| actionGetExpenseClaimsSucceeded
	| actionDeleteExpenseClaimSucceeded
	| actionGetExpenseClaimSucceeded
	| actionGetExpenseClaimsStarted
	| actionCreateExpenseClaimStarted
	| actionReleaseExpenseClaimStarted
	| actionGetExpenseClaimStarted
	| actionDeleteExpenseClaimStarted
	| actionGetExpenseClaimsFailed
	| actionReleaseExpenseClaimFailed
	| actionDeleteExpenseClaimFailed
	| actionCreateExpenseClaimFailed
	| actionDismissExpenseClaimError
	| actionGetExpenseClaimDocumentsSucceeded
	| actionGetExpenseClaimDocumentsFailed
	| actionGetExpenseClaimDocumentSucceeded
	| actionDeleteExpenseClaimDocumentSucceeded
	| actionGetExpenseClaimDocumentStarted
	| actionDeleteExpenseClaimDocumentStarted
	| actionGetExpenseClaimDocumentFailed
	| actionDeleteExpenseClaimDocumentFailed
	| actionGetExpenseClaimsSourcesDataStarted
	| actionGetExpenseClaimsSourcesDataSucceeded
	| actionGetExpenseClaimsSourcesDataFailed
	| actionGetLeaveRequestsStarted
	| actionGetLeaveRequestActivitiesStarted
	| actionGetLeaveRequestsSucceeded
	| actionGetLeaveRequestActivitiesSucceeded
	| actionGetLeaveRequestActivitiesFailed
	| actionGetLeaveRequestsFailed
	| actionPrintTimesheetStarted
	| actionPrintTimesheetSucceeded
	| actionPrintTimesheetFailed
	| actionGetPublicHolidaysStarted
	| actionGetPublicHolidaysSucceeded
	| actionGetPublicHolidaysFailed
	| actionReleaseTimesheetStarted
	| actionUndoReleaseTimesheetStarted
	| actionReleaseTimesheetFailed
	| actionReleaseTimesheetSucceeded
	| actionUndoReleaseTimesheetSucceeded
	| actionClearReleaseTimesheetError
	| actionUndoReleaseTimesheetFailed
	| actionGetSettingsSucceeded
	| actionSaveSettingsSucceeded
	| actionSaveSettingsStarted
	| actionSaveSettingsFailed
	| actionClearSettingsSucceeded
	| actionGetSettingsStarted
	| actionGetSettingsFailed
	| actionSetTempDestination
	| actionSetWeekRangeData
	| actionSetSelectedExpenseClaimsYear
	| actionSetSelectedLeaveRequestsYear
	| actionSetCalendarMonthData
	| actionSetTimesheetRequestHours
	| actionResetTimesheetRequestHours
	| actionGetTimeSheetStarted
	| actionGetTimeSheetSucceeded
	| actionGetCurrentTimeSheetSucceeded
	| actionGetTimeSheetFailed
	| actionDeleteTimeSheetLineItemStarted
	| actionAddTimesheetStarted
	| actionCopyTimesheetStarted
	| actionGetCurrentTimeSheetStarted
	| actionAddTimesheetSucceeded
	| actionDeleteTimeSheetLineItemFailed
	| actionAddTimesheetFailed
	| actionCopyTimesheetFailed
	| actionGetCurrentTimeSheetFailed
	| actionGetTimeSheetSourceDataSucceeded
	| actionGetTimeSheetSourceDataStarted
	| actionGetTimeSheetSourceDataFailed
	| actionGetUserInfoStarted
	| actionGetUserInfoSucceeded
	| actionGetUserInfoFailed
	| actionSetSelectedUser;
