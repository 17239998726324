import React from 'react';

import { DialogTitle, DialogContent, Dialog, Button, Box, Typography, Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { manageWorkingHoursProps } from '../../../config/constants';
import SelectedDayCheckbox from './SelectedDayCheckbox';
import DigitalClockPicker from './DigitalClockPicker';
import { HoursOutput } from './TimeSpentSelector';
import useTranslation from '../../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	confirm: {
		textTransform: 'none',
		backgroundColor: theme.palette.primary.main,
		borderRadius: theme.spacing( 2 ),
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
	dialog: {
		maxWidth: '30em',
		margin: '0 auto',
	},
	title: {
		// fontSize: theme.spacing( 1.5 ),
	},
	daysContainer: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		flexWrap: 'wrap',
		margin: theme.spacing( 0, 2 ),
	},
	dialogPaper: {
		[ theme.breakpoints.down( 'sm' ) ]: {
			margin: theme.spacing( 3 ),
		},
		backgroundImage: 'none',
	},
	optionsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		margin: theme.spacing( 0, 2 ),
		alignItems: 'center',
	},
	optionsTitle: {
		fontSize: theme.spacing( 1.5 ),
		fontWeight: 900,
		margin: theme.spacing( 0, 1.125 ),
	},
	content: {
		padding: theme.spacing( 0 ),
	},
} ) );

interface TimePickerDialogProps {
	handleClose: () => void;
	open: boolean;
	title?: string;
	selectedDayName: string;
	onConfirm: () => void;
	useNegativeHours: ( e: React.ChangeEvent<HTMLInputElement> ) => void;
	setSelectedDay: ( e: React.ChangeEvent<HTMLInputElement> ) => void;
	setTime: ( time: HoursOutput ) => void;
	time: HoursOutput;
}
const TimePickerDialog: React.FC<TimePickerDialogProps> = ( {
	open,
	handleClose,
	onConfirm,
	title,
	setSelectedDay,
	selectedDayName,
	useNegativeHours,
	setTime,
	time,
} ) => {
	const classes = useStyles();

	const t = useTranslation();

	return (
		<Dialog
			open={ open }
			onClose={ handleClose }
			className={ classes.dialog }
			classes={ { paper: classes.dialogPaper } }
		>
			<DialogTitle className={ classes.title }>{ t( title || '' ) }</DialogTitle>
			<DialogContent classes={ { root: classes.content } }>
				<DigitalClockPicker setTime={ setTime } time={ time } />
				<Box p={ 2 }>
					<Button fullWidth className={ classes.confirm } onClick={ onConfirm }>
						{ t( 'Confirm hours' ) }
					</Button>
				</Box>
				<Box className={ classes.daysContainer }>
					{ manageWorkingHoursProps.map( ( dayProps ) => (
						<SelectedDayCheckbox
							key={ dayProps.label }
							selectedDayData={ dayProps }
							setSelectedDay={ setSelectedDay }
							selectedDayName={ selectedDayName }
						/>
					) ) }
				</Box>
				<Box className={ classes.optionsContainer }>
					<Typography className={ classes.optionsTitle }>
						{ t( 'Use negative hours' ) }
					</Typography>
					<Checkbox
						color="primary"
						inputProps={ { 'aria-label': 'Use negative hours' } }
						onChange={ useNegativeHours }
						defaultChecked={ time.hours < 0 }
					/>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default TimePickerDialog;
