import React from 'react';

import { Box } from '@mui/material';

import CalendarDesktopToolbar from './CalendarDesktopToolbar';
import Weekday from './WeekDayElement';

import CalendarControlDesktop from '../../controls/Calendar/CalendarDesktop';
import useWeekRange from '../../../hooks/useWeekRange';
import usePublicHolidays from '../../../hooks/usePublicHolidays';
import OverlayLoader from '../../OverlayLoader';
import { useSelector } from 'react-redux';
import { AppState } from '../../../reducers';

interface CalendarScreenDesktopProps { }

const CalendarScreenDesktop: React.FC<CalendarScreenDesktopProps> = () => {
	const { selectedWeekRange, setSelectedWeekRange } = useWeekRange();

	const { publicHolidays, isPublicHolidaysLoaded } = usePublicHolidays();
	const isLoadingSettings = useSelector(
		( state: AppState ) => state.settingsState.isLoading
	);

	const isLoading =
		!( selectedWeekRange && isPublicHolidaysLoaded ) || isLoadingSettings;

	return (
		<Box position="relative" flex={ isLoading ? 1 : undefined }>
			{ !isLoadingSettings && selectedWeekRange && isPublicHolidaysLoaded ? (
				<CalendarControlDesktop
					selectedWeekRange={ selectedWeekRange }
					onWeekRangeSelected={ setSelectedWeekRange }
					Toolbar={ CalendarDesktopToolbar }
					WeekDayElement={ Weekday }
					publicHolidays={ publicHolidays }
				/>
			) : (
				<Box p={ 4 }>
					<OverlayLoader
						loadingCondition={ isLoading }
						message={ 'Loading calendar data' }
					/>
				</Box>
			) }
		</Box>
	);
};

export default CalendarScreenDesktop;
