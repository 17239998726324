import config from './../config-loader';

const apiUrl = config.apiUrl;


interface IHeaders {
	'Content-Type': string;
	Accept: string;
}

interface IDefaultOptions {
	method: string;
	mode: string;
	headers: IHeaders
}

const defaultOptions: IDefaultOptions = {
	method: 'GET',
	mode: 'cors',
	headers: {
		'Content-Type': 'application/json; charset=utf-8',
		Accept: 'application/json',
	},
};

const getFetchOptions = ( options: any = {} ) => {
	const { currentOptions } = options;
	const result = {
		...defaultOptions,
		...currentOptions,
		headers: {
			...defaultOptions.headers,
			...( currentOptions && currentOptions.headers ),
		},
	};

	if ( options.token ) {
		result.headers.Authorization = `Bearer ${ options.token }`;
	}

	return result;
};

const apiFetch: any = async ( path: string, options: any ) => {
	const res = await fetch( apiUrl + path, getFetchOptions( options ) );
	const error = Object.assign( new Error( res.statusText ), {
		response: res,
		status: res.status,
	} );
	try {
		let data;

		if ( res.headers.get( 'content-length' ) === '0' ) {
			data = null;
		}
		else {
			data = await res.json();
		}

		if ( !res.ok ) {
			if ( data && data.errors ) {
				error.message = data.errors[ 0 ];
			}

			throw error;
		}

		return data;
	} catch ( _ ) {
		throw error;
	}
};

[ 'get', 'delete' ].forEach( method => {
	apiFetch[ method ] = ( path: string, options: any ) =>
		apiFetch( path, {
			currentOptions: {
				method: method.toUpperCase(),
				...( options && options.currentOptions ),
			},
			...options,
		} );
} );

[ 'post', 'put', 'patch' ].forEach( method => {
	apiFetch[ method ] = ( path: string, data: any, options: any ) =>
		apiFetch( path, {
			currentOptions: {
				method: method.toUpperCase(),
				body: data ? JSON.stringify( data ) : '',
				...( options && options.currentOptions ),
			},
			...options,
		} );
} );

const queryBuilder = ( queries: any ) =>
	Object.entries( queries )
		.map( x => x.map( ( q: any ) => encodeURIComponent( q ) ).join( '=' ) )
		.join( '&' );

export { apiFetch, apiUrl, queryBuilder };
