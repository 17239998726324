import {
	TimesheetDetailsWithEmployeeCode,
	CurrentTimesheetData,
	PublicHoliday,
} from '../types/api/timesheets';
import { DateTime } from 'luxon';
import { WeekRangeData } from '../actions/time-actions';

const weekendDaysNumbers = [ 5, 6 ];
const workingHoursPerDay = 8;

export const getTimesheetPrettyName = (
	activity: TimesheetDetailsWithEmployeeCode,
	isLoading: boolean,
	destinations: any
) => {
	const destination = activity.destination.desc;
	const name = activity.projectDescription || activity.activityDescription;
	const hours = activity.totalNumberOfHours;

	if ( !isLoading && destinations ) {
		const destinationTemplateName = Object.keys( destinations ).find(
			( d: string ) => {
				const {
					activityCode,
					projectNumber,
					SubprojectNumber: subProjectNumber,
					projectPhaseNumber,
					subTaskNumber,
				} = destinations[ d ].activity;

				return (
					projectNumber === activity?.projectNumber?.toString() &&
					subProjectNumber === activity?.subProjectNumber?.toString() &&
					activityCode === activity?.activityCode?.toString() &&
					projectPhaseNumber === activity?.projectPhaseNumber?.toString() &&
					subTaskNumber === activity?.subTaskNumber?.toString()
				);
			}
		);

		return {
			destination,
			name: destinationTemplateName || name,
			hours,
			isTemplate: !!destinationTemplateName,
			templateColor: destinationTemplateName
				? destinations[ destinationTemplateName ].templateColor
				: undefined,
		};
	}
	return {
		destination,
		name,
		hours,
		isTemplate: false,
		workingHours: 5,
		holidays: [],
	};
};

export const nextTimesheetReleaseWeekRange = (
	currentTimesheetData: CurrentTimesheetData
) => {
	const weekStartDate = DateTime.fromMillis(
		Date.parse( currentTimesheetData.timeSheetInfos[ 0 ].startDateOfWeek )
	);

	return {
		from: weekStartDate.startOf( 'week' ).toJSDate(),
		to: weekStartDate.endOf( 'week' ).toJSDate(),
	};
};

export const getOvertimeOptions = (
	activity: TimesheetDetailsWithEmployeeCode,
	publicHolidays: PublicHoliday[],
	selectedWeekRange?: WeekRangeData
) => {
	const holidays = getHolidays( publicHolidays, selectedWeekRange );

	// Backend team requirement
	const hasPubHolOvertime =
		!!holidays.find(
			( holiday ) => Number( ( activity as any )[ holiday ] ) > workingHoursPerDay
		) && activity.destination.desc === 'Project';

	const workingHours = getWorkingHours( activity, holidays, hasPubHolOvertime );

	return {
		hasSaturdayOvertime: activity.saturday > 0,
		hasSunPubHolOvertime: activity.sunday > 0 || hasPubHolOvertime,
		workingHours,
	};
};

const getHolidays = (
	publicHolidays: PublicHoliday[],
	selectedWeekRange?: WeekRangeData
) =>
	publicHolidays
		.filter( ( holiday ) => {
			const date = new Date( holiday.date );
			return (
				selectedWeekRange &&
				selectedWeekRange?.from.getTime() <= date.getTime() &&
				selectedWeekRange?.to.getTime() >= date.getTime() &&
				!weekendDaysNumbers.includes( DateTime.fromJSDate( date ).weekday )
			);
		} )
		.map( ( holiday ) =>
			DateTime.fromJSDate( new Date( holiday.date ) ).toFormat( 'EEEE' ).toLowerCase()
		);

const getWorkingHours = (
	activity: TimesheetDetailsWithEmployeeCode,
	holidays: string[],
	hasPubHolOvertime: boolean
): number =>
	activity.totalNumberOfHours -
	activity.saturday -
	activity.sunday -
	holidays.reduce( ( sum: number, holiday: string ) => {
		if ( hasPubHolOvertime && ![ 'saturday', 'sunday' ].includes( holiday ) ) {
			return sum + ( activity as any )[ holiday ] - workingHoursPerDay;
		}
		return sum;
	}, 0 );
