export const fileToBase64String = ( file: any ) =>
	new Promise( ( resolve, reject ) => {
		const reader = new FileReader();
		reader.readAsDataURL( file );
		reader.onload = () => {
			const index = ( reader.result as any ).indexOf( ';base64,' )

			if ( index === -1 ) {
				reject( new Error( 'Invalid file data' ) );
			}

			resolve( ( reader.result as any ).substring( index + 8 ) )
		}

		reader.onerror = reject
	} )

// export const fileToBase64 = ( filename, filepath ) => {
// 	return new Promise( resolve => {
// 		var file = new File( [ filename ], filepath );
// 		var reader = new FileReader();
// 		// Read file content on file loaded event
// 		reader.onload = function ( event ) {
// 			resolve( event.target.result );
// 		};

// 		// Convert data to base64 
// 		reader.readAsDataURL( file );
// 	} );
// };