import { WeekActivity } from '../../types/api/timesheets';
import { TotalHoursGroup } from './DesktopLayout';
import { WeekdayType, ManageWorkingHoursProp } from '../../types/api/common';
import { convertDecimalToTimeString } from '../../utils/date';

export const getBigCalendarTimesheetDetails = (
	hours: number,
	isNegative: boolean,
	activity?: WeekActivity
) => {
	const activityHours = Math.ceil( hours * 2 );

	const name =
		activity?.name && activity?.name.length > 10
			? activity?.name?.substring( 0, 7 ).trimEnd() + '...'
			: activity?.name;

	const activityName = activity?.name;
	const activityDetails = activity?.details.activityDescription;
	const timesheetId = activity?.details.id;
	const comment = activity?.details.comment;

	const result = [ ...Array( activityHours ) ].map( ( _, index ) => {
		if ( ( index + 1 ) % 2 === 0 ) {
			return {
				label:
					name && index < 3
						? `${ name } ${ convertDecimalToTimeString( hours ) }`
						: name
							? convertDecimalToTimeString( hours )
							: `${ ( index + 1 ) / 2 }:00`,
				rawTime: isNegative ? -( index + 1 ) / 2 : ( index + 1 ) / 2,
				activityName,
				activityDetails,
				comment,
			};
		}
		return {
			label:
				name && index < 3
					? `${ name } ${ convertDecimalToTimeString( hours ) }`
					: name
						? convertDecimalToTimeString( hours )
						: `${ ( index + 2 ) / 2 - 1 }:30`,
			rawTime: isNegative
				? -( 0.5 + ( ( index + 2 ) / 2 - 1 ) )
				: 0.5 + ( ( index + 2 ) / 2 - 1 ),
			activityName,
			activityDetails,
			timesheetId,
			comment,
		};
	} );

	return result;
};

export const getDayTotalNumberOfHours = (
	currentAccountWeekActivities: WeekActivity[]
) =>
	currentAccountWeekActivities.length > 0
		? currentAccountWeekActivities
			.filter(
				( activity ) =>
					activity.details.monday >= 0 &&
					activity.details.tuesday >= 0 &&
					activity.details.wednesday >= 0 &&
					activity.details.thursday >= 0 &&
					activity.details.friday >= 0 &&
					activity.details.saturday >= 0 &&
					activity.details.sunday >= 0
			)
			.reduce(
				( totalHours: TotalHoursGroup, currentActivity: WeekActivity ) => {
					return {
						...totalHours,
						hoursMonday:
							currentActivity.details.monday + ( totalHours.hoursMonday || 0 ),
						hoursTuesday:
							currentActivity.details.tuesday +
							( totalHours.hoursTuesday || 0 ),
						hoursWednesday:
							currentActivity.details.wednesday +
							( totalHours.hoursWednesday || 0 ),
						hoursThursday:
							currentActivity.details.thursday +
							( totalHours.hoursThursday || 0 ),
						hoursFriday:
							currentActivity.details.friday + ( totalHours.hoursFriday || 0 ),
						hoursSaturday:
							currentActivity.details.saturday +
							( totalHours.hoursSaturday || 0 ),
						hoursSunday:
							currentActivity.details.sunday + ( totalHours.hoursSunday || 0 ),
					};
				},
				{
					hoursMonday: 0,
					hoursTuesday: 0,
					hoursWednesday: 0,
					hoursThursday: 0,
					hoursFriday: 0,
					hoursSaturday: 0,
					hoursSunday: 0,
				}
			)
		: {
			hoursMonday: 0,
			hoursTuesday: 0,
			hoursWednesday: 0,
			hoursThursday: 0,
			hoursFriday: 0,
			hoursSaturday: 0,
			hoursSunday: 0,
		};

export const getTimesheetHourProp = ( currentDay: string ): WeekdayType =>
	currentDay.split( 'hours' )[ 1 ].toLowerCase() as WeekdayType;

export const getNegativeHoursLabel = (
	activity: any,
	weekDayProp: ManageWorkingHoursProp
) => {
	return ` ${ activity.name.substring( 0, 10 ).trimEnd() +
		`${ activity.name.length > 10 ? '...' : '' }`
		} -${ convertDecimalToTimeString(
			Math.abs( activity.details[ getTimesheetHourProp( weekDayProp.name ) ] )
		) }`;
};
