import React, { useState, useCallback } from 'react';

import { Box, Typography, Paper, Badge, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
	Euro,
	Save,
	Undo,
	AttachFile as AttachmentIcon,
} from '@mui/icons-material';
import clsx from 'clsx';

import DeleteConfirm from '../ManageTimesheet/DeleteConfirm';
import DividerToggle from '../../DividerToggle';
import OverlayLoader from '../../OverlayLoader';
import useTranslation from '../../../hooks/useTranslation';
import useExpenseClaimsData from '../../../hooks/useExpenseClaimsData';
import { expenseClaimStatuses } from './expenses-utils';

const useStyles = makeStyles( ( theme ) => ( {
	paper: {
		boxShadow: `0px 1px 16px ${ theme.palette.custom.boxShadow }`,
		color: theme.palette.text.primary,
		'&:hover': {
			cursor: 'pointer',
		},
		margin: theme.spacing( 0, 1.5 ),
	},
	container: {
		padding: theme.spacing( 1 ),
	},
	actionContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: theme.spacing( 0, 2, 2 ),
	},
	sectionContainer: {
		display: 'flex',
		alignItems: 'center',
		flex: 1,
	},
	actionFlex: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flex: 1,
	},
	infoContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		paddingRight: theme.spacing( 1 ),
	},
	disabledAction: {
		opacity: '0.5',
	},
	status: {
		borderRadius: theme.spacing( 0.5 ),
		textAlign: 'center',
		padding: theme.spacing( 0.5 ),
		color: theme.palette.primary.contrastText,
		fontSize: theme.spacing( 1.75 ),
		alignSelf: 'center',
		fontWeight: 900,
	},
	disabled: {
		opacity: '0.5',
		margin: theme.spacing( 0, 1.5 ),
	},
	action: {
		'& :hover': {
			cursor: 'pointer',
		},
	},
	amount: {
		fontWeight: 900,
	},
	icon: {
		fontSize: theme.spacing( 10 ),
		padding: theme.spacing( 0, 2, 0, 0 ),
		opacity: '0.5',
	},
	currency: {
		fontSize: theme.spacing( 1.75 ),
		fontWeight: 900,
	},
	info: {
		padding: theme.spacing( 0.25, 0, 0, 0 ),
		fontSize: theme.spacing( 1.75 ),
	},
	flexSection: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	customDelete: {
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.background.paper,
		'&:hover': {
			backgroundColor: theme.palette.background.paper,
		},
		textTransform: 'none',
	},
	fontOptions: {
		fontSize: theme.spacing( 1.75 ),
	},
	badgeCircle: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		zIndex: 0,
	},
	badge: {
		margin: theme.spacing( 1, 1, 0, 0 ),
	},
} ) );

export interface ExpenseClaimTemplateCardProps {
	amount: string;
	status: string;
	id: string;
	date: string;
	codeDescription: string;
	description: string;
	onSelect: ( id: string ) => void;
	onRelease: ( id: string ) => void;
	onDelete: ( id: string ) => void;
	className?: string;
	disabled?: boolean;
	statusId: string;
}

const ExpenseClaimTemplateCard: React.FC<ExpenseClaimTemplateCardProps> = ( {
	amount,
	date,
	id,
	onSelect,
	className,
	disabled = false,
	description,
	status,
	codeDescription,
	onRelease,
	onDelete,
	statusId,
} ) => {
	const classes = useStyles();
	const theme = useTheme();
	const [ isActionContainerVisible, setIsActionContainerVisible ] =
		useState<boolean>( true );

	const containerClass = !disabled
		? clsx( classes.container, classes.action )
		: classes.container;

	const { isLoadingExpenseClaim } = useExpenseClaimsData( id );

	const onToggleActionContainer = useCallback( () => {
		setIsActionContainerVisible( !isActionContainerVisible );
	}, [ setIsActionContainerVisible, isActionContainerVisible ] );

	const onClickHandler = useCallback( () => {
		if ( !disabled ) {
			onSelect( id );
		}
	}, [ onSelect, disabled, id ] );

	const onDeleteHandler = useCallback( () => {
		onDelete( id );
	}, [ onDelete, id ] );

	const onReleaseHandler = useCallback( () => {
		if ( !disabled && Number( statusId ) < 2 ) {
			onRelease( id );
		}
	}, [ onRelease, disabled, id, statusId ] );

	const deleteClass =
		Number( statusId ) > 0
			? clsx( classes.actionFlex, classes.disabledAction )
			: classes.actionFlex;

	const releaseClass =
		Number( statusId ) > 1
			? clsx( classes.actionFlex, classes.disabledAction )
			: classes.actionFlex;

	const t = useTranslation();

	const numberOfFiles = useExpenseClaimsData( id ).documents.length;

	return (
		<Box className={ className } m={ 2 } position="relative">
			<OverlayLoader
				loadingCondition={ isLoadingExpenseClaim }
				message={ t( 'Action proceed' ) }
			/>
			<Paper className={ disabled ? classes.disabled : classes.paper }>
				<Box className={ containerClass } onClick={ onClickHandler }>
					<Box className={ classes.flexSection }>
						<Typography
							className={ classes.status }
							style={ {
								backgroundColor:
									theme.palette.custom[ expenseClaimStatuses[ statusId ] ],
							} }
						>
							{ status }
						</Typography>
						{ numberOfFiles ? (
							<Badge
								badgeContent={ numberOfFiles }
								classes={ { badge: classes.badgeCircle, root: classes.badge } }
							>
								<AttachmentIcon />
							</Badge>
						) : null }
					</Box>
					<Box className={ classes.flexSection }>
						<Box className={ classes.infoContainer }>
							<Typography className={ classes.info }>
								{ codeDescription }
							</Typography>
							<Typography className={ classes.info }>{ description }</Typography>
						</Box>
						<Box>
							<Typography variant="h4">{ date }</Typography>
							<Box className={ classes.sectionContainer }>
								<Euro className={ classes.currency } />
								<Typography className={ classes.amount }>{ amount }</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
				<DividerToggle
					expandMore={ isActionContainerVisible }
					onToggle={ onToggleActionContainer }
				/>
				{ isActionContainerVisible && (
					<Box className={ classes.actionContainer }>
						<Box className={ releaseClass } onClick={ onReleaseHandler }>
							{ statusId === '1' ? (
								<Undo className={ classes.fontOptions } />
							) : (
								<Save className={ classes.fontOptions } />
							) }
							<Typography className={ classes.fontOptions }>
								{ statusId === '0' ? t( 'Release' ) : t( 'Back to draft' ) }
							</Typography>
						</Box>
						{
							<Box className={ deleteClass }>
								<DeleteConfirm
									action={ statusId === '0' ? onDeleteHandler : undefined }
									customButtonClass={ classes.customDelete }
									customFontClass={ classes.fontOptions }
								/>
							</Box>
						}
					</Box>
				) }
			</Paper>
		</Box>
	);
};
export default ExpenseClaimTemplateCard;
