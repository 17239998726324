let config = {
	tenantId: '80a5cb6b-ae21-4ea8-bd3f-25e005cefc5b',
	clientId: 'c71d0be6-d042-4949-824c-9d55eb2f6668',
	apiUrl: 'https://ontime-acc-api.ictgroup.eu',
	appUrl: 'http://localhost:3000',
	apiScope: 'api://583e0d45-c18e-49e3-8f81-8bdf23808349/OnTime',
	sentryDsn: 'https://00b4bd78f7fc4196b19538e1ca4dab21@o398716.ingest.sentry.io/5879605',
	env: 'development',
}

if ( window && window.config ) {
	config.tenantId = window.config.tenantId ? window.config.tenantId : config.tenantId;
	config.clientId = window.config.clientId ? window.config.clientId : config.clientId;
	config.apiUrl = window.config.apiUrl ? window.config.apiUrl : config.apiUrl;
	config.appUrl = window.config.appUrl ? window.config.appUrl : config.appUrl;
	config.apiScope = window.config.apiScope ? window.config.apiScope : config.apiScope;
	config.sentryDsn = window.config.sentryDsn ? window.config.sentryDsn : config.sentryDsn;
	config.env = window.config.env ? window.config.env : config.env;
}

export default config;
