import React, { FC, useCallback } from 'react';
import Dropdown from '../../controls/Dropdown';
import IndirectActivityForm from './IndirectActivityForm';
import ProjectForm from './ProjectForm';
import { DestinationFormProps } from './DestinationFormTypes';
import { AppState } from '../../../reducers';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { Activity, ProjectActivity } from '../../../types/api/timesheets';
import { setSelectedUser } from '../../../actions/api-actions';
import { ColorPickerProps } from './ColorPicker';

const getDestinationForm = ( destinationType: string ) => {
	switch ( destinationType ) {
		case 'Indirect or absent':
			return IndirectActivityForm;
		case 'Project':
			return ProjectForm;
		default:
			return null;
	}
};

export interface DestinationConfigurationFormProps {
	autoExpand: boolean;
	destinationType: string;
	onDestinationTypeChange: ( destinationType: string ) => void;
	formValues: any;
	onFormValuesChange: ( newFormValues: string ) => void;
	onFormSubmit: (
		selectedActivity?: ProjectActivity | Activity,
		skipSave?: boolean
	) => void;
	isLoading: boolean;
	ColorPicker: React.ReactElement<ColorPickerProps>;
}

const DestinationConfigurationForm: React.FC<DestinationConfigurationFormProps> = ( {
	autoExpand,
	destinationType,
	onDestinationTypeChange,
	formValues,
	onFormValuesChange,
	onFormSubmit,
	isLoading,
	ColorPicker,
} ) => {
	const dispatch = useDispatch();
	const userAccounts = useSelector(
		( state: AppState ) => state.userState.accounts
	);

	const userCompanies = userAccounts.map(
		( userAccount ) => userAccount.companyName
	);

	// Display the appropriate form based on the selected destination
	let SelectedDestinationForm: FC<
		DestinationFormProps
	> | null = getDestinationForm( destinationType );

	const onSelectAccount = useCallback(
		( value ) => {
			const newAccountIndex = userAccounts.findIndex(
				( userAccount ) => userAccount.companyName === value
			);
			onDestinationTypeChange( destinationType );
			dispatch( setSelectedUser( userAccounts[ newAccountIndex ] ) );
		},
		[ onDestinationTypeChange, dispatch, destinationType, userAccounts ]
	);

	const selectedAccount = useSelector(
		( state: AppState ) => state.userState.selectedUser
	);

	const selectedCompanyName = selectedAccount
		? selectedAccount.companyName
		: '';

	return (
		<>
			<Box
				marginLeft={ 2 }
				marginRight={ 2 }
				display={ userAccounts.length > 1 ? 'block' : 'none' }
			>
				<Dropdown
					autoExpand={ false }
					label={ 'Company' }
					options={ userCompanies }
					selectedValue={ selectedCompanyName }
					onValueChange={ onSelectAccount }
				/>
			</Box>
			{ selectedAccount || userAccounts.length === 1 ? (
				<>
					<Box marginLeft={ 2 } marginRight={ 2 }>
						<Dropdown
							autoExpand={ autoExpand }
							label={ 'Destination' }
							options={ [ 'Indirect or absent', 'Project' ] }
							selectedValue={ destinationType }
							onValueChange={ ( value ) => {
								onDestinationTypeChange( value );
							} }
						/>
					</Box>
					{ SelectedDestinationForm ? (
						<SelectedDestinationForm
							values={ formValues }
							onValuesChange={ onFormValuesChange }
							onSubmit={ onFormSubmit }
							autoExpand={ autoExpand }
							isLoadingData={ isLoading }
							employeeCode={
								selectedAccount?.employeeCode || userAccounts[ 0 ].employeeCode
							}
							ColorPicker={ ColorPicker }
						/>
					) : null }
				</>
			) : null }
		</>
	);
};

export default DestinationConfigurationForm;
