import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles( ( theme ) => ( {
	timesheetList: {
		width: '100%',
		position: 'relative',
		padding: theme.spacing( 0, 0.5, 0, 0 ),
		'&:first-child': {
			padding: theme.spacing( 0, 0.5, 0, 0.5 ),
		},
		'&:hover': {
			'& li:nth-child(n+2)': {
				background: theme.palette.primary.main,
				opacity: 0.5,
				color: theme.palette.primary.main,
				borderColor: theme.palette.primary.main,
				'&:hover': {
					color: theme.palette.primary.contrastText,
					borderColor: theme.palette.primary.main,
					display: 'flex',
					justifyContent: 'flex-end',
					opacity: 0.8,
				},
				cursor: 'pointer',
			},
		},
		'& li:hover ~li:nth-child(n)': {
			background: theme.palette.background.default,
			color: theme.palette.background.default,
			borderColor: theme.palette.custom.paper,
		},
		'& li:nth-child(n)': {
			background: theme.palette.background.default,
			color: theme.palette.background.default,
			borderStyle: 'solid',
			borderColor: theme.palette.custom.paper,
			borderWidth: theme.spacing( 0.1 ),
			height: 'calc((100vh - 22rem)/16)',
		},
	},
	weekSelector: {},
	weekDays: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontWeight: 800,
		fontSize: theme.spacing( 1.5 ),
		color: theme.palette.text.disabled,
		height: '2.5rem',
		'&:hover': {
			background: theme.palette.custom.paper,
		},
	},
	holiday: {
		textAlign: 'center',
		color: theme.palette.secondary.main,
		fontSize: theme.spacing( 1.3 ),
		fontWeight: 900,
		padding: theme.spacing( 0 ),
		height: '2rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		overflow: 'hidden',
	},
	weekDayData: {
		color: theme.palette.text.disabled,
		fontSize: theme.spacing( 1.3 ),
		fontWeight: 900,
		padding: theme.spacing( 0 ),
		height: '2rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		overflow: 'hidden',
	},
	summaryContainer: {
		display: 'flex',
	},
	hoursItem: {
		position: 'relative',
	},
	description: {
		position: 'absolute',
		color: theme.palette.primary.contrastText,
		padding: theme.spacing( 1.25, 0.75 ),
		zIndex: 1,
		fontWeight: 900,
		cursor: 'pointer',
		maxWidth: '90%',
		display: 'flex',
		flexDirection: 'column',
	},
	activityName: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		fontWeight: 700,
		fontSize: theme.spacing( 1.5 ),
	},
	activityDesc: {
		fontSize: theme.spacing( 1.5 ),
	},
	activityTextBox: {
		fontSize: theme.spacing( 1.5 ),
		minWidth: '90%'
	},
	released: {
		cursor: 'default',
		opacity: 0.6,
	},
	activityData: {
		display: 'flex',
		alignItems: 'start',
		gap: theme.spacing( 1 ),

	},
	shortActivity: {
		fontSize: theme.spacing( 1.5 ),
		fontWeight: 700,
		alignSelf: 'center',
	},
} ) );

export const getStyles = (
	theme: Theme,
	destination: string,
	index: number,
	lastIndex: number,
	isReleased: boolean,
	templateColor?: string
) => {
	const textColor = { color: theme.palette.primary.contrastText };

	if ( index !== lastIndex ) {
		textColor.color = templateColor
			? templateColor
			: destination === 'Project'
				? theme.palette.primary.main
				: theme.palette.secondary.main;
	}

	const borderBottomColor =
		index === lastIndex ? theme.palette.custom.paper : undefined;

	const defaultStyles = {
		...textColor,
		display: 'flex',
		justifyContent: 'flex-end',
		fontSize: theme.spacing( 1.5 ),
		borderTopLeftRadius: index === 0 ? '5px' : 0,
		borderTopRightRadius: index === 0 ? '5px' : 0,
		borderBottomLeftRadius: index === lastIndex ? '5px' : 0,
		borderBottomRightRadius: index === lastIndex ? '5px' : 0,
		borderRightColor: theme.palette.custom.paper,
		borderLeftColor: theme.palette.custom.paper,
		marginRight: '5px',
		opacity: isReleased ? 0.6 : 1,
		cursor: isReleased ? 'default' : 'pointer',
	};

	if ( templateColor ) {
		return {
			...defaultStyles,
			backgroundColor: templateColor,
			borderTopColor: templateColor,
			borderBottomColor: borderBottomColor || templateColor,
		};
	}
	if ( destination === 'Project' ) {
		return {
			...defaultStyles,
			backgroundColor: theme.palette.primary.main,
			borderTopColor: theme.palette.primary.main,
			borderBottomColor: borderBottomColor || theme.palette.primary.main,
		};
	}

	return {
		...defaultStyles,
		backgroundColor: theme.palette.secondary.main,
		borderTopColor: theme.palette.secondary.main,
		borderBottomColor: borderBottomColor || theme.palette.secondary.main,
	};
};
