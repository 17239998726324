export const expenseClaimStatuses: Record<
	string,
	| 'statusEntered'
	| 'statusReleased'
	| 'statusVerified'
	| 'statusApproved'
	| 'statusOutstanding'
	| 'statusInProgress'
	| 'statusPaymentInProgress'
	| 'statusSettled'
> = {
	'0': 'statusEntered',
	'1': 'statusReleased',
	'2': 'statusVerified',
	'3': 'statusApproved',
	'4': 'statusOutstanding',
	'5': 'statusInProgress',
	'6': 'statusPaymentInProgress',
	'7': 'statusSettled',
};