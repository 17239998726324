import React from 'react';
import { Box } from '@mui/material';

const ManageTimesheetWrapper: React.FC = ( { children } ) => {
	return (
		<Box
			flex="1"
			display="flex"
			flexDirection="column"
			height="calc( 100vh - 11rem )"
			overflow="auto"
		>
			{ children }
		</Box>
	);
};

export default ManageTimesheetWrapper;
