import React from 'react';

import { Box, Typography, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useTranslation from '../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	messageBox: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '0 auto',
		padding: theme.spacing( 0, 5, 0, 5 ),
		gap: theme.spacing( 0.5 ),
	},
	message: {
		fontSize: theme.spacing( 1.7 ),
		marginBottom: theme.spacing( 0.5 ),
	},
	messageLink: {
		fontSize: theme.spacing( 1.7 ),
		textDecoration: 'none',
		color: theme.palette.primary.main,
	},
	divider: {
		height: theme.spacing( 0.15 ),
		margin: theme.spacing( 1, 0, 2 ),
	},
} ) );

interface SettingsContactLineProps { }

const SettingsContactLine: React.FC<SettingsContactLineProps> = () => {
	const classes = useStyles();
	const t = useTranslation();

	return (
		<Box className={ classes.messageBox }>
			<Typography>
				<a
					className={ classes.messageLink }
					href="https://humiqtrial.sharepoint.com/:b:/r/sites/ICTToday-IT/Shared%20Documents/OnTime%20Instructions.pdf?csf=1&web=1&e=khpSct"
					target="_blank"
					rel="noreferrer"
				>
					{ t( 'User manual' ) }
				</a>
			</Typography>
			<Typography>
				<a className={ classes.messageLink } href="mailto:helpdesk@ict.nl">
					{ t( 'Contact the helpdesk.' ) }
				</a>
			</Typography>
			<Divider className={ classes.divider } />
		</Box>
	);
};

export default SettingsContactLine;
