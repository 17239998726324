import { ManageWorkingHoursProp } from '../types/api/common';
import { PrintPeriodType, PrintDetailsType } from '../types/api/timesheets';

export const formWeekdaysFields = [
	'hoursMonday',
	'hoursTuesday',
	'hoursWednesday',
	'hoursThursday',
	'hoursFriday',
	'hoursSaturday',
	'hoursSunday',
];

export const weekendsHoursProps = [ 'hoursSaturday', 'hoursSunday' ];

export const manageWorkingHoursProps: Array<ManageWorkingHoursProp> = [
	{
		label: 'monday',
		name: 'hoursMonday',
	},
	{
		label: 'tuesday',
		name: 'hoursTuesday',
	},
	{
		label: 'wednesday',
		name: 'hoursWednesday',
	},
	{
		label: 'thursday',
		name: 'hoursThursday',
	},
	{
		label: 'friday',
		name: 'hoursFriday',
	},
	{
		label: 'saturday',
		name: 'hoursSaturday',
	},
	{
		label: 'sunday',
		name: 'hoursSunday',
	},
];

export const printTimesheetOptions: Array<{
	label: string;
	value: PrintPeriodType;
}> = [
		{
			label: 'This week',
			value: 'THISWEEK',
		},
		{
			label: 'Last week',
			value: 'LASTWEEK',
		},
		{
			label: 'This month',
			value: 'THISMONTH',
		},
		{
			label: 'Last month',
			value: 'LASTMONTH',
		},
		{
			label: 'This quarter',
			value: 'THISQUARTER',
		},
		{
			label: 'Last quarter',
			value: 'LASTQUARTER',
		},
		{
			label: 'Last three months',
			value: 'LASTTHREEMONTHS',
		},
	];

export const printExportTypeOptions: Array<PrintDetailsType> = [
	'Day',
	'Week',
	'Month',
];

export const defaultTimesheetHours = {
	hoursMonday: 0,
	hoursTuesday: 0,
	hoursWednesday: 0,
	hoursThursday: 0,
	hoursFriday: 0,
	hoursSaturday: 0,
	hoursSunday: 0,
}

// Auto login time is two(2) seconds
export const autoLoginTime = 2000;

// Working hours per day
export const workingHours = 8;
