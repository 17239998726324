import React, { useState, useCallback } from 'react';

import { Fab, Menu, Fade, MenuItem, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import CopyIcon from '@mui/icons-material/FileCopy';
import PrintIcon from '@mui/icons-material/Print';

import { Add } from '@mui/icons-material';
import ExpandIcon from '@mui/icons-material/MoreVert';

import ReleaseTimesheetUI, {
	ReleaseTimesheetUIProps,
} from '../ReleaseTimesheet/ReleaseTimesheetUI';
import CopyTimesheet from './CopyTimesheetForm';
import { useLocation, useNavigate } from 'react-router-dom';
import { DestinationNavProps } from '../../../hooks/useDestinationNavSettings';
import PrintTimesheetForm from '../PrintTimesheet/PrintTimesheetForm';
import useTranslation from '../../../hooks/useTranslation';

export interface ManageDraftsMenuProps {
	releaseTimesheetUIProps: ReleaseTimesheetUIProps;
	addHoursProps: DestinationNavProps;
}

const useStyles = makeStyles( ( theme ) => ( {
	menuPaper: {
		backgroundColor: 'rgba(255, 255, 255, 0)',
		boxShadow: `0px 1px 16px ${ theme.palette.custom.boxShadow }`,
	},
	menuList: {
		backgroundColor: theme.palette.primary.main,
	},
	buttonsGroup: {
		position: 'absolute',
		bottom: theme.spacing( 1 ),
		right: '12.5%',
		transform: 'translate( 50%, 0 )',
		'@media (max-height: 420px)': {
			bottom: theme.spacing( 1 ),
		},
	},
	menuItem: {
		opacity: 0.9,
		backgroundColor: theme.palette.background.default,
		'&:hover': { backgroundColor: theme.palette.background.default },
		textTransform: 'none',
		borderRadius: theme.spacing( 0 ),
		display: 'flex',
		justifyContent: 'flex-end',
		paddingRight: 0,
	},
	menuItemLabel: {
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.primary,
		fontWeight: 900,
		fontSize: theme.spacing( 1.75 ),
		padding: theme.spacing( 2 ),
	},
	fabIcon: {
		margin: theme.spacing( 0, 1 ),
	},
} ) );

const ManageDraftsMenu: React.FC<ManageDraftsMenuProps> = ( {
	releaseTimesheetUIProps,
	addHoursProps,
} ) => {
	const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>( null );

	const [ isCopyTimesheetFormOpen, setIsCopyTimesheetFormOpen ] = useState<
		boolean
	>( false );

	const [ isPrintTimesheetFormOpen, setIsPrintTimesheetFormOpen ] = useState<
		boolean
	>( false );

	const classes = useStyles();

	const location = useLocation();
	const navigate = useNavigate();

	const onAddHoursClick = useCallback( () => {
		navigate( addHoursProps.path, {
			state: {
				previous: location.pathname,
			}
		} );
	}, [ navigate, addHoursProps, location.pathname ] );
	const handleMenuOpenEvent = useCallback(
		( event: React.MouseEvent<HTMLButtonElement> ) => {
			setAnchorEl( event.currentTarget );
		},
		[]
	);

	const onCloseMenu = useCallback( () => {
		setAnchorEl( null );
	}, [] );

	const onOpenCopyTimesheetForm = useCallback( () => {
		setIsCopyTimesheetFormOpen( true );
		setAnchorEl( null );
	}, [] );

	const onCloseCopyTimesheetForm = useCallback( () => {
		setIsCopyTimesheetFormOpen( false );
	}, [] );

	const onOpenPrintTimesheetForm = useCallback( () => {
		setIsPrintTimesheetFormOpen( true );
		setAnchorEl( null );
	}, [] );

	const onClosePrintTimesheetForm = useCallback( () => {
		setIsPrintTimesheetFormOpen( false );
	}, [] );

	const t = useTranslation();

	return (
		<>
			<Fab
				color="primary"
				className={ classes.buttonsGroup }
				aria-controls="manage-drafts-menu"
				onClick={ handleMenuOpenEvent }
			>
				<ExpandIcon />
			</Fab>
			<Menu
				id="manage-drafts-menu"
				aria-haspopup="true"
				anchorEl={ anchorEl }
				keepMounted
				open={ Boolean( anchorEl ) }
				onClose={ onCloseMenu }
				onClick={ onCloseMenu }
				TransitionComponent={ Fade }
				elevation={ 0 }
				// getContentAnchorEl={ null }
				anchorOrigin={ {
					vertical: 'top',
					horizontal: 'right',
				} }
				transformOrigin={ {
					vertical: 'bottom',
					horizontal: 'right',
				} }
				classes={ { paper: classes.menuPaper } }
			>
				<MenuItem
					aria-label={ addHoursProps.ariaLabel }
					className={ classes.menuItem }
					color="primary"
					onClick={ onAddHoursClick }
				>
					<Typography className={ classes.menuItemLabel }>
						{ t( addHoursProps.title ) }
					</Typography>
					<Fab color="primary" size="small" className={ classes.fabIcon }>
						<Add />
					</Fab>
				</MenuItem>
				<MenuItem
					className={ classes.menuItem }
					color="primary"
					onClick={ onOpenCopyTimesheetForm }
					aria-label={ 'Copy hours' }
				>
					<Typography className={ classes.menuItemLabel }>
						{ t( 'Copy hours' ) }
					</Typography>
					<Fab size="small" color="primary" className={ classes.fabIcon }>
						<CopyIcon />
					</Fab>
				</MenuItem>
				<ReleaseTimesheetUI { ...releaseTimesheetUIProps } />
				<MenuItem
					className={ classes.menuItem }
					color="primary"
					onClick={ onOpenPrintTimesheetForm }
					aria-label={ 'Print timesheet' }
				>
					<Typography className={ classes.menuItemLabel }>
						{ t( 'Print timesheet' ) }
					</Typography>
					<Fab size="small" color="primary" className={ classes.fabIcon }>
						<PrintIcon />
					</Fab>
				</MenuItem>
				<CopyTimesheet
					isOpen={ isCopyTimesheetFormOpen }
					onClose={ onCloseCopyTimesheetForm }
				/>
				<PrintTimesheetForm
					isOpen={ isPrintTimesheetFormOpen }
					onClose={ onClosePrintTimesheetForm }
				/>
			</Menu>
		</>
	);
};

export default ManageDraftsMenu;
