import React from 'react';

import Dropdown from '../../controls/Dropdown';

interface ExpenseClaimsCodesProps {
	expenseClaimsDescriptions: Array<string>;
	selectedValue?: string;
	selectClass: string;
	onChangeValue: ( value: string ) => void;
}

const ExpenseClaimsCodes: React.FC<ExpenseClaimsCodesProps> = ( {
	expenseClaimsDescriptions,
	selectedValue,
	onChangeValue,
	selectClass,
} ) => {

	return (
		<Dropdown
			label="Code"
			options={ expenseClaimsDescriptions }
			onValueChange={ onChangeValue }
			selectedValue={ selectedValue || '' }
			autoExpand={ !selectedValue }
			selectClass={ selectClass }
		/>
	);
};

export default ExpenseClaimsCodes;
