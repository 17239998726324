import React, { useState, useCallback, useContext } from 'react';
import { Close } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../reducers';
import { saveSettings } from '../../actions/api-actions';
import useTranslation from '../../hooks/useTranslation';
import { AddToHomeScreenContext } from '../Providers/AddToHmeScreenProvider';

const useStyles = makeStyles( ( theme ) => ( {
	container: {
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.background.paper,
		width: '100%',
		bottom: theme.spacing( 10 ),
		opacity: '0.9',
		display: 'flex',
		alignItems: 'center',
		borderRadius: theme.spacing( 0.25 ),
		position: 'absolute',
		justifyContent: 'space-between',
		zIndex: 999,
		boxShadow: `0px 1px 16px ${ theme.palette.custom.strongBoxShadow }`,
	},
	button: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
		textTransform: 'none',
		fontSize: theme.spacing( 1.6 ),
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
	text: {
		fontWeight: 900,
		fontSize: theme.spacing( 1.6 ),
		padding: theme.spacing( 1 ),
	},
} ) );

const AddToHomeScreenPrompt = () => {
	const dispatch = useDispatch();

	// const [ prompt, promptToInstall ] = useAddToHomeScreenPrompt();

	const { prompt, promptToInstall } = useContext( AddToHomeScreenContext )

	const [ isVisible, setVisibleState ] = useState( false );

	const classes = useStyles();

	const settings = useSelector(
		( state: AppState ) => state.settingsState.settings
	);

	const hide = useCallback( () => {
		setVisibleState( false );
		dispatch( saveSettings( { ...settings, skipPwaInstall: 'skip' } ) );
	}, [ dispatch, settings ] );

	const skipPwaInstall = !!settings ? settings.skipPwaInstall : undefined;

	React.useEffect( () => {
		if ( prompt && skipPwaInstall !== 'skip' ) {
			setVisibleState( true );
		} else if ( skipPwaInstall === 'skip' ) {
			setVisibleState( false );
		}
	}, [ prompt, skipPwaInstall, setVisibleState ] );

	const t = useTranslation();

	if ( !isVisible || !settings ) {
		return <div />;
	}

	return (
		<Box className={ classes.container } p={ 2 }>
			<Close onClick={ hide } />
			<Typography className={ classes.text }>
				{ t( 'Add OnTime to your home screen' ) }
			</Typography>
			<Button className={ classes.button } onClick={ promptToInstall }>
				{ t( 'Add' ) }
			</Button>
		</Box>
	);
};

export default AddToHomeScreenPrompt;
