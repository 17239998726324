import React from 'react';
import { Box } from '@mui/material';

import { DateTime } from 'luxon';

import LeaveRequestItemTemplateCardDesktop from './LeaveRequestItemTemplateCardDesktop';
import { getLeaveItemTitle } from './leave-utils';
import { LeaveRequest } from '../../../types/api/leaveRequests';
import { days, convertDecimalToTimeString } from '../../../utils/date';
import useLanguage from '../../../hooks/useLanguage';
import { AppState } from '../../../reducers';
import { useSelector } from 'react-redux';

export interface LeaveItemsDesktopProps {
	leaves: Array<LeaveRequest>;
	action: ( id: string ) => void;
	leaveRequest?: LeaveRequest;
}

const LeaveItemsDesktop: React.FC<LeaveItemsDesktopProps> = ( {
	leaves,
	action,
	leaveRequest,
} ) => {
	const language = useLanguage();

	const { activities } = useSelector(
		( state: AppState ) => state.leaveRequestState
	);

	return (
		<Box flex="1">
			{ leaves.map( ( leave, index ) => {
				const isSelected = leaveRequest && leaveRequest.id === leave.id;
				if ( !leave ) {
					return null;
				}

				const startOfWeekDay = DateTime.fromISO( leave.startDateOfWeek );
				const endOfWeekDay = DateTime.fromISO( leave.endDateOfWeek );
				const leaveDayName = days.find( ( day ) => {
					return leave[ day ] !== 0;
				} );

				const activityName =
					activities.find(
						( activity ) => activity.activityCode === leave.activityCode
					)?.activityDescription || 'Leave';
				const activityPrefix =
					activityName.length > 10
						? activityName.substr( 0, 10 ) + '...'
						: activityName;

				if ( days.filter( ( day ) => leave[ day ] !== 0 ).length === 1 ) {
					if ( !leaveDayName ) {
						return null;
					}

					return (
						<LeaveRequestItemTemplateCardDesktop
							title={ getLeaveItemTitle(
								startOfWeekDay,
								endOfWeekDay,
								activityPrefix,
								language,
								leaveDayName
							) }
							status={ leave.status }
							time={
								leave.numberOfHours
									? convertDecimalToTimeString( leave.numberOfHours )
									: 'N/A'
							}
							key={ index }
							onSelect={ action }
							id={ leave.id }
							isSelected={ isSelected }
						/>
					);
				} else {
					return (
						<LeaveRequestItemTemplateCardDesktop
							title={ getLeaveItemTitle(
								startOfWeekDay,
								endOfWeekDay,
								activityPrefix,
								language
							) }
							time={
								leave.numberOfHours
									? convertDecimalToTimeString( leave.numberOfHours )
									: 'N/A'
							}
							status={ leave.status }
							key={ index }
							onSelect={ action }
							id={ leave.id }
							isSelected={ isSelected }
						/>
					);
				}
			} ) }
		</Box>
	);
};

export default LeaveItemsDesktop;
