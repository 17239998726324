import api from "../../middleware/api";
import { ExpenseClaimDocument, ExpenseClaimFile } from "../../types/api/expenseClaims";

export const GET_EXPENSE_CLAIM_DOCUMENTS_STARTED =
	'GET_EXPENSE_CLAIM_DOCUMENTS_STARTED';
export function getExpenseClaimDocumentsStarted ( expenseClaimId: string ) {
	return {
		type: GET_EXPENSE_CLAIM_DOCUMENTS_STARTED,
		expenseClaimId,
	};
}

export const GET_EXPENSE_CLAIM_DOCUMENTS_SUCCEEDED =
	'GET_EXPENSE_CLAIM_DOCUMENTS_SUCCEEDED';
export function getExpenseClaimDocumentsSucceeded (
	documents: Array<ExpenseClaimDocument>,
	expenseClaimId: string
) {
	return {
		type: GET_EXPENSE_CLAIM_DOCUMENTS_SUCCEEDED,
		documents,
		expenseClaimId,
	};
}

export const GET_EXPENSE_CLAIM_DOCUMENTS_FAILED =
	'GET_EXPENSE_CLAIM_DOCUMENTS_FAILED';
export function getExpenseClaimDocumentsFailed ( error: any, expenseClaimId: string ) {
	return {
		type: GET_EXPENSE_CLAIM_DOCUMENTS_FAILED,
		expenseClaimId,
		error,
	};
}

export const getExpenseClaimsDocuments = ( requestData: {
	employeeCode: string;
	id: string;
} ) => {
	return async function ( dispatch: Function ) {
		getExpenseClaimDocumentsStarted( requestData.id )
		await api
			.getExpenseClaimDocuments( requestData )
			.then( ( documents: Array<ExpenseClaimDocument> ) => {
				dispatch( getExpenseClaimDocumentsSucceeded( documents, requestData.id ) );
			} )
			.catch( ( e: any ) => dispatch( getExpenseClaimDocumentsFailed( e, requestData.id ) ) );
	};
};

export const DELETE_EXPENSE_CLAIM_DOCUMENT_STARTED =
	'DELETE_EXPENSE_CLAIM_DOCUMENT_STARTED';
export function deleteExpenseClaimDocumentStarted (
	expenseClaimId: string,
	documentId: string
) {
	return {
		type: DELETE_EXPENSE_CLAIM_DOCUMENT_STARTED,
		expenseClaimId,
		documentId,
	};
}

export const DELETE_EXPENSE_CLAIM_DOCUMENT_SUCCEEDED =
	'DELETE_EXPENSE_CLAIM_DOCUMENT_SUCCEEDED';
export function deleteExpenseClaimDocumentSucceeded (
	expenseClaimId: string,
	documentId: string
) {
	return {
		type: DELETE_EXPENSE_CLAIM_DOCUMENT_SUCCEEDED,
		expenseClaimId,
		documentId,
	};
}

export const DELETE_EXPENSE_CLAIM_DOCUMENT_FAILED =
	'DELETE_EXPENSE_CLAIM_DOCUMENT_FAILED';
export function deleteExpenseClaimDocumentFailed (
	expenseClaimId: string,
	documentId: string
) {
	return {
		type: DELETE_EXPENSE_CLAIM_DOCUMENT_FAILED,
		expenseClaimId,
		documentId,
	};
}

export function deleteExpenseClaimDocument ( data: {
	employeeCode: string;
	expenseClaimId: string;
	documentId: string;
} ) {
	return function ( dispatch: Function ) {
		dispatch(
			deleteExpenseClaimDocumentStarted( data.expenseClaimId, data.documentId )
		);
		api
			.deleteExpenseClaimDocument( data )
			.then( () => {
				dispatch(
					deleteExpenseClaimDocumentSucceeded(
						data.expenseClaimId,
						data.documentId
					)
				);
			} )
			.catch( () =>
				dispatch(
					deleteExpenseClaimDocumentFailed( data.expenseClaimId, data.documentId )
				)
			);
	};
}

export const GET_EXPENSE_CLAIM_DOCUMENT_STARTED =
	'GET_EXPENSE_CLAIM_DOCUMENT_STARTED';
export function getExpenseClaimDocumentStarted (
	expenseClaimId: string,
	documentId: string
) {
	return {
		type: GET_EXPENSE_CLAIM_DOCUMENT_STARTED,
		documentId,
		expenseClaimId,
	};
}

export const GET_EXPENSE_CLAIM_DOCUMENT_SUCCEEDED =
	'GET_EXPENSE_CLAIM_DOCUMENT_SUCCEEDED';
export function getExpenseClaimDocumentSucceeded (
	expenseClaimId: string,
	documentId: string,
	files: Array<ExpenseClaimFile>
) {
	return {
		type: GET_EXPENSE_CLAIM_DOCUMENT_SUCCEEDED,
		expenseClaimId,
		documentId,
		files,
	};
}

export const GET_EXPENSE_CLAIM_DOCUMENT_FAILED =
	'GET_EXPENSE_CLAIM_DOCUMENT_FAILED';
export function getExpenseClaimDocumentFailed (
	expenseClaimId: string,
	documentId: string
) {
	return {
		type: GET_EXPENSE_CLAIM_DOCUMENT_FAILED,
		documentId,
		expenseClaimId,
	};
}

export function getExpenseClaimDocument ( data: {
	employeeCode: string;
	expenseClaimId: string;
	documentId: string;
} ) {
	const { expenseClaimId, documentId } = data;
	return function ( dispatch: Function ) {
		dispatch(
			getExpenseClaimDocumentStarted( data.expenseClaimId, data.documentId )
		);
		api
			.getExpenseClaimDocument( data )
			.then( ( responseData: any ) => {
				const files: Array<ExpenseClaimFile> = responseData.files;
				dispatch(
					getExpenseClaimDocumentSucceeded( expenseClaimId, documentId, files )
				);
			} )
			.catch( () => {
				dispatch(
					getExpenseClaimDocumentFailed( data.expenseClaimId, data.documentId )
				);
			} );
	};
}

