import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add } from '@mui/icons-material';

import YearSelector from './YearSelector';
import LeaveBalance from './LeaveBalance';
import LeaveItemsDesktop from './LeaveItemsDesktop';
import OverlayLoader from '../../OverlayLoader';
import { AppState } from '../../../reducers';
import useTranslation from '../../../hooks/useTranslation';
import { LeaveRequest } from '../../../types/api/leaveRequests';
import { currentYear } from '../../controls/Minimal/helpers';
import { setSelectedLeaveRequestsYear } from '../../../actions/time-actions';

export interface LeavesListDesktopProps {
	setLeaveRequestId: ( id?: string ) => void;
	leaveRequest?: LeaveRequest;
}

const useStyles = makeStyles( ( theme ) => ( {
	pageTitle: {
		fontSize: theme.spacing( 2 ),
		fontWeight: 900,
		color: theme.palette.text.disabled,
		padding: theme.spacing( 0, 0, 0.4, 0 )
	},
	add: {
		padding: theme.spacing( 1 ),
		cursor: 'pointer',
		fontWeight: 700,
		backgroundColor: theme.palette.background.default,
		borderColor: theme.palette.custom.paper,
		borderStyle: 'solid',
		borderWidth: theme.spacing( 0.11 ),
		fontSize: theme.spacing( 1.5 ),
		margin: theme.spacing( 0.5, 1, 0.5, 0 ),
		borderRadius: theme.spacing( 1 ),
		'&:hover': {
			color: theme.palette.primary.main,
		},
		textTransform: 'none',
		color: theme.palette.text.primary
	},
	icon: {
		fontSize: theme.spacing( 2.5 ),
		margin: theme.spacing( 0, 0.25 ),
		cursor: 'pointer',
	},
	actionContainer: {
		borderBottomStyle: 'solid',
		borderBottomColor: theme.palette.text.disabled,
		display: 'flex',
		height: '4rem',
	},
	titleContainer: {
		display: 'flex',
		// alignSelf: 'center',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flex: '1',
		margin: theme.spacing( 0, 0, 0, 2 ),
	},
} ) );

const LeavesListDesktop: React.FC<LeavesListDesktopProps> = ( {
	setLeaveRequestId,
	leaveRequest,
} ) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();

	const { accounts: userAccounts } = useSelector(
		( state: AppState ) => state.userState
	);

	const onAddLeaveRequest = useCallback( () => {
		navigate( '/create-leave-request', {
			state: {
				previous: location.pathname,
			}
		} );
		setLeaveRequestId();
	}, [ navigate, location.pathname, setLeaveRequestId ] );

	const employeeCode = userAccounts[ 0 ]?.employeeCode;

	const dispatch = useDispatch();

	const { isLoading } = useSelector(
		( state: AppState ) => state.leaveRequestState
	);

	const leaveRequests = useSelector(
		( state: AppState ) => state.leaveRequestState.accountRequests?.leaveRequests
	);

	const leaveBalances = useSelector(
		( state: AppState ) => state.leaveRequestState.accountRequests?.leaveBalances
	);

	const { leaveRequestsYear } = useSelector(
		( state: AppState ) => state.timeState
	);

	useEffect( () => {
		if ( !leaveRequestsYear && employeeCode ) {
			dispatch( setSelectedLeaveRequestsYear( currentYear, employeeCode ) );
		}
	}, [ employeeCode, dispatch, leaveRequestsYear ] );

	const onChangeYear = useCallback(
		( year: number ) => {
			dispatch( setSelectedLeaveRequestsYear( year, employeeCode ) );
		},
		[ employeeCode, dispatch ]
	);

	const t = useTranslation();

	return (
		<>
			<OverlayLoader
				message={ 'Loading' }
				loadingCondition={ isLoading || !employeeCode }
			/>
			<Box className={ classes.actionContainer }>
				<Box className={ classes.titleContainer }>
					<Typography className={ classes.pageTitle }>{ t( 'Overview' ) }</Typography>
					<Box>
						<YearSelector
							selectedYear={ leaveRequestsYear || currentYear }
							onChange={ onChangeYear }
						/>
					</Box>
				</Box>
				<Button className={ classes.add } onClick={ onAddLeaveRequest }>
					<Add className={ classes.icon } />
					{ t( 'New' ) }
				</Button>
			</Box>
			<Box
				display="flex"
				flexDirection="column"
				height="calc( 100vh - 14rem)"
			>
				<Box flex="1" overflow="auto" flexDirection="column" display="flex" paddingBottom={ 1 }>
					<Box flex="1">
						{ leaveRequests && (
							<LeaveItemsDesktop
								leaves={ leaveRequests }
								action={ setLeaveRequestId }
								leaveRequest={ leaveRequest }
							/>
						) }
					</Box>
				</Box>
				{ leaveBalances && <LeaveBalance balances={ leaveBalances } /> }
			</Box>
		</>
	);
};

export default LeavesListDesktop;
