import { UserInfo, UserInfoAccount } from '../../types/api/user';
import api from '../../middleware/api';

/*
 * GET_USER_INFO
 */

export const GET_USER_INFO_STARTED = 'GET_USER_INFO_STARTED';
export function getUserInfoStarted () {
	return {
		type: GET_USER_INFO_STARTED,
	};
}

export const GET_USER_INFO_SUCCEEDED = 'GET_USER_INFO_SUCCEEDED';
export function getUserInfoSucceeded ( userInfo: UserInfo ) {
	return {
		type: GET_USER_INFO_SUCCEEDED,
		userInfo,
	};
}

export const GET_USER_INFO_FAILED = 'GET_USER_INFO_FAILED';
export function getUserInfoFailed ( error: any ) {
	return {
		type: GET_USER_INFO_FAILED,
		error: 'Failed to load user data',
	};
}

export function getUserInfo () {

	return function ( dispatch: Function ) {
		dispatch( getUserInfoStarted() );
		api
			.getUserInfo()

			.then( ( userInfo: UserInfo ) => {
				dispatch( getUserInfoSucceeded( userInfo ) );
				// dispatch( getCurrentTimesheet( userInfo.accounts ) );
			} )
			.catch( ( err: any ) => dispatch( getUserInfoFailed( err ) ) );
	};
}

export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const setSelectedUser = ( selectedUser: UserInfoAccount ) => {
	return {
		type: SET_SELECTED_USER,
		selectedUser,
	};
};

