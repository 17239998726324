import { Action, ActionType } from "../types/actions";
import { TimesheetSourceData } from "../types/api/timesheets";

export interface TimesheetSourcesState {
  data: TimesheetSourceData | null;
  error: any;
  isLoading: boolean;
}

export function timesheetSourcesReducer(
  state: TimesheetSourcesState = {
    data: null,
    error: null,
    isLoading: false,
  },
  action: Action
): TimesheetSourcesState {
  switch (action.type) {
    case ActionType.GET_TIME_SHEET_SOURCE_DATA_SUCCEEDED:
      return {
        ...state,
        data: action.timesheetSourceData,
        isLoading: false,
        error: null,
      };
    case ActionType.GET_TIME_SHEET_SOURCE_DATA_STARTED:
      return {
        ...state,
        data: null,
        isLoading: true,
        error: null,
      };
    case ActionType.GET_TIME_SHEET_SOURCE_DATA_FAILED:
      return {
        ...state,
        data: null,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
