import api from '../../middleware/api';
import {
	UpSertExpenseClaimRequestProps,
	ExpenseClaimsData,
	ExpenseClaim,
} from '../../types/api/expenseClaims';
import { WeekRangeData } from '../time-actions';
import { getExpenseClaimsDocuments } from './expenseClaimsAttachments';
import {
	getExpenseClaimApprovalProgress,
	getExpenseClaimDetails,
} from './expenseClaimDetails';

// EXPENSE_CLAIMS
export const GET_EXPENSE_CLAIMS_STARTED = 'GET_EXPENSE_CLAIMS_STARTED';
export function getExpenseClaimsStarted () {
	return {
		type: GET_EXPENSE_CLAIMS_STARTED,
	};
}

export const GET_EXPENSE_CLAIMS_SUCCEEDED = 'GET_EXPENSE_CLAIMS_SUCCEEDED';
export function getExpenseClaimsSucceeded (
	expenseClaimsData: ExpenseClaimsData
) {
	return {
		type: GET_EXPENSE_CLAIMS_SUCCEEDED,
		expenseClaimsData,
	};
}

export const GET_EXPENSE_CLAIMS_FAILED = 'GET_EXPENSE_CLAIMS_FAILED';
export function getExpenseClaimsFailed ( error: any ) {
	return {
		type: GET_EXPENSE_CLAIMS_FAILED,
		error,
	};
}

export const getExpenseClaims = (
	employeeCode: string,
	weekRange: WeekRangeData
) => {
	return async ( dispatch: Function ) => {
		dispatch( getExpenseClaimsStarted() );
		try {
			const expenseClaimsData: ExpenseClaimsData = await api.getExpenseClaims( {
				employeeCode,
				start: weekRange.from,
				end: weekRange.to,
			} );

			dispatch( getExpenseClaimsSucceeded( expenseClaimsData ) );

			expenseClaimsData.expenseClaims.forEach( ( expenseClaim ) => {
				dispatch(
					getExpenseClaimsDocuments( { employeeCode, id: expenseClaim.id } )
				);
			} );
		} catch ( error ) {
			dispatch( getExpenseClaimsFailed( error ) );
		}
	};
};

export const GET_EXPENSE_CLAIM_STARTED = 'GET_EXPENSE_CLAIM_STARTED';
export function getExpenseClaimStarted ( id: string ) {
	return {
		type: GET_EXPENSE_CLAIM_STARTED,
		id,
	};
}

export const GET_EXPENSE_CLAIM_SUCCEEDED = 'GET_EXPENSE_CLAIM_SUCCEEDED';
export function getExpenseClaimSucceeded ( expenseClaim: ExpenseClaim ) {
	return {
		type: GET_EXPENSE_CLAIM_SUCCEEDED,
		expenseClaim,
	};
}

export const GET_EXPENSE_CLAIM_FAILED = 'GET_EXPENSE_CLAIM_FAILED';
export function getExpenseClaimFailed ( errorMessage: string, id: string ) {
	return {
		type: GET_EXPENSE_CLAIM_FAILED,
		error: errorMessage,
		id,
	};
}

export function getExpenseClaim (
	data: { id: string; employeeCode: string },
	withDocuments: boolean = false
) {
	return function ( dispatch: Function ) {
		dispatch( getExpenseClaimStarted( data.id ) );
		api
			.getExpenseClaimById( data )
			.then( ( expenseClaim: ExpenseClaim ) => {
				dispatch( getExpenseClaimSucceeded( expenseClaim ) );
				if ( withDocuments ) {
					dispatch( getExpenseClaimsDocuments( data ) );
				}
			} )
			.catch( () => {
				dispatch( getExpenseClaimFailed( 'Failed to get expense claim data', data.id ) );
			} );
	};
}

export const CREATE_EXPENSE_CLAIM_STARTED = 'CREATE_EXPENSE_CLAIM_STARTED';
export function createExpenseClaimStarted () {
	return {
		type: CREATE_EXPENSE_CLAIM_STARTED,
	};
}

export const CREATE_EXPENSE_CLAIM_SUCCEEDED = 'CREATE_EXPENSE_CLAIM_SUCCEEDED';
export function createExpenseClaimSucceeded () {
	return {
		type: CREATE_EXPENSE_CLAIM_SUCCEEDED,
	};
}

export const CREATE_EXPENSE_CLAIM_FAILED = 'CREATE_EXPENSE_CLAIM_FAILED';
export function createExpenseClaimFailed ( errorMessage: string, id?: string ) {
	return {
		type: CREATE_EXPENSE_CLAIM_FAILED,
		error: errorMessage,
		id,
		isNew: !!id,
	};
}

export function upSertExpenseClaim ( data: UpSertExpenseClaimRequestProps ) {
	return async ( dispatch: Function ) => {
		dispatch( createExpenseClaimStarted() );
		try {
			const id = await api.upSertExpenseClaim( data );
			dispatch( createExpenseClaimSucceeded() );
			dispatch(
				getExpenseClaim(
					{ employeeCode: data.employeeCode, id },
					data.files.length > 0
				)
			);

			if ( !data.id ) {
				getExpenseClaimDetails( {
					employeeCode: data.employeeCode,
					id,
				} );
			} else if ( data.releaseExpenseClaim ) {
				dispatch(
					getExpenseClaimApprovalProgress( {
						employeeCode: data.employeeCode,
						id,
					} )
				);
			}

			return id;
		} catch ( e ) {
			const errorMessage =
				e.message && e.status === 400
					? e.message
					: 'Oops...something went wrong, refresh the page and try again.';
			dispatch( createExpenseClaimFailed( errorMessage ) );
		}
	};
}

export const DISMISS_EXPENSE_CLAIM_ERROR = 'DISMISS_EXPENSE_CLAIM_ERROR';
export function dismissExpenseClaimError () {
	return {
		type: DISMISS_EXPENSE_CLAIM_ERROR,
	};
}

export const DELETE_EXPENSE_CLAIM_STARTED = 'DELETE_EXPENSE_CLAIM_STARTED';
export function deleteExpenseClaimStarted ( id: string ) {
	return {
		type: DELETE_EXPENSE_CLAIM_STARTED,
		id,
	};
}

export const DELETE_EXPENSE_CLAIM_SUCCEEDED = 'DELETE_EXPENSE_CLAIM_SUCCEEDED';
export function deleteExpenseClaimSucceeded ( id: string ) {
	return {
		type: DELETE_EXPENSE_CLAIM_SUCCEEDED,
		id,
	};
}

export const DELETE_EXPENSE_CLAIM_FAILED = 'DELETE_EXPENSE_CLAIM_FAILED';
export function deleteExpenseClaimFailed ( id: string ) {
	return {
		type: DELETE_EXPENSE_CLAIM_FAILED,
		id,
	};
}

export function deleteExpenseClaim ( data: {
	employeeCode: string;
	expenseClaimId: string;
} ) {
	return function ( dispatch: Function ) {
		dispatch( deleteExpenseClaimStarted( data.expenseClaimId ) );
		api
			.deleteExpenseClaim( data )
			.then( () => {
				dispatch( deleteExpenseClaimSucceeded( data.expenseClaimId ) );
			} )
			.catch( () => dispatch( deleteExpenseClaimFailed( data.expenseClaimId ) ) );
	};
}

export const RELEASE_EXPENSE_CLAIM_STARTED = 'RELEASE_EXPENSE_CLAIM_STARTED';
export function releaseExpenseClaimStarted ( id: string ) {
	return {
		type: RELEASE_EXPENSE_CLAIM_STARTED,
		id,
	};
}

export const RELEASE_EXPENSE_CLAIM_SUCCEEDED =
	'RELEASE_EXPENSE_CLAIM_SUCCEEDED';
export function releaseExpenseClaimSucceeded (
	messages: Array<string>,
	expenseClaimId: string
) {
	return {
		type: RELEASE_EXPENSE_CLAIM_SUCCEEDED,
		messages,
		expenseClaimId,
	};
}

export const RELEASE_EXPENSE_CLAIM_FAILED = 'RELEASE_EXPENSE_CLAIM_FAILED';
export function releaseExpenseClaimFailed ( error: any, id: string ) {
	return {
		type: RELEASE_EXPENSE_CLAIM_FAILED,
		error,
		id,
	};
}

export function releaseExpenseClaim ( requestData: {
	expenseClaimId: string;
	employeeCode: string;
} ) {
	return function ( dispatch: Function ) {
		dispatch( releaseExpenseClaimStarted( requestData.expenseClaimId ) );
		api
			.releaseExpenseClaim( requestData )
			.then( ( messages: Array<string> ) => {
				dispatch(
					releaseExpenseClaimSucceeded( messages, requestData.expenseClaimId )
				);
				dispatch(
					getExpenseClaim( {
						employeeCode: requestData.employeeCode,
						id: requestData.expenseClaimId,
					} )
				);
				dispatch(
					getExpenseClaimApprovalProgress( {
						...requestData,
						id: requestData.expenseClaimId,
					} )
				);
			} )
			.catch( ( error: any ) => {
				dispatch( releaseExpenseClaimFailed( error, requestData.expenseClaimId ) )
			}
			);
	};
}
