const lightGray = '#EDEDED'; /* Used for borders */
const lightBlueGray = '#F6F8FD';
const lightBlueGrayContrastText = '#18385E';
const blueGray = '#e6eaf4';
const gray = '#4B547C';
const darkBlueGray = '#18385E';
const darkBlueGrayContrastText = '#F6F8FD';
const orange = '#FF6926';
const turquoise = '#01A3B8';
const picture = 'rgba( 0, 0, 0, 0 )';
const white = '#FFFFFF';
const boxShadow = '#00000012';
const strongBoxShadow = '#00000045';
const paper = 'rgba(232, 232, 232, 0.60)';
const statusEntered = '#B0C7CA';
const statusReleased = '#76BBC4';
const statusVerified = '#259BA3';
const statusApproved = '#01AF2F';
const statusOutstanding = '#D0B78E';
const statusInProgress = '#BF872C';
const statusPaymentInProgress = '#208400';
const statusSettled = '#208400';

export const lightCustomColors = {
	lightGray,
	lightBlueGray,
	lightBlueGrayContrastText,
	blueGray,
	darkBlueGray,
	darkBlueGrayContrastText,
	gray,
	orange,
	turquoise,
	picture,
	white,
	boxShadow,
	strongBoxShadow,
	paper,
	statusEntered,
	statusReleased,
	statusVerified,
	statusApproved,
	statusOutstanding,
	statusInProgress,
	statusSettled,
	statusPaymentInProgress,
};

// TO DO discuss and setup darCustom colors
export const darkCustomColors = {
	...lightCustomColors,
	lightBlueGray: '#18385E',
	gray: '#EDEDED',
	blueGray: '#597ba5',
	lightBlueGrayContrastText: '#ffffff',
	picture: 'rgba( 0, 0, 0, 0.5 )',
	boxShadow: 'rgba(255, 255, 255, 0.26)',
	strongBoxShadow: 'rgba(255, 255, 255, 0.26)',
	paper: '#424242',
};

export const colorPickerColors = [
	'#f44336',
	'#e91e63',
	'#9c27b0',
	'#673ab7',
	'#3f51b5',
	'#2196f3',
	'#03a9f4',
	'#00bcd4',
	'#009688',
	'#4caf50',
	'#8bc34a',
	'#cddc39',
	'#ffeb3b',
	'#ffc107',
	'#ff9800',
	'#ff5722',
	'#795548',
	'#607d8b',
	'#FF6926',
	'#01A3B8',
	'#18385E',
	'#FDA1FF',
	'#FA28FF',
	'orange',
	'turquoise',
];
