import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../reducers';
import { setSelectedUser } from '../actions/api-actions';
import { OvertimeOptions } from '../components/screens/Minimal/Minimal';
import { onSetCalendarMonthData, WeekRangeData } from '../actions/time-actions';
import useSelectedAccount from './useSelectedAccount';
import useWeekRange from './useWeekRange';
import useWeekTimesheets from './useWeekTimesheets';
import { getAccountHoursData } from '../components/screens/Minimal/helpers';
import { getYearWeekRange } from '../components/controls/Minimal/helpers';
import { DateTime } from 'luxon';

const useAccountTimesheetManagement = () => {
	const { selectedWeekRange, setSelectedWeekRange } = useWeekRange();
	const { weekActivities, isLoading, timesheets } = useWeekTimesheets();
	const dispatch = useDispatch();

	const {
		initialAccount,
		setCompanyName,
		accountIndex,
		companyName,
		userAccounts,
	} = useSelectedAccount();

	const userAccountsPendingTimesheetsInfo = useSelector(
		( state: AppState ) => state.userState.currentTimesheets
	);

	const currentAccountData = getAccountHoursData(
		weekActivities,
		timesheets,
		accountIndex,
		initialAccount
	);

	const onRefreshAccountTimesheetData = useCallback(
		( weekRange: WeekRangeData ) => {
			setSelectedWeekRange( weekRange );
			setCompanyName( companyName );
		},
		[ companyName, setSelectedWeekRange, setCompanyName ]
	);

	const onSelectUserAccount = useCallback(
		( selectedCompany ) => {
			const accountIndex = userAccounts.findIndex(
				( account ) => account.companyName === selectedCompany
			);
			const userAccount = userAccounts[ accountIndex ];

			const accountCurrentTimesheet = userAccountsPendingTimesheetsInfo.find(
				( account ) => account.employeeCode === userAccount.employeeCode
			);

			if (
				!userAccount.employeeDetail.internalHired &&
				selectedWeekRange &&
				accountCurrentTimesheet &&
				accountCurrentTimesheet.timeSheetInfos[ 0 ].week <
				DateTime.fromJSDate( selectedWeekRange.from ).weekNumber
			) {
				const { week, year } = accountCurrentTimesheet.timeSheetInfos[ 0 ];
				const weekRange = getYearWeekRange( week.toString(), year.toString() );
				dispatch(
					onSetCalendarMonthData(
						new Date( weekRange.from.getFullYear(), weekRange.from.getMonth() )
					)
				);
				setTimeout( () => {
					dispatch( onSetCalendarMonthData() );
				}, 100 );
				setSelectedWeekRange( weekRange );
				setCompanyName( selectedCompany );
				dispatch( setSelectedUser( userAccount ) );
				return;
			}

			setCompanyName( selectedCompany );
			dispatch( setSelectedUser( userAccount ) );
		},
		[
			userAccounts,
			selectedWeekRange,
			userAccountsPendingTimesheetsInfo,
			setSelectedWeekRange,
			setCompanyName,
			dispatch,
		]
	);

	const currentAccountTimesheets = weekActivities.filter(
		( weekActivity ) =>
			weekActivity.details.employeeCode === currentAccountData?.employeeId
	);

	const lowestStatusTimesheet = currentAccountData
		? timesheets.filter(
			( timesheetElement ) =>
				timesheetElement.employeeCode === currentAccountData.employeeId
		)[ 0 ]?.timeSheetInfos[ 0 ]?.lowestStatusTimesheet?.id
		: undefined;

	const highestStatusTimesheet = currentAccountData
		? timesheets.filter(
			( timesheetElement ) =>
				timesheetElement.employeeCode === currentAccountData.employeeId
		)[ 0 ]?.timeSheetInfos[ 0 ]?.highestStatusTimesheet?.id
		: undefined;

	const disableUnRelease =
		lowestStatusTimesheet === highestStatusTimesheet &&
		lowestStatusTimesheet === 'Z';

	const overtimeOptions: OvertimeOptions = {
		hasMonFriOvertime: currentAccountData
			? currentAccountData?.contractHours <
			currentAccountData?.totalHoursThisWeek
			: false,
		hasSaturdayOvertime: !!currentAccountTimesheets.find(
			( activity ) => activity.hasSaturdayOvertime
		),
		hasSunPubHolOvertime: !!currentAccountTimesheets.find(
			( activity ) => activity.hasSunPubHolOvertime
		),
		employeeCode: currentAccountData?.employeeId || '0',
	};

	const hasEnoughHours = !!(
		currentAccountData &&
		( ( currentAccountData.totalHoursThisWeek >=
			currentAccountData.contractHours &&
			currentAccountData.contractHours !== 0 ) ||
			( currentAccountData.contractHours === 0 &&
				currentAccountData.totalHoursThisWeek > 0 ) )
	);

	const hasUnreleasedTimesheetData = !!currentAccountData?.timesheets.find(
		( timesheet ) => timesheet.details.status.id === 'C'
	);

	return {
		isLoading,
		selectedWeekRange,
		setSelectedWeekRange,
		currentAccountData,
		userAccounts,
		onSelectUserAccount,
		overtimeOptions,
		disableUnRelease,
		hasEnoughHours,
		onRefreshAccountTimesheetData,
		lowestStatusTimesheet,
		highestStatusTimesheet,
		hasUnreleasedTimesheetData
	};
};

export default useAccountTimesheetManagement;
