import React, { useEffect } from 'react';

import {
	BrowserRouter as Router,
	Routes,
	Route,
	useNavigate,
	// Redirect,
} from 'react-router-dom';

import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import Calendar from './screens/Calendar/Calendar';
import Minimal from './screens/Minimal/Minimal';
import Leave from './screens/Leave/Leave';
import Settings from './screens/Settings/Settings';
import ManageDestination from './screens/CreateDestination/ManageDestination';
import SelectDestination from './screens/SelectDestination/SelectDestination';
import CreateTimesheet from './screens/ManageTimesheet/CreateTimesheet';
import CreateTimesheetTempDestination from './screens/ManageTimesheet/CreateTimesheetTempDestination';

import FooterMenu from './FooterMenu/FooterMenu';
import NoMatch from './screens/NoMatch';
import LoginCallback from './LoginCallback';
import EditTimesheet from './screens/ManageTimesheet/EditTimesheet';
import DesktopLayout from './Desktop/DesktopLayout';
import ManageLeaves from './screens/ManageLeaves/ManageLeaves';
import ExpenseClaims from './screens/ExpenseClaims/ExpenseClaims';
import ManageExpenseClaims from './screens/ManageExpenseClaims/ManageExpenseClaim';
import ExpenseClaimOptions from './screens/ExpenseClaimsOptions/ExpenseClaimOptions';
import TimesheetDesktop from './screens/DesktopTimesheet/TimesheetDesktop';
import ExpenseClaimsDesktop from './screens/ExpenseClaims/ExpenseClaimsDesktop';
import ManageExpenseClaimDesktop from './screens/ManageExpenseClaims/ManageExpenseClaimDesktop';
import useDesktopHomeMenuActions from '../hooks/useDesktopHomeMenuActions';
import LeavesDesktop from './screens/Leave/LeavesDesktop';
import useWindowSize from '../hooks/useWindowSize';

export interface RouterProps {
	onSignOut: () => void;
}

const useStyles = makeStyles( ( theme ) => ( {
	mobile: {
		display: 'none',
		[ theme.breakpoints.down( 'lg' ) ]: {
			display: 'flex',
			flexDirection: 'column',
			height: 'calc( 100vh - 10rem )',
			flex: '1',
		},
	},
	desktop: {
		[ theme.breakpoints.down( 'lg' ) ]: {
			display: 'none',
		},
	},
} ) );

const Redirect = ( { to }: { to: string } ) => {
	let navigate = useNavigate();
	useEffect( () => {
		navigate( to );
	} );
	return null;
};

const AppRouter: React.FC<RouterProps> = ( { onSignOut } ) => {
	const classes = useStyles();
	const desktopHomeMenuActions = useDesktopHomeMenuActions();

	const windowSize = useWindowSize();

	return (
		<Router>
			<Routes>
				<Route
					path="/"
					element={
						<>
							<Box className={ classes.mobile }>
								<Minimal />
								<FooterMenu />
							</Box>
							<Box className={ classes.desktop }>
								<DesktopLayout
									onLogOutClick={ onSignOut }
									homeMenuActions={ desktopHomeMenuActions }
								>
									<TimesheetDesktop homeMenuActions={ desktopHomeMenuActions } />
								</DesktopLayout>
							</Box>
						</>
					}
				></Route>
				<Route
					path="/expense-claims/"
					element={
						<>
							<Box className={ classes.mobile }>
								{ windowSize.width && windowSize.width < 1280 && (
									<ExpenseClaims />
								) }
								<FooterMenu />
							</Box>
							<Box className={ classes.desktop }>
								<DesktopLayout onLogOutClick={ onSignOut }>
									{ windowSize.width && windowSize.width >= 1280 && (
										<ExpenseClaimsDesktop />
									) }
								</DesktopLayout>
							</Box>
						</>
					}
				></Route>
				<Route
					path="/expense-claim/:id/*"
					element={
						<>
							<Box className={ classes.mobile }>
								{ windowSize.width && windowSize.width < 1280 && (
									<ExpenseClaimOptions />
								) }
							</Box>
							<Box className={ classes.desktop }>
								<DesktopLayout onLogOutClick={ onSignOut }>
									{ windowSize.width && windowSize.width >= 1280 && (
										<ExpenseClaimsDesktop />
									) }
								</DesktopLayout>
							</Box>
						</>
					}
				></Route>
				<Route
					path="/create-expense-claim"
					element={
						<>
							<Box className={ classes.mobile }>
								<ManageExpenseClaims />
							</Box>
							<Box className={ classes.desktop }>
								<DesktopLayout onLogOutClick={ onSignOut }>
									<ManageExpenseClaimDesktop />
								</DesktopLayout>
							</Box>
						</>
					}
				></Route>
				<Route
					path="/leave"
					element={
						<>
							{ ' ' }
							<Box className={ classes.mobile }>
								{ windowSize.width && windowSize.width < 1280 && <Leave /> }
								<FooterMenu />
							</Box>
							<Box className={ classes.desktop }>
								<DesktopLayout onLogOutClick={ onSignOut }>
									{ windowSize.width && windowSize.width >= 1280 && (
										<LeavesDesktop editMode />
									) }
								</DesktopLayout>
							</Box>
						</>
					}
				></Route>
				<Route
					path="/create-leave-request"
					element={
						<>
							<Box className={ classes.mobile }>
								{ windowSize.width && windowSize.width < 1280 && (
									<ManageLeaves />
								) }
							</Box>
							<Box className={ classes.desktop }>
								<DesktopLayout onLogOutClick={ onSignOut }>
									{ windowSize.width && windowSize.width >= 1280 && (
										<LeavesDesktop editMode={ false } />
									) }
								</DesktopLayout>
							</Box>
						</>
					}
				></Route>
				<Route
					path="/calendar"
					element={
						<>
							<Calendar />
							<FooterMenu />
						</>
					}
				></Route>
				<Route
					path="/minimal"
					element={
						<>
							<Minimal />
							<FooterMenu />
						</>
					}
				></Route>
				<Route
					path="/settings"
					element={
						<>
							<Settings onLogOutClick={ onSignOut } />
							<FooterMenu />
						</>
					}
				></Route>
				<Route
					path="/select-destination"
					element={ <SelectDestination /> }
				></Route>
				<Route
					path="/create-destination"
					element={ <ManageDestination /> }
				></Route>
				<Route
					path="/edit-destination/:destination/*"
					element={ <ManageDestination /> }
				></Route>
				<Route
					path="/create-timesheet/:destination/*"
					element={ <CreateTimesheet /> }
				></Route>
				<Route
					path="/edit-timesheet/:destination/:id/*"
					element={ <EditTimesheet /> }
				></Route>
				<Route path="/edit-timesheet/:id/*" element={ <EditTimesheet /> }></Route>
				<Route
					path="/edit-leave-request/:id/*"
					element={ <ManageLeaves /> }
				></Route>
				<Route
					path="/create-timesheet"
					element={ <Redirect to="/select-destination" /> }
				></Route>
				<Route
					path="/create-timesheet-without-template"
					element={ <CreateTimesheetTempDestination /> }
				></Route>
				<Route path="/login_callback" element={ <LoginCallback /> }></Route>
				<Route element={ <NoMatch /> }></Route>
			</Routes>
		</Router>
	);
};

export default AppRouter;
