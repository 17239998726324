import {
	getTimesheet,
	getExpenseClaims,
	getLeaveRequests,
	// getWorkingHours,
} from './api-actions';
import { getYearRange } from '../utils/date';
import { TotalHoursGroup } from '../components/Desktop/DesktopLayout';

export interface WeekRangeData {
	from: Date;
	to: Date;
}

export const SET_WEEK_RANGE_DATA = 'SET_WEEK_RANGE_DATA';
export const onChangeWeekRange = ( weekRange: WeekRangeData ) => {
	return {
		type: SET_WEEK_RANGE_DATA,
		weekRange,
	};
};

export const SET_CALENDAR_MONTH_DATA = 'SET_CALENDAR_MONTH_DATA';
export const onSetCalendarMonthData = ( calendarMonthData?: Date ) => {
	return {
		type: SET_CALENDAR_MONTH_DATA,
		calendarMonthData,
	};
};

export const setWeekRangeData = ( weekRange: WeekRangeData ) => {
	return function ( dispatch: Function ) {
		const { from, to } = weekRange;
		dispatch( onChangeWeekRange( weekRange ) );
		dispatch( getTimesheet( from, to ) );
		// dispatch( getWorkingHours() );
	};
};

export const SET_SELECTED_EXPENSE_CLAIMS_YEAR =
	'SET_SELECTED_EXPENSE_CLAIMS_YEAR';
export const onChangeSelectedExpenseClaimsYear = ( expenseClaimsYear: number ) => {
	return {
		type: SET_SELECTED_EXPENSE_CLAIMS_YEAR,
		expenseClaimsYear,
	};
};

export const setSelectedExpenseClaimsYear = (
	year: number,
	employeeCode: string
) => {
	const range = getYearRange( year );
	return ( dispatch: Function ) => {
		dispatch( onChangeSelectedExpenseClaimsYear( year ) );
		dispatch( getExpenseClaims( employeeCode, range ) );
	};
};

export const SET_SELECTED_LEAVE_REQUESTS_YEAR =
	'SET_SELECTED_LEAVE_REQUESTS_YEAR';
export const onChangeSelectedLeaveRequestsYear = ( leaveRequestsYear: number ) => {
	return {
		type: SET_SELECTED_LEAVE_REQUESTS_YEAR,
		leaveRequestsYear,
	};
};

export const setSelectedLeaveRequestsYear = (
	year: number,
	employeeCode: string
) => {
	const range = getYearRange( year );
	return ( dispatch: Function ) => {
		dispatch( onChangeSelectedLeaveRequestsYear( year ) );
		dispatch( getLeaveRequests( employeeCode, range, year ) );
	};
};

export const SET_TIMESHEET_REQUEST_HOURS =
	'SET_TIMESHEET_REQUEST_HOURS';
export const setTimesheetHours = ( timesheetHours: TotalHoursGroup ) => {
	return {
		type: SET_TIMESHEET_REQUEST_HOURS,
		timesheetHours,
	};
};

export const RESET_TIMESHEET_REQUEST_HOURS =
	'RESET_TIMESHEET_REQUEST_HOURS';
export const resetTimesheetHours = () => {
	return {
		type: RESET_TIMESHEET_REQUEST_HOURS,
	};
};


