import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../reducers';
import { setSelectedUser } from '../actions/api-actions';

const useManageTimesheetAccount = ( employeeCode?: string ) => {
	const dispatch = useDispatch();

	const userAccount = useSelector(
		( state: AppState ) =>
			state.userState.accounts.filter(
				( account ) => account.employeeCode === employeeCode
			)[ 0 ]
	);

	const selectedUser = useSelector(
		( state: AppState ) => state.userState.selectedUser
	);

	useEffect( () => {
		if ( userAccount?.employeeCode !== selectedUser?.employeeCode ) {
			dispatch( setSelectedUser( userAccount ) );
		}
	}, [ dispatch, selectedUser, userAccount ] );
};

export default useManageTimesheetAccount;
