export const CLEAR_RELEASE_TIMESHEET_ERROR = 'CLEAR_RELEASE_TIMESHEET_ERROR';
export function clearReleaseTimesheetError () {
	return {
		type: CLEAR_RELEASE_TIMESHEET_ERROR
	}
};

export const SET_TEMP_DESTINATION = 'SET_TEMP_DESTINATION';
export function setTempDestination ( destination: any ) {
	return {
		type: SET_TEMP_DESTINATION,
		destination
	}
}

export const CLEAR_TEMP_DESTINATION = 'CLEAR_TEMP_DESTINATION';
export function clearTempDestination () {
	return {
		type: CLEAR_TEMP_DESTINATION,
	}
}