import React, { useState, useCallback } from 'react';

import { DateTime } from 'luxon';

import { Box, FormLabel, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import TimePickerDialog from './TimePickerDialog';
import AddHoursField from './AddHoursField';

import { PublicHoliday } from '../../../types/api/timesheets';
import {
	formatTime,
	getDecimalTimeString,
	convertDecimalToTimeString,
} from '../../../utils/date';
import useTranslation from '../../../hooks/useTranslation';
import { TotalHoursProps } from '../../Desktop/DesktopLayout';
import useTimeRequestHoursData from '../../../hooks/useTimeRequestHoursData';
import { useDispatch } from 'react-redux';
import { setTimesheetHours } from '../../../actions/time-actions';

export interface TimeSpentSelectorProps {
	weekday: Date;
	timeFieldName: string;
	inputRef: any;
	defaultTime?: any;
	holiday?: PublicHoliday;
	onSetTimeFieldValue: ( time: string ) => void;
	selectedDayProp?: string;
}

export interface HoursOutput {
	hours: number;
	minutes: number;
}

const useStyles = makeStyles( ( theme ) => ( {
	timesheetRow: {
		display: 'flex',
		padding: theme.spacing( 0.5, 2 ),
	},
	label: {
		display: 'block',
		padding: theme.spacing( 1, 0 ),
		color: theme.palette.text.primary,
	},
	selectedDayLabel: {
		display: 'block',
		padding: theme.spacing( 1, 0 ),
		color: theme.palette.primary.dark,
		fontWeight: 900,
	},
	publicHoliday: {
		display: 'block',
		padding: theme.spacing( 1, 0 ),
		color: theme.palette.secondary.main,
	},
	clockIcon: {
		'&:hover': {
			cursor: 'pointer',
		},
	},
} ) );

const TimeSpentSelector: React.FC<TimeSpentSelectorProps> = ( props ) => {
	const {
		weekday,
		timeFieldName,
		inputRef,
		onSetTimeFieldValue,
		holiday,
		selectedDayProp,
	} = props;
	const classes = useStyles();
	const dispatch = useDispatch();

	const [ isClockOpen, setIsClockOpen ] = useState<boolean>( false );
	const [ time, setTime ] = useState<HoursOutput>( { hours: 0, minutes: 0 } );
	const [ isAllDaysSelected, setIsAllDaysSelected ] = useState<boolean>( false );
	const [ isUsingNegativeHours, setIsUsingNegativeHours ] =
		useState<boolean>( false );
	const [ selectedDays, setSelectedDays ] = useState<TotalHoursProps[]>( [] );

	const { timesheetHours: timesheets } = useTimeRequestHoursData();

	const onResetTime = useCallback( () => {
		const [ hours, minutes ] = convertDecimalToTimeString(
			timesheets[ timeFieldName as TotalHoursProps ]
		)
			.split( ':' )
			.map( Number );
		setTime( { hours, minutes } );
	}, [ timesheets, timeFieldName ] );

	const onOpenClock = useCallback( () => {
		setIsClockOpen( true );
		setIsAllDaysSelected( false );
		onResetTime();
	}, [ onResetTime ] );

	const handleCloseDialog = useCallback( () => {
		setIsClockOpen( false );
	}, [] );

	const useNegativeHours = useCallback(
		( e: React.ChangeEvent<HTMLInputElement> ) => {
			setIsUsingNegativeHours( e.target.checked );
		},
		[]
	);
	const onSetTimesheetHours = useCallback(
		( timeField, timesheetTime ) => {
			const newTimesheets = { ...timesheets };
			newTimesheets[ timeField as TotalHoursProps ] = timesheetTime;
			dispatch( setTimesheetHours( newTimesheets ) );
		},
		[ timesheets, dispatch ]
	);

	const onConfirmClockTime = useCallback( () => {
		const resultTime = formatTime( time.hours, time.minutes, ':' );
		const newTimesheets = { ...timesheets }
		const timesheetTime = isUsingNegativeHours
			? Number( getDecimalTimeString( resultTime, ':' ) ) * -1
			: Number( getDecimalTimeString( resultTime, ':' ) );
		selectedDays.forEach( ( day ) => {
			newTimesheets[ day ] = timesheetTime;

		} );
		newTimesheets[ timeFieldName as TotalHoursProps ] = timesheetTime
		dispatch( setTimesheetHours( newTimesheets ) )

		setIsUsingNegativeHours( false );
		setSelectedDays( [] );
		setIsClockOpen( false );
	}, [
		timeFieldName,
		isUsingNegativeHours,
		selectedDays,
		time.hours,
		time.minutes,
		timesheets,
		dispatch
	] );

	const setSelectedDay = useCallback(
		( e: React.ChangeEvent<HTMLInputElement> ) => {
			const selectedDay = e.target.name as TotalHoursProps;
			const isChecked = e.target.checked;
			if ( isChecked ) {
				setSelectedDays( [ ...selectedDays, selectedDay ] );
			} else {
				setSelectedDays( selectedDays.filter( ( day ) => day !== selectedDay ) );
			}
		},
		[ selectedDays ]
	);

	const onSetTime = useCallback(
		( currentTime: HoursOutput ) => {
			selectedDays.forEach( ( day ) =>
				onSetTimesheetHours(
					day,
					+getDecimalTimeString(
						`${ currentTime.hours }:${ currentTime.minutes }`,
						':'
					)
				)
			);
			setTime( currentTime );
		},
		[ selectedDays, onSetTimesheetHours ]
	);

	const weekDayLabel = DateTime.fromJSDate( weekday ).toFormat( 'EEEE' );

	const weekDayLabelClass =
		selectedDayProp && selectedDayProp.split( 'hours' )[ 1 ] === weekDayLabel
			? classes.selectedDayLabel
			: classes.label;

	const t = useTranslation();

	return (
		<Box className={ classes.timesheetRow }>
			<Box flex="1" onClick={ () => setIsClockOpen( false ) }>
				<FormLabel className={ weekDayLabelClass }>
					{ t( weekDayLabel.toLowerCase() ) }
				</FormLabel>
			</Box>
			{holiday && (
				<Box flex="1">
					<Typography className={ classes.publicHoliday }>
						{ holiday.description }
					</Typography>
				</Box>
			) }
			<Box flex="1" textAlign="right">
				<Box display="flex" justifyContent="flex-end">
					<AddHoursField
						isAllWorkingDays={ isAllDaysSelected }
						inputRef={ inputRef }
						clockTime={ convertDecimalToTimeString(
							timesheets[ timeFieldName as TotalHoursProps ]
						) }
						name={ timeFieldName }
						onSetFieldValue={ onSetTimeFieldValue }
						timesheets={ timesheets }
						onOpenTimePicker={ onOpenClock }
						selectedDayProp={ selectedDayProp }
					/>
				</Box>
			</Box>
			<TimePickerDialog
				handleClose={ handleCloseDialog }
				open={ isClockOpen }
				onConfirm={ onConfirmClockTime }
				title={ 'Select your working hours' }
				setSelectedDay={ setSelectedDay }
				selectedDayName={ timeFieldName }
				useNegativeHours={ useNegativeHours }
				setTime={ onSetTime }
				time={ time }
			/>
		</Box>
	);
};

export default TimeSpentSelector;
