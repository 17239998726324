import { useSelector } from 'react-redux';
import { AppState } from '../reducers';
import { TimesheetState } from '../reducers/timesheets';
import { Timesheet } from '../types/api/timesheets';

const useUserAccountTimesheet = ( employeeCode: string ) => {
	const timesheetState = useSelector<AppState, TimesheetState>(
		state => state.timesheetsState
	);

	const timesheets: Timesheet[] = timesheetState.timesheets;

	const isLoading: boolean = timesheetState.isLoading;

	const timesheet = timesheets.find(
		stateTimesheet => stateTimesheet.employeeCode === employeeCode
	);

	return {
		timesheet,
		isLoading,
	};
};

export default useUserAccountTimesheet;
