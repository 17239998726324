import { useState, useEffect } from 'react';

import { AccountInfo } from '@azure/msal-browser';

import { getAuthorizationDetails, signIn, signOut, AuthorizationDetails } from './../utils/msal-utils';

export default function useMsalBrowserAuth () {
	const [ account, setAccount ] = useState<AccountInfo | null>( null );
	const [ rawToken, setRawToken ] = useState<string | undefined>();
	const [ error, setError ] = useState<any>( null );
	const [ isLoading, setIsLoading ] = useState<boolean>( true );

	useEffect( () => {
		getAuthorizationDetails().then( ( { account, accessToken }: AuthorizationDetails ) => {
			if ( account && accessToken ) {
				setAccount( account );
				setRawToken( accessToken );
				setIsLoading( false );
			}
		} ).catch( ( error: any ) => {
			setError( error );
			setIsLoading( false );
		} )
	}, [] )

	return {
		account,
		error,
		signIn,
		signOut,
		isLoading,
		rawToken
	}
}