import { Action, ActionType } from "../types/actions";
import { PublicHolidaysData } from "../types/api/timesheets";

export interface PublicHolidaysState {
  publicHolidays: Array<PublicHolidaysData>;
  selectedWeekHolidays: Array<PublicHolidaysData>;
  isLoading: boolean;
  isLoaded: boolean;
}

export const publicHolidaysReducer = (
  state: PublicHolidaysState = {
    publicHolidays: [],
    selectedWeekHolidays: [],
    isLoading: false,
    isLoaded: false,
  },
  action: Action
): PublicHolidaysState => {
  switch (action.type) {
    case ActionType.GET_PUBLIC_HOLIDAYS_STARTED: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ActionType.GET_PUBLIC_HOLIDAYS_SUCCEEDED: {
      const { publicHolidays, selectedWeekHolidays } = action;
      return {
        ...state,
        publicHolidays,
        selectedWeekHolidays,
        isLoading: false,
        isLoaded: true,
      };
    }
    case ActionType.GET_PUBLIC_HOLIDAYS_FAILED: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }
    default:
      return state;
  }
};
