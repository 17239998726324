import React from 'react';

import { Box, Typography, Paper, Tooltip, SvgIconProps } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import Chip from './controls/Chip';
import clsx from 'clsx';

const useStyles = makeStyles( ( theme ) => ( {
	paper: {
		boxShadow: `0px 1px 16px ${ theme.palette.custom.boxShadow }`,
		color: theme.palette.text.primary,
		'&:hover': {
			cursor: 'pointer',
		},
		margin: theme.spacing( 0, 1.5 ),
	},
	disabled: {
		opacity: '0.5',
		margin: theme.spacing( 0, 1.5 ),
	},
	action: {
		'& :hover': {
			cursor: 'pointer',
		},
	},
	static: {},
	titleBox: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing( 1 ),
	},
	mainContainer: { margin: theme.spacing( 2 ) },
	tooltip: {
		zIndex: 10,
	},
} ) );

const defaultChipLabel = 'add hours';

export interface TemplateCardProps {
	title?: string;
	chipLabel?: string;
	color: 'primary' | 'secondary' | 'dark';
	action?: () => void;
	className?: string;
	disabled?: boolean;
	customChipColor?: string;
	tooltip?: string;
	Icon?: ( props: SvgIconProps ) => JSX.Element;
}

const TemplateCard: React.FC<TemplateCardProps> = ( {
	title,
	color,
	chipLabel,
	action,
	className,
	disabled = false,
	customChipColor,
	tooltip,
	Icon,
} ) => {
	const classes = useStyles();

	return (
		<Box className={ clsx( classes.mainContainer, className ) }>
			<Paper
				className={ disabled ? classes.disabled : classes.paper }
				onClick={ disabled ? undefined : action }
			>
				<Box
					p={ 2 }
					display="flex"
					className={ action && !disabled ? classes.action : classes.static }
				>
					<Box className={ classes.titleBox }>
						{ Icon && tooltip && (
							<Tooltip
								title={ tooltip }
								placement="top"
								className={ classes.tooltip }
							>
								<Icon />
							</Tooltip>
						) }
						<Typography variant="h4">{ title }</Typography>
					</Box>
					<Box>
						<Chip
							color={ color }
							label={ chipLabel || defaultChipLabel }
							style={ { backgroundColor: customChipColor } }
						/>
					</Box>
				</Box>
			</Paper>
		</Box>
	);
};
export default TemplateCard;
