import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {
	deleteExpenseClaimDocument,
	getExpenseClaimDocument,
} from '../../../actions/api-actions';
import { Close } from '@mui/icons-material';
import { ExpenseClaimDocument } from '../../../types/api/expenseClaims';
import { useDispatch } from 'react-redux';
import FileView from '../../FileView';

export interface ExpenseClaimFileViewDesktopProps {
	document: ExpenseClaimDocument;
	expenseClaimId?: string;
	employeeCode?: string;
	documentId: string;
}

const useStyles = makeStyles( ( theme ) => ( {
	file: {
		color: theme.palette.text.primary,
		padding: theme.spacing( 0, 1 ),
		fontSize: theme.spacing( 1.75 ),
	},
	files: {
		display: 'flex',
		alignItems: 'center',
	},
	delete: {
		color: theme.palette.error.dark,
		cursor: 'pointer',
		fontSize: theme.spacing( 2 ),
		display: 'flex',
		alignItems: 'center',
	},
	detailsProp: {
		padding: theme.spacing( 1, 2 ),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
} ) );

const ExpenseClaimFileViewDesktop: React.FC<ExpenseClaimFileViewDesktopProps> =
	( { document, expenseClaimId, documentId, employeeCode } ) => {
		const classes = useStyles();
		const dispatch = useDispatch();
		const [ isLoadingDocument, setIsLoadingDocument ] = useState<boolean>( false );

		const onDeleteDocument = useCallback( () => {
			if ( employeeCode && expenseClaimId ) {
				setIsLoadingDocument( true );
				dispatch(
					deleteExpenseClaimDocument( {
						employeeCode,
						expenseClaimId,
						documentId,
					} )
				);
			}
		}, [ dispatch, employeeCode, expenseClaimId, documentId ] );

		const documentTitle =
			document.document.length > 10
				? `${ document.document.slice( 0, 15 ) }...`
				: document.document;

		const onLoadDocument = useCallback( async () => {
			if ( employeeCode && expenseClaimId && !document.isLoaded ) {
				setIsLoadingDocument( true );
				dispatch(
					getExpenseClaimDocument( {
						employeeCode,
						expenseClaimId,
						documentId,
					} )
				);
			}
		}, [ dispatch, employeeCode, expenseClaimId, documentId, document.isLoaded ] );

		useEffect( () => {
			if (
				isLoadingDocument &&
				( document.isLoaded || document.failed || !document.document )
			) {
				setIsLoadingDocument( false );
			}
		}, [
			document.isLoaded,
			document.document,
			document.failed,
			isLoadingDocument,
		] );

		return (
			<Box className={ classes.files } key={ document.id }>
				<Box display="flex" alignItems="center">
					<FileView
						base64String={
							document.files?.length ? document.files[ 0 ].fileData : undefined
						}
						fileName={ document.document }
						onClick={ onLoadDocument }
						isLoading={ isLoadingDocument }
					/>
					<Tooltip title={ document.document } placement="top-end">
						<Typography className={ classes.file } key={ document.document }>
							{ documentTitle }
						</Typography>
					</Tooltip>
				</Box>
				{!isLoadingDocument && (
					<Box textAlign="right">
						<Close className={ classes.delete } onClick={ onDeleteDocument } />
					</Box>
				) }
			</Box>
		);
	};

export default ExpenseClaimFileViewDesktop;
