import React from 'react';

import { Box, Typography, Paper, Dialog } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CirclePicker, ColorChangeHandler } from 'react-color';
import Chip from '../../controls/Chip';
import { colorPickerColors } from '../../../colors';
import useTranslation from '../../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	paper: {
		boxShadow: `0px 1px 16px ${ theme.palette.custom.boxShadow }`,
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.primary,
		margin: theme.spacing( 0, 4, 1 ),
		padding: theme.spacing( 0.5, 1.5 ),
		'&:hover': {
			cursor: 'pointer',
		},
	},
	pickerPaper: {
		display: 'flex',
		padding: theme.spacing( 1, 1 ),
		justifyContent: 'center',
		margin: theme.spacing( 3 )
	},
	static: {},
} ) );

export interface ColorPickerProps {
	selectedColor?: string;
	onOpenColorPicker: () => void;
	onCloseColorPicker: () => void;
	isPickerOpen: boolean;
	onChangeColor: ColorChangeHandler;
}

const ColorPicker: React.FC<ColorPickerProps> = ( {
	isPickerOpen,
	selectedColor,
	onOpenColorPicker,
	onCloseColorPicker,
	onChangeColor,
} ) => {
	const classes = useStyles();

	const t = useTranslation();

	return (
		<Box>
			<Paper className={ classes.paper } onClick={ onOpenColorPicker }>
				<Box p={ 0.25 } display="flex">
					<Box flex="1" display="flex" alignItems="center">
						<Typography variant="h4">{ t( 'Template color' ) }</Typography>
					</Box>
					<Box>
						<Chip
							label={ selectedColor ? "" : t( 'Not selected' ) }
							style={ {
								paddingLeft: '1rem',
								paddingRight: '1rem',
								backgroundColor: selectedColor,
							} }
						/>
					</Box>
				</Box>
			</Paper>
			<Dialog open={ isPickerOpen } onClose={ onCloseColorPicker } maxWidth="lg">
				<Paper className={ classes.pickerPaper }>
					<CirclePicker
						color={ selectedColor }
						onChangeComplete={ onChangeColor }
						colors={ colorPickerColors }
					/>
				</Paper>
			</Dialog>
		</Box>
	);
};
export default ColorPicker;
