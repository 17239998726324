import api from '../../middleware/api';
import { ActivitiesData, Activity } from '../../types/api/timesheets';
import {
	AccountRequests,
	LeaveRequestBalance,
} from '../../types/api/leaveRequests';
import { WeekRangeData } from '../time-actions';
import { days, getWeekdayDate, getYearRange } from '../../utils/date';
import { DateTime } from 'luxon';

// LEAVE_REQUEST

export const GET_LEAVE_REQUESTS_STARTED = 'GET_LEAVE_REQUESTS_STARTED';
export function getLeaveRequestsStarted () {
	return {
		type: GET_LEAVE_REQUESTS_STARTED,
	};
}

export const GET_LEAVE_REQUESTS_SUCCEEDED = 'GET_LEAVE_REQUESTS_SUCCEEDED';
export function getLeaveRequestsSucceeded ( accountRequests: AccountRequests ) {
	return {
		type: GET_LEAVE_REQUESTS_SUCCEEDED,
		accountRequests,
	};
}

export const GET_LEAVE_REQUESTS_FAILED = 'GET_LEAVE_REQUESTS_FAILED';
export function getLeaveRequestsFailed ( error: any ) {
	return {
		type: GET_LEAVE_REQUESTS_FAILED,
		error,
	};
}

export const getLeaveRequest = ( employeeCode: string, id: string ) => {
	return async ( dispatch: Function ) => {
		dispatch( getLeaveRequestsStarted() );
		try {
			const result = await api.getLeaveRequest( {
				employeeCode,
				id,
			} );
			dispatch( getLeaveRequestsSucceeded( result ) );
		} catch ( error ) {
			dispatch( getLeaveRequestsFailed( error ) );
		}
	};
};

export const getLeaveRequests = (
	employeeCode: string,
	weekRange: WeekRangeData,
	selectedYear: number
) => {
	return async ( dispatch: Function ) => {
		dispatch( getLeaveRequestsStarted() );
		try {
			const leaveRequestsData = await api.getLeaveRequests( {
				employeeCode,
				start: weekRange.from,
				end: weekRange.to,
			} );

			const { leaveRequests: rawLeaveRequests } = leaveRequestsData[ 0 ];

			// Filter out single days for not selected year
			const leaveRequests = rawLeaveRequests.filter( ( leave: any ) => {
				const startOfWeekDay = DateTime.fromISO( leave.startDateOfWeek );
				const leaveDayName = days.find( ( day ) => {
					return leave[ day ] !== 0;
				} );

				if ( !leaveDayName ) {
					return false;
				}

				const weekday = getWeekdayDate( startOfWeekDay, leaveDayName );

				return (
					weekday.year === selectedYear ||
					days.filter( ( day ) => leave[ day ] !== 0 ).length > 1
				);
			} );

			const accountBalanceData = await api.getLeaveRequestsBalance( {
				employeeCode,
				year: weekRange.from.getFullYear(),
			} );

			const { leaveBalances } = accountBalanceData[ 0 ];

			// Activity codes for overtime and vacation
			const balancesActivityCodes = [ '00024', '00091' ];
			const filteredOutBalances = leaveBalances?.filter(
				( leaveBalance: LeaveRequestBalance ) =>
					balancesActivityCodes.includes( leaveBalance.activityCode )
			);

			const accountRequests = { employeeCode, leaveRequests, leaveBalances: filteredOutBalances };

			dispatch( getLeaveRequestsSucceeded( accountRequests ) );
		} catch ( error ) {
			dispatch( getLeaveRequestsFailed( error ) );
		}
	};
};

export const GET_LEAVE_REQUEST_ACTIVITIES_STARTED =
	'GET_LEAVE_REQUEST_ACTIVITIES_STARTED';
export function getLeaveRequestActivitiesStarted () {
	return {
		type: GET_LEAVE_REQUEST_ACTIVITIES_STARTED,
	};
}

export const GET_LEAVE_REQUEST_ACTIVITIES_SUCCEEDED =
	'GET_LEAVE_REQUEST_ACTIVITIES_SUCCEEDED';
export function getLeaveRequestActivitiesSucceeded (
	activities: Array<Activity>
) {
	return {
		type: GET_LEAVE_REQUEST_ACTIVITIES_SUCCEEDED,
		activities,
	};
}

export const GET_LEAVE_REQUEST_ACTIVITIES_FAILED =
	'GET_LEAVE_REQUEST_ACTIVITIES_FAILED';
export function getLeaveRequestActivitiesFailed ( error: any ) {
	return {
		type: GET_LEAVE_REQUEST_ACTIVITIES_FAILED,
		error,
	};
}

export function getLeaveRequestActivities ( requestData?: {
	employeeCode: string;
} ) {
	return function ( dispatch: Function ) {
		dispatch( getLeaveRequestActivitiesStarted() );
		api
			.getActivities()
			.then( ( response: Array<ActivitiesData> ) => {
				const activities = response[ 0 ].activities.filter(
					( activity ) => activity.activityType.id === 'a2'
				);
				dispatch( getLeaveRequestActivitiesSucceeded( activities ) );
			} )
			.catch( ( err: any ) => {
				dispatch( getLeaveRequestActivitiesFailed( err ) );
			} );
	};
}

/*
 * MANAGE_LEAVE_REQUEST
 */

export const CREATE_LEAVE_REQUEST_STARTED = 'ADD_LEAVE_REQUEST_STARTED';
export function createLeaveRequestStarted () {
	return {
		type: CREATE_LEAVE_REQUEST_STARTED,
	};
}

export const CREATE_LEAVE_REQUEST_SUCCEEDED = 'CREATE_LEAVE_REQUEST_SUCCEEDED';
export function createLeaveRequestSucceeded () {
	return {
		type: CREATE_LEAVE_REQUEST_SUCCEEDED,
	};
}

export const CREATE_LEAVE_REQUEST_FAILED = 'CREATE_LEAVE_REQUEST_FAILED';
export function createLeaveRequestFailed ( error: any ) {
	return {
		type: CREATE_LEAVE_REQUEST_FAILED,
		error,
	};
}

export function createLeaveRequest ( requestData: any, year: number ) {
	const { employeeCode } = requestData;
	const range = getYearRange( year );

	return function ( dispatch: Function ) {
		dispatch( createLeaveRequestStarted() );
		api
			.upSertLeaveRequest( {
				leaveRequest: {
					...requestData,
					startDate: DateTime.fromJSDate(
						new Date( requestData.startDate )
					).toISODate(),
					endDate: DateTime.fromJSDate(
						new Date( requestData.endDate )
					).toISODate(),
				},
			} )
			.then( ( res: any ) => {
				dispatch( createLeaveRequestSucceeded() );
				dispatch( getLeaveRequests( employeeCode, range, year ) );
			} )

			.catch( ( err: any ) => {
				dispatch( createLeaveRequestFailed( err ) );
				dispatch( getLeaveRequests( employeeCode, range, year ) );
			} );
	};
}

/*
 * DELETE_LEAVE_REQUEST
 */

export const DELETE_LEAVE_REQUEST_STARTED = 'DELETE_LEAVE_REQUEST_STARTED';
export function deleteLeaveRequestStarted () {
	return {
		type: CREATE_LEAVE_REQUEST_STARTED,
	};
}

export const DELETE_LEAVE_REQUEST_SUCCEEDED = 'DELETE_LEAVE_REQUEST_SUCCEEDED';
export function deleteLeaveRequestSucceeded () {
	return {
		type: DELETE_LEAVE_REQUEST_SUCCEEDED,
	};
}

export const DELETE_LEAVE_REQUEST_FAILED = 'DELETE_LEAVE_REQUEST_FAILED';
export function deleteLeaveRequestFailed ( error: any ) {
	return {
		type: DELETE_LEAVE_REQUEST_FAILED,
		error,
	};
}

export function deleteLeaveRequest (
	employeeCode: string,
	id: string,
	year: number
) {
	const range = getYearRange( year );
	return function ( dispatch: Function ) {
		dispatch( deleteLeaveRequestStarted() );
		api
			.deleteLeaveRequest( employeeCode, id )
			.then( () => {
				dispatch( deleteLeaveRequestSucceeded() );
				dispatch( getLeaveRequests( employeeCode, range, year ) );
			} )

			.catch( ( err: any ) => {
				dispatch( deleteLeaveRequestFailed( err ) );
				dispatch( getLeaveRequests( employeeCode, range, year ) );
			} );
	};
}
