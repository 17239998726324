import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Dialog, Slide, SlideProps } from '@mui/material';

import SelectDestinationDesktop from '../SelectDestination/SelectDestinationDesktop';
import CreateTimesheetDesktop from '../ManageTimesheet/CreateTimesheetDesktop';
import EditTimesheetDesktop from '../ManageTimesheet/EditTimesheetDesktop';
import ManageDestinationDesktop from '../CreateDestination/ManageDestinationDesktop';
import { Destination } from '../../../types/api/settings';
import CreateTimesheetTempDestinationDesktop from '../ManageTimesheet/CreateTimesheetTempDestinationDesktop';
import DesktopPopUpHeader from '../../Headers/DesktopPopUpHeader';

const Transition = React.forwardRef<unknown, SlideProps>(
	function Transition ( props, ref ) {
		return <Slide direction="up" ref={ ref } { ...props } />;
	}
);

const useStyles = makeStyles( ( theme: Theme ) =>
	createStyles( {
		mediaHeader: {
			display: 'flex',
			position: 'relative',
			marginBottom: theme.spacing( 1 ),
			padding: theme.spacing( 0.5 ),
		},
		iconButton: {
			marginLeft: theme.spacing( 1 ),
			color: theme.palette.custom.white,
		},
		dialog: {
			backgroundColor: theme.palette.background.default,
			maxWidth: theme.spacing( 80 ),
			padding: 0,
			borderStyle: 'solid',
			borderWidth: theme.spacing( 0.1, ),
			borderColor: theme.palette.background.paper,
			borderRadius: theme.spacing( 1 ),
			width: '50rem',
			height: '100vh',
			backgroundImage: 'none',
		},
	} )
);

export interface TimesheetManagementProps {
	isOpen: boolean;
	onClose: () => void;
	onSetSelectedDestination: ( destination: string ) => void;
	setIsManagingDestination: ( isManagingDestination: boolean ) => void;
	isManagingDestination: boolean;
	tempDestination: Destination;
	selectedDestinationName?: string;
	timesheetId?: string;
	selectedDayProp: string;
	selectedDayHours: number;
}

const TimesheetManagement: React.FC<TimesheetManagementProps> = ( {
	isOpen,
	onClose,
	timesheetId,
	selectedDestinationName,
	onSetSelectedDestination,
	setIsManagingDestination,
	isManagingDestination,
	tempDestination,
	selectedDayProp,
	selectedDayHours,
} ) => {
	const classes = useStyles();

	return (
		<Dialog
			open={ isOpen }
			onClose={ onClose }
			classes={ { paper: classes.dialog } }
			TransitionComponent={ Transition }
		>
			<DesktopPopUpHeader onClosePopUp={ onClose } />
			{ tempDestination && selectedDayProp && !isManagingDestination && (
				<CreateTimesheetTempDestinationDesktop
					selectedDayProp={ selectedDayProp }
					selectedDayHours={ selectedDayHours }
					closeDialog={ onClose }
				/>
			) }
			{ isManagingDestination && (
				<ManageDestinationDesktop
					onSetSelectedDestination={ onSetSelectedDestination }
					templateName={ selectedDestinationName }
					setIsManagingDestination={ setIsManagingDestination }
				/>
			) }
			{ !selectedDestinationName &&
				!isManagingDestination &&
				!tempDestination && (
					<SelectDestinationDesktop
						onSetDestinationName={ onSetSelectedDestination }
						setIsManagingDestination={ setIsManagingDestination }
					/>
				) }
			{ !timesheetId &&
				selectedDayProp &&
				selectedDestinationName &&
				!isManagingDestination && (
					<CreateTimesheetDesktop
						destinationName={ selectedDestinationName }
						selectedDayProp={ selectedDayProp }
						selectedDayHours={ selectedDayHours }
						closeDialog={ onClose }
					/>
				) }
			{ timesheetId && selectedDestinationName && !isManagingDestination && (
				<EditTimesheetDesktop
					timesheetId={ timesheetId }
					destination={ selectedDestinationName }
					closeDialog={ onClose }
				/>
			) }
		</Dialog>
	);
};

export default TimesheetManagement;
