import api from '../../middleware/api';
import { TimesheetSourceDataResponseData } from '../../types/api/timesheets';

/*
 * GET_TIME_SHEET_SOURCE_DATA
 */

export const GET_TIME_SHEET_SOURCE_DATA_STARTED =
	'GET_TIME_SHEET_SOURCE_DATA_STARTED';
export function getTimesheetSourceDataStarted () {
	return {
		type: GET_TIME_SHEET_SOURCE_DATA_STARTED,
	};
}

export const GET_TIME_SHEET_SOURCE_DATA_SUCCEEDED =
	'GET_TIME_SHEET_SOURCE_DATA_SUCCEEDED';
export function getTimesheetSourceDataSucceeded (
	timesheetSourceData: TimesheetSourceDataResponseData
) {
	return {
		type: GET_TIME_SHEET_SOURCE_DATA_SUCCEEDED,
		timesheetSourceData,
	};
}

export const GET_TIME_SHEET_SOURCE_DATA_FAILED =
	'GET_TIME_SHEET_SOURCE_DATA_FAILED';
export function getTimesheetSourceDataFailed ( error: any ) {
	return {
		type: GET_TIME_SHEET_SOURCE_DATA_FAILED,
		error,
	};
}

export function getTimesheetSourceData () {
	return function ( dispatch: Function ) {
		dispatch( getTimesheetSourceDataStarted() );
		api
			.getTimesheetSourceData()
			.then( ( timesheetSourceData: any ) => {
				const { activities, projectActivities } = timesheetSourceData;

				const data: any = {
					activities: activities,
					projectActivities: projectActivities,
				};

				return dispatch( getTimesheetSourceDataSucceeded( data ) );
			} )
			.catch( ( err: any ) => dispatch( getTimesheetSourceDataFailed( err ) ) );
	};
}


