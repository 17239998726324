import React, { useCallback } from 'react';

import makeStyles from '@mui/styles/makeStyles';

import Dropdown from '../../controls/Dropdown';

const PREVIOUS_YEARS_COUNT = 2;
const UPCOMING_YEARS_COUNT = 2;

const useStyles = makeStyles( theme => ( {
	labelClass: {
		display: 'none'
	},
	selectClass: {
		fontWeight: 900,
		maxWidth: `calc( 100% )`,
		fontSize: theme.spacing( 2 ),
		padding: theme.spacing( 0 ),
		margin: theme.spacing( 0 ),
		color: theme.palette.text.disabled,
		[ theme.breakpoints.down( 'lg' ) ]: {
			padding: theme.spacing( 0, 1 ),
		},
	}
} ) )

export interface YearSelectorProps {
	selectedYear: number;
	onChange: ( newYear: number ) => void;
}

const getRecentYears = ( year: number ) => {
	const recentYears = [];
	for (
		let currentYear = year - PREVIOUS_YEARS_COUNT;
		currentYear < year + UPCOMING_YEARS_COUNT;
		currentYear++
	) {
		recentYears.push( currentYear );
	}

	return recentYears;
};

const YearSelector: React.FC<YearSelectorProps> = ( {
	selectedYear,
	onChange,
} ) => {
	const classes = useStyles();
	const years = getRecentYears( new Date().getFullYear() );

	const onChangeValue = useCallback(
		( value: string ) => {
			onChange( Number( value ) );
		},
		[ onChange ]
	);

	return (
		<Dropdown
			selectedValue={ selectedYear.toString() }
			options={ years.map( ( x ) => x.toString() ) }
			onValueChange={ onChangeValue }
			label="Overview"
			labelClass={ classes.labelClass }
			selectClass={ classes.selectClass }
			autoExpand={ false }
		/>
	);
};

export default YearSelector;
