import { useSelector } from 'react-redux';
import { AppState } from '../reducers';

const useCreateTimesheetData = () => {
	const selectedWeekRange = useSelector(
		( state: AppState ) => state.timeState.weekRange
	);

	const isLoadingDestinations = useSelector(
		( state: AppState ) => state.settingsState.isLoading
	);
	const settings = useSelector(
		( state: AppState ) => state.settingsState.settings
	);

	return {
		selectedWeekRange,
		isLoadingDestinations,
		settings,
	};
};

export default useCreateTimesheetData;
