import React, { useEffect, useCallback, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {
	dismissExpenseClaimError,
	getExpenseClaimsSourceData,
	upSertExpenseClaim,
} from '../../../actions/api-actions';

import ManageExpenseClaimForm from './ManageExpenseClaimForm';
import ExpenseClaimsCodes from './ExpenseClaimsCodes';
import ProjectActivitiesMenu from './ProjectActivities';
import CodesCategories from './CodesCategories';
import ManageButtons from './ManageButtons';
import ScreenWrapper from '../ScreenWrapper';
import FooterHint from '../../controls/FooterHint';
import OverlayLoader from '../../OverlayLoader';
import useTranslation from '../../../hooks/useTranslation';
import useManageExpenseClaimsData from '../../../hooks/useManageExpenseClaimData';
import { Close } from '@mui/icons-material';
import { AppState } from '../../../reducers';

const useStyles = makeStyles( ( theme ) => ( {
	button: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		borderTopWidth: theme.spacing( 0.25 ),
		borderTopColor: theme.palette.custom.lightGray,
		borderRadius: 0,
		borderTopStyle: 'solid',
		boxSizing: 'border-box',
		padding: theme.spacing( 1.5 ),
		fontWeight: 900,
		'&:hover': {
			backgroundColor: theme.palette.background.paper,
		},
	},
	selectClass: {
		flex: 1,
		margin: theme.spacing( 1, 0, 0, 0.5 ),
		maxWidth: `calc( 100% )`,
		paddingLeft: theme.spacing( 1 ),
		color: theme.palette.text.primary,
		overflow: 'hidden',
	},
	selectBackground: {
		backgroundColor: theme.palette.custom.lightBlueGray,
	},
	icon: {
		fontSize: theme.spacing( 2.5 ),
		margin: theme.spacing( 0, 0.25 ),
		cursor: 'pointer',
	},
	responseError: {
		color: theme.palette.secondary.main,
		fontSize: theme.spacing( 1.75 ),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: theme.spacing( 2 ),
		padding: theme.spacing( 0, 0, 1 ),
		borderBottom: 'solid',
		borderBottomWidth: theme.spacing( 0.1 ),
	},
	missingData: {
		display: 'flex',
		justifyContent: 'center',
		padding: theme.spacing( 3, 0 ),
	},
} ) );

export interface ManageExpenseClaimProps { }

export interface ExpenseClaimProjectActivity {
	projectNumber?: string;
	projectDescription?: string;
	subProjectNumber?: string;
	subProjectDescription?: string;
	projectPhaseDescription?: string;
	projectPhaseNumber?: string;
}

const ManageExpenseClaim: React.FC<ManageExpenseClaimProps> = () => {
	const classes = useStyles();
	const dispatch: any = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const { id } = useParams<{ id: string }>();

	const [ error, setError ] = useState<string | undefined>();

	const {
		projectActivities,
		expenseClaimsCodesDescriptions,
		defaultExpenseClaimDescription,
		defaultExpenseClaimType,
		defaultExpenseClaimCode,
		isLoading,
		expenseClaimsCodes,
		codesCategory,
		setCodesCategory,
		isActiveDropDown,
		setIsActiveDropDown,
		projectData,
		setSelectedProjectData,
		descriptionExpenseClaimCode,
		setDescriptionExpenseClaimCode,
		date,
		setDate,
		employeeCode,
		expenseClaimToEdit,
	} = useManageExpenseClaimsData( id );

	const { responseError } = useSelector(
		( state: AppState ) => ( {
			responseError: state.expenseClaimsState.error,
		} )
	);

	useEffect( () => {
		if ( employeeCode ) {
			if ( !projectActivities ) {
				dispatch( getExpenseClaimsSourceData() );
			}
		}
	}, [ employeeCode, dispatch, projectActivities ] );

	const expenseClaimCodeData = expenseClaimsCodes?.find(
		( item ) => item.DescriptionExpenseClaimCode === descriptionExpenseClaimCode
	);

	const t = useTranslation();

	const onSubmit = useCallback(
		( data ) => {
			const requestData = {
				...data,
				amount: data.amount || 0,
				KM: data.KM || 0,
				id,
				employeeCode,
			};

			dispatch(
				upSertExpenseClaim( requestData )
			).then( ( updatedItemId: string ) => {
				if ( !!updatedItemId ) {
					navigate( '/expense-claims', {
						state: {
							previous: location.pathname,
						}
					} );
				}
			} );
		},
		[ employeeCode, id, dispatch, navigate, location.pathname ]
	);

	const onSetCategory = useCallback(
		( category: string ) => {
			setCodesCategory( category );
			setIsActiveDropDown( false );
			setTimeout( () => {
				setIsActiveDropDown( true );
			}, 0 );
			setError( undefined );
		},
		[ setCodesCategory, setIsActiveDropDown ]
	);

	const onDismissError = useCallback( () => {
		dispatch( dismissExpenseClaimError() );
	}, [ dispatch ] )

	if ( isLoading || !employeeCode ) {
		return (
			<OverlayLoader
				message={ 'Loading' }
				loadingCondition={ isLoading || !employeeCode }
			/>
		);
	}

	if ( !expenseClaimToEdit && id && employeeCode && !isLoading ) {
		return (
			<Typography className={ classes.missingData }>
				{ t( "Expense claim doesn't exist" ) }
			</Typography>
		);
	}

	return (
		<>
			<ScreenWrapper customTitle="Expense claim" withHeader={ !id }>
				{ !id && (
					<CodesCategories
						onSelectCategory={ onSetCategory }
						selectedCategory={ codesCategory }
					/>
				) }
				{ responseError && (
					<Typography className={ classes.responseError }>
						{ responseError }
						<Close className={ classes.icon } onClick={ onDismissError } />
					</Typography>
				) }
				{ ( isActiveDropDown || id ) &&
					expenseClaimsCodesDescriptions?.length &&
					( codesCategory || id ) ? (
					<ExpenseClaimsCodes
						selectClass={ classes.selectClass }
						expenseClaimsDescriptions={ expenseClaimsCodesDescriptions }
						onChangeValue={ setDescriptionExpenseClaimCode }
						selectedValue={
							( descriptionExpenseClaimCode &&
								expenseClaimsCodesDescriptions.includes(
									descriptionExpenseClaimCode
								)
								? descriptionExpenseClaimCode
								: undefined ) || defaultExpenseClaimDescription
						}
					/>
				) : null }
				{ projectActivities &&
					expenseClaimCodeData?.type.includes( 'project' ) &&
					( descriptionExpenseClaimCode || defaultExpenseClaimCode ) && (
						<ProjectActivitiesMenu
							projectActivities={ projectActivities }
							selectBackground={ classes.selectBackground }
							setSelectedProjectData={ setSelectedProjectData }
							selectedProjectData={ projectData }
							selectClass={ classes.selectClass }
							expenseClaimToEdit={ expenseClaimToEdit }
						/>
					) }
				{ ( expenseClaimCodeData?.ExpenseClaimCode ||
					defaultExpenseClaimCode ) && (
						<ManageExpenseClaimForm
							date={ date }
							setDate={ setDate }
							onSubmit={ onSubmit }
							expenseClaimToEdit={ expenseClaimToEdit }
							expenseClaimCode={
								expenseClaimCodeData?.ExpenseClaimCode || defaultExpenseClaimCode
							}
							expenseClaimCodeType={
								expenseClaimCodeData?.type || defaultExpenseClaimType
							}
							error={ error }
							setError={ setError }
							{ ...projectData }
						/>
					) }
			</ScreenWrapper>

			{ !expenseClaimCodeData && !expenseClaimToEdit ? (
				<FooterHint hint={ 'Select an expense claim code' } />
			) : (
				<Box display="block">
					<ManageButtons
						disabled={ !!error }
						title={ expenseClaimToEdit ? 'Save changes' : 'Save data' }
					/>
				</Box>
			) }
		</>
	);
};
export default ManageExpenseClaim;
