import React, { useCallback } from 'react';
import Dropdown from '../../controls/Dropdown';

interface ReleaseTimesheetItemProps {
	onChangeOvertimeCode: ( value: string, optionIndex: number, userAccountIndex: number ) => void;
	selectedValues: string[];
	optionIndex: number;
	overtimeOption: any;
	userAccountIndex: number;
}

const ReleaseTimesheetDropdown: React.FC<ReleaseTimesheetItemProps> = ( {
	onChangeOvertimeCode,
	selectedValues,
	overtimeOption,
	optionIndex,
	userAccountIndex
} ) => {
	const onChangeHandler = useCallback(
		( value ) => {
			onChangeOvertimeCode( value, optionIndex, userAccountIndex )
		},
		[ onChangeOvertimeCode, optionIndex, userAccountIndex ],
	)

	const defaultOption = Object.keys( overtimeOption.options )[ 0 ]

	return (
		<Dropdown
			key={ overtimeOption.label }
			autoExpand={ false }
			label={ overtimeOption.label }
			options={ Object.keys( overtimeOption.options ) }
			selectedValue={ selectedValues[ optionIndex ] || defaultOption }
			onValueChange={ onChangeHandler }
		/>
	);
};

export default ReleaseTimesheetDropdown;
