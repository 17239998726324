import React from 'react';
import { useSelector } from 'react-redux';

// Styling
import { ThemeProvider, Theme, StyledEngineProvider, useMediaQuery } from '@mui/material';
import { darkTheme, lightTheme } from '../../theme';

// Pickers
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

// Notifications

import { SnackbarProvider } from 'notistack';

// The current theme is based on the settings
import { AppState } from '../../reducers';

// The App
import App from './App';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const ThemedApp: React.FC = () => {
	const settings: any = useSelector(
		( state: AppState ) => state.settingsState.settings
	);
	const prefersDarkMode = useMediaQuery( '(prefers-color-scheme: dark)' );

	const darkMode: string =
		settings.darkMode && settings.darkMode;
	const theme =
		( !darkMode && prefersDarkMode ) ||
			darkMode === 'Dark mode' ||
			( darkMode === 'Use system settings' && prefersDarkMode )
			? darkTheme
			: lightTheme;

	return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={ theme }>
                <MuiPickersUtilsProvider utils={ LuxonUtils }>
                    <SnackbarProvider maxSnack={ 3 }>
                        { settings && <App /> }
                    </SnackbarProvider >
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default ThemedApp;
