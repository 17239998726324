import React, { useCallback, useState, useEffect } from 'react';
import { useSwipeable, SwipeableHandlers } from 'react-swipeable';
import {
	Typography,
	Menu,
	DialogTitle,
	DialogContent,
	Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LeftArrow from '@mui/icons-material/ArrowLeft';
import RightArrow from '@mui/icons-material/ArrowRight';
import Dropdown from '../Dropdown';
import SelectWeek from './SelectWeek';
import {
	getWeekData,
	years,
	getYearWeekRange,
	getWeeks,
	getNumberOfWeeks,
	now,
} from './helpers';
import { DateTime } from 'luxon';
import { WeekRangeData } from '../../../actions/time-actions';
import useTranslation from '../../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => {
	const color = theme.palette.grey[ 500 ];
	return {
		icon: {
			fontSize: theme.spacing( 8 ),
			color,
			'& :hover': {
				cursor: 'pointer',
			},
		},
		button: {
			textTransform: 'none',
		},
		dialogContent: {
			padding: theme.spacing( 1, 0 ),
		},
		menuContainer: {
			justifyContent: 'space-between',
			alignItems: 'center',
			textAlign: 'center',
			display: 'flex',
			padding: theme.spacing( 0, 3 ),
		},
	};
} );
interface MinimalMenuProps {
	onWeekRangeSelected: ( weekRange: WeekRangeData ) => void;
	selectedWeekRange?: WeekRangeData;
}

const MinimalMenu: React.FC<MinimalMenuProps> = ( {
	onWeekRangeSelected,
	selectedWeekRange,
} ) => {
	const classes = useStyles();

	const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>( null );

	const [ selectedYear, setSelectedYear ] = useState<string>(
		DateTime.fromJSDate( new Date() ).year.toString()
	);

	const [ selectedWeek, setSelectedWeek ] = useState<any>(
		getWeekData( '1', selectedYear )
	);

	const open = Boolean( anchorEl );

	const handleClickOpen = useCallback(
		( e ) => {
			setAnchorEl( e.currentTarget );
		},
		[ setAnchorEl ]
	);

	const weeks = getWeeks( getNumberOfWeeks( Number( selectedYear ) ) );

	useEffect( () => {
		if ( selectedWeekRange ) {
			const initialWeekData: DateTime = DateTime.fromJSDate(
				selectedWeekRange.from
			);
			const initialWeekEndData = DateTime.fromJSDate( selectedWeekRange.to );
			const initialYear =
				initialWeekData.weekNumber === 1
					? initialWeekEndData.weekYear.toString()
					: initialWeekData.weekYear.toString();

			const initialValue = getWeekData(
				initialWeekData.weekNumber.toString(),
				initialYear
			);

			setSelectedYear( initialYear );
			setSelectedWeek( initialValue );
		}
	}, [ selectedWeekRange ] );

	const handleClose = useCallback( () => {
		setAnchorEl( null );
	}, [ setAnchorEl ] );

	const onChangeYearSelection = useCallback(
		( year ) => {
			setSelectedWeek( getWeekData( selectedWeek.value, year ) );
			setSelectedYear( year );
		},
		[ setSelectedYear, selectedWeek ]
	);

	const onSelectWeek = useCallback(
		( weekValue ) => {
			const weekRange = getYearWeekRange( weekValue, selectedYear );
			onWeekRangeSelected( weekRange );
			handleClose();
		},
		[ handleClose, onWeekRangeSelected, selectedYear ]
	);

	const onChangeWeekSelection = useCallback(
		( label ) => {
			const value = label.split( ')' )[ 0 ].slice( 1 );
			setSelectedWeek( { value, label } );
			onSelectWeek( value );
		},
		[ setSelectedWeek, onSelectWeek ]
	);

	const onSelectNextWeek = useCallback( () => {
		const nextWeek = Number( selectedWeek.value ) + 1;
		if (
			nextWeek > weeks.length &&
			Number( selectedYear ) + 1 <= now.getFullYear() + 1
		) {
			const weekRange = getYearWeekRange(
				'1',
				( Number( selectedYear ) + 1 ).toString()
			);
			onWeekRangeSelected( weekRange );
			setSelectedYear( ( Number( selectedYear ) + 1 ).toString() );
			handleClose();
		} else if ( Number( selectedYear ) + 1 > now.getFullYear() + 1 ) {
			return;
		} else {
			onSelectWeek( nextWeek );
		}
	}, [
		selectedYear,
		onSelectWeek,
		setSelectedYear,
		handleClose,
		onWeekRangeSelected,
		selectedWeek.value,
		weeks.length,
	] );

	const onSelectPreviousWeek = useCallback( () => {
		const nextWeek = Number( selectedWeek.value ) - 1;
		if ( nextWeek < 1 && Number( selectedYear ) - 1 >= now.getFullYear() - 1 ) {
			const numberOfWeeksString = getNumberOfWeeks(
				Number( selectedYear ) - 1
			).toString();
			const weekRange = getYearWeekRange(
				numberOfWeeksString,
				( Number( selectedYear ) - 1 ).toString()
			);
			onWeekRangeSelected( weekRange );
			setSelectedYear( ( Number( selectedYear ) - 1 ).toString() );
			handleClose();
		} else if ( Number( selectedYear ) < now.getFullYear() - 1 ) {
			return;
		} else {
			onSelectWeek( nextWeek );
		}
	}, [
		selectedYear,
		onSelectWeek,
		setSelectedYear,
		handleClose,
		onWeekRangeSelected,
		selectedWeek.value,
	] );

	const handlers: SwipeableHandlers = useSwipeable( {
		onSwipedLeft: onSelectNextWeek,
		onSwipedRight: onSelectPreviousWeek,
		preventDefaultTouchmoveEvent: true,
		trackMouse: true,
	} );

	const t = useTranslation();

	return (
		<Box { ...handlers } className={ classes.menuContainer }>
			<LeftArrow className={ classes.icon } onClick={ onSelectPreviousWeek } />
			<SelectWeek onMenuOpen={ handleClickOpen } weekRange={ selectedWeekRange } />
			<Menu
				open={ open }
				anchorEl={ anchorEl }
				onClose={ handleClose }
				keepMounted
				aria-labelledby="minimal-menu-title"
			>
				<DialogTitle id="minimal-menu-title">{ t( 'Select week' ) }</DialogTitle>
				<DialogContent className={ classes.dialogContent }>
					<Typography component="span">
						<Dropdown
							autoExpand={ false }
							label={ t( 'Year' ) }
							options={ years }
							selectedValue={ selectedYear }
							onValueChange={ onChangeYearSelection }
						/>
						<Dropdown
							autoExpand={ false }
							label={ t( 'Week' ) }
							options={ weeks.map( ( x ) => {
								const weekData = getWeekData( x, selectedYear );
								return weekData.label;
							} ) }
							selectedValue={ selectedWeek.label }
							onValueChange={ onChangeWeekSelection }
						/>
					</Typography>
				</DialogContent>
			</Menu>
			<RightArrow className={ classes.icon } onClick={ onSelectNextWeek } />
		</Box>
	);
};

export default MinimalMenu;
