import React, { useCallback, useState, useEffect } from 'react';
import { Box, Typography, Checkbox, TextField } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import TimeSpentSelector from '../../controls/CreateTimesheet/TimeSpentSelector';
import { defaultTimesheetHours, formWeekdaysFields } from '../../../config/constants';
import { useForm } from 'react-hook-form';
import { getTotalTime } from '../../screens/ManageTimesheet/helpers';
import usePublicHolidays from '../../../hooks/usePublicHolidays';
import {
	compareDatesAsISODates,
	convertDecimalToTimeString,
} from '../../../utils/date';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers';
import { Timesheet, TimesheetDetails } from '../../../types/api/timesheets';
import useTranslation from '../../../hooks/useTranslation';
import useTimeRequestHoursData from '../../../hooks/useTimeRequestHoursData';
import { setTimesheetHours } from '../../../actions/time-actions';

const useStyles = makeStyles( ( theme ) => ( {
	timesheetRow: {
		display: 'flex',
		padding: theme.spacing( 1, 2 ),
	},
	label: {
		display: 'flex',
		alignItems: 'center',
		flex: 1,
	},
	value: {
		flex: 1,
		overflow: 'hidden',
		textAlign: 'right',
	},
	totalTimeSpent: {
		fontFamily: 'monospace',
		fontSize: '1.25em',
	},
	workingHours: {
		color: theme.palette.primary.main,
		fontFamily: 'monospace',
		fontSize: theme.spacing( 1.8 ),
		fontWeight: 900,
	},
	workingHoursContainer: {
		paddingBottom: theme.spacing( 1.5 ),
	},
	checkBox: {
		padding: theme.spacing( 0.5 ),
	},
	comment: {
		backgroundColor: theme.palette.custom.boxShadow,
		padding: theme.spacing( 0, 1 ),
		borderRadius: theme.spacing( 0.5 ),
	},
} ) );

interface CreateTimesheetFormProps {
	onSubmit: ( data: any ) => void;
	weekDays: Array<Date>;
	currentTimesheet?: TimesheetDetails;
	selectedDayHours?: number;
	employeeCode: string;
	selectedDayProp?: string;
	comment?: string;
	hoursOutsideOfficeTime?: boolean;
}

const CreateTimesheetForm: React.FC<CreateTimesheetFormProps> = ( {
	onSubmit,
	weekDays,
	currentTimesheet,
	employeeCode,
	selectedDayProp,
	selectedDayHours,
	comment,
	hoursOutsideOfficeTime
} ) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [ totalTime, setTotalTime ] = useState<string>( '00:00' );

	const { register, handleSubmit } = useForm();

	const { timesheetHours, currentTimesheetHours } = useTimeRequestHoursData( currentTimesheet );

	useEffect( () => {
		if ( currentTimesheetHours ) { dispatch( setTimesheetHours( currentTimesheetHours ) ) } else {
			dispatch( setTimesheetHours(
				selectedDayProp
					? {
						...( defaultTimesheetHours ),
						[ selectedDayProp ]: selectedDayHours || 0,
					}
					: { ...defaultTimesheetHours }
			) );
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ dispatch, selectedDayProp, selectedDayHours ] );

	const onFormSubmit = handleSubmit( onSubmit );

	const selectedWeekTimesheets = useSelector<AppState, Array<Timesheet>>(
		( state ) => state.timesheetsState.timesheets
	);

	const currentUserTimesheets = selectedWeekTimesheets.find(
		( userTimesheet ) => userTimesheet.employeeCode === employeeCode
	);

	const workingHours =
		currentUserTimesheets?.timeSheetInfos[ 0 ]?.timeSpentWeekDays || '00:00';
	const contractHours = convertDecimalToTimeString(
		currentUserTimesheets?.timeSheetInfos[ 0 ].contractHours || 0
	);

	const { usersHolidays } = usePublicHolidays();

	const publicHolidays = usersHolidays.find(
		( holidays ) => holidays.employeeCode === employeeCode
	);

	useEffect( () => {
		if ( timesheetHours ) {
			setTotalTime( getTotalTime( timesheetHours ) );
		}
	}, [ timesheetHours ] );

	const onSetTimeFieldValue = useCallback(
		( time ) => {
			setTotalTime( time );
		},
		[ setTotalTime ]
	);

	const t = useTranslation();

	return (
		<form
			id="manage-timesheet-form"
			noValidate
			onSubmit={ onFormSubmit }
			autoComplete="off"
		>
			<Box className={ classes.timesheetRow }>
				<Box className={ classes.workingHoursContainer }>
					<Typography>{ t( 'Week hours' ) }</Typography>
				</Box>
				<Box className={ classes.value }>
					<Typography className={ classes.workingHours }>
						{ `${ workingHours }/${ contractHours }` }
					</Typography>
				</Box>
			</Box>
			<Box>
				{ weekDays.map( ( weekday, index ) => {
					const holiday = publicHolidays?.publicHolidays.find( ( publicHoliday ) =>
						compareDatesAsISODates( new Date( publicHoliday.date ), weekday )
					);
					return (
						<TimeSpentSelector
							key={ index }
							weekday={ weekday }
							timeFieldName={ formWeekdaysFields[ index ] }
							inputRef={ register }
							onSetTimeFieldValue={ onSetTimeFieldValue }
							holiday={ holiday }
							selectedDayProp={ selectedDayProp }
						/>
					);
				} ) }
				<Box className={ classes.timesheetRow }>
					<Box className={ classes.label }>
						<Typography>{ t( 'Hours outside office time' ) }</Typography>
					</Box>
					<Box className={ classes.value }>
						<Checkbox
							className={ classes.checkBox }
							inputRef={ register }
							color="primary"
							name="hoursOutsideOfficeTime"
							inputProps={ { 'aria-label': 'Hours outside office' } }
							defaultChecked={ hoursOutsideOfficeTime }
						/>
					</Box>
				</Box>
				<Box className={ classes.timesheetRow }>
					<Box className={ classes.label }>
						<Typography>{ t( 'Comment' ) }</Typography>
					</Box>
					<Box className={ classes.value }>
						<TextField
							multiline={ true }
							placeholder={ t( 'comment here' ) }
							name="comment"
							inputRef={ register }
							defaultValue={ comment }
							className={ classes.comment }
							inputProps={ { maxLength: '60' } }
							variant='standard'
						/>
					</Box>
				</Box>
				<Box className={ classes.timesheetRow }>
					<Box className={ classes.label }>
						<Typography>{ t( 'Total' ) }</Typography>
					</Box>
					<Box className={ classes.value }>
						<Typography className={ classes.totalTimeSpent }>
							{ totalTime }
						</Typography>
					</Box>
				</Box>
			</Box>
		</form>
	);
};

export default CreateTimesheetForm;
