import { AppState } from '../reducers';
import { useSelector } from 'react-redux';

import { EmployeeCompensationOption } from '../types/api/user';
import { OvertimeOptions } from '../components/screens/Minimal/Minimal';

export interface OptionsDictionary {
	[ optionDescription: string ]: string; //option Id
}

export interface OvertimeOption {
	label: string;
	options: OptionsDictionary;
}

export interface OvertimeAccountOptions {
	companyName: string;
	employeeCode: string;
	accountOptions: Array<OvertimeOption>;
}

const getOptions = ( compensationOptions: EmployeeCompensationOption[] ) =>
	compensationOptions.reduce<OptionsDictionary>(
		( accumulatedOptions, compensationOption ) => ( {
			...accumulatedOptions,
			[ compensationOption.desc ]: compensationOption.id,
		} ),
		{}
	);

const useTimesheetReleaseOptions = ( overtimeOptions: OvertimeOptions[] ) => {
	const overtimeAccountsOptions: Array<OvertimeAccountOptions> = [];
	const releasedAccountsOptions: Array<OvertimeAccountOptions> = [];

	const selectedWeekRange = useSelector(
		( state: AppState ) => state.timeState.weekRange
	);

	const currentTimesheets = useSelector(
		( state: AppState ) => state.timesheetsState.timesheets
	);

	const userInfoAccounts = useSelector(
		( state: AppState ) => state.userState.accounts
	);

	if ( !selectedWeekRange ) {
		return { isLoading: true, overtimeAccountsOptions, userInfoAccounts };
	}

	const accountToRelease = userInfoAccounts.filter(
		( account ) => overtimeOptions[ 0 ].employeeCode === account.employeeCode
	)[ 0 ];

	const currentTimesheet = currentTimesheets.find(
		( timesheet ) => timesheet.employeeCode === overtimeOptions[ 0 ].employeeCode
	);

	const { employeeDetail, companyName } = accountToRelease;
	const {
		overtimeMonFri,
		overtimeSat,
		overtimeSunPubHol,
		employeeCode,
	} = employeeDetail;

	const isNotReleasedTimesheet = currentTimesheet?.timeSheetInfos[ 0 ]?.timesheetDetails.find(
		( t ) => t.status.desc === 'Draft'
	);

	const currentAccountOvertimeOptions = overtimeOptions.find(
		( currentOptions ) => currentOptions.employeeCode === employeeCode
	);
	const accountOptions = [];

	if ( currentAccountOvertimeOptions?.hasMonFriOvertime ) {
		accountOptions.push( {
			label: 'Overtime Monday to Friday',
			options: getOptions( overtimeMonFri ),
		} );
	}

	if ( currentAccountOvertimeOptions?.hasSaturdayOvertime ) {
		accountOptions.push( {
			label: 'Overtime Saturday',
			options: getOptions( overtimeSat ),
		} );
	}

	if ( currentAccountOvertimeOptions?.hasSunPubHolOvertime ) {
		accountOptions.push( {
			label: 'Overtime Sunday',
			options: getOptions( overtimeSunPubHol ),
		} );
	}

	if ( isNotReleasedTimesheet ) {
		overtimeAccountsOptions.push( {
			companyName,
			employeeCode,
			accountOptions,
		} );
	} else {
		releasedAccountsOptions.push( {
			companyName,
			employeeCode,
			accountOptions,
		} );
	}

	const isLoading = userInfoAccounts.length === 0;

	return {
		isLoading,
		overtimeAccountsOptions,
		releasedAccountsOptions,
	};
};

export default useTimesheetReleaseOptions;
