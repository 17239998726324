import React from 'react';
import MaskedInput from 'react-text-mask';

interface TextMaskCustomProps {
	timeType: 'hours' | 'minutes';
	inputRef: any;
}

const getMask = ( timeType: 'hours' | 'minutes' ) => {
	return timeType === 'hours' ? [ /[0-1]/, /[0-9]/ ] : [ /[0-5]/, /[0-9]/ ];
};

const MaskedAddHoursInput: React.FC<TextMaskCustomProps> = ( props ) => {
	const { timeType, inputRef, ...other } = props;

	return (
		<MaskedInput
			{ ...other }
			mask={ getMask( timeType ) }
			showMask
			ref={ inputRef }
			inputMode="numeric"
		/>
	);
};

export default MaskedAddHoursInput;
