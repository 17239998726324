import React, { useState } from 'react';
import * as serviceWorker from '../serviceWorkerRegistration';

interface ServiceWorkerContextValueInterface {
	isUpdateAvailable: boolean,
	updateAssets: () => void;
}

const ServiceWorkerContext = React.createContext<ServiceWorkerContextValueInterface>( {
	isUpdateAvailable: false,
	updateAssets: () => { }
} );

export const ServiceWorkerProvider: React.FC<{ children?: React.ReactNode; }> = ( { children } ) => {
	const [ waitingServiceWorker, setWaitingServiceWorker ] = useState<ServiceWorker | null>( null );
	const [ isUpdateAvailable, setUpdateAvailable ] = useState( false );

	React.useEffect( () => {
		// // If you want your app to work offline and load faster, you can change
		// // unregister() to register() below. Note this comes with some pitfalls.
		// // Learn more about service workers: https://bit.ly/CRA-PWA
		// serviceWorker.register();
		serviceWorker.register( {
			onUpdate: ( registration ) => {
				setWaitingServiceWorker( registration.waiting );
				setUpdateAvailable( true );
			},
			onWaiting: ( waiting ) => {
				setWaitingServiceWorker( waiting );
				setUpdateAvailable( true );
			},
		} );
	}, [] );

	React.useEffect( () => {
		// We setup an event listener to automatically reload the page
		// after the Service Worker has been updated, this will trigger
		// on all the open tabs of our application, so that we don't leave
		// any tab in an incosistent state
		if ( waitingServiceWorker ) {
			waitingServiceWorker.addEventListener( 'statechange', ( event: any ) => {
				if ( event.target.state === 'activated' ) {
					window.location.reload();
				}
			} );
		}
	}, [ waitingServiceWorker ] );

	const value = React.useMemo(
		() => ( {
			isUpdateAvailable,
			updateAssets: () => {
				if ( waitingServiceWorker ) {
					// We send the SKIP_WAITING message to tell the Service Worker
					// to update its cache and flush the old one
					waitingServiceWorker.postMessage( { type: 'SKIP_WAITING' } );
				}
			},
		} ),
		[ isUpdateAvailable, waitingServiceWorker ]
	);

	return (
		<ServiceWorkerContext.Provider value={ value }>
			{ children }
		</ServiceWorkerContext.Provider>
	);
};

// With this React Hook we'll be able to access `isUpdateAvailable` and `updateAssets`
export const useServiceWorker = () => {
	return React.useContext( ServiceWorkerContext );
};
