import { combineReducers } from 'redux';

import { timeReducer as timeState, TimeState } from './time';
import { timesheetsReducer as timesheetsState, TimesheetState } from './timesheets';
import { leaveRequestReducer as leaveRequestState, LeaverRequestState } from './leaveRequests';
import { timesheetSourcesReducer as timesheetSourcesState, TimesheetSourcesState } from './timesheetSources';
import { authorizationReducer as authorizationState, AuthorizationState } from './authorization';
import { userReducer as userState, UserState } from './user';
import { settingsReducer as settingsState, SettingsState } from './settings';
import { releasedTimesheetsReducer as releasedTimesheetsState, ReleasedTimesheetsState } from './releasedTimesheets';
import { tempDestinationReducer as tempDestinationState, TempDestinationState } from './tempDestination';
import { publicHolidaysReducer as publicHolidaysState, PublicHolidaysState } from './publicHolidays';
import { printTimesheetReducer as printTimesheetState, PrintTimesheetState } from './printTimesheet';
import { expenseClaimsReducer as expenseClaimsState, ExpenseClaimsState } from './expenseClaims';
import { expenseClaimsSourcesReducer as expenseClaimsSourcesState, ExpenseClaimsSourcesState } from './expenseClaimsSources';
import { expenseClaimDetailsReducer as expenseClaimDetailsState, ExpenseClaimDetailsState } from './expenseClaimDetails';
import { expenseClaimAttachmentsReducer as expenseClaimAttachmentsState, ExpenseClaimAttachmentsState } from './expenseClaimsAttachments';

export interface AppState {
	timeState: TimeState;
	timesheetsState: TimesheetState;
	leaveRequestState: LeaverRequestState;
	timesheetSourcesState: TimesheetSourcesState;
	userState: UserState;
	settingsState: SettingsState;
	authorizationState: AuthorizationState;
	releasedTimesheetsState: ReleasedTimesheetsState;
	tempDestinationState: TempDestinationState;
	publicHolidaysState: PublicHolidaysState;
	printTimesheetState: PrintTimesheetState;
	expenseClaimsState: ExpenseClaimsState;
	expenseClaimsSourcesState: ExpenseClaimsSourcesState;
	expenseClaimDetailsState: ExpenseClaimDetailsState;
	expenseClaimAttachmentsState: ExpenseClaimAttachmentsState;
}

const rootReducer = combineReducers<AppState>( {
	authorizationState,
	timeState,
	timesheetsState,
	leaveRequestState,
	timesheetSourcesState,
	userState,
	settingsState,
	releasedTimesheetsState,
	tempDestinationState,
	publicHolidaysState,
	printTimesheetState,
	expenseClaimsState,
	expenseClaimsSourcesState,
	expenseClaimDetailsState,
	expenseClaimAttachmentsState,
} );

export default rootReducer;
