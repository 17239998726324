import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import ManageLeavesButtons from './ManageLeavesButtons';
import ManageLeaveRequestForm from './ManageLeaveRequestForm';
import ScreenWrapper from '../ScreenWrapper';
import DeleteConfirm from '../ManageTimesheet/DeleteConfirm';
import FooterHint from '../../controls/FooterHint';
import Dropdown from '../../controls/Dropdown';
import OverlayLoader from '../../OverlayLoader';
import { AppState } from '../../../reducers';
import { getDecimalTimeString, days } from '../../../utils/date';
import useTranslation from '../../../hooks/useTranslation';

import {
	createLeaveRequest,
	deleteLeaveRequest,
	getLeaveRequestActivities,
	getLeaveRequest,
} from './../../../actions/api-actions';
import { currentYear } from '../../controls/Minimal/helpers';

const useStyles = makeStyles( ( theme ) => ( {
	button: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		borderTopWidth: theme.spacing( 0.25 ),
		borderTopColor: theme.palette.custom.lightGray,
		borderRadius: 0,
		borderTopStyle: 'solid',
		boxSizing: 'border-box',
		padding: theme.spacing( 1.5 ),
		fontWeight: 900,
		'&:hover': {
			backgroundColor: theme.palette.background.paper,
		},
	},
	selectClass: {
		flex: 1,
		margin: theme.spacing( 2, 1, 0 ),
		maxWidth: `calc( 100% - 9em )`,
		paddingLeft: theme.spacing( 1 ),
		color: theme.palette.text.primary,
	},
	missingData: {
		display: 'flex',
		justifyContent: 'center',
		padding: theme.spacing( 3, 0 ),
	},
	editModeDisabledFields: {
		display: 'flex',
		justifyContent: 'space-between',
		margin: theme.spacing( 2, 3, 2, 1 ),
		maxWidth: `calc( 100% )`,
		paddingLeft: theme.spacing( 1 ),
		color: theme.palette.text.primary,
	},
} ) );

export interface ManageLeavesProps { }

const ManageLeaves: React.FC<ManageLeavesProps> = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const { id } = useParams<{ id: string }>();

	const [ isActionStarted, setIsActionStarted ] = useState<boolean>( false );

	const userAccounts = useSelector(
		( state: AppState ) => state.userState.accounts
	);

	const [ selectedActivity, setSelectedActivity ] = useState<string>( '' );

	const [ selectedPeriod, setSelectedPeriod ] = useState<
		'(part of day)' | 'Week' | 'Period'
	>();

	const { leaveRequestToEdit, isLoading } = useSelector( ( state: AppState ) => {
		const requestToEdit =
			state.leaveRequestState.accountRequests?.leaveRequests.find(
				( leave ) => leave.id === id
			);

		const loading =
			state.leaveRequestState.isLoading ||
			state.leaveRequestState.isLoadingActivities;

		return { leaveRequestToEdit: requestToEdit, isLoading: loading };
	} );

	const { leaveRequestsYear } = useSelector( ( state: AppState ) => {
		return state.timeState;
	} );

	const leaveRequestDaysWithHours =
		leaveRequestToEdit &&
		days.filter( ( day ) => Number( leaveRequestToEdit[ day ] ) > 0 ).length;

	const leaveRequestEditPeriod =
		leaveRequestDaysWithHours !== undefined
			? leaveRequestDaysWithHours === 0
				? 'Period'
				: leaveRequestDaysWithHours > 1
					? 'Week'
					: '(part of day)'
			: undefined;

	const employeeCode = userAccounts[ 0 ]?.employeeCode;
	const companyCode = userAccounts[ 0 ]?.company;

	useEffect( () => {
		if ( isLoading && isActionStarted ) {
			setIsActionStarted( false );
			navigate( '/leave', {
				state: {
					previous: location.pathname,
				}
			} );
		}
	}, [ isActionStarted, isLoading, navigate, location.pathname ] );

	useEffect( () => {
		if ( employeeCode ) {
			dispatch( getLeaveRequestActivities() );
		}
	}, [ employeeCode, dispatch ] );

	useEffect( () => {
		if ( id && employeeCode && !leaveRequestToEdit ) {
			dispatch( getLeaveRequest( employeeCode, id ) );
		}
	}, [ employeeCode, dispatch, leaveRequestToEdit, id ] );

	const { activities } = useSelector(
		( state: AppState ) => state.leaveRequestState
	);

	const activitiesOptions = activities.reduce( ( options, activity ) => {
		return {
			...options,
			[ activity.activityDescription ]: activity.activityCode,
		};
	}, {} );

	const defaultSelectedActivity = leaveRequestToEdit
		? Object.entries( activitiesOptions ).find(
			( option ) => option[ 1 ] === leaveRequestToEdit.activityCode
		)
		: undefined;

	const onChangeActivity = useCallback(
		( activity ) => {
			setSelectedActivity( activity );
			setSelectedPeriod( selectedPeriod || '(part of day)' );
		},
		[ selectedPeriod ]
	);

	const onDeleteLeaveRequest = useCallback( () => {
		if ( leaveRequestToEdit && leaveRequestsYear ) {
			dispatch(
				deleteLeaveRequest(
					employeeCode,
					leaveRequestToEdit.id,
					leaveRequestsYear
				)
			);
			setIsActionStarted( true );
		}
	}, [ dispatch, employeeCode, leaveRequestToEdit, leaveRequestsYear ] );

	const onChangePeriod = useCallback( ( period ) => {
		setSelectedPeriod( period );
	}, [] );

	const onSubmit = useCallback(
		( data ) => {
			const numberOfHours =
				data.hours !== undefined
					? Number( getDecimalTimeString( data.hours, ':' ) )
					: undefined;

			const weekDaysHours = days
				.filter( ( day ) => ![ 'saturday', 'sunday' ].includes( day ) )
				.reduce( ( hoursProps, day ) => {
					const currentDayValue =
						data[ day ] !== undefined
							? Number( getDecimalTimeString( data[ day ], ':' ) )
							: undefined;
					return { ...hoursProps, [ day ]: currentDayValue };
				}, {} );

			const requestData = {
				...data,
				...weekDaysHours,
				numberOfHours,
				employeeCode,
				companyCode,
				activity:
					( activitiesOptions as any )[ selectedActivity ] ||
					leaveRequestToEdit?.activityCode,
				hours: undefined,
			};

			dispatch(
				createLeaveRequest( requestData, leaveRequestsYear || currentYear )
			);
			setIsActionStarted( true );
		},
		[
			employeeCode,
			companyCode,
			dispatch,
			selectedActivity,
			activitiesOptions,
			leaveRequestsYear,
			leaveRequestToEdit,
		]
	);

	const t = useTranslation();

	if ( isLoading || !employeeCode || isActionStarted ) {
		return (
			<ScreenWrapper withHeader customTitle="Leave Request">
				<OverlayLoader message={ 'Loading' } loadingCondition={ true } />
			</ScreenWrapper>
		);
	}

	if ( !leaveRequestToEdit && id && employeeCode && !isLoading ) {
		return (
			<ScreenWrapper withHeader customTitle="Leave Request">
				<Typography className={ classes.missingData }>
					{ t( "Leave request doesn't exist" ) }
				</Typography>
			</ScreenWrapper>
		);
	}

	if ( activities.length === 0 && employeeCode && !isLoading ) {
		return (
			<ScreenWrapper withHeader customTitle="Leave Request">
				<Typography className={ classes.missingData }>
					{ t( 'no indirect activities' ) }
				</Typography>
			</ScreenWrapper>
		);
	}

	return (
		<>
			<ScreenWrapper withHeader customTitle="Leave Request">
				<>
					{ !leaveRequestToEdit ? (
						<>
							<Dropdown
								label="Activity"
								options={ Object.keys( activitiesOptions ) }
								autoExpand={ !selectedActivity && !leaveRequestToEdit && !id }
								selectedValue={
									selectedActivity ||
									( defaultSelectedActivity
										? defaultSelectedActivity[ 0 ]
										: undefined ) ||
									''
								}
								onValueChange={ onChangeActivity }
								selectValueClass={ classes.selectClass }
							/>
							{ selectedActivity && selectedPeriod && (
								<Dropdown
									label="Period"
									options={
										leaveRequestEditPeriod
											? [ leaveRequestEditPeriod ]
											: [ '(part of day)', 'Week', 'Period' ]
									}
									autoExpand={ false }
									selectedValue={
										leaveRequestEditPeriod
											? leaveRequestEditPeriod
											: selectedPeriod || ''
									}
									onValueChange={ onChangePeriod }
									selectValueClass={ classes.selectClass }
								/>
							) }
						</>
					) : (
						<>
							<Box className={ classes.editModeDisabledFields }>
								<Typography>{ t( 'Activity' ) }</Typography>
								<Typography>
									{ selectedActivity ||
										( defaultSelectedActivity && defaultSelectedActivity[ 0 ] ) }
								</Typography>
							</Box>{ ' ' }
							<Box className={ classes.editModeDisabledFields }>
								<Typography>{ t( 'Period' ) }</Typography>
								<Typography>{ leaveRequestEditPeriod }</Typography>
							</Box>
						</>
					) }
					{ ( selectedActivity || defaultSelectedActivity ) && (
						<ManageLeaveRequestForm
							onSubmit={ onSubmit }
							period={
								leaveRequestEditPeriod
									? leaveRequestEditPeriod
									: selectedPeriod || '(part of day)'
							}
							leaveParams={ leaveRequestToEdit }
						/>
					) }
				</>
			</ScreenWrapper>
			{ !selectedActivity && !defaultSelectedActivity ? (
				<FooterHint hint={ 'Select an activity' } />
			) : (
				<Box display="block">
					<ManageLeavesButtons
						isEditButton={ !!leaveRequestToEdit }
						title={ !!leaveRequestToEdit ? 'Save changes' : 'Save request' }
					/>
					{ leaveRequestToEdit && (
						<DeleteConfirm action={ onDeleteLeaveRequest } />
					) }
				</Box>
			) }
		</>
	);
};
export default ManageLeaves;
