import React from 'react';

import { Box, Typography, Tooltip, CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { DateTime } from 'luxon';

import useExpenseClaimsData from '../../../hooks/useExpenseClaimsData';
import useTranslation from '../../../hooks/useTranslation';

interface ExpenseClaimHistoryDesktopProps {
	id?: string;
}

const useStyles = makeStyles( ( theme ) => ( {
	historyContainer: {
		padding: theme.spacing( 2, 0 ),
		display: 'flex',
		flexDirection: 'column',
	},
	progressItem: {
		height: theme.spacing( 7.5 ),
	},
	statusStepContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '20%',
		justifyContent: 'center',
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		textAlign: 'center',
	},
	accountItem: {
		justifySelf: 'center',
		alignItems: 'flex-start',
		display: 'flex',
		flexDirection: "column",
		margin: theme.spacing( 0, 'auto' ),
		gap: theme.spacing( 1 )
	},
	number: {
		width: '15px',
		height: '15px',
		borderRadius: '50%',
		overflow: 'hidden',
		display: 'flex',
		flexShrink: 0,
		justifyContent: 'center',
		alignItems: 'center',
		margin: '0 auto',
		backgroundColor: theme.palette.custom.gray,
		color: theme.palette.primary.contrastText,
	},
	status: {
		width: '50%',
		lineHeight: theme.spacing( 0.175 ),
		whiteSpace: 'nowrap',
	},
	step: {
		width: theme.spacing( 0.5 ),
		backgroundColor: theme.palette.custom.gray,
		height: theme.spacing( 6.75 ),
		marginTop: theme.spacing( -2 ),
		opacity: 0.8,
	},
	date: {
		textAlign: 'left',
		fontSize: theme.spacing( 1.55 ),
		lineHeight: theme.spacing( 0.175 ),
	},
} ) );

const ExpenseClaimHistoryDesktop: React.FC<ExpenseClaimHistoryDesktopProps> = ( {
	id,
} ) => {
	const classes = useStyles();
	const { approvalProgress, isLoadingProgress } = useExpenseClaimsData( id );
	const t = useTranslation();

	return (
		<Box className={ classes.historyContainer }>
			{ !isLoadingProgress ? (
				approvalProgress.map( ( element, index, array ) => (
					<Box key={ index } className={ classes.progressItem }>
						<Box className={ classes.container }>
							<Box className={ classes.statusStepContainer }>
								<Typography className={ classes.number } />
								{ element.status?.desc.length > 16 ? (
									<Tooltip title={ element.status?.desc } placement="top">
										<Typography className={ classes.status }>
											{ element.status?.desc.substring( 0, 12 ) + '...' }
										</Typography>
									</Tooltip>
								) : (
									<Typography className={ classes.status }>
										{ element.status?.desc }
									</Typography>
								) }
							</Box>
							<Box className={ classes.accountItem }>
								<Typography>{ element.nameOfUser }</Typography>
								<Typography className={ classes.date }>
									{ `${ DateTime.fromJSDate(
										new Date( element.date )
									).toISODate() } - ${ element.time }` }
								</Typography>
							</Box>
						</Box>
						{ index < array.length - 1 && (
							<Box className={ classes.container }>
								<Box className={ classes.statusStepContainer }>
									<Box className={ classes.step } />
									<Box className={ classes.status } />
								</Box>
								<Box />
							</Box>
						) }
					</Box>
				) )
			) : (
				<Box alignSelf="center" display="flex" alignItems="center">
					<Typography>{ t( 'Loading progress' ) }</Typography>
					<Box
						width={ 25 }
						height={ 25 }
						display="flex"
						justifyContent="center"
						alignItems="center"
						paddingLeft={ 1 }
					>
						<CircularProgress size={ 20 } />
					</Box>
				</Box>
			) }
		</Box>
	);
};

export default ExpenseClaimHistoryDesktop;
