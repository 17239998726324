import { Action, ActionType } from "../types/actions";
import { AccountRequests } from "../types/api/leaveRequests";
import { Activity } from "../types/api/timesheets";

export interface LeaverRequestState {
  accountRequests: AccountRequests | null;
  isLoading: boolean;
  isLoadingActivities: boolean;
  activities: Array<Activity>;
}

export const leaveRequestReducer = (
  state: LeaverRequestState = {
    accountRequests: null,
    isLoading: false,
    isLoadingActivities: true,
    activities: [],
  },
  action: Action
): LeaverRequestState => {
  switch (action.type) {
    case ActionType.GET_LEAVE_REQUESTS_STARTED: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ActionType.GET_LEAVE_REQUEST_ACTIVITIES_STARTED: {
      return {
        ...state,
        isLoadingActivities: true,
      };
    }
    case ActionType.GET_LEAVE_REQUESTS_SUCCEEDED: {
      const { accountRequests } = action;
      return {
        ...state,
        accountRequests,
        isLoading: false,
      };
    }
    case ActionType.GET_LEAVE_REQUEST_ACTIVITIES_SUCCEEDED: {
      const { activities } = action;
      return {
        ...state,
        activities,
        isLoadingActivities: false,
      };
    }
    case ActionType.GET_LEAVE_REQUEST_ACTIVITIES_FAILED: {
      return {
        ...state,
        isLoadingActivities: false,
      };
    }
    case ActionType.GET_LEAVE_REQUESTS_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
