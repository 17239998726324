import React from 'react';

import { IconButton, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';

interface DesktopPopUpHeaderProps {
	onClosePopUp: () => void;
}

const useStyles = makeStyles( ( theme ) => ( {
	mediaHeader: {
		height: '5rem',
	},

} ) );

const DesktopPopUpHeader: React.FC<DesktopPopUpHeaderProps> = ( {
	onClosePopUp,
} ) => {
	const classes = useStyles();

	return (
        <header>
			<Box className={ classes.mediaHeader } p={ 1 }>
				<Box display="flex" justifyContent="flex-end">
					<IconButton onClick={ onClosePopUp } size="large">
						<Close />
					</IconButton>
				</Box>
			</Box>
		</header>
    );
};

export default DesktopPopUpHeader;
