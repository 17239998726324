import React from 'react';
import { Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Save } from '@mui/icons-material';
import useTranslation from '../../../hooks/useTranslation';
import { CSSProperties } from '@mui/styles';

const useStyles: any = makeStyles( ( theme ) => {
	const buttonProps: CSSProperties = {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		borderTopWidth: theme.spacing( 0.25 ),
		borderTopColor: theme.palette.custom.lightGray,
		borderRadius: 0,
		borderTopStyle: 'solid',
		boxSizing: 'border-box',
		padding: theme.spacing( 1.5 ),
		fontWeight: 900,
	};

	return {
		button: {
			...buttonProps,
			'&:hover': {
				backgroundColor: theme.palette.background.paper,
			},
		},
		editButton: {
			...buttonProps,
			width: '50%',
			'&:hover': {
				backgroundColor: theme.palette.background.paper,
			},
		}
	};
} );

interface ManageTimesheetButtonProps {
	title: string;
	isEditButton?: boolean;
}

const ManageTimesheetButton: React.FC<ManageTimesheetButtonProps> = ( {
	title,
	isEditButton = false,
} ) => {
	const classes = useStyles();
	const t = useTranslation();

	return (
		<Button
			form="manage-timesheet-form"
			className={ isEditButton ? classes.editButton : classes.button }
			type="submit"
		>
			<Save />
			{ t( title ) }
		</Button>
	);
};

export default ManageTimesheetButton;
