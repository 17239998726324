import { UserInfoAccount } from '../types/api/user';
import { AppState } from '../reducers';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { WeekRangeData, setWeekRangeData } from '../actions/time-actions';
import { DateTime } from 'luxon';
import { getSmallestTimeFromISOString } from '../utils/date';

const useWeekRange = () => {
	const dispatch = useDispatch();

	const selectedWeekRange = useSelector(
		( state: AppState ) => state.timeState.weekRange
	);

	const userAccounts = useSelector<
		AppState,
		Array<UserInfoAccount>
	>( state => state.userState.accounts );

	const setSelectedWeekRange = useCallback(
		( newWeekRange: WeekRangeData ) => {
			const processedWeekRange = {
				from: DateTime.fromJSDate( newWeekRange.from ).startOf( 'week' ).toJSDate(),
				to: DateTime.fromJSDate( newWeekRange.to ).endOf( 'week' ).toJSDate()
			};

			const startOfEmploymentPerAccount = userAccounts.map( userAccount => userAccount.employeeDetail.startOfEmployment );
			const smallestStartOfEmployment = getSmallestTimeFromISOString( startOfEmploymentPerAccount );

			if (
				smallestStartOfEmployment !== Infinity &&
				smallestStartOfEmployment > processedWeekRange.from.getTime()
			) {
				const smallestStartOfEmploymentDateTime = DateTime.fromMillis( smallestStartOfEmployment );

				dispatch(
					setWeekRangeData(
						{ from: smallestStartOfEmploymentDateTime.toJSDate(), to: smallestStartOfEmploymentDateTime.endOf( 'week' ).toJSDate() },
						// { from: smallestStartOfEmploymentDateTime.startOf( 'week' ).toJSDate(), to: smallestStartOfEmploymentDateTime.endOf( 'week' ).toJSDate() },
					)
				);
			} else {
				dispatch( setWeekRangeData( processedWeekRange ) );
			}
		},
		[ dispatch, userAccounts ]
	);

	return { setSelectedWeekRange, selectedWeekRange };
};

export default useWeekRange;
