import api from "../../middleware/api";
import { ExpenseClaimsSourceData } from "../../types/api/expenseClaims";

// EXPENSE_CLAIMS_SOURCES
export const GET_EXPENSE_CLAIMS_SOURCES_DATA_STARTED =
	'GET_EXPENSE_CLAIMS_SOURCES_DATA_STARTED';
export function getExpenseClaimsSourcesDataStarted () {
	return {
		type: GET_EXPENSE_CLAIMS_SOURCES_DATA_STARTED,
	};
}

export const GET_EXPENSE_CLAIMS_SOURCES_DATA_SUCCEEDED =
	'GET_EXPENSE_CLAIMS_SOURCES_DATA_SUCCEEDED';
export function getExpenseClaimsSourcesDataSucceeded (
	expenseClaimsSourceData: ExpenseClaimsSourceData
) {
	return {
		type: GET_EXPENSE_CLAIMS_SOURCES_DATA_SUCCEEDED,
		expenseClaimsSourceData,
	};
}

export const GET_EXPENSE_CLAIMS_SOURCES_DATA_FAILED =
	'GET_EXPENSE_CLAIMS_SOURCES_DATA_FAILED';
export function getExpenseClaimsSourcesDataFailed () {
	return {
		type: GET_EXPENSE_CLAIMS_SOURCES_DATA_FAILED,
	};
}

export function getExpenseClaimsSourceData () {
	return async ( dispatch: Function ) => {
		dispatch( getExpenseClaimsSourcesDataStarted() );
		api
			.getExpenseClaimsSources()
			.then( ( data: any ) => {
				dispatch( getExpenseClaimsSourcesDataSucceeded( data ) );
			} )
			.catch( ( e ) => {
				dispatch( getExpenseClaimsSourcesDataFailed() );
			} );
	};
}

