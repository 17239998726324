import { useSelector } from 'react-redux';
import { AppState } from '../reducers';
import { Destinations } from '../types/api/settings';

export interface DestinationNavProps {
	title: string;
	path: string;
	ariaLabel: string;
}

const defaultNavSettings = {
	title: 'Add hours',
	ariaLabel: 'add-hours',
	path: '/select-destination',
};

const useDestinationNavSettings = () => {
	const destinations =
		useSelector<AppState, Destinations>(
			( state ) => state.settingsState.settings.destinations
		) || undefined;

	const destinationNavProps: DestinationNavProps =
		destinations && Object.entries( destinations ).length > 0
			? defaultNavSettings
			: {
				...defaultNavSettings,
				path: '/create-destination',
			};
	return destinationNavProps;
};

export default useDestinationNavSettings;
