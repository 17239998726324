import { useState, useCallback, useRef, useEffect } from 'react';

const tabKeyCode = 9;
const upKeyCode = 38;
const downKeyCode = 40;

const minSelectionRangeValue = 0;
const maxSelectionRangeValue = 2;

const useTimeField = () => {
	const [ fieldMinutesTime, setFieldMinutesTime ] = useState<string>( '' );
	const [ fieldHoursTime, setFieldHoursTime ] = useState<string>( '' );

	const inputForMinutesRef: any = useRef( null )
	const inputForHoursRef: any = useRef( null )

	const onFocusField = useCallback(
		( e: any ) => {
			e.preventDefault();
			e.target.setSelectionRange(
				minSelectionRangeValue,
				maxSelectionRangeValue
			);
			if ( e.target.name === 'minutes' ) {
				setFieldMinutesTime( e.target.value.split( '_' ).join( '' ) );
			} else {
				setFieldHoursTime( e.target.value.split( '_' ).join( '' ) );
			}
		},
		[ setFieldMinutesTime, setFieldHoursTime ]
	);

	useEffect( () => {
		inputForHoursRef.current.inputElement.focus()
	}, [ inputForHoursRef ] )

	const onKeyBoardEventHandler = useCallback(
		( e ) => {
			const maxValue = e.target.name === 'hours' ? 16 : 59;

			if ( e.keyCode === upKeyCode ) {
				const time =
					Number( e.target.value ) >= maxValue ? 0 : Number( e.target.value ) + 1;
				const result = time < 10 ? `0${ time }` : `${ time }`
				maxValue === 59 ? setFieldMinutesTime( result ) : setFieldHoursTime( result );
			}

			if ( e.keyCode === downKeyCode ) {
				const time =
					Number( e.target.value ) <= 0 ? maxValue : Number( e.target.value ) - 1;
				const result = time < 10 ? `0${ time }` : `${ time }`
				maxValue === 59 ? setFieldMinutesTime( result ) : setFieldHoursTime( result );
			}
		},
		[ setFieldMinutesTime, setFieldHoursTime ]
	);

	const onSwitchHoursMinutesByKey = useCallback(
		( e: any ) => {
			const { keyCode, key } = e;
			if ( key === 'Unidentified' ) {
				alert( 'Please enter a number' )
			}
			const start = e.target.selectionStart;
			const end = e.target.selectionEnd;

			if ( keyCode !== tabKeyCode ) {
				e.preventDefault();
			}

			if ( !isNaN( key ) && key !== ' ' ) {
				const time = e.target.value.split( '' );
				const maxValue = e.target.name === 'hours' ? 16 : 59;

				time[ 0 ] = time[ 1 ];
				time[ 1 ] = key;

				if ( start === 2 ) {
					inputForMinutesRef.current.inputElement.focus()
				}


				if ( start === 0 && end === 2 ) {
					time[ 0 ] = 0;
					time[ 1 ] = key;
				}

				const result = time.join( '' );
				if ( maxValue === 59 ) {
					setFieldMinutesTime( Number( result ) > maxValue ? maxValue : result );
				} else {
					setFieldHoursTime( Number( result ) > maxValue ? maxValue : result );
				}

				e.target.setSelectionRange( start + 1, end );
			}
		},
		[ setFieldMinutesTime ]
	);

	return {
		onKeyBoardEventHandler,
		onFocusField,
		onSwitchHoursMinutesByKey,
		fieldMinutesTime,
		fieldHoursTime,
		setFieldHoursTime,
		setFieldMinutesTime,
		inputForMinutesRef,
		inputForHoursRef,
	};
};

export default useTimeField;
