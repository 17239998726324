import { AppState } from '../reducers';
import { useSelector } from 'react-redux';
import { TimesheetDetails } from '../types/api/timesheets';
import { TotalHoursGroup } from '../components/Desktop/DesktopLayout';
import { defaultTimesheetHours } from '../config/constants';

const useTimeRequestHoursData = (
	currentTimesheet?: TimesheetDetails
): {
	timesheetHours: TotalHoursGroup;
	currentTimesheetHours?: TotalHoursGroup;
} => {
	const currentTimesheetHours = currentTimesheet
		? {
			hoursMonday: currentTimesheet?.monday || 0,
			hoursTuesday: currentTimesheet?.tuesday || 0,
			hoursWednesday: currentTimesheet?.wednesday || 0,
			hoursThursday: currentTimesheet?.thursday || 0,
			hoursFriday: currentTimesheet?.friday || 0,
			hoursSaturday: currentTimesheet?.saturday || 0,
			hoursSunday: currentTimesheet?.sunday || 0,
		}
		: undefined;
	const timesheetHours = useSelector<AppState, TotalHoursGroup>( ( state ) => {
		const requestHours = state.timeState.timesheetHours;
		if ( !requestHours ) {
			return defaultTimesheetHours;
		} else return requestHours;
	} );

	return { timesheetHours, currentTimesheetHours };
};

export default useTimeRequestHoursData;
