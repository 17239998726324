import api from "../../middleware/api";

// CLEAR_CACHE
export const CLEAR_CACHE_STARTED = 'CLEAR_CACHE_STARTED';
export function clearCacheStarted () {
	return {
		type: CLEAR_CACHE_STARTED,
	};
}

export const CLEAR_CACHE_SUCCEEDED = 'CLEAR_CACHE_SUCCEEDED';
export function clearCacheSucceeded () {
	return {
		type: CLEAR_CACHE_SUCCEEDED,
	};
}

export const CLEAR_CACHE_FAILED = 'CLEAR_CACHE_FAILED';
export function clearCacheFailed ( error: any ) {
	return {
		type: CLEAR_CACHE_FAILED,
		error,
	};
}

export function clearCache () {
	return function ( dispatch: Function ) {
		dispatch( clearCacheStarted() );
		api
			.clearCache()
			.then( () => {
				return dispatch( clearCacheSucceeded() );
			} )
			.catch( ( err: any ) => dispatch( clearCacheFailed( err ) ) );
	};
}
