import React from 'react';
import { Box, Divider } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { ExpandMore, ExpandLess, } from '@mui/icons-material';

export interface DividerToggleProps {
	onToggle: () => void;
	expandMore: boolean;
}

const useStyles = makeStyles( ( theme ) => ( {
	expand: {
		width: '20px',
		height: '20px',
		maxWidth: '5vh',
		maxHeight: '5vh',
		borderRadius: '50%',
		backgroundColor: theme.palette.custom.paper,
		color: theme.palette.text.primary,
		position: 'absolute',
		left: '0',
		right: '0',
		marginLeft: 'auto',
		marginRight: 'auto',
		top: '20%',
		cursor: 'pointer',
		fontWeight: 900,
		opacity: '0.9',
		fontSize: theme.spacing( 1.8 ),
	},
} ) );

const DividerToggle: React.FC<DividerToggleProps> = ( { onToggle, expandMore } ) => {

	const classes = useStyles();

	return (

		<Box position="relative" p={ 2 }>
			{ expandMore ? (
				<ExpandMore className={ classes.expand } onClick={ onToggle } />
			) : (
					<ExpandLess className={ classes.expand } onClick={ onToggle } />
				) }
			<Divider />
		</Box>
	);
};

export default DividerToggle;
