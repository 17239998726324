import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { ReactNode, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAccountTimesheetManagement from '../../hooks/useAccountTimesheetManagement';
import useTranslation from '../../hooks/useTranslation';
import ConfirmBox from '../controls/ConfirmBox';

interface GuardedFooterNavProps {
	className?:
	| string
	| ( ( props: { isActive: boolean } ) => string | undefined )
	| undefined;
	to: string;
	children: ReactNode;
}

const useStyles = makeStyles( ( theme ) => ( {
	root: {
		textDecoration: 'none',
		textTransform: 'none',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyItems: 'center',
		padding: 0,
		margin: 0,
		width: '100%',
		cursor: 'pointer',
	},
} ) );

const GuardedFooterNav: React.FC<GuardedFooterNavProps> = ( {
	className,
	to,
	children,
} ) => {
	const classes = useStyles();
	const t = useTranslation();

	const {
		hasUnreleasedTimesheetData,
	} = useAccountTimesheetManagement();

	const location = useLocation();
	const navigate = useNavigate();
	const [ modalVisible, setModalVisible ] = useState( false );
	const { pathname } = location;

	const onNavigateHandler = useCallback( () => {
		if ( to !== pathname && ( !hasUnreleasedTimesheetData || pathname !== '/' ) ) {
			navigate( to );
		} else if ( to !== pathname ) {
			setModalVisible( true );
		}
	}, [ navigate, pathname, to, hasUnreleasedTimesheetData ] );

	const onCloseModal = useCallback( () => {
		setModalVisible( false );
	}, [] )

	const handleConfirmNavigationClick = useCallback( () => {
		navigate( to );
	}, [ navigate, to ] )

	const customClass =
		typeof className === 'string'
			? className
			: className && className( { isActive: pathname === to } );
	const buttonClassName = customClass
		? clsx( customClass, classes.root )
		: undefined;

	return (
		<>
			<div onClick={ onNavigateHandler } className={ buttonClassName }>
				{ children }
			</div>
			<ConfirmBox
				visible={ modalVisible }
				onCancel={ onCloseModal }
				onConfirm={ handleConfirmNavigationClick }
				title={ t( "Are you sure?" ) }
				yes={ t( "Leave page" ) }
				no={ t( "Stay" ) }
				contentMessage={ t( "You have unreleased timesheet items, are you sure you want to leave the page?" ) }
			/>
		</>
	);
};

export default GuardedFooterNav;
