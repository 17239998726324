import React, { useState, useCallback } from 'react';
import { DateTime } from 'luxon';

import { Box, IconButton, Slide } from '@mui/material';
import CloseSettings from '@mui/icons-material/ArrowForward';

import { useStyles } from './desktop-styles';

import { getDayTotalNumberOfHours } from './desktop-utils';

import HomeDesktop from '../screens/Home/HomeDesktop';

import useAccountTimesheetManagement from '../../hooks/useAccountTimesheetManagement';
import Settings from '../screens/Settings/Settings';
import DesktopHeader from '../Headers/DesktopHeader';
import { HomeMenuActions } from '../../hooks/useDesktopHomeMenuActions';
import { useDispatch } from 'react-redux';
import { setTempDestination } from '../../actions/ui-actions';

export type TotalHoursProps =
	'hoursMonday' |
	'hoursTuesday' |
	'hoursWednesday' |
	'hoursThursday' |
	'hoursFriday' |
	'hoursSaturday' |
	'hoursSunday'

export interface TotalHoursGroup {
	hoursMonday: number;
	hoursTuesday: number;
	hoursWednesday: number;
	hoursThursday: number;
	hoursFriday: number;
	hoursSaturday: number;
	hoursSunday: number;
}

export interface DesktopLayoutProps {
	onLogOutClick: () => void;
	homeMenuActions?: HomeMenuActions;
	children?: React.ReactNode;
}

const DesktopLayout: React.FC<DesktopLayoutProps> = ( {
	homeMenuActions,
	onLogOutClick,
	children,
} ) => {
	const [ isSettingsVisible, setIsSettingsVisible ] = useState<boolean>( false );
	const classes = useStyles();

	const dispatch = useDispatch();

	const showSettings = useCallback( () => {
		setIsSettingsVisible( true );
	}, [ setIsSettingsVisible ] );

	const hideSettings = useCallback( () => {
		setIsSettingsVisible( false );
	}, [ setIsSettingsVisible ] );

	const {
		isLoading,
		overtimeOptions,
		hasEnoughHours,
		onRefreshAccountTimesheetData,
		disableUnRelease,
		selectedWeekRange,
		currentAccountData,
	} = useAccountTimesheetManagement();

	const totalHoursByDay = currentAccountData
		? getDayTotalNumberOfHours( currentAccountData.timesheets )
		: undefined;

	const releaseTimesheetUIProps =
		selectedWeekRange && overtimeOptions.employeeCode !== '0'
			? {
				overtimeOptions: [ overtimeOptions ],
				hasEnoughHours,
				fromDateTime: DateTime.fromJSDate( selectedWeekRange.from ),
				onRefreshTimesheetData: onRefreshAccountTimesheetData,
				disableUnRelease,
				isDesktop: true,
			}
			: undefined;

	const onAddTimeFromHomeMenuHandler = useCallback( () => {
		homeMenuActions && homeMenuActions.setIsAddingHours( true );
		dispatch( setTempDestination( null ) );
	}, [ homeMenuActions, dispatch ] );

	return (
        <Box className={ classes.layoutContainer }>
			<Box className={ classes.homeContainer }>
				<HomeDesktop
					releaseTimesheetUIProps={ releaseTimesheetUIProps }
					isLoadingTimesheets={
						isLoading || !selectedWeekRange || !totalHoursByDay
					}
					onAddTime={ onAddTimeFromHomeMenuHandler }
					isInactiveAccount={ !!currentAccountData?.isInactiveForSelectedWeek }
				/>
			</Box>
			<Box className={ classes.dataContainer }>
				<DesktopHeader
					onLogOutClick={ onLogOutClick }
					showSettings={ showSettings }
				/>
				<Box
					display="flex"
					flexDirection="column"
					height="calc( 100vh - 10rem )"
					flex="1"
					overflow="hidden"
					position="relative"
				>
					{ children }
				</Box>
			</Box>
			{ isSettingsVisible && (
				<Box className={ classes.settingsOverLay } onClick={ hideSettings } />
			) }
			<Slide direction="left" in={ isSettingsVisible }>
				<Box className={ classes.settings }>
					<Box className={ classes.closeSettings }>
						<IconButton onClick={ hideSettings } size="large">
							<CloseSettings />
						</IconButton>
					</Box>
					<Settings isDesktopActive />
				</Box>
			</Slide>
		</Box>
    );
};

export default DesktopLayout;
