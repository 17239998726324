import { Action, ActionType } from "../types/actions";
import { TimesheetStatus, Timesheet } from "../types/api/timesheets";

export interface TimesheetInfo {
	id: string;
	year: number;
	week: number;
	startDateOfWeek: string;
	endDateOfWeek: string;
	contractHours: number;
	timeSpent: number;
	timeSpentDirect: number;
	timeSpentIndirect: number;
	timeSpentAbsence: number;
	percentageDirect: number;
	employeeSearchKey: string;
	homeDepartment: string;
	percentageDirectRelativeTo40Hrs: number;
	lowestStatusTimesheet: TimesheetStatus;
	highestStatusTimesheet: TimesheetStatus;
}

export interface TimesheetState {
	timesheets: Array<Timesheet>;
	isLoading: boolean;
	startedAt: number;
	error: string;
}

export const timesheetsReducer = (
	state: TimesheetState = {
		timesheets: [],
		isLoading: false,
		startedAt: 0,
		error: "",
	},
	action: Action
): TimesheetState => {
	switch ( action.type ) {
		case ActionType.GET_TIME_SHEET_STARTED:
		case ActionType.DELETE_TIME_SHEET_LINE_ITEM_STARTED:
		case ActionType.ADD_TIMESHEET_STARTED:
		case ActionType.COPY_TIMESHEET_STARTED:
		case ActionType.GET_CURRENT_TIME_SHEET_STARTED: {
			if ( action.startedAt >= state.startedAt ) {
				return {
					...state,
					isLoading: true,
					startedAt: action.startedAt,
					error: "",
				};
			}
			return {
				...state,
				isLoading: true,
				startedAt: action.startedAt,
			};
		}
		case ActionType.GET_TIME_SHEET_SUCCEEDED:
		case ActionType.ADD_TIMESHEET_SUCCEEDED: {
			const timesheets = action.timesheets as Array<Timesheet>;
			if ( action.startedAt && action.startedAt >= state.startedAt ) {
				return {
					...state,
					timesheets,
					isLoading: false,
					error: "",
				};
			}
			return state;
		}
		case ActionType.GET_CURRENT_TIME_SHEET_SUCCEEDED:
			{
				if ( action.startedAt && action.startedAt >= state.startedAt ) {
					return {
						...state,
						isLoading: false,
						error: "",
					};
				}
				return state;
			}
		case ActionType.GET_TIME_SHEET_FAILED:
		case ActionType.DELETE_TIME_SHEET_LINE_ITEM_FAILED:
		case ActionType.ADD_TIMESHEET_FAILED:
		case ActionType.COPY_TIMESHEET_FAILED:
		case ActionType.GET_CURRENT_TIME_SHEET_FAILED: {
			if ( action.startedAt >= state.startedAt ) {
				return {
					...state,
					isLoading: false,
					error: action.error,
					timesheets: [],
				};
			}
			return state;
		}


		default:
			return state;
	}
};
