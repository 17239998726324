import { useCallback } from 'react';
import {
	deleteExpenseClaim,
	releaseExpenseClaim,
} from '../actions/api-actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../reducers';
import useExpenseClaimsData from './useExpenseClaimsData';

const useExpenseClaimActions = () => {
	const dispatch = useDispatch();

	const employeeCode = useSelector(
		( state: AppState ) => state.userState.selectedUser?.employeeCode
	);

	const { expenseClaimsYear } = useSelector(
		( state: AppState ) => state.timeState
	);

	const { expenseClaimsDetails } = useExpenseClaimsData();

	const onDeleteExpenseClaim = useCallback(
		( expenseClaimId: string ) => {
			if ( expenseClaimsYear && employeeCode ) {
				dispatch(
					deleteExpenseClaim( { employeeCode, expenseClaimId } )
				);
			}
		},
		[ employeeCode, dispatch, expenseClaimsYear ]
	);

	const onReleaseExpenseClaim = useCallback(
		( expenseClaimId: string ) => {
			if ( expenseClaimsYear && employeeCode ) {
				const expenseClaimDetailsIndex = expenseClaimsDetails.findIndex(
					( element ) => element.id === expenseClaimId
				);

				const details = expenseClaimsDetails;

				details[ expenseClaimDetailsIndex ] = {
					...details[ expenseClaimDetailsIndex ],
				};

				dispatch( releaseExpenseClaim( { employeeCode, expenseClaimId } ) );
			}
		},
		[ employeeCode, dispatch, expenseClaimsYear, expenseClaimsDetails ]
	);

	return {
		onReleaseExpenseClaim,
		onDeleteExpenseClaim,
	};
};

export default useExpenseClaimActions;
