import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../reducers';
import {
	getUserInfo,
	getSettings,
	getCurrentTimesheet,
	getPublicHolidays,
	setSelectedUser,
} from '../actions/api-actions';
import { getSmallestTimeFromISOString } from '../utils/date';
import { useEffect } from 'react';
import { setWeekRangeData } from '../actions/time-actions';
import { DateTime } from 'luxon';
import useApiAuth from './useApiAuth';

const useAppStateController = () => {
	const { isLoading, error, rawToken } = useApiAuth();

	const weekRange = useSelector( ( state: AppState ) => state.timeState.weekRange );
	const {
		currentTimesheets,
		// isLoadingCurrentTimesheets,
		accounts: userInfoAccounts,
	} = useSelector( ( state: AppState ) => state.userState );

	const dispatch = useDispatch();

	useEffect( () => {
		if ( !isLoading && !error && rawToken ) {
			dispatch( getUserInfo() );
			dispatch( getSettings() );
		}
	}, [ dispatch, rawToken, isLoading, error ] );

	useEffect( () => {
		if (
			userInfoAccounts.length &&
			// !isLoadingCurrentTimesheets &&
			currentTimesheets.length === 0
		) {
			dispatch( getCurrentTimesheet() );
			const initialAccount =
				userInfoAccounts.filter(
					( userAccount ) => !userAccount.employeeDetail.internalHired
				)[ 0 ] || userInfoAccounts[ 0 ];
			dispatch( setSelectedUser( initialAccount ) );
		}
	}, [
		dispatch,
		userInfoAccounts,
		// isLoadingCurrentTimesheets,
		currentTimesheets.length,
	] );

	useEffect( () => {
		if ( weekRange && userInfoAccounts.length ) {
			dispatch( getPublicHolidays( weekRange ) );
		}
	}, [ dispatch, weekRange, userInfoAccounts.length ] );

	useEffect( () => {
		if ( currentTimesheets && currentTimesheets.length && !weekRange ) {
			const employmentStartDates = userInfoAccounts.map(
				( userAccount ) => userAccount.employeeDetail.startOfEmployment
			);
			const smallestEmploymentStart = getSmallestTimeFromISOString(
				employmentStartDates
			);
			const smallestEmploymentStartDateTime = DateTime.fromMillis(
				smallestEmploymentStart
			);

			// Find the smallest week start that needs input.

			const { employeeCode } = userInfoAccounts.filter(
				( userAccount ) => !userAccount.employeeDetail.internalHired
			)[ 0 ];

			const currentTimesheetWeekStart = Date.parse(
				currentTimesheets.filter(
					( pendingTimesheet ) => pendingTimesheet.employeeCode === employeeCode
				)[ 0 ].timeSheetInfos[ 0 ].startDateOfWeek
			);

			const smallestWeekStartDateTime = DateTime.fromMillis(
				currentTimesheetWeekStart
			);

			// The from date is the smaller of the two.
			const from =
				smallestEmploymentStart < currentTimesheetWeekStart
					? smallestWeekStartDateTime
					: smallestEmploymentStartDateTime;

			dispatch(
				setWeekRangeData( {
					from: from.toJSDate(),
					to: smallestWeekStartDateTime.endOf( 'week' ).toJSDate(),
				} )
			);
		}
		// Select the week
	}, [ dispatch, currentTimesheets, weekRange, userInfoAccounts ] );

	return null;
};

export default useAppStateController;
