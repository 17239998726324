import React, { useState, useCallback } from 'react';
import 'react-day-picker/lib/style.css';
import DayPicker, {
	WeekdayElementProps,
	Modifiers,
	NavbarElementProps,
} from 'react-day-picker';
import { DateTime } from 'luxon';
import { SwipeableHandlers } from 'react-swipeable';

import { Typography } from '@mui/material';
import { useStyles, CalendarClasses } from '././calendar-desktop-styles';

import { CalendarToolbarCustomProps } from '../../screens/Calendar/CalendarToolbar';

import {
	getWeekDays,
	getWeekRange,
	compareDatesAsISODates,
} from '../../../utils/date';

import { PublicHoliday } from '../../../types/api/timesheets';
import { onSetCalendarMonthData, WeekRangeData } from '../../../actions/time-actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers';

const getWeekClassName = (
	selectedWeek: number | null,
	weekNumber: number,
	classes: CalendarClasses
) => ( selectedWeek === weekNumber ? classes.selectedWeek : classes.week );

const getSelectedDaysFromRange = ( selectedWeekRange: WeekRangeData ) => {
	if ( selectedWeekRange ) {
		return getWeekDays( selectedWeekRange.from );
	}
	return [];
};

const CalendarControlDesktop = ( {
	Toolbar,
	WeekDayElement,
	onWeekRangeSelected,
	selectedWeekRange,
	publicHolidays,
}: {
	Toolbar: React.FC<Partial<NavbarElementProps> & CalendarToolbarCustomProps>;
	WeekDayElement: React.FC<WeekdayElementProps>;
	onWeekRangeSelected: ( weekRange: WeekRangeData ) => void;
	selectedWeekRange: WeekRangeData;
	publicHolidays: PublicHoliday[];
} ) => {
	const classes = useStyles();

	const dispatch = useDispatch();

	let selectedDays: Array<Date> = selectedWeekRange
		? getSelectedDaysFromRange( selectedWeekRange )
		: [];

	const [ hoverRange, setHoverRange ] = useState<WeekRangeData>();
	const [ swipeHandlers, setSwipeHandlers ] = useState<SwipeableHandlers>();

	const monthData = useSelector(
		( state: AppState ) => state.timeState.calendarMonthData
	);

	const handleDayEnter = useCallback(
		( date: Date ) => {
			setHoverRange( getWeekRange( date ) );
		},
		[ setHoverRange ]
	);

	const handleDayLeave = useCallback( () => {
		setHoverRange( undefined );
	}, [ setHoverRange ] );

	const modifiers: Partial<Modifiers> | undefined =
		selectedDays.length > 0
			? {
				hoverRange: hoverRange,
				selectedRange: {
					from: selectedDays[ 0 ],
					to: selectedDays[ 6 ],
				},
				hoverRangeStart: hoverRange && hoverRange.from,
				hoverRangeEnd: hoverRange && hoverRange.to,
				selectedRangeStart: selectedDays[ 0 ],
				selectedRangeEnd: selectedDays[ 6 ],
			}
			: undefined;

	const onSetWeekRange = useCallback(
		( days: Date[] ) => {
			onWeekRangeSelected( { from: days[ 0 ], to: days[ days.length - 1 ] } );
		},
		[ onWeekRangeSelected ]
	);

	const onWeekChange = useCallback(
		( _, days ) => {
			dispatch( onSetCalendarMonthData() );
			onSetWeekRange( days );
		},
		[ onSetWeekRange, dispatch ]
	);

	const onDayChange = useCallback(
		( date ) => {
			dispatch( onSetCalendarMonthData() );
			onWeekRangeSelected( getWeekRange( date ) );
		},
		[ onWeekRangeSelected, dispatch ]
	);

	const [ monthClass, setMonthClass ] = useState<string>( classes.root );

	const selectedWeekNumber = selectedWeekRange
		? DateTime.fromJSDate( selectedWeekRange.from ).get( 'weekNumber' )
		: null;

	const navElementCustomProps: CalendarToolbarCustomProps = {
		setHandlers: setSwipeHandlers,
		setMonthClass: setMonthClass,
		monthClasses: classes,
		onChange: onDayChange,
	};

	const onRenderWeek = useCallback(
		( weekNumber ) => (
			<Typography
				className={ getWeekClassName( selectedWeekNumber, weekNumber, classes ) }
			>
				{weekNumber }
			</Typography>
		),
		[ classes, selectedWeekNumber ]
	);

	const onRenderDay = useCallback(
		( day: Date ) => (
			<Typography
				className={
					!!publicHolidays.find( ( holiday ) =>
						compareDatesAsISODates( new Date( holiday.date ), day )
					)
						? classes.holiday
						: classes.day
				}
			>
				{day.getDate() }
			</Typography>
		),
		[ classes, publicHolidays ]
	);

	return (
		<div className={ monthClass } { ...swipeHandlers }>
			<DayPicker
				selectedDays={ selectedDays }
				showWeekNumbers
				showOutsideDays
				initialMonth={ selectedWeekRange.to }
				modifiers={ modifiers }
				onDayClick={ onDayChange }
				onDayMouseEnter={ handleDayEnter }
				onDayMouseLeave={ handleDayLeave }
				onWeekClick={ onWeekChange }
				firstDayOfWeek={ 1 }
				navbarElement={ <Toolbar { ...navElementCustomProps } /> }
				renderWeek={ onRenderWeek }
				renderDay={ onRenderDay }
				weekdayElement={ WeekDayElement }
				month={ monthData }
			/>
		</div>
	);
};

export default CalendarControlDesktop;
