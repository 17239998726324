import React from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { convertMinutesToTimeString } from '../../../utils/date';
import usePublicHolidays from '../../../hooks/usePublicHolidays';
import { DateTime } from 'luxon';
import useTranslation from '../../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	weekDayContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing( 1, 2 ),
		opacity: '0.8',
	},
	weekDay: {
		fontWeight: 900,
		fontSize: theme.spacing( 1.8 ),
		textTransform: 'capitalize',
		[ theme.breakpoints.down( 'sm' ) ]: {
			fontSize: theme.spacing( 1.6 ),
		},
	},
	totalHours: {
		fontWeight: 900,
		fontSize: theme.spacing( 1.8 ),
		color: theme.palette.text.primary,
		[ theme.breakpoints.down( 'sm' ) ]: {
			fontSize: theme.spacing( 1.6 ),
		},
	},
	missingHours: {
		fontWeight: 900,
		fontSize: theme.spacing( 1.8 ),
		color: theme.palette.secondary.main,
		[ theme.breakpoints.down( 'sm' ) ]: {
			fontSize: theme.spacing( 1.6 ),
		},
	},
	holiday: {
		fontWeight: 900,
		fontSize: theme.spacing( 1.8 ),
		alignSelf: 'center',
		color: theme.palette.secondary.main,
		[ theme.breakpoints.down( 'md' ) ]: {
			fontSize: theme.spacing( 1.6 ),
		},
	},
	hide: {
		display: 'none',
	},
} ) );

interface MinimalWeekDaysProps {
	isHidden: boolean;
	totalHours: number;
	holiday: string;
	weekDay: string;
}

const MinimalWeekDays: React.FC<MinimalWeekDaysProps> = ( {
	isHidden,
	weekDay,
	totalHours,
	holiday,
} ) => {
	const { currentWeekHolidays } = usePublicHolidays();
	const weekHolidays: any = {};
	currentWeekHolidays.forEach( ( weekHoliday ) => {
		const day = DateTime.fromJSDate( new Date( weekHoliday.date ) )
			.toFormat( 'EEEE' )
			.toLowerCase();
		weekHolidays[ day ] = weekHoliday.description;
	} );

	const classes = useStyles();

	const t = useTranslation()

	return (
		<Box className={ isHidden ? classes.hide : classes.weekDayContainer }>
			<Typography className={ classes.weekDay }>{ t( weekDay ) }</Typography>
			<Typography
				className={ weekHolidays[ weekDay ] ? classes.holiday : classes.hide }
			>
				{ weekHolidays[ weekDay ] }
			</Typography>
			<Typography
				className={
					totalHours > 0
						? classes.totalHours
						: weekHolidays[ weekDay ]
							? classes.hide
							: classes.missingHours
				}
			>
				{ totalHours > 0
					? `${ t( 'Total hours' ) }: ${ convertMinutesToTimeString( totalHours ) }`
					: t( 'missing' ) }
			</Typography>
		</Box>
	);
};

export default MinimalWeekDays;
