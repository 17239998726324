import React, { useCallback } from 'react';
import { AppState } from '../../reducers';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import { WeekRangeData } from '../../actions/time-actions';
import { Destination } from '../../types/api/settings';
import SelectDestinationTemplateCard from '../SelectDestinationTemplateCard';
import { saveSettings } from '../../actions/api-actions';

type TemplateChipColor = 'primary' | 'secondary';

const MostRecentDestinations: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const settingsState = useSelector( ( state: AppState ) => state.settingsState );

	const {
		isLoading: isLoadingSettings,
		settings,
		error: settingError,
	} = settingsState;

	const { destinations: timesheetDestinationTemplates } = settings;

	// TO DO CHECK
	const cardClickHandler = useCallback(
		( title: string ) => {
			navigate( `/create-timesheet/${ title }`, { state: { previous: location.pathname } } );
		},
		[ navigate, location.pathname ]
	);

	const editClickHandler = useCallback(
		( title: string ) => {
			navigate( `/edit-destination/${ title }`, { state: { previous: location.pathname } } );
		},
		[ navigate, location.pathname ]
	);

	const deleteClickHandler = useCallback(
		( title: string ) => {
			const {
				[ title ]: removedDestinationTemplate,
				...otherDestinationTemplates
			} = timesheetDestinationTemplates;

			dispatch(
				saveSettings( {
					...settings,
					destinations: otherDestinationTemplates,
				} )
			);
		},
		[ dispatch, settings, timesheetDestinationTemplates ]
	);

	const weekRange: WeekRangeData | undefined = useSelector<
		AppState,
		WeekRangeData | undefined
	>( ( state ) => state.timeState.weekRange );

	if ( isLoadingSettings ) {
		return (
			<Box p={ 2 }>
				<Typography>Loading most recent destinations...</Typography>
			</Box>
		);
	}

	if ( settingError ) {
		return (
			<Box>
				<Typography>
					{ 'An error occurred while trying to load the destinations:' }
				</Typography>
				<Typography>{ JSON.stringify( settingError ) }</Typography>
			</Box>
		);
	}

	if (
		timesheetDestinationTemplates &&
		Object.keys( timesheetDestinationTemplates ).length
	) {
		return (
			<>
				{ Object.values( timesheetDestinationTemplates )
					.sort( ( a: any, b: any ) => {
						const aDate = new Date( a.updatedAt ).getTime();
						const bDate = new Date( b.updatedAt ).getTime();
						return bDate - aDate;
					} )
					.map( ( timesheetDestinationTemplate: Destination, index: number ) => {
						const chipColor: TemplateChipColor =
							timesheetDestinationTemplate.type === 'Project'
								? 'primary'
								: 'secondary';

						const destinationTemplateName =
							timesheetDestinationTemplate.details.Name;

						const projectActivity = timesheetDestinationTemplate.activity;
						const isInactive =
							weekRange &&
							( Date.parse( projectActivity.startDateProjectTeam ) >
								weekRange.to.getTime() ||
								Date.parse( projectActivity.endDateProjectTeam ) <
								weekRange.from.getTime() );

						return (
							<SelectDestinationTemplateCard
								key={ index }
								isActive={ !isInactive }
								title={ destinationTemplateName }
								color={ chipColor }
								onCardClick={ cardClickHandler }
								onEditClick={ editClickHandler }
								onDeleteClick={ deleteClickHandler }
								customChipColor={ timesheetDestinationTemplate.templateColor }
							/>
						);
					} ) }
			</>
		);
	}

	return (
		<Box style={ { paddingLeft: '1em', paddingRight: '1em' } }>
			<Typography style={ { marginBottom: '1em' } }>
				Seems like you don't have any quick actions.
			</Typography>
			<Typography>
				{ `Would you like to ` }
				<NavLink to="/create-destination">add a new one?</NavLink>
			</Typography>
		</Box>
	);
};

export default MostRecentDestinations;
