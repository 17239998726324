import { Action, ActionType } from "../types/actions";
import { WeekRangeData } from "../actions/time-actions";
import { TotalHoursGroup } from "../components/Desktop/DesktopLayout";

export interface TimeState {
  weekRange?: WeekRangeData;
  expenseClaimsYear: number | null;
  leaveRequestsYear: number | null;
  calendarMonthData?: Date;
  timesheetHours: TotalHoursGroup | null;
}

export const timeReducer = (
  state: TimeState = {
    weekRange: undefined,
    expenseClaimsYear: null,
    leaveRequestsYear: null,
    timesheetHours: null,
  },
  action: Action
): TimeState => {
  switch (action.type) {
    case ActionType.SET_WEEK_RANGE_DATA: {
      const { weekRange } = action;

      return {
        ...state,
        weekRange,
      };
    }
    case ActionType.SET_SELECTED_EXPENSE_CLAIMS_YEAR: {
      const { expenseClaimsYear } = action;

      return {
        ...state,
        expenseClaimsYear,
      };
    }
    case ActionType.SET_SELECTED_LEAVE_REQUESTS_YEAR: {
      const { leaveRequestsYear } = action;

      return {
        ...state,
        leaveRequestsYear,
      };
    }
    case ActionType.SET_CALENDAR_MONTH_DATA: {
      const { calendarMonthData } = action;

      return {
        ...state,
        calendarMonthData,
      };
    }
    case ActionType.SET_TIMESHEET_REQUEST_HOURS: {
      return { ...state, timesheetHours: action.timesheetHours };
    }
    case ActionType.RESET_TIMESHEET_REQUEST_HOURS: {
      return { ...state, timesheetHours: null };
    }
    default:
      return state;
  }
};
