import api from '../../middleware/api';
import { GetSettingsResponseData } from '../../types/api/settings';

/*
 * GET_SETTINGS
 */

export const GET_SETTINGS_STARTED = 'GET_SETTINGS_STARTED';
export function getSettingsStarted () {
	return {
		type: GET_SETTINGS_STARTED,
	};
}

export const GET_SETTINGS_SUCCEEDED = 'GET_SETTINGS_SUCCEEDED';
export function getSettingsSucceeded ( settings: GetSettingsResponseData ) {
	return {
		type: GET_SETTINGS_SUCCEEDED,
		settings,
	};
}

export const GET_SETTINGS_FAILED = 'GET_SETTINGS_FAILED';
export function getSettingsFailed ( error: any ) {
	return {
		type: GET_SETTINGS_FAILED,
		error: 'Failed to load settings',
	};
}

export function getSettings () {
	return function ( dispatch: Function ) {
		dispatch( getSettingsStarted() );

		api
			.getSettings()
			.then( ( settings: any ) => dispatch( getSettingsSucceeded( settings ) ) )
			.catch( ( err: any ) => dispatch( getSettingsFailed( err ) ) );
	};
}

/*
 * SAVE_SETTINGS
 */

export const SAVE_SETTINGS_STARTED = 'SAVE_SETTINGS_STARTED';
export function saveSettingsStarted () {
	return {
		type: SAVE_SETTINGS_STARTED,
	};
}

export const SAVE_SETTINGS_SUCCEEDED = 'SAVE_SETTINGS_SUCCEEDED';
export function saveSettingsSucceeded ( settings: any ) {
	return {
		type: SAVE_SETTINGS_SUCCEEDED,
		settings,
	};
}

export const SAVE_SETTINGS_FAILED = 'SAVE_SETTINGS_FAILED';
export function saveSettingsFailed ( error: any ) {
	return {
		type: SAVE_SETTINGS_FAILED,
		error: "Failed to save settings",
	};
}

export function saveSettings ( settings: any ) {
	return function ( dispatch: Function ) {
		dispatch( saveSettingsStarted() );

		api
			.saveSettings( settings )
			.then( () => {
				// dispatch( getCurrentTimesheet() );
				dispatch( saveSettingsSucceeded( settings ) );
			} )
			.catch( ( err: any ) => {
				dispatch( saveSettingsFailed( err ) );
			} );
	};
}

/*
 * CLEAR_SETTINGS
 */

export const CLEAR_SETTINGS_STARTED = 'CLEAR_SETTINGS_STARTED';
export function clearSettingsStarted () {
	return {
		type: CLEAR_SETTINGS_SUCCEEDED,
	};
}

export const CLEAR_SETTINGS_SUCCEEDED = 'CLEAR_SETTINGS_SUCCEEDED';
export function clearSettingsSucceeded () {
	return {
		type: CLEAR_SETTINGS_SUCCEEDED,
	};
}

export const CLEAR_SETTINGS_FAILED = 'CLEAR_SETTINGS_FAILED';
export function clearSettingsFailed ( error: any ) {
	return {
		type: CLEAR_SETTINGS_FAILED,
		error,
	};
}

export function clearSettings () {
	return function ( dispatch: Function ) {
		dispatch( saveSettingsStarted() );

		api
			.clearSettings()
			.then( () => {
				dispatch( clearSettingsSucceeded() );
			} )
			.catch( ( err: any ) => {
				dispatch( clearSettingsFailed( err ) );
			} );
	};
}


