import { Action, ActionType } from "../types/actions";

export interface AuthorizationState {
  rawToken: string;
  expiresOn?: number;
}

export const authorizationReducer = (
  state: AuthorizationState = {
    rawToken: "",
    expiresOn: undefined,
  },
  action: Action
) => {
  switch (action.type) {
    case ActionType.SET_AUTHORIZATION_DATA: {
      const { rawToken, expiresOn } = action.authorizationData;
      return {
        ...state,
        rawToken,
        expiresOn,
      };
    }
    default:
      return state;
  }
};
