import { Action, ActionType } from "../types/actions";

export interface TempDestinationState {
  destination: any;
}

export const tempDestinationReducer = (
  state: TempDestinationState = {
    destination: null,
  },
  action: Action
): TempDestinationState => {
  switch (action.type) {
    case ActionType.SET_TEMP_DESTINATION: {
      return {
        ...state,
        destination: action.destination,
      };
    }
    default:
      return state;
  }
};
