import { useSelector } from 'react-redux';
import { AppState } from '../reducers';
import { currentYear } from '../components/controls/Minimal/helpers';

const useExpenseClaimsData = ( id?: string ) => {
	const expenseClaimsData = useSelector( ( state: AppState ) => {
		// Expense claims details
		const currentExpenseClaimDetails =
			state.expenseClaimDetailsState.details.find(
				( element ) => id && element.id === id
			);

		const expenseClaimsDetails = state.expenseClaimDetailsState.details;

		const approvalRoute = currentExpenseClaimDetails?.approvalRoute || null;
		const approvalProgress = currentExpenseClaimDetails?.approvalProgress || [];

		const currentAttachments =
			state.expenseClaimAttachmentsState.attachments.find(
				( element ) => element.id === id
			);
		const documents = currentAttachments?.documents || [];

		// Overview data
		const expenseClaims =
			state.expenseClaimsState.expenseClaimsData?.expenseClaims;
		const { isLoading: isLoadingExpenseClaims } = state.expenseClaimsState;

		//Expense Claims source data
		const expenseClaimsCodes =
			state.expenseClaimsSourcesState.expenseClaimsSourceData
				?.expenseClaimsCodes;
		const projectActivities =
			state.expenseClaimsSourcesState.expenseClaimsSourceData
				?.projectActivities;
		const isLoadingSources = state.expenseClaimsSourcesState.isLoading;

		// Current expense claim
		const selectedExpenseClaim = expenseClaims?.find(
			( expenseClaim ) => expenseClaim.id === id
		);

		// Loading data
		const isLoadingExpenseClaim = !!expenseClaims?.find(
			( expenseClaim ) => expenseClaim.id === id && expenseClaim.isLoading
		);
		const isLoadingDocuments = !!currentAttachments?.isLoading;
		const isLoadingApprovalRoute =
			!!currentExpenseClaimDetails?.isLoadingApprovalRoute;
		const isLoadingProgress = !!currentExpenseClaimDetails?.isLoadingProgress;
		const { isLoading: isLoadingDetails } = state.expenseClaimDetailsState;


		//Range
		const expenseClaimsYear = state.timeState.expenseClaimsYear || currentYear;

		return {
			approvalProgress,
			approvalRoute,
			documents,
			expenseClaims,
			selectedExpenseClaim,
			expenseClaimsCodes,
			projectActivities,
			isLoadingExpenseClaims,
			isLoadingDetails,
			isLoadingSources,
			expenseClaimsYear,
			currentExpenseClaimDetails,
			expenseClaimsDetails,
			isLoadingDocuments,
			isLoadingApprovalRoute,
			isLoadingProgress,
			isLoadingExpenseClaim,
		};
	} );

	return expenseClaimsData;
};

export default useExpenseClaimsData;
