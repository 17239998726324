import React from 'react'
import { Provider } from 'react-redux';

import configureStore from './../store/configureStore';

import ThemedApp from './App/ThemedApp';

let store = configureStore();

const Root: React.FC = ( props ) => {
	return (
		<Provider store={ store }>
			<ThemedApp { ...props } />
		</Provider>
	)
};

export default Root
