import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
	Dialog,
	Slide,
	TextField,
	DialogContent,
	Box,
	Typography,
	DialogTitle,
	Button,
	Checkbox,
	SlideProps,
} from '@mui/material';

import { AppState } from '../../../reducers';
import { copyTimesheet } from '../../../actions/api-actions';
import useTranslation from '../../../hooks/useTranslation';
import { DateTime } from 'luxon';
import { yupResolver } from '@hookform/resolvers/yup';

export interface CopyTimesheetProps {
	isOpen: boolean;
	onClose: () => void;
}

const Transition = React.forwardRef<unknown, SlideProps>(
	function Transition ( props, ref ) {
		return <Slide direction="up" ref={ ref } { ...props } />;
	}
);

const useStyles = makeStyles( ( theme: Theme ) =>
	createStyles( {
		menuItem: {
			[ theme.breakpoints.down( 'sm' ) ]: {
				fontSize: theme.spacing( 1.4 ),
				fontWeight: 900,
			},
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.primary.main,
			textTransform: 'none',
			borderRadius: theme.spacing( 0 ),
			'&:hover': {
				backgroundColor: theme.palette.background.paper,
			},
		},
		icon: {
			[ theme.breakpoints.down( 'sm' ) ]: {
				fontSize: theme.spacing( 1.8 ),
			},
			margin: theme.spacing( 0, 1 ),
		},
		mediaHeader: {
			display: 'flex',
			position: 'relative',
			marginBottom: theme.spacing( 1 ),
			padding: theme.spacing( 0.5 ),
		},
		iconButton: {
			marginLeft: theme.spacing( 1 ),
			color: theme.palette.custom.white,
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',
		},
		withHoursContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			margin: theme.spacing( 1, 0 ),
		},
		textField: {
			margin: theme.spacing( 1, 0 ),
			'& .MuiOutlinedInput-input': {
				padding: theme.spacing( 1.5, 1.5 ),
				fontSize: theme.spacing( 1.75 ),
			},
		},
		submitButton: {
			textTransform: 'none',
			fontWeight: 900,
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText,
			'&:hover': {
				backgroundColor: theme.palette.primary.main,
			},
			margin: theme.spacing( 1, 0 )
		},
		dialogPaper: {
			backgroundImage: 'none',
		}
	} )
);

const actualYear = DateTime.fromJSDate( new Date() ).year;

const FormSchema: yup.AnyObjectSchema = yup.object().shape( {
	fromyear: yup
		.number()
		.required( 'empty year' )
		.moreThan(
			actualYear - 2,
			`year should be greater`
		)
		.lessThan(
			actualYear + 2,
			`year should be less`
		),
	fromweek: yup
		.number()
		.moreThan( 0, 'week should be greater' )
		.lessThan( 53, 'week should be less' )
		.required( 'empty week' ),
} );

const CopyTimesheetForm: React.FC<CopyTimesheetProps> = ( {
	isOpen,
	onClose,
} ) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const { register, handleSubmit, errors } = useForm( {
		resolver: yupResolver( FormSchema ),
	} );

	const employeeCode = useSelector(
		( state: AppState ) => state.userState.selectedUser?.employeeCode
	);

	const { toyear, toweek, weekRange } = useSelector( ( state: AppState ) => {
		const timeSheetInfos = state.timesheetsState.timesheets.filter(
			( timesheet ) => timesheet.employeeCode === employeeCode
		)[ 0 ].timeSheetInfos[ 0 ];

		const weekRange = state.timeState.weekRange;
		if ( timeSheetInfos && weekRange ) {
			const toyear = timeSheetInfos.year;
			const toweek = timeSheetInfos.week;

			return { toyear, toweek, weekRange };
		}

		return {};
	} );

	const yearFieldError =
		errors.fromyear && !( errors.fromyear as any ).message.includes( 'NaN' )
			? ( errors.fromyear as any ).message
			: 'empty year';

	const weekFieldError =
		errors.fromweek && !( errors.fromweek as any ).message.includes( 'NaN' )
			? ( errors.fromweek as any ).message
			: 'empty week';

	const onSubmitForm = useCallback(
		( data ) => {
			const requestData = {
				...data,
				employeeCode,
				toyear,
				toweek,
			};
			if ( weekRange ) {
				dispatch( copyTimesheet( requestData, weekRange ) );
			}

			onClose();
		},
		[ employeeCode, toyear, toweek, dispatch, weekRange, onClose ]
	);

	const t = useTranslation()

	return (
		<Dialog open={ isOpen } onClose={ onClose } TransitionComponent={ Transition } classes={ { paper: classes.dialogPaper } }>
			<DialogTitle>{ t( 'Copy a week timesheet' ) }</DialogTitle>
			<DialogContent>
				<form onSubmit={ handleSubmit( onSubmitForm ) }>
					<Box className={ classes.contentContainer }>
						<TextField
							defaultValue={ !!toweek && toweek - 1 > 0 ? toweek - 1 : 52 }
							className={ classes.textField }
							label={ t( 'From Week' ) }
							variant="outlined"
							name="fromweek"
							error={ !!errors.fromweek }
							inputRef={ register }
							helperText={ !!errors.fromweek && t( weekFieldError ) }
							type="number"
						/>
						<TextField
							defaultValue={
								!!toweek && toweek - 1 > 0 ? toyear : Number( toyear ) - 1
							}
							className={ classes.textField }
							label={ t( 'From Year' ) }
							variant="outlined"
							inputRef={ register }
							error={ !!errors.fromyear }
							name="fromyear"
							helperText={ !!errors.fromyear && t( yearFieldError, {
								firstYear: yearFieldError === "year should be greater" ? actualYear - 2 : undefined,
								lastYear: yearFieldError === "year should be less" ? actualYear + 2 : undefined,
							} ) }
							type="number"
						/>
						<Box className={ classes.withHoursContainer }>
							<Typography>{ t( 'Copy with logged hours' ) }</Typography>
							<Checkbox
								color="primary"
								inputRef={ register }
								name="includehours"
								inputProps={ { 'aria-label': 'Include hours' } }
							/>
						</Box>
					</Box>
					<Button className={ classes.submitButton } type="submit" fullWidth>
						{ t( 'Copy' ) }
					</Button>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default CopyTimesheetForm;
