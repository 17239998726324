import makeStyles from '@mui/styles/makeStyles';
import { CSSProperties } from '@mui/styles';

export type CalendarClasses = Record<
	| 'day'
	| 'holiday'
	| 'week'
	| 'selectedWeek'
	| 'root'
	| 'slideMonthLeft'
	| 'moveMonthLeft'
	| 'slideMonth'
	| 'slideMonthRight'
	| 'moveMonthRight',
	string
>;

export const useStyles = makeStyles( ( theme ) => {
	const selectedWeek: CSSProperties = {
		backgroundColor: theme.palette.custom.darkBlueGray,
		color: theme.palette.primary.contrastText,
	};

	const circleItem: CSSProperties = {
		width: '30px',
		height: '30px',
		borderRadius: '50%',
		overflow: 'hidden',
		display: 'flex',
		flexShrink: 0,
		justifyContent: 'center',
		alignItems: 'center',
		margin: '0 auto',
		marginRight: theme.spacing( 0.5 )
	}

	const week: CSSProperties = {
		color: theme.palette.custom.lightBlueGrayContrastText,
		backgroundColor: 'inherit',
		...circleItem,
	};

	const selectedRange: CSSProperties = {
		backgroundColor: theme.palette.background.default,
		color: theme.palette.primary.light,
		borderBottomColor: theme.palette.primary.main,
		borderBottomStyle: 'solid',
		borderRadius: 0,
	};

	const selectedOutside: CSSProperties = {
		borderBottomColor: theme.palette.secondary.main,
		color: theme.palette.secondary.light,
	};

	const hoveredRange: CSSProperties = {
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.text.secondary,
		borderRadius: 0,
	};

	const hoverSelected: CSSProperties = {
		color: theme.palette.primary.dark,
		borderBottomColor: theme.palette.primary.dark,
	};

	const rootStyles: any = {
		'& .DayPicker': {
			width: '100%',
			overflow: 'hidden',
		},
		'& .DayPicker-wrapper': {
			outline: 'none',
		},
		'& .DayPicker-Caption': {
			display: 'none',
		},
		'& .DayPicker-Month': {
			borderCollapse: 'separate',
			// width: '80%',
			padding: theme.spacing( 0 ),
			margin: theme.spacing( 1, 0 ),
		},
		'& .DayPicker-WeekNumber': {
			outline: 'none',
			width: theme.spacing( 4 ),
			padding: theme.spacing( 0 ),
		},
		'& .DayPicker-Day': {
			outline: 'none',
			borderRadius: 0,
			padding: 0,
			'&:hover': {
				backgroundColor: 'black',
			},
		},
		'& .DayPicker-Weekday': {
			padding: 0,
			fontSize: theme.spacing( 2 ),
		},
		'& .DayPicker-Week': {
			'&:hover': {
				'& .DayPicker-WeekNumber': {
					...selectedWeek,
					backgroundColor: 'inherit',
					'& *': {
						...selectedWeek,
					},
				},
				'& .DayPicker-Day': {
					...hoveredRange,
					'& .DayPicker-Day:not(.DayPicker-Day--outside).DayPicker-Day--selected': {
						...hoverSelected,
					},
				},
				'& .DayPicker-Day--selected.DayPicker-Day--outside': {
					...selectedRange,
					...selectedOutside,
				},
			},
		},
		'& .DayPicker-Day--hoverRange': {
			...hoveredRange,
			'&.DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected': {
				...hoverSelected,
			},
		},
		'& .DayPicker-Day--selectedRange': {
			'&.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
				...selectedRange,
			},
			'&.DayPicker-Day--selectedRange.DayPicker-Day--selectedRangeStart.DayPicker-Day--selected': {
				...selectedRange,
			},
			'&.DayPicker-Day--selectedRange.DayPicker-Day--selectedRangeEnd.DayPicker-Day--selected': {
				...selectedRange,
			},
			'&.DayPicker-Day--selectedRange.DayPicker-Day--selectedRangeEnd.DayPicker-Day--selected.DayPicker-Day--outside': {
				...selectedRange,
				...selectedOutside,
			},
			'&.DayPicker-Day--selectedRange.DayPicker-Day--selectedRangeStart.DayPicker-Day--selected.DayPicker-Day--outside': {
				...selectedRange,
				...selectedOutside,
			},
		},
		'& .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover': {
			backgroundColor: theme.palette.background.paper,
		},

		'& .DayPicker-Day--selected.DayPicker-Day--outside': {
			...selectedRange,
			...selectedOutside,
		},
	};

	return {
		day: {
			padding: theme.spacing( 1 ),
			outline: 'none',
			fontFamily: 'Roboto',
			'&:hover': {
				backgroundColor: theme.palette.background.paper,
			},
		},
		holiday: {
			...circleItem,
			outline: 'none',
			fontFamily: 'Roboto',
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.primary.contrastText,
			opacity: 0.8,
			'&:hover': {
				backgroundColor: theme.palette.secondary.main,
				color: theme.palette.primary.contrastText,
				opacity: 0.9,
			},
		},
		week: {
			...week,
			'&:hover': { ...selectedWeek },
		},
		selectedWeek: {
			...week,
			...selectedWeek,
		},
		root: { ...rootStyles },
		slideMonthLeft: {
			...rootStyles,
			'& .DayPicker-Months': {
				transform: 'translateX(-90%)',
				transitionTimingFunction: 'ease-out',
				transition: '0.3s',
			},
		},
		moveMonthLeft: {
			...rootStyles,
			'& .DayPicker-Months': {
				transform: 'translateX(-90%)',
				transitionTimingFunction: 'ease-out',
				transition: '0s',
			},
		},
		slideMonth: {
			...rootStyles,
			'& .DayPicker-Months': {
				transition: '0.3s',
				transitionTimingFunction: 'ease-in',
				transform: 'translateX(0)',
			},
		},
		slideMonthRight: {
			...rootStyles,
			'& .DayPicker-Months': {
				transform: 'translateX(90%)',
				transitionTimingFunction: 'ease-out',
				transition: ' 0.3s',
			},
		},
		moveMonthRight: {
			...rootStyles,
			'& .DayPicker-Months': {
				transform: 'translateX(90%)',
				transitionTimingFunction: 'ease-out',
				transition: '0s',
			},
		},
	};
} );
