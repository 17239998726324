import React from 'react';

import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import AddToHomeScreenPrompt from '../../controls/AddToHomeScreenPrompt';
import CalendarScreenDesktop from '../Calendar/CalendarDesktop';
import HomeLogoHeader from '../../Headers/HomeLogoHeader';
import ManageDraftsMenuDesktop from '../DesktopTimesheet/ManageDraftsMenuDesktop';
import { ReleaseTimesheetUIProps } from '../ReleaseTimesheet/ReleaseTimesheetUI';
import OverlayLoader from '../../OverlayLoader';
import LeavesMenuDesktop from '../Leave/LeavesMenuDesktop';
import ExpenseClaimsDesktop from '../ExpenseClaims/ManageExpenseClaimsDesktop';
import FooterMenuDesktop from '../../FooterMenu/FooterMenuDesktop';
import { useLocation } from 'react-router-dom';
import ReloadPage from '../../ReloadPage';
import useErrorData from '../../../hooks/useErrorData';

interface HomeDesktopProps {
	onLogOutClick?: () => void;
	releaseTimesheetUIProps?: ReleaseTimesheetUIProps;
	isLoadingTimesheets: boolean;
	isInactiveAccount: boolean;
	onAddTime: () => void;
}

const useStyles = makeStyles( ( theme ) => ( {
	mainContainer: {
		flex: '1',
		display: 'flex',
		flexDirection: 'column',
		height: 'calc( 100vh )',
		overflow: 'hidden',
		borderRightStyle: 'solid',
		borderColor: theme.palette.custom.paper,
	},
} ) );

const HomeDesktop: React.FC<HomeDesktopProps> = ( {
	releaseTimesheetUIProps,
	isLoadingTimesheets,
	isInactiveAccount,
	onAddTime,
} ) => {
	const location = useLocation();
	const historyPath = location.pathname;
	const classes = useStyles();

	const error = useErrorData();
	return (
		<>
			<Box className={ classes.mainContainer }>
				<HomeLogoHeader />
				<FooterMenuDesktop />
				{ error?.type !== 'USER' && error?.type !== 'SETTINGS' ? (
					<Box
						display="flex"
						flexDirection="column"
						height="calc( 100vh - 18rem )"
						flex="1"
						overflow="auto"
					>
						<Box
							position="relative"
							display="flex"
							flexDirection="column"
							height="100%"
						>
							<Box position="relative" marginBottom={ 3 } flex={ 1 }>
								<OverlayLoader
									message=""
									loadingCondition={ isLoadingTimesheets }
								/>
								{ releaseTimesheetUIProps &&
									!historyPath.includes( 'expense' ) &&
									!historyPath.includes( 'leave' ) && (
										<ManageDraftsMenuDesktop
											onAddTime={ onAddTime }
											releaseTimesheetUIProps={ releaseTimesheetUIProps }
											isInactiveWeek={ isInactiveAccount }
										/>
									) }
								{ historyPath.includes( 'leave' ) && <LeavesMenuDesktop /> }
								{ historyPath.includes( 'expense' ) && <ExpenseClaimsDesktop /> }
							</Box>
							{ !historyPath.includes( 'expense' ) &&
								!historyPath.includes( 'leave' ) && <CalendarScreenDesktop /> }
						</Box>
					</Box>
				) : (
					<ReloadPage error={ error } />
				) }
			</Box>
			<AddToHomeScreenPrompt />
		</>
	);
};

export default HomeDesktop;
