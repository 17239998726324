import React from 'react';

import { MdInsertInvitation } from 'react-icons/md'

interface IconProps {
	color?: string,
	width: number,
	height: number
}
const CalendarIcon = ( { color, width, height }: IconProps ) => (
	<MdInsertInvitation style={ { width, height, color } } />
);

export default CalendarIcon