import React from 'react'

import { Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import monthImage from './../../images/june.png'
import overlay from './../../images/overlay.png'

import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'
import { AppState } from '../../reducers'


interface LoadingHeaderProps {
	onLogOutClick?: () => void,
}

const useStyles = makeStyles( ( theme ) => ( {
	mediaHeader: {
		height: '17.5rem',
		backgroundImage: `linear-gradient(${ theme.palette.custom.picture },
                       ${ theme.palette.custom.picture }), url( ${ overlay } ), url( ${ monthImage } )`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',

	},
	headerTitle: {
		color: theme.palette.custom.white,
		textShadow: '0px 3px 6px #000000CC, 0px 3px 6px #000000CC',
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	backButton: {
		color: theme.palette.background.default,
	}
} ) )

const LoadingHeader: React.FC<LoadingHeaderProps> = () => {
	const weekRange = useSelector( ( state: AppState ) => state.timeState.weekRange )
	const classes = useStyles()

	const fromDateTime = weekRange?.from ? DateTime.fromJSDate( weekRange.from ) : null;
	const toDateTime = weekRange?.to ? DateTime.fromJSDate( weekRange.to ) : null;

	return (
		<header>
			<Box className={ classes.mediaHeader } p={ 2 }>
				<Box className={ classes.headerTitle } textAlign="center">
					<Box>
						<Typography variant="h3" >ICT OnTime</Typography>
					</Box>
					<Box m={ 3 }>
						{
							( fromDateTime && toDateTime ) ? (
								<>
									<Typography>{ fromDateTime.toFormat( "yyyy '- Week' W" ) }</Typography>
									<Typography >{ fromDateTime.toFormat( 'LLL d' ) } - { fromDateTime.get( 'month' ) !== toDateTime.get( 'month' ) ? toDateTime.toFormat( 'LLL d' ) : toDateTime.toFormat( 'd' ) }</Typography>
								</>
							) : null
						}

					</Box>
				</Box>
			</Box>
		</header>
	)
};


export default LoadingHeader;