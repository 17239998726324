import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TemplateCard from '../../TemplateCard';
import { WeekActivity } from '../../../types/api/timesheets';
import { useLocation, useNavigate } from 'react-router-dom';

interface WeekTimesheetsProps {
	action?: () => void;
	weekActivities: WeekActivity[];
}

const useStyles = makeStyles( ( theme ) => ( {
	timesheetCard: {
		'&:last-child': {
			marginBottom: theme.spacing( 10 ),
		},
	},
} ) );

const WeekTimesheets: React.FC<WeekTimesheetsProps> = ( {
	action,
	weekActivities,
} ) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<Box>
			{ weekActivities.map( ( weekActivity, index ) => (
				<TemplateCard
					className={ classes.timesheetCard }
					disabled={ !( weekActivity.details.status.desc === 'Draft' ) }
					key={ index }
					color={
						weekActivity.destination === 'Project' ? 'primary' : 'secondary'
					}
					title={ weekActivity.name }
					chipLabel={ `${ weekActivity.hours } Hours` }
					customChipColor={ weekActivity.templateColor }
					action={ () => {
						navigate(
							weekActivity.isTemplate
								? `/edit-timesheet/${ weekActivity.name }/${ weekActivity.details.id }`
								: `/edit-timesheet/${ weekActivity.details.id }`, { state: { previous: location.pathname } }
						);
					} }
				/>
			) ) }
		</Box>
	);
};

export default WeekTimesheets;
