import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box } from '@mui/material';

const LoginCallback: React.FC = () => {
	const navigate = useNavigate();

	useEffect( () => {
		navigate( "/", { replace: true } );
	} )

	return (
		<Box padding={ 2 }>
			<Typography>We're processing your login information...</Typography>
		</Box>
	);
};

export default LoginCallback;