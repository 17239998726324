import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../reducers';
import { AccountTimesheetHoursData } from '../components/screens/Minimal/MinimalTimesheets';

const useSelectedAccount = () => {
	const [ accountHoursData, setAccountHoursData ] = useState<
		AccountTimesheetHoursData
	>();
	const [ companyName, setCompanyName ] = useState<string>();

	const userAccounts = useSelector(
		( state: AppState ) => state.userState.accounts
	);

	const initialAccount = useSelector(
		( state: AppState ) => state.userState.selectedUser
	);

	const accountIndex = userAccounts.findIndex(
		( account ) => account.employeeCode === initialAccount?.employeeCode
	);

	return {
		initialAccount,
		setCompanyName,
		accountIndex,
		companyName,
		userAccounts,
		accountHoursData,
		setAccountHoursData,
	};
};

export default useSelectedAccount;
