import {
	convertDecimalToTimeString,
	getDecimalTimeString,
	formatTime,
} from '../../../utils/date';
import { formWeekdaysFields } from '../../../config/constants';
import {
	Timesheet,
	TimesheetLineItem,
} from '../../../types/api/timesheets';
import { Destination, DestinationTimesheet } from '../../../types/api/settings';

export interface GetDestinationDescription {
	chipColor: 'primary' | 'secondary';
	moreInfoLabel: string;
	moreInfoText: string;
}

const decimalMinutesCompareArray = [ 12.5, 37.5, 62.5 ];
const timeFormatMinutesCompareArray = [ 7.5, 22.5, 37.5 ];

export const convertTimeArrayToFieldValue = (
	time: string[],
	splitter: string
) => {
	const hours: string = `${ time[ 0 ] }${ time[ 1 ] }`;
	const minutes: string = `${ time[ 3 ] }${ time[ 4 ] }`;
	const result = [ hours, splitter, minutes ];
	const maxMinutesArray =
		splitter === ':'
			? timeFormatMinutesCompareArray
			: decimalMinutesCompareArray;

	if ( Number( minutes ) > maxMinutesArray[ 0 ] ) {
		result[ 2 ] = splitter === ':' ? '15' : '25';
	}

	if ( Number( minutes ) > maxMinutesArray[ 1 ] ) {
		result[ 2 ] = splitter === ':' ? '30' : '50';
	}

	if ( Number( minutes ) > maxMinutesArray[ 2 ] ) {
		result[ 2 ] = splitter === ':' ? '45' : '75';
	}

	if ( Number( minutes ) < maxMinutesArray[ 0 ] ) {
		result[ 2 ] = '00';
	}

	if ( Number( hours ) + Number( `0.${ minutes }` ) > 16 ) {
		result[ 0 ] = '16';
		result[ 2 ] = '00';
	}

	return result.join( '' );
};

export const getDestinationDescriptionProps = (
	destination: Destination
): GetDestinationDescription => {
	return destination && destination.type === 'Project'
		? {
			chipColor: 'primary',
			moreInfoLabel: 'Project',
			moreInfoText: destination.activity.projectDescription,
		}
		: {
			chipColor: 'secondary',
			moreInfoLabel: 'Activity',
			moreInfoText: destination.activity.activityDescription,
		};
};

export const getTotalTime = ( timesheets: DestinationTimesheet ) => {
	const result = { hours: 0, minutes: 0 };
	Object.values( timesheets ).forEach( ( time ) => {
		const timeString = convertDecimalToTimeString( Number( time ) );
		result.hours += Number( timeString.split( ':' )[ 0 ] );
		result.minutes += Number( timeString.split( ':' )[ 1 ] );
	} );

	if ( result.minutes >= 60 ) {
		const additionalHours = ( result.minutes - ( result.minutes % 60 ) ) / 60;
		result.hours += additionalHours;
		result.minutes = result.minutes % 60;
	}
	return formatTime( result.hours, result.minutes, ':' );
};

export const getRequestHours = (
	data: TimesheetLineItem | any,
	timesheet: Timesheet | any,
	t: ( stringValue: string ) => string,
	selectedItemId?: string
) => {
	const requestData: TimesheetLineItem | any = data;
	const error: any = {};

	Object.keys( data ).forEach( ( key: string ) => {
		if ( formWeekdaysFields.includes( key ) ) {
			const timesheetKey = key.split( 'hours' )[ 1 ].toLowerCase();
			const weekHours = timesheet?.timeSheetInfos[ 0 ].timesheetDetails.reduce(
				( a: any, b: any ) => {
					if ( selectedItemId && selectedItemId === b.id ) {
						a.excludedHours[ key ] = ( a.excludedHours[ key ] || 0 ) + b[ timesheetKey ];
					}
					a.totalHours[ key ] = ( a.totalHours[ key ] || 0 ) + b[ timesheetKey ];
					return { ...a };
				},
				{ totalHours: {}, excludedHours: {} }
			);

			const requestHours = getDecimalTimeString( data[ key ], ':' );
			if ( weekHours.totalHours[ key ] + parseFloat( requestHours ) - ( weekHours.excludedHours[ key ] || 0 ) > 24 ) {
				error.message = `${ t( timesheetKey ) } ${ t( 'too much hours' ) }`;
			}

			requestData[ key ] = parseFloat( requestHours );
		}
	} );
	return { requestData, error };
};
