import { Action, ActionType } from "../types/actions";

export interface ReleasedTimesheetsState {
	isLoading: boolean;
	isLoadingUndo: boolean;
	error: any;
}

export function releasedTimesheetsReducer (
	state: ReleasedTimesheetsState = {
		isLoading: false,
		isLoadingUndo: false,
		error: null,
	},
	action: Action
) {
	switch ( action.type ) {
		case ActionType.RELEASE_TIMESHEET_STARTED: {
			return {
				...state,
				isLoading: true,
				error: null,
			};
		}
		case ActionType.UNDO_RELEASE_TIMESHEET_STARTED: {
			return {
				...state,
				isLoadingUndo: true,
				error: null,
			};
		}
		case ActionType.RELEASE_TIMESHEET_FAILED:
		case ActionType.UNDO_RELEASE_TIMESHEET_FAILED:
			{
				return {
					...state,
					isLoading: false,
					isLoadingUndo: false,
					error: action.error,
				};
			}
		case ActionType.RELEASE_TIMESHEET_SUCCEEDED: {
			return {
				...state,
				isLoading: false,
			};
		}
		case ActionType.UNDO_RELEASE_TIMESHEET_SUCCEEDED: {
			return {
				...state,
				isLoadingUndo: false,
			};
		}
		case ActionType.CLEAR_RELEASE_TIMESHEET_ERROR: {
			return {
				...state,
				error: null,
			};
		}
		default:
			return state;
	}
}
