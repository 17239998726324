import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Fab, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';


export interface NavigationFabProps {
	path: string;
	ariaLabel: string;
	title: string
}

const useStyles = makeStyles( theme => ( {
	fab: {
		position: 'absolute',
		bottom: theme.spacing( 11 ),
		right: '12.5%',
		transform: 'translate( 50%, 0 )',

		'@media (max-height: 420px)': {
			bottom: theme.spacing( 7.5 ),
		}
	},
} ) );

const NavigationFab: React.FC<NavigationFabProps> = ( { path, ariaLabel, title } ) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const onClick = useCallback( () => {
		navigate( path, { state: { previous: location.pathname } } );
	}, [ navigate, path, location.pathname ] );

	return (
		<Box textAlign="right">
			<Tooltip title={ title } placement="top">
				<Fab
					color="primary"
					aria-label={ ariaLabel }
					className={ classes.fab }
					onClick={ onClick }
				>
					<AddIcon />
				</Fab>
			</Tooltip>
		</Box>
	);
};

export default NavigationFab