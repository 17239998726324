import React, { useState, useEffect, useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';

import {
	saveSettings,
	getTimesheetSourceData,
	setSelectedUser,
} from './../../../actions/api-actions';

import { AppState } from '../../../reducers';

import { getWeekRange } from './../../../utils/date';

import AddTimesheetHeader from './../../Headers/AddTimesheetHeader';
import FooterHint from '../../controls/FooterHint';
import { ProjectActivity, Activity } from '../../../types/api/timesheets';
import DestinationConfigurationForm from './DestinationConfigurationForm';
import { setTempDestination } from '../../../actions/ui-actions';
import { WeekRangeData } from '../../../actions/time-actions';
import { AppSettings } from '../../../types/api/settings';
import ColorPicker from './ColorPicker';

const getDestinationActivity = ( selectedActivity: any ) => {
	const projectNumber = selectedActivity.projectNumber || '';
	const SubprojectNumber = selectedActivity.SubprojectNumber || '0';
	const projectPhaseNumber = selectedActivity.projectPhaseNumber || '0';
	const subTaskNumber = selectedActivity.subTaskNumber || '0';
	const activityCode =
		selectedActivity.activityCode || selectedActivity.componentCode;

	return {
		...selectedActivity,
		projectNumber: projectNumber.toString(),
		SubprojectNumber: SubprojectNumber.toString(),
		projectPhaseNumber: projectPhaseNumber.toString(),
		subTaskNumber: subTaskNumber.toString(),
		activityCode,
	};
};

const ManageDestination = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const [ selectedColor, setSelectedColor ] = useState<string>();
	const [ isPickerOpen, setIsPickerOpen ] = useState<boolean>( false );

	const onChangeColor = useCallback(
		( color ) => {
			setSelectedColor( color.hex );
			setIsPickerOpen( false );
		},
		[ setSelectedColor ]
	);

	const onOpenColorPicker = useCallback( () => {
		setIsPickerOpen( true );
	}, [ setIsPickerOpen ] );

	const onCloseColorPicker = useCallback( () => {
		setIsPickerOpen( false );
	}, [ setIsPickerOpen ] );

	const settings = useSelector<AppState, AppSettings>(
		( state ) => state.settingsState.settings
	);

	const userAccounts = useSelector(
		( state: AppState ) => state.userState.accounts
	);

	const isLoading: boolean = useSelector<AppState, boolean>(
		( state ) => state.timesheetSourcesState.isLoading
	);

	const employeeCode = useSelector(
		( state: AppState ) => state.userState.selectedUser?.employeeCode
	);

	useEffect( () => {
		if ( settings.destinations ) {
			dispatch( getTimesheetSourceData() );
		}
	}, [ dispatch, settings.destinations ] );

	const templateName = location.pathname.split( '/' )[ 2 ];

	const [ isEditForm, setIsEditForm ] = useState<boolean>( !!templateName );

	const [ destination, setDestination ] = useState<string>( '' );

	const [ destinationFormValues, setDestinationFormValues ] = useState<any>( {} );

	const now = new Date();
	const [ currentWeekRange ] = useState<WeekRangeData>( getWeekRange( now ) );

	useEffect( () => {
		if ( templateName && settings.destinations ) {
			if ( !settings.destinations[ templateName ] ) {
				navigate( -1 );
				return;
			} else {
				setDestination( settings.destinations[ templateName ].type );
				setDestinationFormValues( settings.destinations[ templateName ].details );
				setSelectedColor( settings.destinations[ templateName ].templateColor );

				const selectedAccount = userAccounts.filter(
					( account ) =>
						account.employeeCode ===
						settings.destinations[ templateName ].employeeCode
				)[ 0 ];

				dispatch( setSelectedUser( selectedAccount ) );
			}
		}
	}, [
		currentWeekRange,
		dispatch,
		settings.destinations,
		templateName,
		navigate,
		employeeCode,
		userAccounts,
	] );

	const onSubmit = useCallback(
		(
			selectedActivity?: ProjectActivity | Activity,
			skipSave: boolean = false
		) => {
			let destinations;
			if ( settings.destinations ) {
				destinations = settings.destinations;
			} else {
				destinations = {};
			}

			const activity = getDestinationActivity( selectedActivity );

			if ( !!templateName ) {
				delete destinations[ templateName ];
			}

			destinations = {
				...destinations,
				[ destinationFormValues[ 'Name' ] ]: {
					type: destination,
					details: destinationFormValues,
					activity,
					updatedAt: new Date(),
					employeeCode,
					templateColor: selectedColor,
				},
			};

			if ( skipSave ) {
				dispatch(
					setTempDestination( {
						type: destination,
						details: destinationFormValues,
						activity,
						updatedAt: new Date(),
						employeeCode,
					} )
				);
				navigate( `/create-timesheet-without-template`, {
					state: { previous: location.pathname },
				} );
			} else {
				dispatch(
					saveSettings( {
						...settings,
						destinations,
					} )
				);
				// TODO: show loading and navigate after the destination is stored
				isEditForm &&
					navigate( `/edit-destination/${ destinationFormValues[ 'Name' ] }`, {
						state: { previous: location.pathname },
						replace: true,
					} );
				navigate( `/create-timesheet/${ destinationFormValues[ 'Name' ] }`, {
					state: { previous: location.pathname },
				} );
			}
		},
		[
			settings,
			destinationFormValues,
			destination,
			dispatch,
			navigate,
			templateName,
			employeeCode,
			isEditForm,
			selectedColor,
			location.pathname,
		]
	);

	return (
		<>
			<AddTimesheetHeader />
			<Box
				marginTop={ 2 }
				display="flex"
				flexDirection="column"
				height="calc( 100vh - 10rem )"
				flex="1"
				overflow="auto"
			>
				<DestinationConfigurationForm
					autoExpand={ !isEditForm }
					destinationType={ destination }
					onDestinationTypeChange={ ( value ) => {
						setDestination( value );
						setDestinationFormValues( {} );
						setIsEditForm( false );
					} }
					formValues={ destinationFormValues }
					onFormValuesChange={ setDestinationFormValues }
					onFormSubmit={ onSubmit }
					isLoading={ isLoading }
					ColorPicker={
						<ColorPicker
							key="colorPicker"
							onChangeColor={ onChangeColor }
							onCloseColorPicker={ onCloseColorPicker }
							onOpenColorPicker={ onOpenColorPicker }
							isPickerOpen={ isPickerOpen }
							selectedColor={ selectedColor }
						/>
					}
				/>
			</Box>
			{ !destination ? <FooterHint hint="Select a destination" /> : null }
		</>
	);
};

export default ManageDestination;
