import React from 'react';

import { IconButton, Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import logo from './../../images/ontime-logo.svg';
import { ReactComponent as LogOutIcon } from './../../images/_ionicons_svg_md-log-out.svg';
import BackButton from '../controls/BackButton';

const useStyles = makeStyles( ( theme ) => ( {
	backButtonContainer: {
		position: 'absolute',
		left: '1em',
	},
	backButton: {
		color: theme.palette.custom.gray,
	},
	logOutContainer: {
		position: 'absolute',
		right: '1em',
	},
	logoContainer: {
		paddingTop: '3vh',
		'@media (max-height: 420px)': {
			'& h1, & img': {
				display: 'inline-block',
			},
			'& img': {
				maxHeight: '2em',
				marginRight: theme.spacing( 1 ),
			},
		},
	},
} ) );

interface HeaderProps {
	showBackButton?: boolean;
	onLogOutClick?: () => void;
}

const LogoHeader: React.FC<HeaderProps> = ( {
	showBackButton,
	onLogOutClick,
} ) => {
	const classes = useStyles();

	return (
        <header className="App-header">
			{showBackButton ? (
				<Box className={ classes.backButtonContainer }>
					<BackButton className={ classes.backButton } />
				</Box>
			) : null }
			<Box
				className={ classes.logOutContainer }
				display="flex"
				flexDirection="row-reverse"
			>
				{ onLogOutClick ? (
					<IconButton aria-label="Log Out" onClick={ onLogOutClick } size="large">
						<LogOutIcon fill={ '#4B547C' } />
					</IconButton>
				) : null }
			</Box>
			<Box textAlign="center" className={ classes.logoContainer }>
				<img src={ logo } alt="OnTime Logo" />
				<Typography variant="h1">onTime</Typography>
			</Box>
		</header>
    );
};

export default LogoHeader;
