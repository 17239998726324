import { PublicHolidaysData } from '../../types/api/timesheets';
import api from '../../middleware/api';
import { WeekRangeData } from '../time-actions';

// GET_PUBLIC_HOLIDAYS
export const GET_PUBLIC_HOLIDAYS_STARTED = 'GET_PUBLIC_HOLIDAYS_STARTED';
export function getPublicHolidaysStarted () {
	return {
		type: GET_PUBLIC_HOLIDAYS_STARTED,
	};
}

export const GET_PUBLIC_HOLIDAYS_SUCCEEDED = 'GET_PUBLIC_HOLIDAYS_SUCCEEDED';
export function getPublicHolidaysSucceeded (
	publicHolidays: Array<PublicHolidaysData>,
	selectedWeekHolidays: Array<PublicHolidaysData>
) {
	return {
		type: GET_PUBLIC_HOLIDAYS_SUCCEEDED,
		selectedWeekHolidays,
		publicHolidays,
	};
}

export const GET_PUBLIC_HOLIDAYS_FAILED = 'GET_PUBLIC_HOLIDAYS_FAILED';
export function getPublicHolidaysFailed ( error: any ) {
	return {
		type: GET_PUBLIC_HOLIDAYS_FAILED,
		error,
	};
}

export function getPublicHolidays ( weekRange: WeekRangeData ) {
	return function ( dispatch: Function ) {
		dispatch( getPublicHolidaysStarted() );
		api
			.getPublicHolidays( weekRange.from, weekRange.to )
			.then( ( publicHolidays: Array<PublicHolidaysData> ) => {
				const selectedWeekHolidays: Array<PublicHolidaysData> = publicHolidays.map(
					( holidays ) => {
						const currentHolidays = holidays.publicHolidays.filter(
							( holiday ) => {
								const holidayDate = new Date( holiday.date );
								return (
									holidayDate.getTime() >= weekRange.from.getTime() &&
									holidayDate.getTime() <= weekRange.to.getTime()
								);
							}
						);
						return { ...holidays, publicHolidays: currentHolidays };
					}
				);

				return dispatch(
					getPublicHolidaysSucceeded( publicHolidays, selectedWeekHolidays )
				);
			} )
			.catch( ( err: any ) => dispatch( getPublicHolidaysFailed( err ) ) );
	};
}


