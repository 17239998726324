import React, { useCallback } from 'react';
import {
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	Dialog,
	Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ClearAll as ClearIcon } from '@mui/icons-material';
import useTranslation from '../../../hooks/useTranslation';
import SettingsTemplateCard from '../../SettingsTemplateCard';

const useStyles = makeStyles( ( theme ) => ( {
	confirm: {
		textTransform: 'none',
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
	close: {
		textTransform: 'none',
		backgroundColor: theme.palette.grey[ 600 ],
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.grey[ 500 ],
		},
	},
} ) );

interface BackToFactorySettingsConfirmProps {
	action?: () => void;
}

const BackToFactorySettingsConfirm: React.FC<BackToFactorySettingsConfirmProps> = ( {
	action,
} ) => {
	const [ open, setOpen ] = React.useState( false );

	const classes = useStyles();

	const handleClickOpen = useCallback( () => {
		if ( action ) {
			setOpen( true );
		}
	}, [ action ] );

	const handleClose = useCallback( () => {
		setOpen( false );
	}, [] );

	const onDelete = useCallback( () => {
		if ( action ) {
			action();
			handleClose();
		}
	}, [ handleClose, action ] );

	const t = useTranslation();

	return (
		<>
			<SettingsTemplateCard
				title={ 'Back to factory settings' }
				action={ handleClickOpen }
				actionTitle={ 'Clear' }
				Icon={ ClearIcon }
				isCardClickable
			/>
			<Dialog open={ open } onClose={ handleClose }>
				<DialogTitle>{ t( 'Back to factory settings' ) }</DialogTitle>
				<DialogContent>
					<DialogContentText id="delete-alert">
						{ t( 'Confirm alert' ) }
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={ onDelete } className={ classes.confirm }>
						{ t( 'Confirm' ) }
					</Button>
					<Button onClick={ handleClose } className={ classes.close }>
						{ t( 'Close' ) }
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default BackToFactorySettingsConfirm;
