import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Typography, useTheme, CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { DateTime } from 'luxon';

import ExpenseClaimFileView from '../ExpenseClaims/ExpenseClaimFileView';
import DividerToggle from '../../DividerToggle';
import useSelectedAccount from '../../../hooks/useSelectedAccount';
import useExpenseClaimsData from '../../../hooks/useExpenseClaimsData';
import useTranslation from '../../../hooks/useTranslation';
import { expenseClaimStatuses } from '../ExpenseClaims/expenses-utils';

export interface ExpenseClaimDetailsProps {
	id?: string;
}

const useStyles = makeStyles( ( theme ) => ( {
	file: {
		color: theme.palette.primary.main,
		padding: theme.spacing( 0, 1 ),
		fontWeight: 900,
	},
	files: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing( 1 ),
	},
	delete: {
		color: theme.palette.error.dark,
		cursor: 'pointer',
	},
	detailsProp: {
		padding: theme.spacing( 1, 2 ),
		display: 'flex',
		alignItems: 'flex-start',
	},
	status: {
		padding: theme.spacing( 3, 2 ),
		display: 'flex',
		alignItems: 'center',
	},
	tab: {
		textAlign: 'center',
		backgroundColor: theme.palette.custom.paper,
		color: theme.palette.custom.gray,
		padding: theme.spacing( 2, 2, 1.75, 2 ),
		borderWidth: theme.spacing( 0, 0, 0.25, 0 ),
		borderStyle: 'solid',
		borderColor: theme.palette.custom.paper,
		textTransform: 'uppercase',
	},
	selectedTab: {
		fontWeight: 900,
		color: theme.palette.primary.dark,
		borderBottomColor: theme.palette.primary.dark,
	},
	value: {
		textOverflow: 'ellipsis',
		overflowX: 'auto',
		overflowY: 'hidden',
		textAlign: 'right',
	},
	statusValue: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.contrastText,
		fontWeight: 900,
		padding: theme.spacing( 1 ),
		borderRadius: theme.spacing( 0.25 ),
		display: 'inline-block',
	},
	statusApprovedValue: {
		backgroundColor: theme.palette.primary.main,
	},
	statusText: {
		display: 'flex',
		justifyContent: 'center',
		fontWeight: 900,
		padding: theme.spacing( 0.5, 0 ),
		color: theme.palette.primary.contrastText,
		borderRadius: theme.spacing( 1 ),
		width: '80%',
		textAlign: 'center',
	},
} ) );

const ExpenseClaimDetails: React.FC<ExpenseClaimDetailsProps> = () => {
	const classes = useStyles();
	const { id } = useParams<{ id: string }>();
	const theme = useTheme();

	const [ isFilesHidden, setIsFilesHidden ] = useState<boolean>( true );
	const {
		documents,
		approvalRoute,
		selectedExpenseClaim: expenseClaim,
		isLoadingApprovalRoute,
	} = useExpenseClaimsData( id );

	const { initialAccount } = useSelectedAccount();
	const employeeCode = initialAccount?.employeeCode;

	const onFilesViewToggle = useCallback( () => {
		setIsFilesHidden( !isFilesHidden );
	}, [ isFilesHidden ] );

	const t = useTranslation();

	return (
		<Box>
			<Box className={ classes.detailsProp }>
				<Box flex="1">
					<Typography
						className={ classes.statusText }
						style={ {
							backgroundColor:
								theme.palette.custom[
								expenseClaimStatuses[ expenseClaim?.status.id || '0' ]
								],
						} }
					>{ `Amount ${ expenseClaim?.status.desc }` }</Typography>
				</Box>
				<Box flex="1" textAlign="right">
					<Typography className={ classes.value }>
						{ `€${ expenseClaim?.salesAmount?.toFixed( 2 ) }` }
					</Typography>
				</Box>
			</Box>
			{ [
				{
					label: t( 'Date' ),
					value: DateTime.fromJSDate(
						new Date( expenseClaim?.date || '' )
					).toISODate(),
				},
				{ label: t( 'Code' ), value: expenseClaim?.descriptionExpenseClaimCode },
				{
					label: t( 'Description' ),
					value: expenseClaim?.description || '-',
				},
				{
					label: t( 'Detailed description' ),
					value: expenseClaim?.detailedDescription || '-',
				},
				{ label: t( 'Approver' ), value: approvalRoute?.employeeName },
			].map( ( element ) => (
				<Box className={ classes.detailsProp } key={ element.label }>
					<Typography>{ element.label }</Typography>
					{ element.label === 'Approver' && isLoadingApprovalRoute && (
						<Box
							width={ 25 }
							height={ 25 }
							display="flex"
							justifyContent="center"
							alignItems="center"
							paddingLeft={ 1 }
						>
							<CircularProgress size={ 20 } />
						</Box>
					) }

					<Box flex="1" overflow="hidden">
						{ element.label === 'Approver' && isLoadingApprovalRoute ? null : (
							<Typography className={ classes.value }>{ element.value }</Typography>
						) }
					</Box>
				</Box>
			) ) }
			<DividerToggle expandMore={ isFilesHidden } onToggle={ onFilesViewToggle } />
			{ !isFilesHidden && (
				<>
					<Box className={ classes.files }>
						<Box flex="2" display="flex">
							<Typography className={ classes.file }>{ t( 'Name' ) }</Typography>
						</Box>
						<Box flex="2">
							<Typography className={ classes.file }>
								{ t( 'Created at' ) }
							</Typography>
						</Box>
						<Box flex="1" textAlign="right" />
					</Box>
					{ documents.map( ( document ) => (
						<ExpenseClaimFileView
							key={ document.id }
							documentId={ document.id }
							document={ document }
							expenseClaimId={ id }
							employeeCode={ employeeCode }
						/>
					) ) }
				</>
			) }
		</Box>
	);
};

export default ExpenseClaimDetails;
