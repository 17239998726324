import { useSelector } from 'react-redux';
import { AppState } from '../reducers';
import {
	TimesheetDetailsWithEmployeeCode,
	WeekActivity,
} from '../types/api/timesheets';
import {
	getTimesheetPrettyName,
	getOvertimeOptions,
} from '../utils/timesheets';
import usePublicHolidays from './usePublicHolidays';
import useWeekRange from './useWeekRange';

const useWeekTimesheets = () => {
	const timesheets: TimesheetDetailsWithEmployeeCode[] = useSelector<
		AppState,
		TimesheetDetailsWithEmployeeCode[]
	>( ( state ) => {
		let timesheetsDetails: TimesheetDetailsWithEmployeeCode[] = [];

		state.timesheetsState.timesheets.forEach( ( timesheetItem ) => {
			timesheetItem.timeSheetInfos.forEach( ( timesheetInfoItem ) => {
				timesheetsDetails = [
					...timesheetsDetails,
					...timesheetInfoItem.timesheetDetails.map( ( timesheetDetailsItem ) => ( {
						...timesheetDetailsItem,
						employeeCode: timesheetItem.employeeCode,
					} ) ),
				];
			} );
		} );

		return timesheetsDetails;
	} );

	const rawTimesheets = useSelector(
		( state: AppState ) => state.timesheetsState.timesheets
	);

	const { selectedWeekRange } = useWeekRange();

	const destinations: any = useSelector<AppState, any>(
		( state ) => state.settingsState.settings.destinations
	);

	const isLoading: boolean = useSelector<AppState, boolean>(
		( state ) => state.timesheetsState.isLoading
	);

	const { usersHolidays } = usePublicHolidays();

	const publicHolidays = usersHolidays.filter(
		( holiday ) =>
			timesheets.length > 0 &&
			holiday.employeeCode === timesheets[ 0 ].employeeCode
	);

	const weekActivities: WeekActivity[] = timesheets.map( ( timesheetItem ) => {
		return {
			...getTimesheetPrettyName( timesheetItem, isLoading, destinations ),
			...getOvertimeOptions(
				timesheetItem,
				publicHolidays[ 0 ].publicHolidays,
				selectedWeekRange
			),
			details: timesheetItem,
		};
	} );

	return { weekActivities, isLoading, timesheets: rawTimesheets };
};

export default useWeekTimesheets;
