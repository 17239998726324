import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../reducers';
import {
	clearSettings,
	saveSettings,
	clearCache,
} from '../actions/api-actions';

const useSettingsOptions = () => {
	const dispatch = useDispatch();

	const settings = useSelector(
		( state: AppState ) => state.settingsState.settings
	);

	const selectedTheme: string = settings?.darkMode;

	const setThemeSettings = useCallback(
		( value ) => {
			dispatch(
				saveSettings( {
					...settings,
					darkMode: value,
				} )
			);
		},
		[ dispatch, settings ]
	);

	const selectedLanguage = settings.language;

	const setLanguageSettings = useCallback( ( language ) => {

		dispatch( saveSettings( {
			...settings,
			language: language === 'Use system settings' ? null : language,
		} ) )
	}, [ dispatch, settings ] )

	const onClearSettings = useCallback( () => {
		dispatch( clearSettings() );
	}, [ dispatch ] );

	const onClearCache = useCallback( () => {
		dispatch( clearCache() );
	}, [ dispatch ] );


	return {
		selectedTheme,
		setThemeSettings,
		onClearSettings,
		onClearCache,
		selectedLanguage,
		setLanguageSettings,
	};
};

export default useSettingsOptions;
