import { DateTime } from 'luxon';
import { apiFetch, queryBuilder } from '../utils/apiFetch';
import {
	Timesheet,
	CurrentTimesheetData,
	AddTimesheetLineItemsArguments,
	PublicHolidaysData,
	ActivitiesData,
	WorkingHoursData,
	ProjectActivitiesData,
	UpdateTimesheetLineItemsArguments,
	ReleaseTimeSheetArguments,
	CopyTimeSheetRequestArguments,
	PrintTimesheetRequestArguments,
} from '../types/api/timesheets';
import { UserInfo } from '../types/api/user';
import { GetSettingsResponseData } from '../types/api/settings';

import {
	LeaveRequest,
	LeaveRequestBalance,
	GetLeaveRequestsResponseDataItem,
} from './../types/api/leaveRequests';

import {
	ExpenseClaimsData,
	UpSertExpenseClaimRequestProps,
	ExpenseClaimApprovalRoute,
	ExpenseClaimApprovalProgress,
	ExpenseClaimDetails,
} from './../types/api/expenseClaims';

export interface ResponseError {
	code: string;
	description: string;
}

export interface Response<T> {
	data: T;
	error: ResponseError;
}

let _token: string;

let _tokenErrorHandler: ( error?: any ) => void;

const _updateToken = (
	newToken: string,
	newTokenErrorHandler?: ( error?: any ) => void
) => {
	_token = newToken;
	if ( newTokenErrorHandler ) {
		_tokenErrorHandler = newTokenErrorHandler;
	}
};

const _triggerTokenError = ( error?: any ) => {
	if ( _tokenErrorHandler ) {
		_tokenErrorHandler( error );
	}
};

/*
 * CopyWeekTimesheet
 */

const copyTimesheet = ( requestData: CopyTimeSheetRequestArguments ) => {
	return apiFetch
		.post( `/api/timesheet/copytimesheet`, requestData, { token: _token } )
		.then( ( resJson: any ) => resJson.data )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

/*
 * Print Timesheet
 */

const printTimesheet = ( requestData: PrintTimesheetRequestArguments ) => {
	return apiFetch
		.post( `/api/timesheet/PrintTimesheet`, requestData, { token: _token } )
		.then( ( resJson: any ) => resJson.data )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

/*
 * GetUserInfo
 */

const getUserInfo = () => {
	return apiFetch( `/api/userInfo/GetUserInfo`, { token: _token } )
		.then( ( resJson: Response<UserInfo> ) => resJson.data )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

/*
 * GetTimesheet
 */

const getTimesheet = ( start: Date, end: Date ) => {
	const startDate = DateTime.fromJSDate( start ).toISODate(),
		endDate = DateTime.fromJSDate( end ).toISODate();

	return apiFetch
		.get(
			`/api/timesheet/GetTimesheet/?StartDate=${ startDate }&EndDate=${ endDate }`,
			{ token: _token }
		)
		.then( ( resJson: Response<Array<Timesheet>> ) => {
			return resJson.data;
		} )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const getWeekTimesheet = ( date: Date ) => {
	const data = {
		date: DateTime.fromJSDate( date ).toISODate(),
	};
	return apiFetch
		.get( `/api/timesheet/GetWeekTimesheetInfo?${ queryBuilder( data ) }`, {
			token: _token,
		} )
		.then( ( resJson: Response<Array<Timesheet>> ) => {
			return resJson.data;
		} )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const getCurrentTimesheet = () => {
	return apiFetch( `/api/timesheet/GetCurrentTimesheet`, { token: _token } )
		.then( ( resJson: Response<CurrentTimesheetData> ) => resJson.data )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const addTimesheetLineItems = ( {
	year,
	week,
	employeeCode,
	addLineItemRequestRecords,
}: AddTimesheetLineItemsArguments ) => {
	const data = {
		year,
		week,
		employeeCode,
		upSertLineItemRequestRecords: addLineItemRequestRecords,
	};
	return apiFetch
		.post( `/api/timesheet/UpSertTimesheetLineItems`, data, { token: _token } )
		.then( ( resJson: Response<any> ) => resJson )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const updateTimesheetLineItems = ( {
	year,
	week,
	updateLineItemRequestRecords,
}: UpdateTimesheetLineItemsArguments ) => {
	const data = {
		year,
		week,
		upSertLineItemRequestRecords: updateLineItemRequestRecords,
	};
	return apiFetch
		.post( `/api/timesheet/UpSertTimesheetLineItems`, data, { token: _token } )
		.then( ( resJson: Response<any> ) => resJson )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const deleteTimesheetLineItems = ( data: any ) => {
	return apiFetch
		.post( `/api/timesheet/DeleteTimesheetLineItems`, data, { token: _token } )
		.then( ( resJson: Response<any> ) => resJson )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const releaseTimesheet = ( data: ReleaseTimeSheetArguments ) => {
	return apiFetch
		.post( `/api/timesheet/ReleaseTimesheet`, data, { token: _token } )
		.then( ( resJson: Response<any> ) => resJson )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

/*
 * GetTimesheetSourceData
 */

const getTimesheetSourceData = async () => {
	try {
		const activities: Array<ActivitiesData> = await getActivities();
		const projectActivities: Array<ProjectActivitiesData> = await getProjectActivities();

		return {
			activities,
			projectActivities,
		};
	} catch ( error ) {
		if ( error && error.status === 401 ) {
			_triggerTokenError( error );
		} else {
			throw error;
		}
	}
};

const _getPublicHolidaysForYear = ( year: number ) => {
	return apiFetch
		.get( `/api/timesheet/GetPublicHolidays?Year=${ year }`, { token: _token } )
		.then( ( resJson: Response<Array<PublicHolidaysData>> ) => resJson.data )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

export const getPublicHolidays = ( start: Date, end: Date ) => {
	const startYear = start.getFullYear();
	const endYear = end.getFullYear();

	if ( startYear === endYear ) {
		return _getPublicHolidaysForYear( startYear );
	} else {
		return Promise.all( [
			_getPublicHolidaysForYear( startYear ),
			_getPublicHolidaysForYear( endYear ),
		] ).then(
			( [ startYearHolidaysResponse, endYearHolidaysResponse ]: Array<
				Array<PublicHolidaysData>
			> ) => {
				return [ ...startYearHolidaysResponse, ...endYearHolidaysResponse ];
			}
		);
	}
};

const getActivities = () => {
	return apiFetch
		.get( `/api/timesheet/GetActivities/`, { token: _token } )
		.then( ( resJson: Response<Array<ActivitiesData>> ) => resJson.data )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const getWorkingHours = () => {
	return apiFetch
		.get( `/api/timesheet/GetWorkingHours`, { token: _token } )
		.then( ( resJson: Response<Array<WorkingHoursData>> ) => resJson.data )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const getProjectActivities = () => {
	return apiFetch
		.get( `/api/timesheet/GetProjectActivities`, { token: _token } )
		.then( ( resJson: Response<Array<ProjectActivitiesData>> ) => resJson.data )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const getExpenseClaimsProjectActivities = () => {
	return apiFetch
		.get( `/api/expenseclaim/GetProjectActivities`, { token: _token } )
		.then( ( resJson: Response<Array<ProjectActivitiesData>> ) => {
			return resJson.data
		} )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

/**
 * Leave Request
 */
export interface GetLeaveRequestsParams {
	employeeCode: string;
	start: Date;
	end: Date;
}

const getLeaveRequests = ( {
	employeeCode,
	start,
	end,
}: GetLeaveRequestsParams ) => {
	const startDateTime = DateTime.fromJSDate( start );
	const endDateTime = DateTime.fromJSDate( end );

	const startYear = startDateTime.get( 'year' );
	const fromWeek = startDateTime.get( 'weekNumber' );
	const fromYear = fromWeek !== 1 ? startYear - 1 : startYear;

	const toYear = endDateTime.get( 'year' );
	const toWeek = endDateTime.get( 'weeksInWeekYear' );

	const requestQueryData = queryBuilder( {
		employeeCode,
		fromYear,
		fromWeek,
		toYear,
		toWeek,
	} );

	return apiFetch
		.get( `/api/LeaveRequest/GetLeaveRequests?${ requestQueryData }`, {
			token: _token,
		} )
		.then(
			( resJson: Response<Array<GetLeaveRequestsResponseDataItem>> ) =>
				resJson.data
		)
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

export interface GetLeaveRequestParams {
	employeeCode: string;
	id: string;
}
const getLeaveRequest = ( { employeeCode, id }: GetLeaveRequestParams ) => {
	const requestQueryData = queryBuilder( {
		employeeCode,
		leaveRequestId: id,
	} );

	return apiFetch
		.get( `/api/LeaveRequest/GetLeaveRequest?${ requestQueryData }`, {
			token: _token,
		} )
		.then( ( resJson: Response<LeaveRequest> ) => resJson.data )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const getExpenseClaims = ( {
	start,
	end,
	employeeCode,
}: GetLeaveRequestsParams ) => {
	const FromDate = DateTime.fromJSDate( start ).toISODate();
	const ToDate = DateTime.fromJSDate( end ).toISODate();

	const requestQueryData = queryBuilder( {
		FromDate,
		ToDate,
		employeeCode,
	} );

	return apiFetch
		.get( `/api/ExpenseClaim/GetExpenseClaims?${ requestQueryData }`, {
			token: _token,
		} )
		.then( ( resJson: Response<Array<ExpenseClaimsData>> ) => {
			return resJson.data[ 0 ];
		} )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const getExpenseClaimById = ( {
	id,
	employeeCode,
}: { id: string, employeeCode: string } ) => {

	const requestQueryData = queryBuilder( {
		expenseClaimId: id,
		employeeCode,
	} );

	return apiFetch
		.get( `/api/ExpenseClaim/GetExpenseClaimById?${ requestQueryData }`, {
			token: _token,
		} )
		.then( ( resJson: Response<ExpenseClaimsData> ) => {
			return resJson.data.expenseClaims[ 0 ];
		} )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const getExpenseClaimsCodes = () => {
	return apiFetch
		.get( `/api/ExpenseClaim/GetExpenseClaimCodes`, {
			token: _token,
		} )
		.then( ( resJson: Response<Array<ExpenseClaimsData>> ) => {
			return resJson.data;
		} )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const getExpenseClaimsSources = async () => {
	try {
		const expenseClaimsCodes = await getExpenseClaimsCodes();
		const projectActivities = await getExpenseClaimsProjectActivities();

		return { expenseClaimsCodes, projectActivities };
	} catch ( error ) {
		if ( error && error.status === 401 ) {
			_triggerTokenError( error );
		} else {
			throw error;
		}
	}
};

const upSertExpenseClaim = ( requestData: UpSertExpenseClaimRequestProps ) => {
	return apiFetch
		.post( `/api/ExpenseClaim/UpSertExpenseClaim`, requestData, {
			token: _token,
		} )
		.then( ( resJson: Response<string> ) => resJson.data )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const releaseExpenseClaim = ( requestData: {
	employeeCode: string;
	expenseClaimId: string;
} ) => {
	const requestQueryData = queryBuilder( requestData );

	return apiFetch
		.get( `/api/ExpenseClaim/ReleaseExpenseClaim?${ requestQueryData }`, {
			token: _token,
		} )
		.then( ( resJson: Response<Array<string>> ) => {
			return resJson.data;
		} )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const deleteExpenseClaim = ( requestData: {
	employeeCode: string;
	expenseClaimId: string;
} ) => {
	const requestQueryData = queryBuilder( requestData );

	return apiFetch
		.get( `/api/ExpenseClaim/DeleteExpenseClaim?${ requestQueryData }`, {
			token: _token,
		} )
		.then( ( resJson: Response<Array<ExpenseClaimsData>> ) => {
			return resJson.data;
		} )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const deleteExpenseClaimDocument = ( requestData: {
	employeeCode: string;
	expenseClaimId: string;
	documentId: string;
} ) => {
	const requestQueryData = queryBuilder( requestData );

	return apiFetch
		.get( `/api/ExpenseClaim/DeleteDocument?${ requestQueryData }`, {
			token: _token,
		} )
		.then( ( resJson: Response<Array<ExpenseClaimsData>> ) => {
			return resJson.data;
		} )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const getExpenseClaimDocuments = ( requestData: {
	employeeCode: string;
	id: string;
} ) => {
	const { employeeCode, id: expenseClaimId } = requestData;
	const requestQueryData = queryBuilder( { employeeCode, expenseClaimId } );

	return apiFetch
		.get( `/api/ExpenseClaim/GetDocuments?${ requestQueryData }`, {
			token: _token,
		} )
		.then( ( resJson: Response<any> ) => {
			return resJson.data;
		} )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};


const getExpenseClaimDocument = ( requestData: {
	employeeCode: string;
	documentId: string;
	expenseClaimId: string;
} ) => {
	const { employeeCode, documentId, expenseClaimId } = requestData
	const requestQueryData = queryBuilder( { employeeCode, expenseClaimId, documentId } );

	return apiFetch
		.get( `/api/ExpenseClaim/GetDocument?${ requestQueryData }`, {
			token: _token,
		} )
		.then( ( resJson: Response<any> ) => {
			return resJson.data;
		} )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
}

const getExpenseClaimApprovalRoute = ( requestData: {
	employeeCode: string;
	id: string;
} ) => {
	const { employeeCode, id: expenseClaimId } = requestData;
	const requestQueryData = queryBuilder( { employeeCode, expenseClaimId } );

	return apiFetch
		.get( `/api/ExpenseClaim/GetExpenseClaimApprovalRoute?${ requestQueryData }`, {
			token: _token,
		} )
		.then( ( resJson: Response<Array<ExpenseClaimApprovalRoute>> ) => {
			return resJson.data[ 0 ];
		} )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const getExpenseClaimApprovalProgress = ( requestData: {
	employeeCode: string;
	id: string;
} ) => {
	const { employeeCode, id: expenseClaimId } = requestData;
	const requestQueryData = queryBuilder( { employeeCode, expenseClaimId } );

	return apiFetch
		.get( `/api/ExpenseClaim/GetExpenseClaimProgress?${ requestQueryData }`, {
			token: _token,
		} )
		.then( ( resJson: Response<Array<ExpenseClaimApprovalProgress>> ) => {
			return resJson.data;
		} )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const getExpenseClaimDetails = async ( requestData: {
	employeeCode: string;
	id: string;
} ): Promise<ExpenseClaimDetails | undefined> => {
	try {
		const approvalRoute: ExpenseClaimApprovalRoute = await getExpenseClaimApprovalRoute(
			requestData
		);
		const approvalProgress: Array<ExpenseClaimApprovalProgress> = await getExpenseClaimApprovalProgress(
			requestData
		);
		const expenseClaimDetails: ExpenseClaimDetails = {
			approvalRoute,
			approvalProgress,
			id: requestData.id,
			isLoadingApprovalRoute: false,
			isLoadingProgress: false,
		};

		return expenseClaimDetails;
	} catch ( error ) {
		if ( error && error.status === 401 ) {
			_triggerTokenError( error );
		} else {
			throw error;
		}
	}
};

export interface UpSertLeaveRequestParams {
	leaveRequest: LeaveRequest;
}

const upSertLeaveRequest = ( { leaveRequest }: UpSertLeaveRequestParams ) => {
	return apiFetch
		.post( `/api/LeaveRequest/UpSertLeaveRequest`, leaveRequest, {
			token: _token,
		} )
		.then( ( resJson: Response<LeaveRequestBalance> ) => resJson.data )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

export interface GetLeaveRequestsBalanceParams {
	employeeCode: string;
	year: number;
}

const getLeaveRequestsBalance = ( {
	employeeCode,
	year,
}: GetLeaveRequestsBalanceParams ) => {
	const requestQueryData = queryBuilder( {
		employeeCode,
		year,
	} );

	return apiFetch
		.get( `/api/LeaveRequest/GetBalance?${ requestQueryData }`, {
			token: _token,
		} )
		.then( ( resJson: Response<LeaveRequest> ) => resJson.data )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const deleteLeaveRequest = ( employeeCode: string, id: string ) => {
	const requestQueryData = queryBuilder( {
		employeeCode,
		leaveRequestID: id,
	} );

	return apiFetch
		.get( `/api/LeaveRequest/DeleteLeaveRequest?${ requestQueryData }`, {
			token: _token,
		} )
		.then()
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

/*
 * GetSettings / SaveSettings
 */

const getSettings = () => {
	return apiFetch
		.post( `/api/Settings/GetSettings`, {}, { token: _token } )
		.then( ( resJson: Response<GetSettingsResponseData> ) => resJson.data )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const saveSettings = ( settings: any ) => {
	const settingsData = Object.keys( settings ).reduce(
		( settingsBody: any, settingsKey: string ) => {
			const currentSettingsItemType = typeof settings[ settingsKey ];
			if (
				currentSettingsItemType === 'string' ||
				currentSettingsItemType === 'number' ||
				currentSettingsItemType === 'boolean' ||
				settings[ settingsKey ] === null
			) {
				settingsBody[ settingsKey ] = settings[ settingsKey ];
			} else {
				settingsBody[ settingsKey ] = JSON.stringify( settings[ settingsKey ] );
			}
			return settingsBody;
		},
		{}
	);
	const data = { Settings: settingsData };

	return apiFetch
		.post( `/api/Settings/SaveSettings`, data, { token: _token } )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const clearSettings = () => {
	return apiFetch
		.get( `/api/userinfo/clearsettings`, { token: _token } )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const clearCache = () => {
	return apiFetch
		.get( `/api/userinfo/clearcache`, { token: _token } )
		.catch( ( error: any ) => {
			if ( error && error.status === 401 ) {
				_triggerTokenError( error );
			} else {
				throw error;
			}
		} );
};

const api = {
	_updateToken,
	getUserInfo,
	getTimesheet,
	copyTimesheet,
	printTimesheet,
	getWeekTimesheet,
	getCurrentTimesheet,
	addTimesheetLineItems,
	deleteTimesheetLineItems,
	updateTimesheetLineItems,
	releaseTimesheet,
	getTimesheetSourceData,
	getPublicHolidays,
	getActivities,
	getWorkingHours,
	getProjectActivities,
	getSettings,
	saveSettings,
	clearSettings,
	clearCache,
	getLeaveRequests,
	getLeaveRequest,
	deleteLeaveRequest,
	upSertLeaveRequest,
	getLeaveRequestsBalance,
	getExpenseClaims,
	getExpenseClaimById,
	getExpenseClaimsCodes,
	getExpenseClaimsSources,
	upSertExpenseClaim,
	deleteExpenseClaim,
	getExpenseClaimDocuments,
	getExpenseClaimDocument,
	deleteExpenseClaimDocument,
	getExpenseClaimApprovalRoute,
	getExpenseClaimApprovalProgress,
	getExpenseClaimDetails,
	releaseExpenseClaim,
	getExpenseClaimsProjectActivities,
};

export default api;
