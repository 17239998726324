import React, { useCallback } from 'react';
import AddTimesheetHeader from '../../Headers/AddTimesheetHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

import SelectDestinationTemplateCard from './../../SelectDestinationTemplateCard';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../reducers';
import { DateTime } from 'luxon';
import { setWeekRangeData } from '../../../actions/time-actions';
import {
	getWeekRange,
	getSmallestTimeFromISOString,
} from '../../../utils/date';
import Loading from '../../Loading';
import { saveSettings, setSelectedUser } from '../../../actions/api-actions';
import useDestinationNavSettings from '../../../hooks/useDestinationNavSettings';
import Dropdown from '../../controls/Dropdown';
import useSelectedAccount from '../../../hooks/useSelectedAccount';
import useTranslation from '../../../hooks/useTranslation';
import useLanguage from '../../../hooks/useLanguage';

const useStyles = makeStyles( ( theme ) => ( {
	button: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		borderTopWidth: theme.spacing( 0.25 ),
		borderTopColor: theme.palette.custom.lightGray,
		borderRadius: 0,
		borderTopStyle: 'solid',
		boxSizing: 'border-box',
		padding: theme.spacing( 1.5 ),
		paddingBottom: theme.spacing( 2.5 ),
		fontWeight: 900,
		'&:hover': {
			backgroundColor: theme.palette.background.paper,
		},
	},
	links: {
		textDecoration: 'none',
	},
} ) );

type TemplateChipColor = 'primary' | 'secondary';

const SelectDestination: React.FC = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const settings = useSelector(
		( state: AppState ) => state.settingsState.settings
	);
	const timesheetDestinationTemplates = settings.destinations;

	const destinationNavOptions = useDestinationNavSettings();

	if ( settings && destinationNavOptions.path !== '/select-destination' ) {
		navigate( '/create-destination', { replace: true } );
	}

	const onAddClick = useCallback( () => {
		navigate( '/create-destination', {
			state: {
				previous: location.pathname,
			}
		} );
	}, [ navigate, location.pathname ] );

	const cardClickHandler = useCallback(
		( title: string ) => {
			navigate( `/create-timesheet/${ encodeURIComponent( title ) }`, {
				state: {
					previous: location.pathname,
				},
			} );
		},
		[ navigate, location.pathname ]
	);

	const editClickHandler = useCallback(
		( title: string ) => {
			navigate( `/edit-destination/${ title }`, {
				state: {
					previous: location.pathname,
				}
			} );
		},
		[ navigate, location.pathname ]
	);

	const deleteClickHandler = useCallback(
		( title: string ) => {
			const {
				[ title ]: removedDestinationTemplate,
				...otherDestinationTemplates
			} = timesheetDestinationTemplates;

			dispatch(
				saveSettings( {
					...settings,
					destinations: otherDestinationTemplates,
				} )
			);
		},
		[ dispatch, settings, timesheetDestinationTemplates ]
	);

	const currentTimesheets = useSelector(
		( state: AppState ) => state.userState.currentTimesheets
	);
	const selectedWeekRange = useSelector(
		( state: AppState ) => state.timeState.weekRange
	);

	const onWeekErrorSuggestionClick = useCallback( () => {
		if ( currentTimesheets ) {
			const startDatesOfWeekPerAccount = currentTimesheets.map(
				( currentTimesheet ) => currentTimesheet.timeSheetInfos[ 0 ].startDateOfWeek
			);
			const lowestStartDate = getSmallestTimeFromISOString(
				startDatesOfWeekPerAccount
			);
			const weekRage = getWeekRange(
				DateTime.fromMillis( lowestStartDate ).toJSDate()
			);
			dispatch( setWeekRangeData( weekRage ) );
		}
	}, [ currentTimesheets, dispatch ] );

	const {
		initialAccount: selectedUser,
		setCompanyName,
		companyName,
		userAccounts,
	} = useSelectedAccount();

	const onSelectUserAccount = useCallback(
		( selectedCompany ) => {
			setCompanyName( selectedCompany );
			const selectedUser = userAccounts.filter(
				( account ) => account.companyName === selectedCompany
			)[ 0 ];
			dispatch( setSelectedUser( selectedUser ) );
		},
		[ setCompanyName, dispatch, userAccounts ]
	);

	const selectedAccountEmployeeCode = useSelector(
		( state: AppState ) => state.userState.selectedUser?.employeeCode
	);

	const t = useTranslation();
	const language = useLanguage();

	// In order to log time for a specific week, the not internal hired user must have logged the time for each of the previous ones.
	// The last week without completed timesheet is in the currentTimesheetData for the selectedUser
	if ( currentTimesheets && selectedWeekRange && selectedUser ) {
		const selectedAccountCurrentTimesheet = currentTimesheets.filter(
			( currentTimesheet ) =>
				currentTimesheet.employeeCode === selectedUser.employeeCode
		)[ 0 ];

		const lowestStartDate = Date.parse(
			selectedAccountCurrentTimesheet.timeSheetInfos[ 0 ].startDateOfWeek
		).valueOf();

		const currentTimesheetWeekStart = DateTime.fromMillis( lowestStartDate );
		const selectedRangeWeekStart = DateTime.fromJSDate( selectedWeekRange.from );

		// So we check if the selected week is greater than the one returned from the API.
		if (
			currentTimesheetWeekStart < selectedRangeWeekStart &&
			!selectedUser.employeeDetail.internalHired
		) {
			return (
				<>
					<Box
						flex="1"
						display="flex"
						flexDirection="column"
						height="calc( 100vh - 11rem )"
						overflow="hidden"
					>
						<AddTimesheetHeader />
						<Box p={ 2 }>
							<Typography variant="h3">{ 'Oops..' }</Typography>
							<Box paddingTop={ 1 }>
								<Typography>{ t( 'move to current timesheet week' ) }</Typography>
								<Typography>
									{ currentTimesheetWeekStart
										.setLocale( language )
										.toFormat( `dd MMM yyyy` ) }{ ' ' }
									-{ ' ' }
									{ currentTimesheetWeekStart
										.plus( { week: 1 } )
										.minus( { millisecond: 1 } )
										.toFormat( `dd MMM yyyy` ) }
								</Typography>
							</Box>
						</Box>
					</Box>
					<Button
						fullWidth
						className={ classes.button }
						onClick={ onWeekErrorSuggestionClick }
					>
						<CheckIcon />
						{ 'Okay' }
					</Button>
				</>
			);
		}
	} else {
		return <Loading />;
	}

	return (
		<>
			<Box
				flex="1"
				display="flex"
				flexDirection="column"
				height="calc( 100vh - 11rem )"
				overflow="hidden"
			>
				<AddTimesheetHeader />
				<Box
					overflow="auto"
					boxShadow="rgba(0, 0, 0, 0.05) 0px -3px 3px 0px"
					flex="1"
					p={ 1 }
					paddingTop={ 2 }
				>
					{ userAccounts.length > 1 && selectedUser && (
						<Dropdown
							label="Select Account"
							options={ userAccounts.map( ( account ) => account.companyName ) }
							selectedValue={ companyName || selectedUser.companyName }
							autoExpand={ false }
							onValueChange={ onSelectUserAccount }
						/>
					) }
					{ timesheetDestinationTemplates
						? Object.values( timesheetDestinationTemplates )
							.reverse()
							.map( ( timesheetDestinationTemplate: any ) => {
								if ( !timesheetDestinationTemplate ) {
									return null;
								}

								let chipColor: TemplateChipColor;
								let isActive = true;

								if ( timesheetDestinationTemplate.type === 'Project' ) {
									chipColor = 'primary';

									let projectStart: string =
										timesheetDestinationTemplate.activity
											.startDateProjectTeam;
									let projectEnd: string =
										timesheetDestinationTemplate.activity.endDateProjectTeam;

									// If the start of the project is after the end of the selected week
									// or if the end of the project is before the start of the selected week
									// it is marked as inactive
									if (
										Date.parse( projectStart ) >
										selectedWeekRange.to.getTime() ||
										Date.parse( projectEnd ) < selectedWeekRange.from.getTime()
									) {
										isActive = false;
									}
								} else {
									chipColor = 'secondary';
								}

								if (
									selectedAccountEmployeeCode !==
									timesheetDestinationTemplate.employeeCode
								) {
									isActive = false;
								}

								const destinationTemplateName =
									timesheetDestinationTemplate.details.Name;

								return (
									<SelectDestinationTemplateCard
										isActive={ isActive }
										onCardClick={ cardClickHandler }
										onEditClick={ editClickHandler }
										onDeleteClick={ deleteClickHandler }
										key={ destinationTemplateName }
										color={ chipColor }
										customChipColor={
											timesheetDestinationTemplate.templateColor
										}
										title={ destinationTemplateName }
									/>
								);
							} )
						: null }
				</Box>
			</Box>
			<Button fullWidth className={ classes.button } onClick={ onAddClick }>
				<AddIcon />
				{ t( 'Add new' ) }
			</Button>
		</>
	);
};

export default SelectDestination;
