import React from 'react';

import { Box, Typography, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles( ( theme ) => ( {
	messageBox: {
		display: 'flex',
		flexDirection: 'column',
		margin: '0 auto',
		padding: theme.spacing( 0, 5, 0, 5 ),
	},
	message: {
		fontSize: theme.spacing( 1.7 ),
		marginBottom: theme.spacing( 0.5 ),
	},
	subMessage: {
		fontSize: theme.spacing( 1.3 ),
		marginBottom: theme.spacing( 0.5 ),
		maxWidth: theme.spacing( 35 ),
		// fontStyle: 'italic'
	},
	messageLink: {
		fontSize: theme.spacing( 1.7 ),
		textDecoration: 'none',
		color: theme.palette.primary.main,
	},
	divider: {
		height: theme.spacing( 0.15 ),
		margin: theme.spacing( 1, 0, 2 ),
	},
} ) );

interface ContactLineProps {
	contactText?: string;
}

const ContactLine: React.FC<ContactLineProps> = ( { contactText } ) => {
	const classes = useStyles();

	return (
		<Box className={ classes.messageBox }>
			<Typography className={ classes.message }>{ contactText }</Typography>
			<Typography>
				<a className={ classes.messageLink } href="mailto:helpdesk@ict.nl">
					{ 'Contact the helpdesk.' }
				</a>
			</Typography>
			<Divider className={ classes.divider } />
			<Typography className={ classes.subMessage }>{ 'OnTime is available for the ICT Group employees who log their hours in AllSolutions.' }</Typography>
		</Box>
	);
};

export default ContactLine;
