import React, { useState, useCallback } from 'react';

import { Box, Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { HoursOutput } from '../../controls/CreateTimesheet/TimeSpentSelector';

import DigitalClockPicker from '../../controls/CreateTimesheet/DigitalClockPicker';
import { formatTime } from '../../../utils/date';
import useTranslation from '../../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	timPickerOverlay: {
		position: 'fixed',
		width: '100%',
		height: '100%',
		top: 0,
		zIndex: 2,
	},
	timePicker: {
		position: 'absolute',
		top: 0,
		zIndex: 3,
		background: theme.palette.background.paper,
		right: theme.spacing( 2 ),
		borderRadius: theme.spacing( 0.5 ),
		boxShadow: `0px 1px 16px ${ theme.palette.custom.boxShadow }`,
	},
	confirm: {
		textTransform: 'none',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
} ) );

export interface TimePickerMenuProps {
	open: boolean;
	onClose: () => void;
	onConfirm: ( time: string ) => void;
	defaultValue: string;
}

const TimePickerMenu: React.FC<TimePickerMenuProps> = ( {
	open,
	onClose,
	onConfirm,
	defaultValue,
} ) => {
	const classes = useStyles();

	const [ timePickerTime, setTimePickerTime ] = useState<HoursOutput>( {
		hours: Number( defaultValue.split( ':' )[ 0 ] ),
		minutes: Number( defaultValue.split( ':' )[ 1 ] ),
	} );

	const t = useTranslation();

	const onConfirmData = useCallback( () => {
		const timeToConfirm = formatTime(
			timePickerTime.hours,
			timePickerTime.minutes,
			':'
		);
		onConfirm( timeToConfirm );
		onClose();
	}, [ timePickerTime, onConfirm, onClose ] );

	return open ? (
		<Box>
			<Box className={ classes.timPickerOverlay } onClick={ onClose } />
			<Box className={ classes.timePicker }>
				<Box>
					<DigitalClockPicker
						time={ timePickerTime }
						setTime={ setTimePickerTime }
					/>
					<Button fullWidth className={ classes.confirm } onClick={ onConfirmData }>
						{ t( 'Confirm hours' ) }
					</Button>
				</Box>
			</Box>
		</Box>
	) : null;
};

export default TimePickerMenu;
