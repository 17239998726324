import {
	ProjectActivityGroupDefinition,
	GroupedProjectActivities,
} from './DestinationFormTypes';
import { ProjectActivity } from '../../../types/api/timesheets';

export const getActivityOptionName = (
	projectActivity: ProjectActivity,
	grouping: ProjectActivityGroupDefinition
) =>
	grouping.number === 'projectNumber'
		? `${ projectActivity[ grouping.description ] } - ${
		projectActivity[ grouping.number ]
		}`
		: grouping.number === 'subTaskNumber'
			? projectActivity[ grouping.description ] ||
			projectActivity[ grouping.number ].toString()
			: projectActivity[ grouping.description ];

/**
 * Groups activities based on grouping definition
 * The grouping definitions represent the structure of the categories (the taxonomy definition).
 * Based on the data in the projectActivities the categories for each level are created with the
 * actual possible values.
 * The result is a nested Object that each level is a hierarchical filter for the activities
 *
 * @param groupingKeys			Describes how to group the activities
 * @param projectActivities		The activities to be grouped
 *
 * @returns {GroupedProjectActivities} The result is a nested Object that each level is a filter for the activities
 */
export const groupProjectActivities: (
	groupingKeys: Array<ProjectActivityGroupDefinition>,
	projectActivities: Array<ProjectActivity>
) => GroupedProjectActivities = ( groupingKeys, projectActivities ) => {
	// For each of the activities we add the new categories/sub-categories/.../ if their values are not present
	// and add the activity to the array of activities that match all of the filters.
	const groupedProjectActivities = projectActivities.reduce(
		(
			groupedActivities: GroupedProjectActivities,
			projectActivity: ProjectActivity
		) => {
			let subGroup: any = groupedActivities;

			// We traverse the the grouping definition and add the levels if they don't yet exist (there might be another activity with the same values for the filters).
			groupingKeys.forEach(
				(
					groupingKey: ProjectActivityGroupDefinition,
					index: number,
					groupingKeysArray: Array<ProjectActivityGroupDefinition>
				) => {
					// Get the name of the current level
					const option: string = getActivityOptionName(
						projectActivity,
						groupingKey
					);

					// Get the children of the current level (if there are any)
					let children: any;

					if ( subGroup[ option ] && subGroup[ option ].children ) {
						children = subGroup[ option ].children;
					}

					// If we're at the end of the grouping definition, the last item is the activity itself
					if ( index === groupingKeysArray.length - 1 ) {
						// NB: The groupingKeys should be defined is such a way, that
						// the full path `category/sub-cat.../last-sub-cat` is unique for each item.
						// If not, the next activity with the same values will be overriding the previous one
						children = projectActivity;
					} else {
						if ( !children ) {
							children = {};
						}
					}

					if ( !subGroup[ option ] ) {
						subGroup[ option ] = {
							// name: projectActivity[ groupingKey.description ],
							// number: projectActivity[ groupingKey.number ],
							children,
						};
					}

					// We continue to the next level
					subGroup = children;
				}
			);

			return groupedActivities;
		},
		{}
	);

	return groupedProjectActivities;
};

export const getOptionsByFieldName = () => { };

export const getDestinationTextFieldCustomPlaceHolder = ( label: string ) =>
	label.toLowerCase() === 'name' ? 'Quick action name' : undefined;
