import React, { useCallback, useEffect } from 'react';
import { ProjectActivity } from '../../../types/api/timesheets';
import Dropdown from '../../controls/Dropdown';
import { ExpenseClaimProjectActivity } from './ManageExpenseClaim';
import { groupProjectActivities } from '../CreateDestination/destination-utils';
import { ProjectActivityGroupDefinition } from '../CreateDestination/DestinationFormTypes';
import clsx from 'clsx';
import { ExpenseClaim } from '../../../types/api/expenseClaims';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useTranslation from '../../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	errorMessage: {
		color: theme.palette.error.dark,
		padding: theme.spacing( 0, 0, 0, 2 ),
		fontSize: theme.spacing( 1.5 ),
	},
} ) );

const groupingKeys: Array<ProjectActivityGroupDefinition> = [
	{
		label: 'Project',
		number: 'projectNumber',
		description: 'projectDescription',
	},
	{
		label: 'Sub Project',
		number: 'SubprojectNumber',
		description: 'subProjectDescription',
	},
	{
		label: 'Project Phase',
		number: 'projectPhaseNumber',
		description: 'projectPhaseDescription',
	},
];

interface ProjectActivitiesProps {
	selectClass: string;
	selectBackground: string;
	projectActivities: Array<ProjectActivity>;
	selectedProjectData?: ExpenseClaimProjectActivity;
	setSelectedProjectData: ( data: ExpenseClaimProjectActivity ) => void;
	expenseClaimToEdit?: ExpenseClaim;
	autoExpand?: boolean;
}

const ProjectActivitiesMenu: React.FC<ProjectActivitiesProps> = ( {
	projectActivities,
	selectedProjectData,
	setSelectedProjectData,
	selectClass,
	selectBackground,
	expenseClaimToEdit,
} ) => {

	const groupedProjectActivities = groupProjectActivities(
		groupingKeys,
		projectActivities
	);

	const classes = useStyles();
	const t = useTranslation();

	const projectActivitiesOptions = Object.keys( groupedProjectActivities );

	const subProjectOptions =
		selectedProjectData?.projectDescription &&
			groupedProjectActivities[ selectedProjectData?.projectDescription ]
			? Object.keys(
				groupedProjectActivities[ selectedProjectData.projectDescription ]
					.children
			)
			: [];

	const projectPhaseOptions =
		selectedProjectData?.projectDescription &&
			selectedProjectData.subProjectDescription &&
			groupedProjectActivities[ selectedProjectData?.projectDescription ]
			? Object.keys(
				groupedProjectActivities[ selectedProjectData.projectDescription ]
					.children[ selectedProjectData.subProjectDescription ].children
			)
			: [];

	const onChangeValue = useCallback(
		( value, label ) => {
			if ( label === 'Project' ) {
				if ( value === '' ) {
					setSelectedProjectData( {} );
					return;
				}

				if ( value === selectedProjectData?.projectDescription ) {
					return;
				}

				if ( Object.keys( groupedProjectActivities[ value ].children ).length >= 1 ) {
					const subProjectDescription = Object.keys(
						groupedProjectActivities[ value ].children
					)[ 0 ];

					const projectPhaseData = Object.keys(
						groupedProjectActivities[ value ].children[ subProjectDescription ]
							.children
					);

					const groupedActivitiesData: any =
						groupedProjectActivities[ value ].children[ subProjectDescription ]
							.children[ projectPhaseData[ 0 ] ].children;

					const data: ExpenseClaimProjectActivity =
						projectPhaseData.length === 1
							? {
								projectNumber: groupedActivitiesData.projectNumber,
								subProjectNumber: groupedActivitiesData.SubprojectNumber,
								projectPhaseNumber: groupedActivitiesData.projectPhaseNumber,
							}
							: {};

					setSelectedProjectData( {
						projectDescription: value,
						subProjectDescription,
						projectPhaseDescription:
							projectPhaseData.length === 1 ? projectPhaseData[ 0 ] : undefined,
						...data,
					} );
					return;
				}

				setSelectedProjectData( { projectDescription: value } );
			}

			if ( label === 'Project phase' ) {
				setSelectedProjectData( {
					...selectedProjectData,
					projectPhaseDescription: value,
				} );
			}
		},
		[ setSelectedProjectData, selectedProjectData, groupedProjectActivities ]
	);

	useEffect( () => {
		if (
			projectPhaseOptions.length > 1 &&
			!selectedProjectData?.projectPhaseNumber &&
			selectedProjectData?.projectDescription &&
			selectedProjectData?.subProjectDescription
		) {
			const projectPhaseData: any =
				groupedProjectActivities[ selectedProjectData?.projectDescription ]
					.children[ selectedProjectData?.subProjectDescription ].children;

			const projectPhase =
				selectedProjectData.projectPhaseDescription ||
				Object.keys( projectPhaseData )[ 0 ];

			const groupedActivitiesData = projectPhaseData[ projectPhase ].children;

			const data: ExpenseClaimProjectActivity = {
				projectNumber: groupedActivitiesData.projectNumber,
				subProjectNumber: groupedActivitiesData.SubprojectNumber,
				projectPhaseNumber: groupedActivitiesData.projectPhaseNumber,
			};
			setSelectedProjectData( { ...selectedProjectData, ...data } );
		}
	} );

	return (
		<>
			<Dropdown
				autoExpand={ false }
				label="Project"
				options={ projectActivitiesOptions }
				onValueChange={ onChangeValue }
				selectedValue={
					selectedProjectData?.projectDescription ||
					expenseClaimToEdit?.projectDescription ||
					''
				}
				selectClass={
					Object.keys( groupedProjectActivities ).length > 1
						? selectClass
						: clsx( selectClass, selectBackground )
				}
			/>
			{ !(
				selectedProjectData?.projectDescription ||
				expenseClaimToEdit?.projectDescription
			) && (
					<Box className={ classes.errorMessage } paddingLeft={ 2 }>
						{ t( 'Project name is required' ) }
					</Box>
				) }
			{ subProjectOptions.length > 0 && (
				<Dropdown
					autoExpand={ !selectedProjectData?.subProjectDescription }
					label="Sub Project"
					options={ subProjectOptions }
					onValueChange={ onChangeValue }
					selectedValue={
						selectedProjectData?.subProjectDescription || subProjectOptions[ 0 ]
					}
					selectClass={
						subProjectOptions.length > 1
							? selectClass
							: clsx( selectClass, selectBackground )
					}
				/>
			) }
			{ projectPhaseOptions.length > 0 && (
				<Dropdown
					autoExpand={ !selectedProjectData?.projectPhaseNumber }
					label="Project phase"
					options={ projectPhaseOptions }
					onValueChange={ onChangeValue }
					selectedValue={
						selectedProjectData?.projectPhaseDescription ||
						projectPhaseOptions[ 0 ]
					}
					selectClass={
						projectPhaseOptions.length > 1
							? selectClass
							: clsx( selectClass, selectBackground )
					}
				/>
			) }
		</>
	);
};

export default ProjectActivitiesMenu;
