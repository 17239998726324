import { UserInfoAccount } from '../../../types/api/user';
import { WeekActivity, Timesheet } from '../../../types/api/timesheets';
import { AccountTimesheetHoursData } from './MinimalTimesheets';
import { getDecimalTimeString } from '../../../utils/date';

export const getAccountHoursData = (
	weekActivities: WeekActivity[],
	timesheets: Timesheet[],
	accountIndex: number,
	userAccount?: UserInfoAccount
): AccountTimesheetHoursData | undefined => {
	if ( !userAccount ) {
		return;
	}

	const currentAccountTimesheets = weekActivities.filter(
		( weekActivity ) =>
			weekActivity.details.employeeCode === userAccount.employeeCode
	);

	const selectedWeekTimesheets = timesheets.find(
		( pendingTimesheetsInfo ) =>
			pendingTimesheetsInfo.employeeCode === userAccount.employeeCode
	);

	let isInactiveForSelectedWeek = false;

	if ( !selectedWeekTimesheets?.timeSheetInfos.length ) {
		isInactiveForSelectedWeek = true;
	}

	const totalHoursThisWeek = Number(
		getDecimalTimeString(
			selectedWeekTimesheets?.timeSheetInfos[ 0 ]?.timeSpentWeekDays || '00:00',
			':'
		)
	);

	if ( !selectedWeekTimesheets?.timeSheetInfos.length ) {
		return {
			index: accountIndex,
			companyKey: userAccount.company,
			companyName: userAccount.companyName,
			employeeId: userAccount.employeeCode,
			contractHours: 0,
			totalHoursThisWeek,
			timeSpentWeekDays: '00:00',
			startOfEmployment: userAccount.employeeDetail.startOfEmployment,
			endOfEmployment: userAccount.employeeDetail.endOfEmployment,
			timesheets: currentAccountTimesheets,
			isInactiveForSelectedWeek: true,
		};
	}

	let contractHours = selectedWeekTimesheets?.timeSheetInfos[ 0 ].contractHours;

	if ( !contractHours ) {
		if ( userAccount?.employeeDetail.numberOfHours ) {
			contractHours = userAccount.employeeDetail.numberOfHours;
		} else {
			contractHours = 0;
		}
	}

	const timeSpentWeekDays =
		selectedWeekTimesheets?.timeSheetInfos[ 0 ]?.timeSpentWeekDays;

	return {
		index: accountIndex,
		companyKey: userAccount.company,
		companyName: userAccount.companyName,
		employeeId: userAccount.employeeCode,
		contractHours,
		timeSpentWeekDays,
		totalHoursThisWeek,
		startOfEmployment: userAccount.employeeDetail.startOfEmployment,
		endOfEmployment: userAccount.employeeDetail.endOfEmployment,
		timesheets: currentAccountTimesheets,
		isInactiveForSelectedWeek,
	};
};

