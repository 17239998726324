import React, { useEffect, useState } from 'react';
import {
	DialogTitle,
	DialogContentText,
	DialogContent,
	Dialog,
} from '@mui/material';
import { AppState } from '../../../reducers';
import { useSelector } from 'react-redux';
import useTranslation from '../../../hooks/useTranslation';
interface DeleteConfirmProps {
	open: boolean;
	onRefreshTimesheetData: () => void;
}

const UndoReleaseConfirm: React.FC<DeleteConfirmProps> = ( {
	onRefreshTimesheetData,
} ) => {
	const [ isUndoingRelease, setIsUndoingRelease ] = useState<boolean>( false );
	const { isLoadingUndo } = useSelector(
		( state: AppState ) => state.releasedTimesheetsState
	);

	useEffect( () => {
		if ( isLoadingUndo ) {
			setIsUndoingRelease( true );
		} else if ( isUndoingRelease ) {
			setIsUndoingRelease( false );
			onRefreshTimesheetData();
		}
	}, [ isLoadingUndo, isUndoingRelease, onRefreshTimesheetData ] );

	const t = useTranslation();

	return (
		<Dialog open={ isLoadingUndo && isUndoingRelease }>
			<DialogTitle>{ t( 'Undo release timesheet' ) }</DialogTitle>
			<DialogContent>
				<DialogContentText id="undo-release-timesheet">
					{ t( 'Loading' ) }
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
};

export default UndoReleaseConfirm;
