import { OvertimeOption } from '../../../hooks/useTimesheetReleaseOptions';

export const getOvertimeCompensationOptionsByType = (
	overtimeOptions: Array<OvertimeOption>,
	typeIndex: number,
	types: Array<string>
) =>
	// Return the selected option value (compensation id) if it was set.
	// If not - return the compensation id of the first item.
	overtimeOptions[ typeIndex ]
		? overtimeOptions[ typeIndex ].options[ types[ typeIndex ] ] ||
		overtimeOptions[ typeIndex ].options[
		Object.keys( overtimeOptions[ typeIndex ].options )[ 0 ]
		]
		: undefined;
