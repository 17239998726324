import React from 'react';
import { Box, Typography, Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useTranslation from '../../../hooks/useTranslation';

export interface SelectedTimePickerDayData {
	label: string;
	name: string;
}

export interface SelectedDayCheckboxProps {
	selectedDayData: SelectedTimePickerDayData;
	setSelectedDay: ( e: React.ChangeEvent<HTMLInputElement> ) => void;
	selectedDayName: string;
}

const useStyles = makeStyles( ( theme ) => ( {
	checkboxContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	dayTitle: {
		fontSize: theme.spacing( 1.5 ),
		fontWeight: 900,
	},
	checkbox: {
		fontSize: theme.spacing( 1.5 ),
		fontWeight: 900,
	},
} ) );

const SelectedDayCheckbox: React.FC<SelectedDayCheckboxProps> = ( {
	selectedDayData,
	setSelectedDay,
	selectedDayName,
} ) => {
	const classes = useStyles();
	const t = useTranslation();

	return (
		<Box className={ classes.checkboxContainer }>
			<Typography className={ classes.dayTitle }>
				{ t( selectedDayData.label ).slice( 0, 3 ) }
			</Typography>
			<Checkbox
				className={ classes.checkbox }
				color={ 'primary' }
				checked={ selectedDayName === selectedDayData.name ? true : undefined }
				inputProps={ { 'aria-label': selectedDayData.label } }
				name={ selectedDayData.name }
				onChange={ setSelectedDay }
			/>
		</Box>
	);
};

export default SelectedDayCheckbox;
