import React from 'react';

import { Box, Typography, Paper, Tooltip, SvgIconProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Add } from '@mui/icons-material';
import useTranslation from '../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	cardContainer: {
		margin: theme.spacing( 1.5, 8 ),
	},
	paper: {
		boxShadow: `0px 1px 16px ${ theme.palette.custom.boxShadow }`,
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.background.paper,
		opacity: '1.25',
		padding: theme.spacing( 1.25 ),
		cursor: 'pointer',
	},
	disabled: {
		opacity: 0.7,
		cursor: 'default',
	},
	icon: {
		color: theme.palette.primary.main,
		'&:hover': {
			color: theme.palette.primary.dark,
		},
	},
} ) );

export interface DesktopTemplateCardProps {
	title: string;
	toolTipTitle?: string;
	action: () => void;
	Icon?: ( props: SvgIconProps ) => JSX.Element;
	CustomSvg?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	disabled?: boolean;
}

const DesktopTemplateCard: React.FC<DesktopTemplateCardProps> = ( {
	title,
	action,
	toolTipTitle,
	Icon = Add,
	CustomSvg,
	disabled = false,
} ) => {
	const classes = useStyles();

	const paperClass = disabled
		? clsx( classes.paper, classes.disabled )
		: classes.paper;

	const cardAction = !disabled ? action : undefined;

	const t = useTranslation();

	return (
		<Box className={ classes.cardContainer }>
			<Paper className={ paperClass } onClick={ cardAction }>
				<Box display="flex">
					<Box flex="1" display="flex" alignItems="center">
						<Typography>{ t( title ) }</Typography>
					</Box>
					<Tooltip
						title={ t( toolTipTitle || '' ) || false }
						placement="top"
						disableHoverListener={ disabled || !toolTipTitle }
					>
						{ CustomSvg ? (
							<CustomSvg />
						) : (
							<Box paddingRight={ 1 }>
								<Icon className={ classes.icon } />
							</Box>
						) }
					</Tooltip>
				</Box>
			</Paper>
		</Box>
	);
};
export default DesktopTemplateCard;
