import React from 'react';

import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Chip from '../../controls/Chip';
import { getDestinationDescriptionProps } from './helpers';
import { Destination } from '../../../types/api/settings';
import { useSelector } from 'react-redux';
import { AppState } from '../../../reducers';
import useTranslation from '../../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	timesheetRow: {
		display: 'flex',
		padding: theme.spacing( 1, 2 ),
	},
	label: {
		display: 'flex',
		alignItems: 'center',
		flex: 1,
	},
	value: {
		flex: 1,
		textAlign: 'right',
	},
	company: {
		textAlign: 'center',
		fontWeight: 900,
		marginTop: theme.spacing( 1 ),
		fontSize: theme.spacing( 1.8 ),
	},
} ) );

interface DestinationDescriptionProps {
	destination: Destination;
	destinationName: string;
	customChipColor?: string;
}

const DestinationDescription: React.FC<DestinationDescriptionProps> = ( {
	destination,
	destinationName,
	customChipColor,
} ) => {
	const classes = useStyles();
	const {
		chipColor,
		moreInfoLabel,
		moreInfoText,
	} = getDestinationDescriptionProps( destination );

	const { userAccounts, companyName } = useSelector(
		( state: AppState ) => {
			const companyName = state.userState.selectedUser?.companyName
			const userAccounts = state.userState.accounts
			return { companyName, userAccounts }
		}
	);

	const t = useTranslation();

	return (
		<>
			{ userAccounts.length > 1 && companyName && (
				<Typography className={ classes.company }>{ companyName }</Typography>
			) }
			<Box className={ classes.timesheetRow }>
				<Box className={ classes.label }>
					<Typography>{ t( 'Destination' ) }</Typography>
				</Box>
				<Box className={ classes.value }>
					<Chip
						color={ chipColor }
						label={ destinationName }
						style={ { backgroundColor: customChipColor } }
					/>
				</Box>
			</Box>
			<Box className={ classes.timesheetRow }>
				<Box className={ classes.label }>
					<Typography>{ t( moreInfoLabel ) }</Typography>
				</Box>
				<Box className={ classes.value }>
					<Typography>{ moreInfoText }</Typography>
				</Box>
			</Box>
		</>
	);
};

export default DestinationDescription;
