import { UserInfoAccount } from "../types/api/user";
import { Action, ActionType } from "../types/actions";
import { CurrentTimesheetData } from "../types/api/timesheets";

interface CurrentTimesheetSuccessAction {
  type: string;
  currentTimesheets: Array<CurrentTimesheetData>;
}

export interface UserState {
  accounts: Array<UserInfoAccount>;
  isLoadingAccounts: boolean;
  currentTimesheets: Array<CurrentTimesheetData>;
  isLoadingCurrentTimesheets: boolean;
  photo: string;
  displayName: string;
  selectedUser?: UserInfoAccount;
  error: string;
}

export function userReducer(
  state: UserState = {
    accounts: [],
    isLoadingAccounts: false,
    currentTimesheets: [],
    isLoadingCurrentTimesheets: false,
    photo: "",
    displayName: "",
    selectedUser: undefined,
    error: "",
  },
  action: Action
): UserState {
  switch (action.type) {
    case ActionType.GET_USER_INFO_STARTED: {
      return {
        ...state,
        isLoadingAccounts: true,
        error: "",
      };
    }
    case ActionType.GET_USER_INFO_SUCCEEDED: {
      const { accounts, photo, displayName } = action.userInfo;

      return {
        ...state,
        accounts,
        photo,
        displayName,
        isLoadingAccounts: false,
        error: "",
      };
    }
    case ActionType.GET_USER_INFO_FAILED: {
      return {
        ...state,
        isLoadingAccounts: false,
        error: action.error,
      };
    }
    case ActionType.GET_CURRENT_TIME_SHEET_STARTED: {
      return {
        ...state,
        isLoadingCurrentTimesheets: true,
      };
    }
    case ActionType.GET_CURRENT_TIME_SHEET_FAILED: {
      return {
        ...state,
        isLoadingCurrentTimesheets: false,
      };
    }
    case ActionType.GET_CURRENT_TIME_SHEET_SUCCEEDED: {
      const { currentTimesheets } = action as CurrentTimesheetSuccessAction;

      return {
        ...state,
        isLoadingCurrentTimesheets: false,
        currentTimesheets,
      };
    }
    case ActionType.SET_SELECTED_USER: {
      const { selectedUser } = action;
      return {
        ...state,
        selectedUser,
      };
    }
    default:
      return state;
  }
}
