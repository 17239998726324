import { useSelector } from 'react-redux';
import { AppState } from '../reducers';

const useErrorData = () => {
	const error = useSelector<
		AppState,
		| {
			type: 'SETTINGS' | 'USER' | 'TIMESHEET';
			message: string;
		}
		| undefined
	>( ( state ) => {
		const timesheetError = state.timesheetsState.error;
		const settingsError = state.settingsState.error;
		const userError = state.userState.error;

		if ( userError ) {
			return {
				type: 'USER',
				message: userError,
			};
		}
		if ( settingsError ) {
			return {
				type: 'SETTINGS',
				message: settingsError,
			};
		}
		if ( timesheetError ) {
			return {
				type: 'TIMESHEET',
				message: timesheetError,
			};
		}
	} );

	return error;
};

export default useErrorData;

