import { useState, useCallback, useEffect } from 'react';
import api from '../middleware/api';
import useMsalBrowserAuth from './usseMsalBrowserAuth';

const useApiAuth = () => {
	const {
		account,
		error,
		isLoading,
		rawToken,
		signIn: onSignIn,
		signOut: onSignOut
	} = useMsalBrowserAuth();

	const [ hasTokenExpired, setHasTokenExpired ] = useState<boolean>( false );

	const onTokenExpired = useCallback( () => {
		setHasTokenExpired( true );
	}, [ setHasTokenExpired ] );

	// We set the token internally to the API so we don't have to pass rawToken with each request
	useEffect( () => {
		if ( rawToken ) {
			api._updateToken( rawToken, onTokenExpired );
			// Set that the token is up to date
			setHasTokenExpired( false );
		}
	}, [ rawToken, onTokenExpired ] );

	return {
		isLoading,
		error,
		rawToken: rawToken,
		account,
		onSignIn,
		onSignOut,
		hasTokenExpired
	}
}

export default useApiAuth;