import React from 'react';
import { Box } from '@mui/material';
import { PublicHoliday } from '../../../types/api/timesheets';
import PublicHolidaysTemplateCard from '../../PublicHolidaysTemplateCard';
import { DateTime } from 'luxon';

interface WeekHolidaysProps {
	weekHolidays: PublicHoliday[];
}

const WeekHolidays: React.FC<WeekHolidaysProps> = ( { weekHolidays } ) => (
	<Box>
		{ weekHolidays.reverse().map( ( weekHoliday, index ) => (
			<PublicHolidaysTemplateCard
				key={ index }
				title={ weekHoliday.description }
				day={ DateTime.fromJSDate(
					new Date( weekHoliday.date )
				).day }
			/>
		) ) }
	</Box>
);

export default WeekHolidays;
