import { Action, ActionType } from "../types/actions";

export interface PrintTimesheetState {
  isLoading: boolean;
  message?: string;
}

export const printTimesheetReducer = (
  state: PrintTimesheetState = {
    isLoading: false,
    message: undefined,
  },
  action: Action
): PrintTimesheetState => {
  switch (action.type) {
    case ActionType.PRINT_TIMESHEET_STARTED: {
      return {
        ...state,
        message: undefined,
        isLoading: true,
      };
    }
    case ActionType.PRINT_TIMESHEET_SUCCEEDED: {
      const { message } = action;
      return {
        ...state,
        message,
        isLoading: false,
      };
    }
    case ActionType.PRINT_TIMESHEET_FAILED: {
      return {
        ...state,
        message: undefined,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
