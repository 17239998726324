import api from '../../middleware/api';
import { AppState } from '../../reducers';
import {
	ExpenseClaimApprovalRoute,
	ExpenseClaimApprovalProgress,
	ExpenseClaimDetails,
} from '../../types/api/expenseClaims';

export const GET_EXPENSE_CLAIM_APPROVAL_ROUTE_STARTED =
	'GET_EXPENSE_CLAIM_APPROVAL_ROUTE_STARTED';
export function getExpenseClaimApprovalRouteStarted ( expenseClaimId: string ) {
	return {
		type: GET_EXPENSE_CLAIM_APPROVAL_ROUTE_STARTED,
		expenseClaimId,
	};
}

export const GET_EXPENSE_CLAIM_APPROVAL_ROUTE_SUCCEEDED =
	'GET_EXPENSE_CLAIM_APPROVAL_ROUTE_SUCCEEDED';
export function getExpenseClaimApprovalRouteSucceeded (
	approvalRoute: ExpenseClaimApprovalRoute,
	expenseClaimId: string
) {
	return {
		type: GET_EXPENSE_CLAIM_APPROVAL_ROUTE_SUCCEEDED,
		approvalRoute,
		expenseClaimId,
	};
}

export const GET_EXPENSE_CLAIM_APPROVAL_ROUTE_FAILED =
	'GET_EXPENSE_CLAIM_APPROVAL_ROUTE_FAILED';
export function getExpenseClaimsApprovalRouteFailed ( error: any ) {
	return {
		type: GET_EXPENSE_CLAIM_APPROVAL_ROUTE_FAILED,
		error,
	};
}

export const getExpenseClaimApprovalRoute = ( requestData: {
	employeeCode: string;
	id: string;
} ) => {
	return function ( dispatch: Function ) {
		dispatch( getExpenseClaimApprovalRouteStarted( requestData.id ) );
		api
			.getExpenseClaimApprovalRoute( requestData )
			.then( ( approvalRoute: ExpenseClaimApprovalRoute ) => {
				dispatch(
					getExpenseClaimApprovalRouteSucceeded( approvalRoute, requestData.id )
				);
			} )
			.catch( ( e: any ) => dispatch( getExpenseClaimsApprovalRouteFailed( e ) ) );
	};
};

export const GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_STARTED =
	'GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_STARTED';
export function getExpenseClaimApprovalProgressStarted ( expenseClaimId: string ) {
	return {
		type: GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_STARTED,
		expenseClaimId,
	};
}

export const GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_SUCCEEDED =
	'GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_SUCCEEDED';
export function getExpenseClaimApprovalProgressSucceeded (
	approvalProgress: ExpenseClaimApprovalProgress,
	expenseClaimId: string
) {
	return {
		type: GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_SUCCEEDED,
		approvalProgress,
		expenseClaimId,
	};
}

export const GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_FAILED =
	'GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_FAILED';
export function getExpenseClaimsApprovalProgressFailed ( error: any ) {
	return {
		type: GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_FAILED,
		error,
	};
}

export const getExpenseClaimApprovalProgress = ( requestData: {
	employeeCode: string;
	id: string;
} ) => {
	return function ( dispatch: Function ) {
		dispatch( getExpenseClaimApprovalProgressStarted( requestData.id ) );
		api
			.getExpenseClaimApprovalProgress( requestData )
			.then( ( approvalProgress: ExpenseClaimApprovalProgress ) => {
				dispatch(
					getExpenseClaimApprovalProgressSucceeded(
						approvalProgress,
						requestData.id
					)
				);
			} )
			.catch( ( e: any ) => dispatch( getExpenseClaimsApprovalProgressFailed( e ) ) );
	};
};

export const GET_EXPENSE_CLAIM_DETAILS_STARTED =
	'GET_EXPENSE_CLAIM_DETAILS_STARTED';
export function getExpenseClaimsDetailsStarted () {
	return {
		type: GET_EXPENSE_CLAIM_DETAILS_STARTED,
	};
}

export const GET_EXPENSE_CLAIM_DETAILS_SUCCEEDED =
	'GET_EXPENSE_CLAIM_DETAILS_SUCCEEDED';
export function getExpenseClaimDetailsSucceeded (
	details: Array<ExpenseClaimDetails>
) {
	return {
		type: GET_EXPENSE_CLAIM_DETAILS_SUCCEEDED,
		details,
	};
}

export const GET_EXPENSE_CLAIM_DETAILS_FAILED =
	'GET_EXPENSE_CLAIM_DETAILS_FAILED';
export function getExpenseClaimDetailsFailed (
	error: any,
	details: Array<ExpenseClaimDetails>
) {
	return {
		type: GET_EXPENSE_CLAIM_DETAILS_FAILED,
		details,
		error,
	};
}

export const getExpenseClaimDetails = ( requestData: {
	employeeCode: string;
	id: string;
} ) => {
	return function ( dispatch: Function, getState: () => AppState ) {
		const rootState = getState();

		const currentElement = rootState.expenseClaimDetailsState.details.find(
			( element ) => element.id === requestData.id
		);

		if (
			!currentElement?.approvalRoute ||
			currentElement.approvalProgress.length === 0
		) {
			dispatch( getExpenseClaimsDetailsStarted() );
			dispatch( getExpenseClaimApprovalProgress( requestData ) );
			dispatch( getExpenseClaimApprovalRoute( requestData ) );
		}
	};
};
