import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import QuickAction from '../../controls/QuickAction';
import DesktopTemplateCard from '../../DesktopTemplateCard';
import { ReactComponent as ExpenseIcon } from './../../../images/expense-icon.svg';

export interface ManageExpenseClaimsDesktopProps { }

const useStyles = makeStyles( theme => ( {
	expenseClaimsAction: {
		margin: theme.spacing( 1.5, 8 )
	}
} ) )

const ManageExpenseClaimsDesktop: React.FC<ManageExpenseClaimsDesktopProps> = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const classes = useStyles();

	const onCreateExpenseClaim = useCallback( () => {
		navigate( '/create-expense-claim', { state: { previous: location.pathname } } );
	}, [ navigate, location.pathname ] );

	const onSelectOverview = useCallback( () => {
		navigate( '/expense-claims', { state: { previous: location.pathname } } );
	}, [ navigate, location.pathname ] );

	return (
		<>
			<QuickAction
				title={ 'Expense claims overview' }
				tooltipTitle={ 'Alt+E' }
				onClick={ onSelectOverview }
				Icon={ ExpenseIcon }
				className={ classes.expenseClaimsAction }
			/>
			<DesktopTemplateCard
				title={ 'Add expense claim' }
				action={ onCreateExpenseClaim }
			/>
		</>
	);
};

export default ManageExpenseClaimsDesktop;
