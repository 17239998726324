import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import resources from './translations'

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use( initReactI18next ) // passes i18n down to react-i18next
	.init( {
		resources,
		lng: 'en',
		keySeparator: false,
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	} );

export default i18n;
