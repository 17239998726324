import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import DestinationDescription from './DestinationDescription';

import { getWeekDays } from '../../../utils/date';

import AddTimesheetHeader from '../../Headers/AddTimesheetHeader';

import { addTimesheet } from '../../../actions/api-actions';
import { TimesheetLineItem } from '../../../types/api/timesheets';

import CreateTimesheetForm from '../../controls/CreateTimesheet/CreateTimesheetForm';
import { AppState } from '../../../reducers';
import ManageTimesheetWrapper from './ManageTimeSheetWrapper';
import ManageTimesheetButton from './ManageTimesheetButton';
import useUserAccountTimesheet from '../../../hooks/useUserAccountTimesheet';
import { getRequestHours } from './helpers';
import FooterHint from '../../controls/FooterHint';
import useManageTimesheetAccount from '../../../hooks/useManageTimesheetAccount';
import useTranslation from '../../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	createTimesheetFormContainer: {
		height: 'calc(100vh - 175px)',
	},
	button: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		borderTopWidth: theme.spacing( 0.25 ),
		borderTopColor: theme.palette.custom.lightGray,
		borderRadius: 0,
		borderTopStyle: 'solid',
		boxSizing: 'border-box',
		padding: theme.spacing( 1.5 ),
		fontWeight: 900,
		'&:hover': {
			backgroundColor: theme.palette.background.paper,
		},
	},
} ) );

// Same as CreateTimesheet, but uses temp destination instead of one that is saved.
const CreateTimesheetTempDestination: React.FC = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const [ error, setError ] = useState<string>( '' );
	const selectedWeekRange = useSelector(
		( state: AppState ) => state.timeState.weekRange
	);

	const destination = useSelector(
		( state: AppState ) => state.tempDestinationState.destination
	);

	useEffect( () => {
		if ( destination === null ) {
			navigate( '/select-destination', {
				state: {
					previous: location.pathname,
				},
			} );
		}
	}, [ destination, navigate, location.pathname ] );

	const employeeCode = destination && destination.employeeCode;

	const { timesheet } = useUserAccountTimesheet(
		destination && destination.employeeCode
	);

	const timesheetInfos = useSelector(
		( state: AppState ) =>
			state.timesheetsState.timesheets.filter(
				( timesheetData ) => timesheetData.employeeCode === employeeCode
			)[ 0 ].timeSheetInfos[ 0 ]
	);

	useManageTimesheetAccount( destination?.employeeCode );

	const t = useTranslation();

	const onSubmitForm = useCallback(
		( data: TimesheetLineItem ) => {
			if ( timesheetInfos && destination !== null ) {
				const { error: hoursError, requestData } = getRequestHours(
					data,
					timesheet,
					t
				);

				if ( hoursError.message ) {
					setError( hoursError.message );
					return;
				} else if ( data.comment.length > 60 ) {
					setError( t( 'Maximum comment length is 60 symbols' ) );
					return;
				}

				const timesheetLineItem: TimesheetLineItem =
					destination.type !== 'Project'
						? {
							...requestData,
							projectNumber: '',
							activityCode: destination.activity.activityCode,
						}
						: {
							...requestData,
							activityCode: destination.activity.componentCode,
							projectNumber: destination.activity.projectNumber.toString(),
							subProjectNumber:
								destination.activity.SubprojectNumber.toString(),
							projectPhaseNumber:
								destination.activity.projectPhaseNumber.toString(),
							subTaskNumber: destination.activity.subTaskNumber.toString(),
						};

				dispatch(
					addTimesheet( {
						year: timesheetInfos.year,
						week: timesheetInfos.week,
						employeeCode: destination.employeeCode,
						addLineItemRequestRecords: [ timesheetLineItem ],
					} )
				);

				navigate( '/', {
					state: {
						previous: location.pathname,
					},
				} );
			} else {
				console.error( 'No week selected' );
			}
		},
		[
			destination,
			dispatch,
			navigate,
			timesheetInfos,
			timesheet,
			t,
			location.pathname,
		]
	);

	if ( !selectedWeekRange || !destination ) {
		return (
			<Box>
				<AddTimesheetHeader />
				<Box p={ 2 } textAlign="center">
					<Typography>{ 'Loading...' }</Typography>
				</Box>
			</Box>
		);
	}

	if ( !destination ) {
		navigate( '/select-destination', {
			state: {
				previous: location.pathname,
			},
		} );
	}

	const weekDays = getWeekDays( selectedWeekRange.from );

	const destinationName = `${ destination.activity.activityCode } - ${ destination.activity.componentSearchKey ||
		destination.activity.activityDescription.toUpperCase()
		}`;

	return (
		<>
			<ManageTimesheetWrapper>
				<AddTimesheetHeader />
				<Box
					className={ classes.createTimesheetFormContainer }
					overflow="auto"
					p={ 2 }
					paddingTop={ 0 }
				>
					<DestinationDescription
						destination={ destination }
						destinationName={ destinationName }
					/>
					<CreateTimesheetForm
						onSubmit={ onSubmitForm }
						weekDays={ weekDays }
						employeeCode={ employeeCode }
					/>
				</Box>
			</ManageTimesheetWrapper>
			<ManageTimesheetButton title="Save changes" />
			{ error && (
				<FooterHint
					errorText={ error }
					onCloseError={ () => {
						setError( '' );
					} }
					hint=""
				/>
			) }
		</>
	);
};

export default CreateTimesheetTempDestination;
