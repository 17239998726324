import React, { useCallback } from 'react';
import { Box, Menu, MenuItem, Avatar, Typography, Divider, IconButton } from '@mui/material/';
import makeStyles from '@mui/styles/makeStyles';
import { CSSProperties } from '@mui/styles';
import { UserInfoAccount } from '../../types/api/user';

import { ReactComponent as LogOutIcon } from './../../images/_ionicons_svg_md-log-out.svg';
import { AppState } from '../../reducers';
import { useSelector } from 'react-redux';
import useTranslation from '../../hooks/useTranslation';
import { ExpandMore } from '@mui/icons-material';

const useStyles = makeStyles( ( theme ) => {
	const menuItem: CSSProperties = {
		padding: theme.spacing( 1 ),
		'&:nth-child(2)': {
			marginBottom: theme.spacing( 1 ),
		},
	};

	return {
		avatar: {
			width: theme.spacing( 7 ),
			height: theme.spacing( 7 ),
			margin: theme.spacing( 0, 0, 0, 1 ),
		},
		menu: {
			display: 'flex',
			alignItems: 'center',
			'&:hover': {
				filter: 'brightness(90%)',
				cursor: 'pointer',
				fontWeight: 900,
			},
			textAlign: 'right',
		},
		menuPaper: {
			borderStyle: 'solid',
			borderColor: theme.palette.custom.paper,
			padding: 0,
		},
		menuList: { padding: theme.spacing( 0.5, 0, 0 ) },
		menuItem: {
			...menuItem,
		},
		logOut: {
			...menuItem,
			display: 'flex',
			justifyContent: 'space-between',
			margin: theme.spacing( 1, 0, 0 ),
			fontSize: theme.spacing( 1.75 ),
			padding: theme.spacing( 1 ),
			'&:hover': {
				backgroundColor: theme.palette.primary.light,
				color: theme.palette.primary.contrastText,
				'&>svg': {
					color: theme.palette.primary.contrastText,
				},
			},
			'&>svg': {
				color: theme.palette.text.disabled,
			},
		},
		selectedAccount: {
			...menuItem,
			fontWeight: 900,
		},
		userInfo: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			padding: theme.spacing( 0, 0, 0, 1 ),
		},
		displayName: {
			fontSize: theme.spacing( 1.7 ),
			color: theme.palette.text.primary,
			fontWeight: 700,
		},
		companyName: {
			fontSize: theme.spacing( 1.6 ),
			color: theme.palette.text.primary,
		},
	};
} );

export interface AccountMenuProps {
	userAccounts: UserInfoAccount[];
	onSelectAccount: ( companyName: any ) => void;
	selectedCompany?: string;
	onLogout: () => void;
}

const AccountMenu: React.FC<AccountMenuProps> = ( {
	userAccounts,
	onSelectAccount,
	selectedCompany,
	onLogout,
} ) => {
	const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>( null );

	const classes = useStyles();

	const onOpenMenuClick = useCallback(
		( event: React.MouseEvent<HTMLDivElement> ) => {
			setAnchorEl( event.currentTarget );
		},
		[]
	);

	const handleClose = useCallback( () => {
		setAnchorEl( null );
	}, [] );

	const onLogoutClick = useCallback( () => {
		handleClose();
		onLogout();
	}, [ onLogout, handleClose ] );

	const onAccountClick = useCallback(
		( e ) => {
			onSelectAccount( e.target.textContent );
			handleClose();
		},
		[ onSelectAccount, handleClose ]
	);

	const { photo: imageUrl, displayName } = useSelector(
		( state: AppState ) => state.userState
	);

	const t = useTranslation();

	return (
		<Box>
			<Box className={ classes.menu } onClick={ onOpenMenuClick } >
				<Avatar
					sizes="small"
					className={ classes.avatar }
					src={ `data:image;base64,${ imageUrl }` }
				/>
				<Box className={ classes.userInfo }>
					<Typography className={ classes.displayName }>{ displayName }</Typography>
					<Typography className={ classes.companyName }>
						{ selectedCompany }
					</Typography>
				</Box>
				<IconButton size="small">
					<ExpandMore fill='currentColor' />
				</IconButton>
			</Box>
			<Menu
				id="account-menu"
				anchorEl={ anchorEl }
				keepMounted
				open={ Boolean( anchorEl ) }
				elevation={ 0 }

				anchorOrigin={ {
					vertical: 'bottom',
					horizontal: 'center',
				} }
				transformOrigin={ {
					vertical: 'top',
					horizontal: 'center',
				} }
				classes={ { paper: classes.menuPaper, list: classes.menuList } }
				onClose={ handleClose }
			>
				{ userAccounts.map( ( account ) => (
					<MenuItem
						onClick={ onAccountClick }
						key={ account.companyName }
						className={
							selectedCompany === account.companyName
								? classes.selectedAccount
								: classes.menuItem
						}
					>
						{ account.companyName }
					</MenuItem>
				) ) }
				<Divider></Divider>
				<MenuItem onClick={ onLogoutClick } className={ classes.logOut }>
					{ t( 'Logout' ) }
					<LogOutIcon fill={ 'currentColor' } width={ 18 } height={ 18 } />
				</MenuItem>
			</Menu>
		</Box>
	);
};

export default AccountMenu;
