import { Action, ActionType } from "../types/actions";
import { AppSettings } from "../types/api/settings";

export interface SettingsState {
	settings: AppSettings;
	error: string;
	isLoading: boolean;
}

export function settingsReducer (
	state: any = {
		settings: {},
		error: "",
		isLoading: false,
	},
	action: Action
): SettingsState {
	switch ( action.type ) {
		case ActionType.GET_SETTINGS_SUCCEEDED: {
			let settings: any = action.settings?.settings;

			if ( settings && settings.printOptions ) {
				settings.printOptions = JSON.parse( settings.printOptions );
			}

			if ( settings && settings.destinations ) {
				settings.destinations = JSON.parse( settings.destinations );
			} else {
				settings = {
					destinations: {},
				};
			}

			return {
				...state,
				settings,
				isLoading: false,
				error: "",
			};
		}
		case ActionType.SAVE_SETTINGS_SUCCEEDED: {
			return {
				...state,
				settings: action.settings,
				isLoading: false,
				error: "",
			};
		}
		case ActionType.GET_SETTINGS_STARTED:
		case ActionType.SAVE_SETTINGS_STARTED:
			return {
				...state,
				isLoading: true,
				error: "",
			};
		case ActionType.SAVE_SETTINGS_FAILED:
		case ActionType.GET_SETTINGS_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.error,
			};
		case ActionType.CLEAR_SETTINGS_SUCCEEDED:
			return {
				...state,
				settings: {},
				isLoading: false,
				error: "",
			};
		default:
			return state;
	}
}
