import React from 'react';
import {
    Dialog,
    Avatar,
    DialogTitle,
    DialogContent,
    Box,
    Slide,
    SlideProps,
    CircularProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Image, PictureAsPdf } from '@mui/icons-material';
import useTranslation from '../hooks/useTranslation';
import PDFViewer from './PDFViewer';

interface FileViewProps {
	base64String?: string;
	fileName: string;
	onClick?: () => void;
	isLoading?: boolean;
}

const Transition = React.forwardRef<unknown, SlideProps>( function Transition (
	props,
	ref
) {
	return <Slide direction="up" ref={ ref } { ...props } />;
} );

const useStyles = makeStyles( ( theme ) => ( {
	image: {
		width: '100%',
		height: 'auto',
		borderStyle: 'solid',
		borderWidth: theme.spacing( 1.5 ),
		borderColor: theme.palette.text.disabled,
		borderRadius: theme.spacing( 2.5 ),
	},
	imageAvatar: {
		width: '25px',
		height: '25px',
		cursor: 'pointer',
	},
	content: {
		display: 'flex',
		justifyContent: 'center',
	},
} ) );

const FileView: React.FC<FileViewProps> = ( {
	base64String = '',
	fileName,
	onClick,
	isLoading = false,
} ) => {
	const classes = useStyles();
	const [ open, setOpen ] = React.useState( false );

	const handleClickOpen = () => {
		onClick && onClick();

		setOpen( true );
	};

	const handleClose = () => {
		setOpen( false );
	};

	const t = useTranslation();

	const file = `data:image;base64,${ base64String }`;

	const isPDF = fileName.includes( '.pdf' );

	return (
		<Box>
			{ isLoading ? (
				<Box
					width={ 25 }
					height={ 25 }
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<CircularProgress size={ 12 } />
				</Box>
			) : (
				<Avatar
					src={ !isPDF ? file : undefined }
					onClick={ handleClickOpen }
					className={ classes.imageAvatar }
					variant="square"
				>
					{ isPDF ? <PictureAsPdf /> : <Image /> }
				</Avatar>
			) }
			<Dialog
				open={ open && !!base64String }
				TransitionComponent={ Transition }
				keepMounted
				onClose={ handleClose }
				aria-describedby="file-view-dialog"
				fullWidth
				maxWidth="lg"
			>
				<DialogTitle id="alert-dialog-slide-title">
					{ `${ t( 'File name' ) }: ${ fileName }` }
				</DialogTitle>
				<DialogContent className={ classes.content }>
					{ isPDF && base64String ? (
						<PDFViewer base64String={ base64String } />
					) : (
						<img src={ file } alt="file-preview" className={ classes.image } />
					) }
				</DialogContent>
			</Dialog>
		</Box>
	);
};

export default FileView;
