import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import clsx from 'clsx';

import ExpenseClaimHistory from './ExpenseClaimHistory';
import ExpenseClaimDetails from './ExpenseClaimDetails';
import ManageExpenseClaim from '../ManageExpenseClaims/ManageExpenseClaim';
import OverlayLoader from '../../OverlayLoader';
import {
	getExpenseClaimDetails,
	getExpenseClaims,
} from '../../../actions/api-actions';
import useSelectedAccount from '../../../hooks/useSelectedAccount';
import ScreenWrapper from '../ScreenWrapper';
import useExpenseClaimsData from '../../../hooks/useExpenseClaimsData';
import useTranslation from '../../../hooks/useTranslation';
import { getYearRange } from '../../../utils/date';
import { currentYear } from '../../controls/Minimal/helpers';

export interface ExpenseClaimOptionsProps { }

const useStyles = makeStyles( ( theme ) => ( {
	tab: {
		textAlign: 'center',
		backgroundColor: theme.palette.custom.paper,
		color: theme.palette.custom.gray,
		padding: theme.spacing( 1.75, 2, 1.75, 2 ),
		borderWidth: theme.spacing( 0.25, 0, 0.25, 0 ),
		borderStyle: 'solid',
		borderColor: theme.palette.custom.paper,
		borderTopColor: theme.palette.primary.main,
		textTransform: 'capitalize',
	},
	disabledTab: {
		color: theme.palette.text.disabled,
	},
	selectedTab: {
		fontWeight: 900,
		color: theme.palette.primary.light,
		borderBottomColor: theme.palette.primary.light,
	},
	errorMessage: {
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.secondary.main,
	},
} ) );

const detailsTabs = [ 'Details', 'History', 'Edit' ];

const ExpenseClaimOptions: React.FC<ExpenseClaimOptionsProps> = () => {
	const classes = useStyles();

	const [ selectedTab, setSelectedTab ] =
		useState<'Edit' | 'Details' | 'History'>( 'Details' );

	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();

	const {
		selectedExpenseClaim: expenseClaim,
		expenseClaims,
		isLoadingExpenseClaims: isLoading,
		approvalRoute,
	} = useExpenseClaimsData( id );
	const isDisabled = expenseClaim?.status?.id !== '0';

	const onSelectTab = useCallback(
		( tab ) => {
			if ( isDisabled && tab === 'Edit' ) {
				return;
			}
			setSelectedTab( tab );
		},
		[ isDisabled ]
	);

	const { initialAccount } = useSelectedAccount();
	const employeeCode = initialAccount?.employeeCode;

	const selectedTabClass = clsx( classes.tab, classes.selectedTab );

	const tabClass = !isDisabled
		? classes.tab
		: clsx( classes.tab, classes.disabledTab );

	useEffect( () => {
		if ( id && !expenseClaim && employeeCode ) {
			const range = getYearRange( currentYear );
			dispatch( getExpenseClaims( employeeCode, range ) );
		}
	}, [ expenseClaim, id, dispatch, employeeCode ] );

	const expenseClaimsLength = expenseClaims?.length || 0;

	useEffect( () => {
		if ( id && employeeCode && expenseClaimsLength !== 0 && !approvalRoute ) {
			dispatch(
				getExpenseClaimDetails( {
					employeeCode,
					id,
				} )
			);
		}
	}, [ dispatch, employeeCode, id, expenseClaimsLength, approvalRoute ] );

	const t = useTranslation();

	return (
		<>
			<Box>
				<OverlayLoader loadingCondition={ isLoading } message={ 'Loading' } />
			</Box>
			{ !isLoading && expenseClaim && (
				<ScreenWrapper customTitle={ t( 'Expense Claim' ) } withHeader>
					<>
						<Box display="flex">
							{ detailsTabs.map( ( tab ) => {
								return (
									<Box flex="1" key={ tab }>
										<Typography
											key={ tab }
											className={
												tab === selectedTab
													? selectedTabClass
													: tab === 'Edit'
														? tabClass
														: classes.tab
											}
											onClick={ () => onSelectTab( tab ) }
										>
											{ t( tab ) }
										</Typography>
									</Box>
								);
							} ) }
						</Box>
						{ selectedTab === 'Edit' && <ManageExpenseClaim /> }
						{ selectedTab === 'Details' && <ExpenseClaimDetails /> }
						{ selectedTab === 'History' && <ExpenseClaimHistory id={ id } /> }
					</>
				</ScreenWrapper>
			) }
		</>
	);
};

export default ExpenseClaimOptions;
