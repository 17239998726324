import React, {
	// useCallback,
	// useState,
} from 'react';

import {
	Box,
	List,
	ListItem,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { TextsmsOutlined as CommentIcon } from '@mui/icons-material';

import { getStyles, useStyles } from './week-timesheet-styles';

import {
	getBigCalendarTimesheetDetails,
	getTimesheetHourProp,
	getNegativeHoursLabel,
} from '../../Desktop/desktop-utils';

import { TotalHoursGroup } from '../../Desktop/DesktopLayout';

import { workingHours } from '../../../config/constants';

import { WeekActivity, PublicHoliday } from '../../../types/api/timesheets';
import { ManageWorkingHoursProp } from '../../../types/api/common';
import usePublicHolidays from '../../../hooks/usePublicHolidays';
import { DateTime } from 'luxon';
import {
	convertDecimalToTimeString,
	convertMinutesToTimeString,
	days,
} from '../../../utils/date';
import useTranslation from '../../../hooks/useTranslation';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { AppState } from '../../../reducers';

export interface ListItemTimesheetProps {
	label: string;
	rawTime: number;
	activityName?: string;
	activityDetails?: string;
	timesheetId?: string;
	comment?: string;
}

export interface WeekTimesheetListProps {
	weekDayProp: ManageWorkingHoursProp;
	currentAccountWeekActivities: Array<WeekActivity>;
	totalHoursByDay: TotalHoursGroup;
	onOpenTimesheetManagement: (
		hours: number,
		dayProp: string,
		templateName?: string,
		timesheetId?: string
	) => void;
}

const WeekTimesheetList: React.FC<WeekTimesheetListProps> = ( {
	weekDayProp,
	currentAccountWeekActivities,
	totalHoursByDay,
	onOpenTimesheetManagement,
} ) => {
	const classes = useStyles();
	const theme = useTheme();
	// const [ currentWorkingHours, setWorkingHours ] = useState( workingHours );

	const { currentWeekHolidays } = usePublicHolidays();
	const selectedWeekRange = useSelector(
		( state: AppState ) => state.timeState.weekRange
	);

	const holidays: any = currentWeekHolidays.reduce(
		( holidayProp: {}, holidayData: PublicHoliday ) => {
			const prop = DateTime.fromJSDate( new Date( holidayData.date ) )
				.toFormat( 'EEEE' )
				.toLowerCase();
			return { ...holidayProp, [ prop ]: holidayData.description };
		},
		{}
	);

	const weekDayData =
		selectedWeekRange &&
		days
			.map( ( weekDay, dayIndex ) => ( {
				dayNumber: DateTime.fromJSDate( selectedWeekRange?.from )
					.startOf( 'week' )
					.plus( {
						day: dayIndex,
					} ).day,
				monthNumber: DateTime.fromJSDate( selectedWeekRange?.from ).plus( {
					day: dayIndex,
				} ).month,
				weekDay,
				weekDayNumber: DateTime.fromJSDate( selectedWeekRange?.from )
					.startOf( 'week' )
					.plus( {
						day: dayIndex,
					} ).weekday,
			} ) )
			.find( ( day ) => day.weekDay === weekDayProp.label );

	const weekDayDataText = weekDayData
		? `${ weekDayData.dayNumber }/${ weekDayData.monthNumber }`
		: '';
	const weekDaySectionText = holidays[ weekDayProp.label ]
		? `${ weekDayDataText } (${ holidays[ weekDayProp.label ] })`
		: weekDayDataText;

	const t = useTranslation();

	const hasDisabledDays =
		selectedWeekRange &&
		DateTime.fromJSDate( selectedWeekRange?.from ).startOf( 'day' ) >
		DateTime.fromJSDate( selectedWeekRange?.from ).startOf( 'week' );

	const disabledFromDay =
		selectedWeekRange && hasDisabledDays
			? DateTime.fromJSDate( selectedWeekRange?.from ).startOf( 'day' ).weekday - 1
			: undefined;

	// This code is commented out for future improvements of the desktop grid onMouseEnter - method

	// const onSelectLastListItem = useCallback(
	// 	( index: number, numberOfLoggedHours: number, isLastListItem ) => {
	// 		if (
	// 			numberOfLoggedHours < workingHours &&
	// 			isLastListItem &&
	// 			index / 2 + 1 < 16 &&
	// 			numberOfLoggedHours === 0
	// 		) {
	// 			setWorkingHours( index / 2 + 1 );
	// 		} else if (
	// 			( numberOfLoggedHours >= workingHours || numberOfLoggedHours > 0 ) &&
	// 			currentWorkingHours < 16 &&
	// 			isLastListItem
	// 		) {
	// 			setWorkingHours( ( hours ) => hours + 1 );
	// 		} else if (
	// 			!isLastListItem &&
	// 			numberOfLoggedHours === 0 &&
	// 			index / 2 + 0.5 < currentWorkingHours &&
	// 			currentWorkingHours > 8
	// 		) {
	// 			setWorkingHours( index / 2 );
	// 		} else if ( !isLastListItem && index / 2 < workingHours && numberOfLoggedHours > 0 ) {
	// 			setWorkingHours( 8 );
	// 		} else if (
	// 			( numberOfLoggedHours >= workingHours || numberOfLoggedHours > 0 ) &&
	// 			!isLastListItem &&
	// 			index / 2 + 0.5 < currentWorkingHours &&
	// 			currentWorkingHours > 8
	// 		) {
	// 			// setWorkingHours( index / 2 );
	// 		}
	// 	},
	// 	[ currentWorkingHours ]
	// );

	return (
		<List className={ classes.timesheetList }>
			<Typography
				className={
					holidays[ weekDayProp.label ] ? classes.holiday : classes.weekDayData
				}
				key={ `day-data-${ weekDayProp.label }` }
			>
				{ weekDaySectionText }
			</Typography>
			<Typography
				key={ `weekday-${ weekDayProp.label }` }
				className={ classes.weekDays }
			>
				{ t( weekDayProp.label ).slice( 0, 3 ) }
				{ ' - ' }
				{ convertMinutesToTimeString(
					currentAccountWeekActivities
						.map( ( activity ) => {
							const time = convertDecimalToTimeString(
								activity.details[ getTimesheetHourProp( weekDayProp.name ) ]
							).split( ':' );
							const minutes = Number( time[ 0 ] ) * 60 + Number( time[ 1 ] );
							return minutes;
						} )
						.reduce( ( sum, currentElement ) => sum + currentElement, 0 )
				) }{ ' ' }
				{ t( 'hrs' ) }
			</Typography>
			{ currentAccountWeekActivities
				.filter(
					( activity ) =>
						activity.details[ getTimesheetHourProp( weekDayProp.name ) ] < 0
				)
				.map( ( activity ) => (
					<ListItem
						key={ `negative-${ weekDayProp.name }-${ activity.details.id }` }
						style={ {
							borderRadius: theme.spacing( 1 ),
							borderColor: theme.palette.custom.darkBlueGray,
							color: theme.palette.primary.contrastText,
							backgroundColor: theme.palette.custom.darkBlueGray,
							display: 'flex',
							justifyContent: 'space-between',
							fontWeight: 600,
							opacity: 0.9,
							padding: theme.spacing( 0.75 ),
						} }
						onClick={
							activity.details.status.desc === 'Draft'
								? () =>
									onOpenTimesheetManagement(
										0,
										weekDayProp.name,
										activity.name,
										activity.details.id
									)
								: undefined
						}
					>
						<Box className={ classes.activityData }>
							{ activity.details.comment && (
								<Tooltip
									title={ activity.details.comment || 'N/A' }
									placement="top"
								>
									<CommentIcon />
								</Tooltip>
							) }
							<Typography className={ classes.shortActivity }>
								{ getNegativeHoursLabel( activity, weekDayProp ) }
							</Typography>
						</Box>
					</ListItem>
				) ) }
			{ currentAccountWeekActivities.map( ( timesheet ) =>
				getBigCalendarTimesheetDetails(
					Math.abs( timesheet.details[ getTimesheetHourProp( weekDayProp.name ) ] ),
					timesheet.details[ getTimesheetHourProp( weekDayProp.name ) ] < 0,
					timesheet
				)
					.filter(
						( listItemElement: ListItemTimesheetProps ) =>
							listItemElement.rawTime >= 0
					)
					.map(
						(
							listItemElement: ListItemTimesheetProps,
							arrayIndex: number,
							array: ListItemTimesheetProps[]
						) => {
							return (
								<Box
									key={ `label-${ weekDayProp.label }-${ listItemElement.rawTime }-${ listItemElement.timesheetId }` }
								// onMouseEnter={ () => {
								// 	onSelectLastListItem(
								// 		arrayIndex,
								// 		totalHoursByDay[ weekDayProp.name ],
								// 		arrayIndex === array.length - 1
								// 	);
								// } }
								>
									<Box className={ classes.hoursItem }>
										{ arrayIndex === 0 && (
											<Box
												className={
													timesheet.details.status.desc !== 'Draft'
														? clsx( classes.description, classes.released )
														: classes.description
												}
												onClick={
													timesheet.details.status.desc === 'Draft'
														? () =>
															onOpenTimesheetManagement(
																listItemElement.rawTime,
																weekDayProp.name,
																timesheet.name,
																timesheet.details.id
															)
														: undefined
												}
											>
												{ array.length > 3 ? (
													<Box className={ classes.activityData }>
														{ listItemElement.comment && (
															<Tooltip
																title={ listItemElement.comment || 'N/A' }
																placement="top"
															>
																<CommentIcon />
															</Tooltip>
														) }
														<Box className={ classes.activityTextBox }>
															<Typography className={ classes.activityName }>
																{ listItemElement.activityName }
															</Typography>
															<Typography className={ classes.activityDesc }>
																{ listItemElement.activityDetails }
															</Typography>
														</Box>
													</Box>
												) : (
													<Box className={ classes.activityData }>
														{ listItemElement.comment && (
															<Tooltip
																title={ listItemElement.comment || 'N/A' }
																placement="top"
															>
																<CommentIcon />
															</Tooltip>
														) }
														<Typography className={ classes.shortActivity }>
															{ listItemElement.label }
														</Typography>
													</Box>
												) }
											</Box>
										) }
									</Box>
									<ListItem
										style={ getStyles(
											theme,
											timesheet.destination,
											arrayIndex,
											array.length - 1,
											timesheet.details.status.desc !== 'Draft',
											timesheet.templateColor
										) }
										onClick={
											timesheet.details.status.desc === 'Draft'
												? () =>
													onOpenTimesheetManagement(
														listItemElement.rawTime,
														weekDayProp.name,
														timesheet.name,
														timesheet.details.id
													)
												: undefined
										}
										className={ classes.hoursItem }
									>
										{ arrayIndex > 3 || listItemElement.rawTime > 1.5
											? listItemElement.label
											: '' }
									</ListItem>
								</Box>
							);
						}
					)
			) }
			{ getBigCalendarTimesheetDetails(
				totalHoursByDay[ weekDayProp.name ] <= workingHours
					? workingHours - Math.abs( totalHoursByDay[ weekDayProp.name ] )
					: 0,
				totalHoursByDay[ weekDayProp.name ] < 0
			).map( ( listItemElement: ListItemTimesheetProps, index, array ) => {
				return (
					<ListItem
						key={ `not-event-${ weekDayProp.label }-${ listItemElement.label }-${ listItemElement.timesheetId }` }
						style={ {
							height: `calc((100vh - 22rem)/16)`,
							// color: arrayIndex === 0 ? theme.palette.text.disabled : 'none',
							// display: 'flex',
							// justifyContent: 'flex-end',
							// fontWeight: 900,
						} }
						onClick={ () => {
							if (
								( !!disabledFromDay &&
									weekDayData &&
									weekDayData?.weekDayNumber > disabledFromDay ) ||
								!disabledFromDay
							) {
								onOpenTimesheetManagement(
									listItemElement.rawTime,
									weekDayProp.name
								);
							}
						} }
						className={ classes.hoursItem }
					// onMouseEnter={ () => {
					// 	onSelectLastListItem(
					// 		index,
					// 		totalHoursByDay[ weekDayProp.name ],
					// 		index === array.length - 1
					// 	);
					// } }
					>
						{ listItemElement.label }
					</ListItem>
				);
			} ) }
		</List>
	);
};

export default WeekTimesheetList;
