import React from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useTranslation from '../hooks/useTranslation';

interface ScreenMessageProps {
	message: string;
	messageProps?: Object;
	className?: string;
}

const useStyles = makeStyles( ( theme ) => ( {
	defaultMessageStyle: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
	},
} ) );

const ScreenMessage: React.FC<ScreenMessageProps> = ( {
	message,
	messageProps,
	className,
} ) => {
	const classes = useStyles();
	const t = useTranslation();

	return (
		<Box p={ 2 } className={ className || classes.defaultMessageStyle }>
			<Typography>
				{ messageProps
					? t( message, {
						...messageProps,
					} )
					: t( message ) }
			</Typography>
		</Box>
	);
};

export default ScreenMessage;
