import { useEffect, useState } from 'react';

import useExpenseClaimsData from './useExpenseClaimsData';
import { ExpenseClaimProjectActivity } from '../components/screens/ManageExpenseClaims/ManageExpenseClaimDesktop';
import { DateTime } from 'luxon';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import useSelectedAccount from './useSelectedAccount';

const useManageExpenseClaimsData = ( id?: string ) => {
	const [ date, setDate ] = useState<MaterialUiPickersDate>(
		DateTime.fromJSDate( new Date() )
	);

	const [ codesCategory, setCodesCategory ] = useState<string>();
	const [ isActiveDropDown, setIsActiveDropDown ] = useState<boolean>( false );

	const [
		selectedProjectData,
		setSelectedProjectData,
	] = useState<ExpenseClaimProjectActivity>();

	const [
		descriptionExpenseClaimCode,
		setDescriptionExpenseClaimCode,
	] = useState<string>();

	const { initialAccount } = useSelectedAccount();

	const employeeCode = initialAccount?.employeeCode;

	const {
		selectedExpenseClaim: expenseClaimToEdit,
		expenseClaimsCodes: codes,
		projectActivities: activities,
		isLoadingExpenseClaims,
		isLoadingSources,
		expenseClaimsYear,
		isLoadingExpenseClaim
	} = useExpenseClaimsData( id );

	const expenseClaimsCodes = codes
		?.find( ( codeData ) => codeData.employeeCode === employeeCode )
		?.expenseClaimCodes.filter(
			( expenseClaimCode ) => id || expenseClaimCode.category === codesCategory
		);

	const expenseClaimsCodesDescriptions = expenseClaimsCodes?.map(
		( c ) => c.DescriptionExpenseClaimCode
	);

	const defaultExpenseClaimDescription = expenseClaimsCodes?.find(
		( c ) => c.ExpenseClaimCode === expenseClaimToEdit?.expenseClaimCode
	)?.DescriptionExpenseClaimCode;
	const defaultExpenseClaimType = expenseClaimsCodes?.find(
		( c ) => c.ExpenseClaimCode === expenseClaimToEdit?.expenseClaimCode
	)?.type;

	const defaultExpenseClaimCode = expenseClaimToEdit?.expenseClaimCode;

	const isLoading = isLoadingExpenseClaims || isLoadingSources;

	useEffect( () => {
		if ( employeeCode ) {
			setSelectedProjectData( {
				projectNumber: expenseClaimToEdit?.projectNumber,
				subProjectNumber: expenseClaimToEdit?.subProjectNumber?.toString(),
				projectPhaseNumber: expenseClaimToEdit?.projectPhaseNumber?.toString(),
				projectDescription: expenseClaimToEdit?.projectDescription?.toString(),
				subProjectDescription: expenseClaimToEdit?.descriptionSubProject?.toString(),
				projectPhaseDescription: expenseClaimToEdit?.descriptionProjectPhase?.toString(),
			} );

		}
	}, [ employeeCode, expenseClaimToEdit, activities ] );

	return {
		projectActivities: ( activities?.find(
			( a ) => a.employeeCode === employeeCode
		)?.projectActivities ),
		expenseClaimsCodesDescriptions,
		defaultExpenseClaimDescription,
		defaultExpenseClaimType,
		defaultExpenseClaimCode,
		isLoading,
		expenseClaimsCodes,
		codesCategory,
		setCodesCategory,
		isActiveDropDown,
		setIsActiveDropDown,
		projectData: selectedProjectData,
		setSelectedProjectData,
		descriptionExpenseClaimCode,
		setDescriptionExpenseClaimCode,
		date,
		setDate,
		employeeCode,
		expenseClaimToEdit,
		expenseClaimsYear,
		isLoadingExpenseClaim,
	};
};

export default useManageExpenseClaimsData;
