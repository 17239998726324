import { AccountInfo } from '@azure/msal-common';

import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import config from '../config-loader';

const LoginScope = {
	scopes: [
		config.apiScope
	]
}

const isIE = () => {
	const ua = window.navigator.userAgent;
	const msie = ua.indexOf( 'MSIE ' ) > -1;
	const msie11 = ua.indexOf( 'Trident/' ) > -1;

	// If you as a developer are testing using Edge InPrivate mode, please add 'isEdge' to the if check
	// const isEdge = ua.indexOf('Edge/') > -1;

	return msie || msie11;
};

const msalApp = new PublicClientApplication( {
	auth: {
		clientId: config.clientId,
		authority: `https://login.microsoftonline.com/${ config.tenantId }`,
		postLogoutRedirectUri: config.appUrl,
		navigateToLoginRequestUrl: false,
		redirectUri: `${ config.appUrl }/login_callback`
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: isIE(),
	},
	system: {
		loadFrameTimeout: 0
	}
} );

const redirectPromise = msalApp.handleRedirectPromise();

async function getTokenRedirect ( request: any ) {
	return await msalApp.acquireTokenSilent( request ).catch( async ( error ) => {
		console.log( "silent token acquisition fails." );
		if ( error instanceof InteractionRequiredAuthError ) {
			// fallback to interaction when silent call fails
			console.log( "acquiring token using redirect" );
			msalApp.acquireTokenRedirect( request );
		} else {
			console.log( 'Auth failed - no token' );
		}
	} );
}
const restoreRouteAfterRedirect = () => {
	const ictOnTimeLastRoute = sessionStorage.getItem( 'ictOnTimeLastRoute' );
	sessionStorage.removeItem( 'ictOnTimeLastRoute' );

	if ( ictOnTimeLastRoute ) {
		window.history.replaceState( undefined, '', ictOnTimeLastRoute );
	}
}

export function signIn () {
	sessionStorage.setItem( 'ictOnTimeLastRoute', window.location.href );
	msalApp.loginRedirect( LoginScope );
}

export function signOut () {
	msalApp.logout();
}

export interface AuthorizationDetails {
	account: AccountInfo | null,
	accessToken: string,
}

export const getAuthorizationDetails = () => {
	return new Promise<AuthorizationDetails>( ( resolve, reject ) => {
		redirectPromise
			.then( ( redirectResponse ) => {
				if ( redirectResponse ) {
					const accounts = msalApp.getAllAccounts();
					const account = accounts.length ? accounts[ 0 ] : null;

					restoreRouteAfterRedirect();
					resolve( {
						account,
						accessToken: redirectResponse.accessToken,
					} );

					return;
				}

				getTokenRedirect( LoginScope )
					.then( ( tokenResponse ) => {
						const accounts = msalApp.getAllAccounts();
						const account = accounts.length ? accounts[ 0 ] : null;
						if ( accounts.length && tokenResponse ) {
							restoreRouteAfterRedirect();
							resolve( {
								account,
								accessToken: tokenResponse.accessToken
							} );
						}
						else {
							reject( {
								message: 'Problem loading account or token',
								account,
								tokenResponse
							} )
						}
					} )
					.catch( error => {
						console.log( error );
						reject( {
							message: 'Error during get token redirect',
							error
						} )
					} )
			} )
			.catch( error => {
				reject( {
					message: error.message,
					data: error
				} )
			} )
	} )
};
