import { Action, ActionType } from "../types/actions";
import { ExpenseClaimsSourceData } from "../types/api/expenseClaims";

export interface ExpenseClaimsSourcesState {
  expenseClaimsSourceData: ExpenseClaimsSourceData | null;
  isLoading: boolean;
}

export const expenseClaimsSourcesReducer = (
  state: ExpenseClaimsSourcesState = {
    expenseClaimsSourceData: null,
    isLoading: false,
  },
  action: Action
): ExpenseClaimsSourcesState => {
  switch (action.type) {
    case ActionType.GET_EXPENSE_CLAIMS_SOURCES_DATA_STARTED: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ActionType.GET_EXPENSE_CLAIMS_SOURCES_DATA_SUCCEEDED: {
      const { expenseClaimsSourceData } = action;
      return {
        ...state,
        expenseClaimsSourceData,
        isLoading: false,
      };
    }
    case ActionType.GET_EXPENSE_CLAIMS_SOURCES_DATA_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
