import React from 'react'

import { Box, FormControl, TextField, Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export interface TextBoxProps {
	label: string;
	customText?: string;
	value: string;
	onChange: ( value: string ) => void;
}

const useStyles = makeStyles( ( theme: Theme ) => createStyles( {
	formControl: {
		display: 'flex',
		flexDirection: 'row',
		boxShadow: `0px 1px 16px ${ theme.palette.custom.boxShadow }`,
		padding: theme.spacing( 2 ),
		[ theme.breakpoints.down( 'sm' ) ]: {
			fontSize: theme.spacing( 1 ),
		},
	},
	text: {
		[ theme.breakpoints.down( 'sm' ) ]: {
			fontSize: theme.spacing( 5 ),
		},
		flex: 1,
		'&::-webkit-input-placeholder': {
			color: theme.palette.custom.darkBlueGray,
			opacity: 1
		},
		'&::-ms-input-placeholder': {
			color: theme.palette.custom.darkBlueGray,
			opacity: 1
		}

	}
} ) )

const TextBox: React.FC<TextBoxProps> = ( { label, value, onChange, customText } ) => {
	const classes = useStyles();

	return (
		<Box m={ 2 } p={ 2 }>
			<FormControl
				fullWidth={ true }
				size="medium"
				className={ classes.formControl }
			>
				<TextField
					placeholder={ customText || `Please enter a ${ label.toLowerCase() }` }
					className={ classes.text }
					value={ value }
					onChange={ ( e: any ) => onChange( e.target.value ) }
				/>
			</FormControl>
		</Box>
	)
}

export default TextBox;
