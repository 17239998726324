
import React from 'react';

import { Icon } from '@mui/material';
import { IconProps } from './interfaces';

const LeaveRequestIcon = ( { color, width, height, className }: IconProps ) => (
	<Icon className={ className }>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.22 26.53" stroke="currentColor" fill="currentColor" strokeWidth="0" width={ width } height={ height }>
			<g id="bbef2c43-753d-4a88-95ec-5c128dab348b" data-name="Protype">
				<path d="M22.62,5.72a.93.93,0,0,0-.93-.36L1.17,9.49a.94.94,0,0,0-.73.7,1,1,0,0,0,.35,1l4.39,3.28.18,5.84a.92.92,0,0,0,.63.86l.11,0,.18,0A.86.86,0,0,0,6.79,21l3.85-2.5L13.94,21a.91.91,0,0,0,.26.13.88.88,0,0,0,.51,0,.92.92,0,0,0,.61-.48l7.38-14A1,1,0,0,0,22.62,5.72ZM5.73,13.59h0L1.51,10.44l17.91-3.6Zm.59,5.51-.14-4.62,10.75-5.3L8.05,15.51a.59.59,0,0,0-.23.31Zm.89.46,1.23-2.69,1.35,1Zm7.27.58L11,17.51l-.27-.21L9,16.05,21.25,7.32Z" />
			</g>
		</svg>
	</Icon>
);

export default LeaveRequestIcon;


