import { useSelector } from 'react-redux';
import { languageOptions } from '../components/screens/Settings/Settings';

import { AppState } from '../reducers';

const useLanguage = () => {
	const selectedLanguage = useSelector(
		( state: AppState ) => state.settingsState.settings.language
	);

	const browserLanguage = languageOptions.includes( navigator.language ) ? navigator.language : 'en'

	return selectedLanguage ? selectedLanguage : browserLanguage;
};

export default useLanguage;
