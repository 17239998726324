import React, { useState, useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { Box, Typography, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import intLocale from 'date-fns/locale/en-IN';

import { DateTime } from 'luxon';

import { defaultStartDate, defaultEndDate } from '../Leave/leave-utils';
import TimeInput from './TimeInput';
import {
	days,
	convertDecimalToTimeString,
	getWeekdayDate,
	Weekday,
} from '../../../utils/date';
import { LeaveRequest } from '../../../types/api/leaveRequests';
import useTranslation from '../../../hooks/useTranslation';
import { useSelector } from 'react-redux';
import { AppState } from '../../../reducers';
import OverlayLoader from '../../OverlayLoader';

const useStyles = makeStyles( ( theme ) => ( {
	dialog: {
		'& .MuiDialog-paper': {
			backgroundColor: theme.palette.background.paper,
			backgroundImage: 'none',
		},
		'& .MuiDialogActions-root': {
			display: 'none'
		}
	},
	formRow: {
		display: 'flex',
		padding: theme.spacing( 1, 2 ),
	},
	label: {
		display: 'flex',
		alignItems: 'center',
		flex: 1,
	},
	value: {
		flex: 1,
		overflow: 'hidden',
		textAlign: 'right',
	},
	pickerContainer: {
		flex: 1,
		overflow: 'hidden',
		textAlign: 'right',
	},
	comment: {
		padding: theme.spacing( 0, 1 ),
		backgroundColor: theme.palette.custom.paper,
		borderRadius: theme.spacing( 0.25 ),
	},
	picker: {
		padding: theme.spacing( 0, 1 ),
		'& .MuiInputBase-input ': {
			textAlign: 'right',
		},
	},
	timeLabel: {
		textTransform: 'capitalize',
	},
} ) );

export interface ManageLeaveRequestFormProps {
	onSubmit: ( data: any ) => void;
	period: '(part of day)' | 'Week' | 'Period';
	leaveParams?: LeaveRequest;
}

const getWeekDay = ( date: DateTime ) => {
	if ( date.weekday === 7 ) {
		return date.plus( { day: 1 } );
	}
	if ( date.weekday === 6 ) {
		return date.plus( { day: 2 } );
	}
	return date;
};

const ManageLeaveRequestForm: React.FC<ManageLeaveRequestFormProps> = ( {
	onSubmit,
	period,
	leaveParams,
} ) => {
	const classes = useStyles();
	const [ isChangingData, setIsChangingData ] = useState<boolean>( false );

	const publicHolidays = useSelector<
		AppState,
		Array<{ day: Weekday; dateMillis: number }>
	>( ( state ) => {
		return state.publicHolidaysState.publicHolidays[ 0 ]?.publicHolidays.map(
			( holiday ) => {
				const dateTimeDate = DateTime.fromJSDate( new Date( holiday.date ) );
				const day = days[ dateTimeDate.weekday - 1 ];

				return { dateMillis: dateTimeDate.toMillis(), day };
			}
		);
	} );

	const [ startDate, setStartDate ] = useState<MaterialUiPickersDate>( null );
	const [ endDate, setEndDate ] = useState<MaterialUiPickersDate>( null );

	useEffect( () => {
		if ( period === 'Week' && !leaveParams ) {
			setStartDate( ( date: MaterialUiPickersDate ) => {
				setEndDate( date?.endOf( 'week' ) || null );
				return date?.startOf( 'week' ) || null;
			} );
		}
	}, [ period, leaveParams ] );

	useEffect( () => {
		if ( period === 'Week' && startDate ) {
			setEndDate( startDate.endOf( 'week' ) );
		}
	}, [ period, startDate, leaveParams ] );

	useEffect( () => {
		setIsChangingData( true );
		setTimeout( () => { setIsChangingData( false ) }, 300 )
	}, [ leaveParams?.id ] );

	const disableDays = useCallback(
		( data: DateTime | Date ) => {
			const date = DateTime.fromJSDate( new Date( data as any ) )
			if ( date?.weekday === 6 || date?.weekday === 7 ) {
				return true;
			}
			if (
				date &&
				( publicHolidays || [] )
					.map( ( day ) => day.dateMillis )
					.includes( date.startOf( 'day' ).toMillis() )
			) {
				return true;
			}

			if (
				date &&
				DateTime.fromJSDate( new Date() ).startOf( 'day' ).toMillis() ===
				date.startOf( 'day' ).toMillis()
			) {
				return true;
			}
			return false;
		},
		[ publicHolidays ]
	);

	useEffect( () => {
		if ( leaveParams ) {
			const leaveDays = days.filter( ( day ) => Number( leaveParams[ day ] ) > 0 );

			const start = DateTime.fromJSDate( new Date( leaveParams.startDateOfWeek ) );
			const end = DateTime.fromJSDate( new Date( leaveParams.endDateOfWeek ) );

			const customStartDate = leaveDays.length
				? getWeekdayDate( start, leaveDays[ 0 ] )
				: start;

			const customEndDate = leaveDays.length
				? getWeekdayDate( end, leaveDays[ leaveDays.length - 1 ] )
				: end;

			setStartDate( customStartDate );
			setEndDate( customEndDate );
		}
	}, [ leaveParams ] );

	const onSetDateRange = useCallback(
		(
			date: MaterialUiPickersDate,
			setDate: ( date: MaterialUiPickersDate ) => void
		) => {
			if ( date && period === 'Week' ) {
				const startDateOfWeek =
					date.startOf( 'week' ).toMillis() < defaultStartDate.toMillis()
						? defaultStartDate
						: date.startOf( 'week' );
				const endDateOfWeek = date.endOf( 'week' );

				setStartDate( startDateOfWeek );
				setEndDate( endDateOfWeek );
			} else {
				setDate( date );
			}
		},
		[ period ]
	);

	const onSetStartDate = useCallback(
		( date: MaterialUiPickersDate ) => {
			if (
				date &&
				endDate &&
				period === 'Period' &&
				date.toMillis() >= endDate?.toMillis()
			) {
				setEndDate( date.plus( { day: 1 } ) );
			}
			onSetDateRange( date, setStartDate );
		},
		[ period, endDate, onSetDateRange ]
	);

	const onSetEndDate = useCallback(
		( date: MaterialUiPickersDate ) => {
			if (
				date &&
				startDate &&
				period === 'Period' &&
				date.toMillis() <= startDate?.toMillis()
			) {
				const start =
					date.toMillis() > defaultStartDate.toMillis()
						? date.minus( { day: 1 } )
						: date;
				setStartDate( start );
			}
			if ( date ) {
				const end =
					date.toMillis() >= defaultEndDate.toMillis() ? date : defaultEndDate;
				onSetDateRange( end, setEndDate );
			}
		},
		[ period, startDate, onSetDateRange ]
	);

	const getStartDate = useCallback(
		( date?: DateTime ): DateTime => {
			const initialDate = getWeekDay(
				date || DateTime.fromJSDate( new Date() ).startOf( 'day' ).plus( { day: 1 } )
			);
			if (
				( publicHolidays || [] )
					.map( ( holiday ) => holiday.dateMillis )
					.includes( initialDate.toMillis() )
			) {
				return getStartDate( initialDate.plus( { day: 1 } ) );
			}
			onSetStartDate( initialDate );
			return initialDate;
		},
		[ publicHolidays, onSetStartDate ]
	);

	useEffect( () => {
		if ( period === 'Period' && !leaveParams ) {
			getStartDate( startDate || undefined );
		}
	}, [ period, startDate, leaveParams, getStartDate ] );

	const { register, handleSubmit } = useForm();

	const onFormSubmit = handleSubmit( ( data: any ) => {
		if ( period === 'Period' ) {
			days.forEach( ( day ) => delete data[ day ] );
			delete data[ 'hours' ];
		}
		onSubmit( {
			...data,
			id: leaveParams?.id,
			startDate: startDate?.startOf( 'day' ),
			endDate:
				period !== '(part of day)'
					? endDate?.startOf( 'day' )
					: startDate?.startOf( 'day' ),
		} );
	} );

	const t = useTranslation();

	const currentDay = DateTime.fromJSDate( new Date() ).startOf( 'day' );
	const excludedDays = [
		...days.filter( ( day, weekDayNumber ) => {
			if (
				startDate &&
				currentDay.weekNumber === startDate.weekNumber &&
				currentDay.weekday >= weekDayNumber + 1
			) {
				return true;
			}
			if ( day === 'sunday' || day === 'saturday' ) {
				return true;
			}
			return false;
		} ),
		...( publicHolidays || [] )
			.filter( ( holiday ) => {
				if (
					startDate &&
					endDate &&
					holiday.dateMillis >= startDate.startOf( 'week' ).toMillis() &&
					holiday.dateMillis <= endDate.toMillis()
				) {
					return true;
				}
				return false;
			} )
			.map( ( day ) => day.day ),
	];

	if ( isChangingData ) {
		return <OverlayLoader message={ 'Loading' } loadingCondition />;
	}

	return (
		<form onSubmit={ onFormSubmit } id="manage-leave-request">
			<Box className={ classes.formRow }>
				<Box className={ classes.label }>
					<Typography>
						{ period !== '(part of day)' ? t( 'Start Date' ) : t( 'Date' ) }
					</Typography>
				</Box>
				<Box className={ classes.pickerContainer }>
					<LocalizationProvider dateAdapter={ AdapterDateFns } adapterLocale={ intLocale } >
						<MobileDatePicker
							value={ startDate || leaveParams ? startDate : getStartDate() }
							onChange={ ( value: any ) => {
								onSetStartDate( DateTime.fromJSDate( new Date( value ) ) )
							} }
							inputFormat={ 'dd-MMM yyyy' }
							minDate={
								leaveParams
									? new Date( leaveParams.startDateOfWeek )
									: defaultStartDate
							}
							renderInput={ ( params ) => <TextField { ...params } variant='standard' className={ classes.picker } /> }
							closeOnSelect
							shouldDisableDate={ disableDays }
							showToolbar={ false }
							DialogProps={ { classes: { root: classes.dialog } } }
							disableHighlightToday
						/>
					</LocalizationProvider>

				</Box>
			</Box>
			{ period !== '(part of day)' && (
				<Box className={ classes.formRow }>
					<Box className={ classes.label }>
						<Typography>{ t( 'End Date' ) }</Typography>
					</Box>
					<Box className={ classes.value }>
						<LocalizationProvider dateAdapter={ AdapterDateFns } adapterLocale={ intLocale }>
							<MobileDatePicker
								value={ endDate }
								onChange={ ( value: any ) => {
									onSetEndDate( DateTime.fromJSDate( new Date( value ) ) )
								} }
								inputFormat={ 'dd-MMM yyyy' }

								minDate={
									leaveParams
										? new Date( leaveParams.startDateOfWeek )
										: defaultStartDate
								}
								renderInput={ ( params ) => <TextField { ...params } variant='standard' className={ classes.picker } /> }
								closeOnSelect
								shouldDisableDate={ disableDays }
								showToolbar={ false }
								DialogProps={ { classes: { root: classes.dialog } } }
							/>
						</LocalizationProvider>
					</Box>
				</Box>
			) }
			<Box position="relative">
				{ period === 'Week' &&
					days
						.filter( ( d ) => !excludedDays.includes( d ) )
						.map( ( day ) => {
							const defaultTimeValue = leaveParams
								? convertDecimalToTimeString(
									!isNaN( Number( leaveParams[ day ] ) )
										? Number( leaveParams[ day ] )
										: 0
								)
								: undefined;

							return (
								<Box className={ classes.formRow } key={ day }>
									<Box className={ classes.label }>
										<Typography className={ classes.timeLabel }>
											{ t( day ) }
										</Typography>
									</Box>
									<TimeInput
										name={ day }
										inputClass={ classes.picker }
										containerClass={ classes.value }
										inputRef={ register }
										defaultValue={ defaultTimeValue }
									/>
								</Box>
							);
						} ) }
				{ period === '(part of day)' && (
					<Box className={ classes.formRow }>
						<Box className={ classes.label }>
							<Typography>{ t( 'Hours' ) }</Typography>
						</Box>
						<TimeInput
							name="hours"
							inputClass={ classes.picker }
							containerClass={ classes.value }
							inputRef={ register }
							defaultValue={
								leaveParams
									? convertDecimalToTimeString(
										!isNaN( Number( leaveParams.numberOfHours ) )
											? Number( leaveParams.numberOfHours )
											: 0
									)
									: undefined
							}
						/>
					</Box>
				) }
			</Box>
			<Box className={ classes.formRow }>
				<Box className={ classes.label }>
					<Typography>{ t( 'Comment' ) }</Typography>
				</Box>
				<Box className={ classes.value }>
					<TextField
						placeholder={ t( 'comment here' ) }
						name="description"
						inputRef={ register }
						className={ classes.comment }
						defaultValue={ leaveParams?.leaveDescription }
						inputProps={ { maxLength: '40' } }
						variant='standard'
					/>
				</Box>
			</Box>
		</form>
	);
};

export default ManageLeaveRequestForm;
