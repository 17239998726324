import { DateTime } from 'luxon';
import { roundToSecondDecimalDigit } from './math';
import { WeekRangeData } from '../actions/time-actions';

export const getWeekRange = ( date: Date ): WeekRangeData => {
	return {
		from: DateTime.fromJSDate( date ).startOf( 'week' ).toJSDate(),
		to: DateTime.fromJSDate( date ).endOf( 'week' ).toJSDate(),
	};
};

export const getWeekDays = ( date: Date ) => {
	const weekStart = getWeekRange( date ).from;
	return [ ...Array( 7 ) ].map( ( _, i ) =>
		DateTime.fromJSDate( weekStart ).plus( { days: i } ).toJSDate()
	);
};

export const getDateOfISOWeek = ( w: number, y: number ) => {
	var simple = new Date( y, 0, 1 + ( w - 1 ) * 7 );
	var dow = simple.getDay();
	var weekStart = simple;
	if ( dow <= 4 ) {
		weekStart.setDate( simple.getDate() - simple.getDay() + 1 );
	} else {
		weekStart.setDate( simple.getDate() + 8 - simple.getDay() );
	}

	return weekStart;
};

export const convertMinutesToTimeString = ( minutes: number ) => {
	const resultMinutes = minutes % 60;
	const hours = ( minutes - resultMinutes ) / 60;
	return formatTime( hours, resultMinutes, ':' );
};

export const convertDecimalToTimeString = ( decimalTime: number ) => {
	const time = Math.round( decimalTime * 60 );
	const convertedHours = Number( decimalTime.toString().split( '.' )[ 0 ] );
	const convertedMinutes = time % 60;
	return formatTime( convertedHours, Math.abs( Number( convertedMinutes ) ), ':' );
};

export const getDecimalTimeString = ( time: string, splitter: string ) => {
	const timeString: string = time.split( '_' ).join( '0' );
	const hours = timeString.split( splitter )[ 0 ];
	if ( splitter === ':' ) {
		const minutes = Number( timeString.split( ':' )[ 1 ] )
			? ( parseFloat( timeString.split( ':' )[ 1 ] ) / 60 ).toString().split( '.' )[ 1 ]
			: '00';
		return roundToSecondDecimalDigit( hours + '.' + minutes ).toFixed( 2 );
	}
	const minutes = timeString.split( '.' )[ 1 ] ? timeString.split( '.' )[ 1 ] : '00';
	return formatTime( Number( hours ), Number( minutes ), '.' );
};

export const formatTime = (
	hours: number,
	minutes: number,
	minutesHoursSplitter: string
) => {
	const result: any = { hours, minutes };

	if ( Math.abs( hours ) <= 9 ) {
		const prefix = hours >= 0 ? '0' : '-0';
		result.hours = prefix + Math.abs( hours );
	}

	if ( minutes <= 9 ) {
		result.minutes = '0' + minutes;
	}

	return `${
		result.hours
		}${ minutesHoursSplitter }${ result.minutes.toString().slice( 0, 2 ) }`;
};

export const getSmallestTimeFromISOString = ( isoDates: string[] ) => {
	const smallestDateTimeMillis = isoDates.reduce(
		( smallestDateTimeMillisSoFar, nextDate ) => {
			let nextDateMillis = Date.parse( nextDate ).valueOf();
			if ( nextDateMillis < smallestDateTimeMillisSoFar ) {
				return nextDateMillis;
			}
			return smallestDateTimeMillisSoFar;
		},
		Infinity
	);

	return smallestDateTimeMillis;
};

export const compareDatesAsISODates = (
	firstDate: Date,
	secondDate: Date
): boolean => {
	return (
		DateTime.fromJSDate( firstDate ).toISODate() ===
		DateTime.fromJSDate( secondDate ).toISODate()
	);
};

export const getNextMonthStartDate = ( date: Date ) => {
	return DateTime.fromJSDate( date ).plus( { months: 1 } ).toJSDate();
};

export const getPreviousMonthStartDate = ( date: Date ) => {
	return DateTime.fromJSDate( date ).minus( { months: 1 } ).toJSDate();
};

export const getNextWeekRange = (
	numberOfWeeks: number,
	date: Date
): WeekRangeData => {
	const days = 7 * numberOfWeeks;
	date.setDate( date.getDate() + days );
	const weekRange = getWeekRange( date );
	return weekRange;
};

export type Weekday =
	| 'monday'
	| 'tuesday'
	| 'wednesday'
	| 'thursday'
	| 'friday'
	| 'saturday'
	| 'sunday';

export const days: Array<Weekday> = [
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
	'sunday',
];

export const getWeekdayDate = ( startOfWeek: DateTime, weekday: Weekday ) => {
	switch ( weekday ) {
		case 'monday':
			return startOfWeek;
		case 'tuesday':
			return startOfWeek.plus( { day: 1 } );
		case 'wednesday':
			return startOfWeek.plus( { day: 2 } );
		case 'thursday':
			return startOfWeek.plus( { day: 3 } );
		case 'friday':
			return startOfWeek.plus( { day: 4 } );
		case 'saturday':
			return startOfWeek.plus( { day: 5 } );
		case 'sunday':
			return startOfWeek.plus( { day: 6 } );
	}
};

export const getYearRange = ( year: number ) => {
	const yearDateTime = DateTime.fromObject( { year } );
	const yearRange = {
		from: yearDateTime.startOf( 'year' ).toJSDate(),
		to: yearDateTime.endOf( 'year' ).toJSDate(),
	};
	return yearRange;
}
